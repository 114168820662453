import { connect } from 'react-redux';

import ProtectedSystemCard from '../components/ProtectedSystemCard';
import { protectedSystemsSystems } from '../store/reducers';
import { fetchProtectedSystems } from '../store/reducers/protectedSystems/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    systems: protectedSystemsSystems(state),
    systemId: ownProps.match.params.systemId,
    ...ownProps,
  };
};

const mapDispatchToProps = {
  fetchSystems: fetchProtectedSystems,
};

const ProtectedSystemCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProtectedSystemCard);
export default ProtectedSystemCardContainer;
