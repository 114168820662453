import * as React from 'react';
import AnimateHeight from 'react-animate-height';
import { createPortal } from 'react-dom';
import isEqual from 'react-fast-compare';
import { Translate } from 'react-redux-i18n';
import { CSSTransition } from 'react-transition-group';

import {
  DeleteOutlined,
  MinusOutlined,
  PlusOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { Progress } from 'antd';

import './DownloadNotification.scss';

export class DownloadNotification extends React.Component {
  constructor(props) {
    super(props);

    const hasDownloads = Object.keys(this.props.downloads).length > 0;

    this.state = {
      isClosed: false,
      isShow: hasDownloads,
    };

    this.timeout = 500;
    this.timeoutId = undefined;

    this.parent = document.createElement('div');
    if (hasDownloads) {
      document.body.appendChild(this.parent);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  componentDidUpdate(prevProps) {
    const [prev, curr] = [
      Object.keys(prevProps.downloads).length,
      Object.keys(this.props.downloads).length,
    ];

    if (prev === 0 && curr !== 0) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      } else {
        document.body.appendChild(this.parent);
      }

      this.setState({ isShow: true });
    }
    if (curr === 0 && prev !== 0) {
      this.setState({ isShow: false }, () => {
        this.timeoutId = setTimeout(() => {
          document.body.removeChild(this.parent);
          this.timeoutId = undefined;
        }, this.timeout);
      });
    }
  }

  switchState = (e) => {
    e.preventDefault();

    this.setState({
      isClosed: !this.state.isClosed,
    });
  };

  delete = (endpoint) => (event) => {
    event.preventDefault();

    this.props.remove(endpoint);
  };

  retry = (endpoint, filename) => (event) => {
    event.preventDefault();
    this.props.downloadFile(endpoint, filename);
  };

  render() {
    const { downloads } = this.props;
    const { isClosed } = this.state;

    const downloadsLinks = Object.keys(downloads);
    let header = '';
    if (downloadsLinks.length) {
      header =
        downloadsLinks.length > 1 ? (
          <Translate
            value="downloadNotification.countHeader"
            count={downloadsLinks.length}
          />
        ) : (
          <Translate
            value="downloadNotification.filenameHeader"
            filename={downloads[downloadsLinks[0]].filename}
          />
        );
    }

    const body = (
      <CSSTransition
        classNames="notification-fade"
        in={this.state.isShow}
        timeout={this.timeout}
        appear
        enter
        exit
        mountOnEnter
        unmountOnExit
      >
        <div className="download-notification">
          <div className="download-notification-header">{header}</div>
          <AnimateHeight
            duration={500}
            height={isClosed ? 0 : 'auto'}
            className="download-notification-content"
          >
            {downloadsLinks.map((link) => {
              const { filename, percent, status } = downloads[link];
              return (
                <span key={link} style={{ position: 'relative' }}>
                  <div className="filename">{filename}:</div>
                  {status === 'exception' && (
                    <span className="controls">
                      <ReloadOutlined onClick={this.retry(link, filename)} />
                      <DeleteOutlined onClick={this.delete(link)} />
                    </span>
                  )}
                  <Progress percent={percent} status={status} />
                </span>
              );
            })}
          </AnimateHeight>

          <div className="download-notification-btn" onClick={this.switchState}>
            {isClosed ? <PlusOutlined /> : <MinusOutlined />}
          </div>
        </div>
      </CSSTransition>
    );

    return createPortal(body, this.parent);
  }
}

DownloadNotification.defaultProps = {
  downloads: {},
};
