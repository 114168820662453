import React, { FC, memo } from 'react';
import { Link } from 'react-router-dom';

import { Flex, Text, Tooltip } from 'combinezone/core/index';

import { HeaderProps } from '../types';

const Header: FC<HeaderProps> = ({
  caption,
  captionTooltip,
  createdDate,
  id,
  idRule,
}) => {
  return (
    <div className="Header">
      <Flex>
        <Link className="HeadingM" to={`/rule/${id}`}>
          {idRule}
        </Link>
        <div className="Separator" />
        <Tooltip content={captionTooltip || null} position="top">
          <Link className="HeadingM-Link" to={`/rule/${id}`}>
            <Text className="HeadingM" isClipped>
              {caption}
            </Text>
          </Link>
        </Tooltip>
      </Flex>
      <Text className="Caption">{createdDate}</Text>
    </div>
  );
};

export default memo(Header);
