import { RSAA } from 'redux-api-middleware';

import { withAuth } from '..';
import { encodeReportsFilter, REPORTS_ENDPOINT } from '../../../services/api';

import {
  DELETE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  FIRST_FAILURE,
  FIRST_REQUEST,
  FIRST_SUCCESS,
  MORE_FAILURE,
  MORE_REQUEST,
  MORE_SUCCESS,
} from './actionTypes';

export const firstFetch = ({ filters = {}, sort = {} } = {}) => ({
  [RSAA]: {
    endpoint: `${REPORTS_ENDPOINT}?${encodeReportsFilter(filters, sort)}`,
    method: 'GET',
    headers: withAuth({}),
    types: [FIRST_REQUEST, FIRST_SUCCESS, FIRST_FAILURE],
  },
});

export const fetchMore = ({ filters = {}, sort = {} } = {}, page) => ({
  [RSAA]: {
    endpoint: `${REPORTS_ENDPOINT}?${encodeReportsFilter(
      filters,
      sort,
    )}&page=${page}`,
    method: 'GET',
    headers: withAuth({}),
    types: [
      MORE_REQUEST,
      MORE_SUCCESS,
      {
        type: MORE_FAILURE,
        meta: { showNotification: false },
      },
    ],
  },
});

export const deleteReport = (body) => {
  return {
    [RSAA]: {
      endpoint: `${REPORTS_ENDPOINT}delete-multiple/`,
      method: 'POST',
      body: JSON.stringify(body),
      headers: withAuth({ 'Content-Type': 'application/json' }),
      types: [
        {
          type: DELETE_REQUEST,
          meta: body,
        },
        {
          type: DELETE_SUCCESS,
          meta: body,
        },
        {
          type: DELETE_FAILURE,
          meta: body,
        },
      ],
    },
  };
};
