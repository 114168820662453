import { useMemo } from "react";

import { useTranslate } from "combinezone/utils";

import locales from "./locales";

type UseNewFilters = () => {
  [k: string]: {
    label: string;
    type: string;
    getEndpoint: (searchString: string) => string;
    handleResult: (response: any) => { key: string; text: string }[];
  };
};

export const useNewFilters: UseNewFilters = () => {
  const t = useTranslate(locales);

  return useMemo(
    () => ({
      sensors: {
        label: t("sensors"),
        type: "suggest",
        getEndpoint: (search = "") => {
          const trimmedSearch = search.trim();
          const searchParam = trimmedSearch
            ? `&name__icontains=${trimmedSearch}`
            : "";
          return `/soc/sensor/?page_size=7${searchParam}`;
        },
        handleResult: (response: {
          results: {
            description: string;
            id: number;
            info_url: null | string;
            name: string;
            sensor_type: number;
            vendor: number;
          }[];
        }) =>
          response.results.map((item) => ({
            key: item.name,
            text: item.name,
          })),
      },
      sensor_types: {
        label: t("type"),
        type: "suggest",
        getEndpoint: (search = "") => {
          const trimmedSearch = search.trim();
          const searchParam = trimmedSearch
            ? `&name__icontains=${trimmedSearch}`
            : "";
          return `/soc/sensor_type/?page_size=7${searchParam}`;
        },
        handleResult: (response: {
          results: {
            description: string;
            id: number;
            name: string;
          }[];
        }) =>
          response.results.map((item) => ({
            key: item.name,
            text: item.name,
          })),
      },
      sensor_modules: {
        label: t("module"),
        type: "suggest",
        getEndpoint: (search = "") => {
          const trimmedSearch = search.trim();
          const searchParam = trimmedSearch
            ? `&name__icontains=${trimmedSearch}`
            : "";
          return `/soc/sensor_module/?page_size=7${searchParam}`;
        },
        handleResult: (response: {
          results: {
            description: string;
            id: number;
            name: string;
          }[];
        }) =>
          response.results.map((item) => ({
            key: item.name,
            text: item.name,
          })),
      },
      installed_sensors__system: {
        label: t("rulesAvailable"),
        type: "suggest",
        getEndpoint: (search = "") => {
          const trimmedSearch = search.trim();
          const searchParam = trimmedSearch ? `&search=${trimmedSearch}` : "";
          return `/soc/systems/?page_size=7${searchParam}`;
        },
        handleResult: (response: {
          results: {
            is_id: string;
          }[];
        }) =>
          response.results.map((item) => ({
            key: item.is_id,
            text: item.is_id,
          })),
      },
    }),
    [t]
  );
};
