import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { Button, useToast, Text } from 'combinezone/core';

import {
  DownloadControlProps,
  RootState,
} from 'shared/components/DownloadControl/types';
import { downloadFile } from 'store/reducers/fileDownloader/actions';
import {
  clearedNotification,
  sentByEmailCsv,
} from 'store/reducers/sentByEmail/actions';

const DownloadControl: FC<DownloadControlProps> = ({
  endpoint,
  endpointAsync,
  fileName,
  format,
  isDisabled,
  testId,
}) => {
  const dispatch = useDispatch();
  const { toastSuccess } = useToast();
  const emailNotification = useSelector(
    (state: RootState) => state.sentByEmail.sentByEmailNotification,
  );

  useEffect(() => {
    if (emailNotification) {
      toastSuccess({
        title: () => (
          <Text variant="placeholder">
            {I18n.t('download.notifications.success')}
          </Text>
        ),
      });

      dispatch(clearedNotification());
    }
  }, [emailNotification, dispatch, clearedNotification]);

  const onClickDownloadHandler = useCallback((): void => {
    dispatch(downloadFile(endpoint, fileName)).then((result) => {
      if (result.error && result.payload.response.max_records_count_exceeded) {
        dispatch(sentByEmailCsv(endpointAsync));
      }
    });
  }, [dispatch, endpoint, downloadFile, fileName]);

  return (
    <Button
      testId={testId}
      onClick={onClickDownloadHandler}
      isDisabled={isDisabled}
    >
      {I18n.t(`download.buttons.${format}.text`)}
    </Button>
  );
};

export default DownloadControl;

DownloadControl.displayName = 'DownloadControl';
