import * as React from 'react';

import { bemCls } from '@common/soc-react-kit';

import './ReadMore.scss';

export default class ReadMore extends React.Component {
  state = {
    isExpanded: false,
    isExpandable: true,
  };

  toggleExpanded = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.children !== this.props.children) {
      if (this.container.scrollHeight > this.props.maxHeight) {
        this.setState({ isExpandable: true });
      } else {
        this.setState({ isExpandable: false });
      }
    }
  }

  render() {
    const { children, less, maxHeight, more } = this.props;
    const maxHeightContainer =
      maxHeight > 66 ? maxHeight - (maxHeight % 21) + 4 : 66;
    const className = bemCls('ReadMoreContainer', {
      collapsed: !this.state.isExpanded,
    });
    return (
      <div>
        <div
          className={className}
          ref={(ref) => (this.container = ref)}
          style={
            !this.state.isExpanded ? { maxHeight: maxHeightContainer } : {}
          }
        >
          {children}
        </div>
        {this.state.isExpandable ? (
          <div className="ReadMoreBtn" onClick={this.toggleExpanded}>
            {' '}
            <span>{this.state.isExpanded ? less : more}</span>
          </div>
        ) : null}
      </div>
    );
  }
}
