import React, { FC, memo } from 'react';
import { Translate } from 'react-redux-i18n';

import { Flex, Text, Tooltip } from 'combinezone/core/index';

import { CategoriesProps } from '../types';

const Categories: FC<CategoriesProps> = ({ categories, id, isNeedTooltip }) => (
  <div className="Categories">
    <Text className="Caption">
      <Translate value="rulePage.category" />
    </Text>
    <Flex className="Tag-Content">
      {categories?.map((el) => (
        <Tooltip
          key={`${id}_${el}`}
          content={isNeedTooltip({ text: el, maxLength: 19 }) || null}
          position="top"
        >
          <Text
            testId={`category_${el}`}
            className="Badge Badge-InnerText Badge-Border"
            isClipped
          >
            {el}
          </Text>
        </Tooltip>
      ))}
    </Flex>
  </div>
);

export default memo(Categories);
