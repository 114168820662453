import React from 'react';

import styled from 'styled-components';

import { Flex } from 'combinezone/core';

export const LayoutList = styled(Flex).attrs(() => ({
  direction: 'column',
  justify: 'stretch',
}))`
  height: calc(100% - 70px);
`;

export const List = styled.div`
  position: relative;
  flex-grow: 1;
  min-height: 0;
  height: 100%;
  overflow: auto;
  width: 100%;
  padding: 10px 10px 0px 16px;
`;

export const OverlayBox = styled.div`
  position: relative;
  height: 100%;
  padding: 0;
  margin: 0 16px;
`;

export const SpinnerBox = styled(Flex).attrs(() => ({
  justify: 'center',
}))`
  margin: 16px;
`;

export const NoData = styled(Flex).attrs(() => ({
  justify: 'center',
  alignItems: 'center',
}))`
  height: calc(100vh - 150px);
`;
