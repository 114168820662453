import { RSAA } from 'redux-api-middleware';

import { reportsShedulesNextPage, withAuth } from '..';
import { REPORT_TASKS_ENDPOINT } from '../../../services/api';

import * as types from './actionTypes';

export const firstFetch = () => {
  const timestamp = Date.now();

  return (dispatch) => {
    dispatch({
      [RSAA]: {
        endpoint: `${REPORT_TASKS_ENDPOINT}`,
        method: 'GET',
        headers: withAuth({ 'Content-Type': 'application/json' }),
        types: [
          {
            type: types.FIRST_FETCH_REQUEST,
            meta: { timestamp },
          },
          {
            type: types.FIRST_FETCH_SUCCESS,
            meta: { timestamp },
          },
          {
            type: types.FIRST_FETCH_FAIL,
            meta: { timestamp },
          },
        ],
      },
    });
  };
};

export const fetchMore = () => {
  return (dispatch, getState) => {
    const page = reportsShedulesNextPage(getState());

    return dispatch({
      [RSAA]: {
        endpoint: `${REPORT_TASKS_ENDPOINT}?page=${page}`,
        method: 'GET',
        headers: withAuth({ 'Content-Type': 'application/json' }),
        types: [
          types.FETCH_MORE_REQUEST,
          types.FETCH_MORE_SUCCESS,
          {
            type: types.FETCH_MORE_FAIL,
            meta: { showNotification: false },
          },
        ],
      },
    });
  };
};

export const select = (reportShedule = {}) => ({
  type: types.SELECT_REPORTSHEDULE,
  meta: { reportShedule },
});
