import { RSAA } from 'redux-api-middleware';

import {
  logSourcesNextPage,
  withAuth,
  getFilterParams,
  logSourcesActiveForEndpoint,
} from '..';
import {
  LOGSOURCES_ENDPOINT,
  LOGSOURCES_CSV_ENDPOINT,
  SOURCES_ENTITY,
  LOGSOURCES_COUNTERS_ENDPOINT,
} from '../../../services/api';

import * as types from './actionTypes';

const fetchTotalCount = (params, dispatch) => {
  dispatch({
    [RSAA]: {
      endpoint: `${LOGSOURCES_COUNTERS_ENDPOINT}${params}`,
      method: 'GET',
      headers: withAuth({ 'Content-Type': 'application/json' }),
      types: [
        types.FETCH_ACTIVE_TOTAL_COUNT_REQUEST,
        types.FETCH_ACTIVE_TOTAL_COUNT_SUCCESS,
        types.FETCH_ACTIVE_TOTAL_COUNT_FAIL,
      ],
    },
  });
};

export const firstFetch = () => {
  const timestamp = Date.now();

  return (dispatch, getState) => {
    const params = getFilterParams({
      state: getState(),
      entity: SOURCES_ENTITY,
    });
    const active = logSourcesActiveForEndpoint(getState());
    fetchTotalCount(params, dispatch);

    dispatch({
      [RSAA]: {
        endpoint: `${LOGSOURCES_ENDPOINT}${params}${active}`,
        method: 'GET',
        headers: withAuth({ 'Content-Type': 'application/json' }),
        types: [
          {
            type: types.FIRST_FETCH_REQUEST,
            meta: { timestamp },
          },
          {
            type: types.FIRST_FETCH_SUCCESS,
            meta: { timestamp },
          },
          {
            type: types.FIRST_FETCH_FAIL,
            meta: { timestamp },
          },
        ],
      },
    });
  };
};

export const fetchMore = () => {
  return (dispatch, getState) => {
    const page = logSourcesNextPage(getState());
    const active = logSourcesActiveForEndpoint(getState());
    const params = getFilterParams({
      state: getState(),
      entity: SOURCES_ENTITY,
    });

    return dispatch({
      [RSAA]: {
        endpoint: `${LOGSOURCES_ENDPOINT}${params}&page=${page}${active}`,
        method: 'GET',
        headers: withAuth({ 'Content-Type': 'application/json' }),
        types: [
          types.FETCH_MORE_REQUEST,
          types.FETCH_MORE_SUCCESS,
          {
            type: types.FETCH_MORE_FAIL,
            meta: { showNotification: false },
          },
        ],
      },
    });
  };
};

export const loadCsv = () => {
  return (dispatch, getState) => {
    const params = getFilterParams({
      state: getState(),
      entity: SOURCES_ENTITY,
    });

    dispatch({
      [RSAA]: {
        endpoint: `${LOGSOURCES_CSV_ENDPOINT}${params}`,
        method: 'GET',
        headers: withAuth({ 'Content-Type': 'application/json' }),
        types: [
          types.LOAD_CSV_REQUEST,
          types.LOAD_CSV_SUCCESS,
          types.LOAD_CSV_FAIL,
        ],
      },
    });
  };
};

export const select = (source = {}) => ({
  type: types.SELECT_SOURCE,
  meta: { source },
});

export const setActiveForEndpoint = (payload = '') => ({
  type: types.ACTIVE_FOR_ENDPONT,
  payload,
});
