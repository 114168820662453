import React from 'react';
import { I18n } from 'react-redux-i18n';

import { Flex, Input, Button } from 'combinezone/core';
import { Search } from 'combinezone/icons';

import {
  SearchBlockProps,
  SearchBlockState,
} from 'components/LogSources/LogSourcesFilters/components/SearchBlock/types';

export default class SearchBlock extends React.Component<
  SearchBlockProps,
  SearchBlockState
> {
  state = {
    searchText: this.props.defaultText,
  };

  componentDidUpdate(prevProps: { defaultText: string }): void {
    const { defaultText = '' } = this.props;
    const { searchText = '' } = this.state;

    if (
      searchText.length === 0 &&
      defaultText.length !== 0 &&
      prevProps.defaultText !== defaultText
    ) {
      this.setState({
        searchText: defaultText,
      });
    }
  }

  onChangeSearch = (value: string): void => {
    const { searchText } = this.state;
    const { defaultText, onSearch } = this.props;

    if (!value.length && searchText.length && defaultText.length) {
      onSearch(value);
    }

    this.setState({
      searchText: value,
    });
  };

  onSearch = (): void => {
    const { onSearch } = this.props;
    const { searchText } = this.state;

    const trimmedText = searchText.trim();

    this.setState({
      searchText: trimmedText,
    });

    onSearch(trimmedText);
  };

  render(): JSX.Element {
    const { searchText } = this.state;
    const testIdSearchBlock = `${this.props.testId}-search-block`;

    return (
      <Flex fillWidth gap="15px">
        <Input
          placeholder={I18n.t('logSources.searchPlaceholder')}
          isClearable
          LeftIcon={Search}
          value={searchText}
          onChange={this.onChangeSearch}
          onEnter={this.onSearch}
          testId={`${testIdSearchBlock}-input`}
        />
        <Button
          onClick={this.onSearch}
          testId={`${testIdSearchBlock}-button`}
          variant="transparentWithBorder"
        >
          {I18n.t('incidentList.search.button')}
        </Button>
      </Flex>
    );
  }
}
