import { createAction } from 'redux-api-middleware';

import { ruleListNexPage, withAuth, getFilterParams } from '..';
import { RULE_ENDPOINT, RULES_ENTITY } from '../../../services/api';

import * as types from './actionTypes';

export const firstFetch = () => (dispatch, getState) => {
  const timestamp = Date.now();
  const params = getFilterParams({ state: getState(), entity: RULES_ENTITY });

  const action = createAction({
    endpoint: `${RULE_ENDPOINT}${params}`,
    method: 'GET',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      {
        type: types.FIRST_FETCH_REQUEST,
        meta: { timestamp },
      },
      {
        type: types.FIRST_FETCH_SUCCESS,
        meta: { timestamp },
      },
      {
        type: types.FIRST_FETCH_FAIL,
        meta: { timestamp },
      },
    ],
  });

  return dispatch(action);
};

export const fetchTotalCount = () =>
  createAction({
    endpoint: `${RULE_ENDPOINT}?page_size=1`,
    method: 'GET',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      types.GET_TOTAL_COUNT_REQUEST,
      types.GET_TOTAL_COUNT_SUCCESS,
      types.GET_TOTAL_COUNT_FAIL,
    ],
  });

export const fetchMore = () => (dispatch, getState) => {
  const state = getState();
  const page = ruleListNexPage(state);
  const { lastFetchTime } = state.ruleList;
  const params = getFilterParams({ state: getState(), entity: RULES_ENTITY });

  const action = createAction({
    endpoint: `${RULE_ENDPOINT}${params}&page=${page}`,
    method: 'GET',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      types.FETCH_MORE_REQUEST,
      {
        type: types.FETCH_MORE_SUCCESS,
        meta: { timestamp: lastFetchTime },
      },
      {
        type: types.FETCH_MORE_FAIL,
        meta: { showNotification: false },
      },
    ],
  });

  return dispatch(action);
};

export const fetchAnalytics = () => (dispatch, getState) => {
  const timestamp = Date.now();
  const params = getFilterParams({ state: getState(), entity: RULES_ENTITY });

  const action = createAction({
    endpoint: `${RULE_ENDPOINT}analytics_counters/${params}`,
    method: 'GET',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      {
        type: types.FETCH_ANALYTICS_REQUEST,
        meta: { timestamp },
      },
      {
        type: types.FETCH_ANALYTICS_SUCCESS,
        meta: { timestamp },
      },
      {
        type: types.FETCH_ANALYTICS_FAIL,
        meta: { timestamp },
      },
    ],
  });

  return dispatch(action);
};

export const fetchAnalyticsMitre = () => (dispatch, getState) => {
  const timestamp = Date.now();
  const params = getFilterParams({ state: getState(), entity: RULES_ENTITY });

  const action = createAction({
    endpoint: `${RULE_ENDPOINT}coverage/${params}`,
    method: 'GET',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      {
        type: types.FETCH_ANALYTICS_MITRE_REQUEST,
        meta: { timestamp },
      },
      {
        type: types.FETCH_ANALYTICS_MITRE_SUCCESS,
        meta: { timestamp },
      },
      {
        type: types.FETCH_ANALYTICS_MITRE_FAIL,
        meta: { timestamp },
      },
    ],
  });

  return dispatch(action);
};

export const selectTechnique = (technique) => ({
  type: types.SELECT_TECHNIQUE,
  payload: technique,
});
