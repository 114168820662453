export const FIRST_REQUEST = '@@reports/FIRST_REQUEST';
export const FIRST_SUCCESS = '@@reports/FIRST_SUCCESS';
export const FIRST_FAILURE = '@@reports/FIRST_FAILURE';

export const MORE_REQUEST = '@@reports/MORE_REQUEST';
export const MORE_SUCCESS = '@@reports/MORE_SUCCESS';
export const MORE_FAILURE = '@@reports/MORE_FAILURE';

export const DELETE_REQUEST = '@@reports/DELETE_REQUEST';
export const DELETE_SUCCESS = '@@reports/DELETE_SUCCESS';
export const DELETE_FAILURE = '@@reports/DELETE_FAILURE';
