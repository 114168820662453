import {
  DOWNLOAD_ERROR,
  DOWNLOAD_PROGRESS,
  DOWNLOAD_SUCCESS,
  REMOVE_FROM_DOWNLOAD,
  DOWNLOAD_EVENT_FAIL,
  DOWNLOAD_EVENT_SUCCESS,
  DOWNLOAD_START,
} from './actionTypes';

const initialState = {
  downloadList: {},
};

export default function fileDownloader(state = initialState, action = {}) {
  switch (action.type) {
    case DOWNLOAD_START: {
      if (action.error) {
        return { ...state };
      }
      return {
        ...state,
        downloadList: {
          ...state.downloadList,
          [action.meta.endpoint]: {
            filename: action.meta.filename,
            percent: 0,
            status: 'active',
          },
        },
      };
    }

    case DOWNLOAD_PROGRESS: {
      return {
        ...state,
        downloadList: {
          ...state.downloadList,
          [action.meta.endpoint]: {
            ...state.downloadList[action.meta.endpoint],
            percent: action.meta.percent,
          },
        },
      };
    }

    case DOWNLOAD_EVENT_FAIL:
    case DOWNLOAD_SUCCESS:
    case DOWNLOAD_EVENT_SUCCESS:
    case REMOVE_FROM_DOWNLOAD: {
      const newDownloads = { ...state.downloadList };

      if (action.meta) {
        delete newDownloads[action.meta.endpoint];
      }
      return {
        ...state,
        downloadList: {
          ...newDownloads,
        },
      };
    }

    case DOWNLOAD_ERROR: {
      return {
        ...state,
        downloadList: {
          ...state.downloadList,
          [action.meta.endpoint]: {
            ...state.downloadList[action.meta.endpoint],
            status: 'exception',
          },
        },
      };
    }

    default:
      return state;
  }
}
