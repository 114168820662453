export const FETCH_ASSET_CARD_INCIDENTS_REQUEST =
  '@@assetsPage/FETCH_ASSET_CARD_INCIDENTS_REQUEST';
export const FETCH_ASSET_CARD_INCIDENTS_SUCCESS =
  '@@assetsPage/FETCH_ASSET_CARD_INCIDENTS_SUCCESS';
export const FETCH_ASSET_CARD_INCIDENTS_FAILURE =
  '@@assetsPage/FETCH_ASSET_CARD_INCIDENTS_FAILURE';

export const FETCH_ASSET_CARD_MORE_INCIDENTS_REQUEST =
  '@@assetsPage/FETCH_ASSET_CARD_MORE_INCIDENTS_REQUEST';
export const FETCH_ASSET_CARD_MORE_INCIDENTS_SUCCESS =
  '@@assetsPage/FETCH_ASSET_CARD_MORE_INCIDENTS_SUCCESS';
export const FETCH_ASSET_CARD_MORE_INCIDENTS_FAILURE =
  '@@assetsPage/FETCH_ASSET_CARD_MORE_INCIDENTS_FAILURE';
