import { RSAA } from 'redux-api-middleware';

import { withAuth } from '..';
import { REPORT_TASKS_ENDPOINT, FILTERS_ENDPOINT } from '../../../services/api';

import * as types from './actionTypes';

export const cancelEditReportModal = () => ({
  type: types.EDIT_REPORT_MODAL_CANCEL,
});

export const openEditReportModal = () => {
  return (dispatch) => {
    dispatch(fetchSystems());

    return dispatch({
      type: types.EDIT_REPORT_MODAL_OPEN,
    });
  };
};

export const editReport = (body, id) => ({
  [RSAA]: {
    endpoint: `${REPORT_TASKS_ENDPOINT}${id}/`,
    method: 'PATCH',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      types.EDIT_REPORT_REQUEST,
      types.EDIT_REPORT_SUCCESS,
      types.EDIT_REPORT_FAIL,
    ],
    body: JSON.stringify(body),
  },
});

export const deleteReport = (id) => ({
  [RSAA]: {
    endpoint: `${REPORT_TASKS_ENDPOINT}${id}/`,
    method: 'DELETE',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      types.DELETE_REPORT_REQUEST,
      types.DELETE_REPORT_SUCCESS,
      types.DELETE_REPORT_FAIL,
    ],
  },
});

export const fetchSystems = () => ({
  [RSAA]: {
    endpoint: FILTERS_ENDPOINT,
    method: 'GET',
    headers: withAuth({}),
    types: [
      types.FETCH_SYSTEMS_REQUEST,
      types.FETCH_SYSTEMS_SUCCESS,
      types.FETCH_SYSTEMS_FAIL,
    ],
  },
});
