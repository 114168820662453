import React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import { Block } from '@bizone/ui-bundle/esm/Block';
import { Checkbox } from '@bizone/ui-bundle/esm/Checkbox';
import { Header } from '@bizone/ui-bundle/esm/Header';
import { IconButton } from '@bizone/ui-bundle/esm/IconButton';
import { Tooltip } from '@bizone/ui-bundle/esm/Tooltip';

import { bemCls } from '@common/soc-react-kit';
import { OverlaySpinner } from 'combinezone/core';
import PropTypes from 'prop-types';

import TechniqueCell from '../../containers/TechniqueCellContainer';

import './MitreWidget.scss';

export class MitreWidget extends React.Component {
  state = {
    isExpanded: false,
    hideMitre: true,
    mitreDataHidden: [],
  };

  componentDidMount() {
    const { fetchMitreMapping } = this.props;

    fetchMitreMapping();
  }

  componentDidUpdate(prevProps) {
    const { mitreData, mitreMapping } = this.props;
    const { mitreDataHidden } = this.state;

    if (
      (!mitreDataHidden.length && mitreData.length) ||
      prevProps.mitreData !== mitreData
    ) {
      const mitreDataHidden = this.hiddenWithBlankCounts(
        mitreMapping,
        mitreData,
      );
      this.setState({ mitreDataHidden });
    }
  }

  hiddenWithBlankCounts(mitreMapping, mitreDataPrev) {
    const mitreData = [...mitreDataPrev];
    for (let i = 0; i < mitreData.length; i++) {
      mitreData[i] = [...mitreDataPrev[i]];
    }
    for (let k = 0; k < Object.keys(mitreMapping).length; k++) {
      for (let i = 0; i < mitreData.length; i++) {
        for (let j = 0; j < mitreData.length; j++) {
          if (mitreData[j] && mitreData[j]) {
            if (mitreData[j][k] && mitreData[j][k].count === 0) {
              mitreData[j][k] = undefined;
            }
            if (mitreData[i][k] && mitreData[i][k].count === 0) {
              mitreData[i][k] = undefined;
            }
            if (
              !mitreData[j][k] &&
              mitreData[i][k] &&
              mitreData[i][k].count > 0
            ) {
              [mitreData[i][k], mitreData[j][k]] = [
                mitreData[j][k],
                mitreData[i][k],
              ];
            }
          }
        }
      }
    }
    return mitreData;
  }

  render() {
    const { mitreMapping, tactics, tacticsIds, typeCounts } = this.props;
    const { hideMitre, isExpanded, mitreDataHidden } = this.state;
    const mitreData = hideMitre ? mitreDataHidden : this.props.mitreData;

    const isLoading = Object.keys(mitreMapping).length === 0;

    return (
      <Block
        cls={bemCls('MitreWidget', { isExpanded })}
        title={<Header size={16}>{I18n.t('mitreWidget.title')}</Header>}
        headerControls={[
          <Checkbox
            key="checkbox"
            onChange={(isChecked) => this.setState({ hideMitre: isChecked })}
            checked={hideMitre}
            label={<Translate value="mitreWidget.hideMitreTechniques" />}
          />,
          <Tooltip
            key="tooltip"
            position={isExpanded ? 'bottom' : 'top'}
            inverted
            content={
              <div className="MitreWidget-Help">
                {I18n.t('mitreWidget.helpFrequency')}
                <div>
                  <div className={bemCls('Dot', { critical: true })} />
                  <span>{I18n.t('mitreWidget.critical')}</span>
                </div>
                <div>
                  <div className={bemCls('Dot', { high: true })} />
                  <span>{I18n.t('mitreWidget.high')}</span>
                </div>
                <div>
                  <div className={bemCls('Dot', { medium: true })} />
                  <span>{I18n.t('mitreWidget.medium')}</span>
                </div>
                <div>
                  <div className={bemCls('Dot', { low: true })} />
                  <span>{I18n.t('mitreWidget.low')}</span>
                </div>
                <div>
                  <div className={bemCls('Dot', { none: true })} />
                  <span>{I18n.t('mitreWidget.none')}</span>
                </div>
              </div>
            }
          >
            <IconButton basic icon="help" />
          </Tooltip>,
        ]}
        separateHeader
        expandable
        onExpand={(isExpanded) => this.setState({ isExpanded })}
      >
        {isLoading && <OverlaySpinner />}
        {!isLoading && (
          <table>
            <thead>
              <tr>
                {tacticsIds.map((id) => (
                  <th key={id}>{tactics[id].name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {mitreData.map((row, i) => (
                <tr key={i}>
                  {row.map((technique, j) => (
                    <TechniqueCell
                      key={`${i}-${j}`}
                      technique={technique}
                      tactic={tacticsIds[j]}
                    />
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Block>
    );
  }
}

MitreWidget.propTypes = {
  fetchMitreMapping: PropTypes.func,
  mitreData: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        count: PropTypes.number,
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
  ),
  mitreMapping: PropTypes.object,
  typeCounts: PropTypes.object,
};

MitreWidget.defaultProps = {
  mitreMapping: {},
  mitreData: [],
  typeCounts: {},
};
