import * as am4core from '@amcharts/amcharts4/core';

export const RED_COLOR = '#e04a3f';
export const BLUE_COLOR = '#209ace';
export const GRAY_COLOR = '#999999';
export const BLACK_COLOR = '#333333';

export const COLUMN_CONFIG = {
  xAxes: [
    {
      cursorTooltipEnabled: false,
      id: 'a111',
      type: 'DateAxis',
      dateFormats: {
        day: 'd MMMM',
      },
      periodChangeDateFormats: {
        day: 'd MMMM',
      },
      tooltipDateFormat: 'd MMMM (EEE)',
      renderer: {
        grid: {
          template: {
            disabled: true,
          },
        },
        labels: {
          template: {
            location: 0,
            fill: am4core.color(GRAY_COLOR),
          },
        },
      },
      startLocation: 0,
      endLocation: 0,
    },
  ],
  yAxes: [
    {
      cursorTooltipEnabled: false,
      strictMinMax: true,
      id: 'try1',
      type: 'ValueAxis',
      min: 0,
      renderer: {
        minGridDistance: 25,
        labels: {
          template: {
            fill: am4core.color(GRAY_COLOR),
          },
        },
        grid: {
          template: {
            stroke: am4core.color(GRAY_COLOR),
          },
        },
      },
    },
  ],
  series: [
    {
      type: 'ColumnSeries',
      stacked: true,
      sequencedInterpolation: true,
      dataFields: {
        dateX: 'date',
        valueY: 'value',
      },
      columns: {
        cursorOverStyle: am4core.MouseCursorStyle.pointer,
        interactionsEnabled: true,
      },
      tooltip: {
        getFillFromObject: false,
        background: {
          fill: BLACK_COLOR,
          fillOpacity: 0.9,
          strokeWidth: 0,
        },
      },
      strokeWidth: 0,
      fill: BLUE_COLOR,
      stroke: BLUE_COLOR,
      fillOpacity: 0.5,
      strokeOpacity: 0.5,
    },
  ],
  paddingTop: 25,
};
