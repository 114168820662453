import React, { useCallback, useEffect, useState, forwardRef } from 'react';

import { Textarea } from '@bizone/ui-bundle/esm/Textarea';
import { Tooltip } from '@bizone/ui-bundle/esm/Tooltip';

export const Comment = forwardRef(
  ({ 'data-__field': dataField, ...restProps }, ref) => {
    const [error, setError] = useState(false);
    const [isActive, setActive] = useState(false);

    const onFocus = useCallback(() => {
      setActive(true);
    }, []);

    const onBlur = useCallback(() => {
      setActive(false);
    }, []);

    useEffect(() => {
      setError(
        dataField?.errors
          ? dataField.errors.map(({ message }) => message).join('\n')
          : undefined,
      );
    }, [dataField]);

    return (
      <Tooltip show={isActive && error} inverted content={error}>
        <Textarea
          {...restProps}
          ref={ref}
          error={!!error}
          onFocus={onFocus}
          onBlur={onBlur}
          fluid
        />
      </Tooltip>
    );
  },
);
