import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { useHistory, useParams } from 'react-router-dom';

import styled from 'styled-components';

import { Flex, Tab, Tabs, Text } from 'combinezone/core';
import { CombinezoneTheme } from 'combinezone/theme';

import LogSourcesFilters from 'components/LogSources/LogSourcesFilters';
import LogSourcesList from 'components/LogSources/LogSourcesList';
import { LogSourcesTabs } from 'components/LogSources/enums';
import { TotalCountValue } from 'components/LogSources/types';
import { SOURCES_ENTITY } from 'services/api';
import { logSourcesActiveTotalCount } from 'store/reducers';
import {
  firstFetch,
  setActiveForEndpoint,
} from 'store/reducers/logSources/actions';

const LogSourcesContainer = styled(Flex)`
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
`;

const LogSourcesContentHeaderWrapper = styled(Flex)`
  width: 100%;
  justify-content: center;
  box-shadow: ${({ theme }: { theme: CombinezoneTheme }) =>
    `0px 4px 4px -4px ${theme.basis?.colors.shadows.default}`};
  background-color: ${({ theme }: { theme: CombinezoneTheme }) =>
    theme.basis?.colors.base.block};
`;

const LogSourcesContentHeader = styled(Flex)`
  max-width: 1920px;
  min-width: 1280px;
  width: 100%;
  flex-direction: column;
  padding: 0 24px;
`;

const LogSourcesContentBody = styled(Flex)`
  flex-direction: column;
  overflow: auto;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  overscroll-behavior-y: contain;
  position: relative;
  padding: 0 16px 16px;
  margin-top: 16px;
`;

const PageHeader = styled(Text).attrs(({ children }) => children)`
  display: block;
  padding: 24px 0;
  font-size: 24px;
  font-family: Ubuntu;
  color: ${({ theme }: { theme: CombinezoneTheme }) =>
    theme.basis?.colors.text.default};
`;

const TabElement = styled(Tab).attrs(({ children }) => children)`
  border: none;
`;

const Label = styled(Text).attrs(({ children }) => children)`
  display: inline-block;
  background-color: ${({ theme }: { theme: CombinezoneTheme }) =>
    theme.components.badge.colors.secondary.default};
  margin-left: 4px;
  padding: 2px 8px;
  font-size: 12px;
  border-radius: 3px;
`;

const LogSources: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const totalCount: TotalCountValue[] = useSelector(logSourcesActiveTotalCount);
  const { tab } = useParams<{ tab: string }>();
  const enumTab = tab?.charAt(0).toUpperCase() + tab?.slice(1);

  const [activeTabIndex, setActiveTabIndex] = useState<number>(
    LogSourcesTabs[enumTab as keyof typeof LogSourcesTabs],
  );
  const testId = 'log-sources';

  const onChangeTabHandler = (tabIndex: number): void => {
    setActiveTabIndex(tabIndex);

    history?.push({
      pathname: `/log-sources/${LogSourcesTabs[tabIndex].toLowerCase()}`,
      search: '',
    });
  };

  const [activeToEndpoint, setActiveToEndpoint] = useState('');
  useEffect(() => {
    if (activeTabIndex === LogSourcesTabs.Total) {
      setActiveToEndpoint('');
    }
    if (activeTabIndex === LogSourcesTabs.Active) {
      setActiveToEndpoint('&is_active=true');
    }
    if (activeTabIndex === LogSourcesTabs.Archived) {
      setActiveToEndpoint('&is_active=false');
    }
  }, [activeTabIndex]);

  useEffect(() => {
    dispatch(setActiveForEndpoint(activeToEndpoint));
    dispatch(firstFetch());
  }, [activeToEndpoint, dispatch]);

  const getTotalValueByIndex = (index: number): number | string =>
    totalCount.find((el) => el.name === LogSourcesTabs[index].toLowerCase())
      ?.value ?? '—';

  return (
    <LogSourcesContainer>
      <LogSourcesContentHeaderWrapper>
        <LogSourcesContentHeader>
          <PageHeader>{I18n.t('main.logSources')}</PageHeader>
          <LogSourcesFilters
            testId={testId}
            entity={SOURCES_ENTITY}
            firstFetch={firstFetch}
          />

          <Tabs
            onChange={onChangeTabHandler}
            selectedTabIndex={activeTabIndex}
            type="horizontal"
          >
            <TabElement testId={`${testId}-tab-total`}>
              {I18n.t('logSources.total')}
              <Label>{getTotalValueByIndex(0)}</Label>
            </TabElement>
            <TabElement testId={`${testId}-tab-active`}>
              {I18n.t('logSources.active')}
              <Label>{getTotalValueByIndex(1)}</Label>
            </TabElement>
            <TabElement testId={`${testId}-tab-archive`}>
              {I18n.t('logSources.archive')}
              <Label>{getTotalValueByIndex(2)}</Label>
            </TabElement>
          </Tabs>
        </LogSourcesContentHeader>
      </LogSourcesContentHeaderWrapper>

      <LogSourcesContentBody>
        <LogSourcesList entity={SOURCES_ENTITY} />
      </LogSourcesContentBody>
    </LogSourcesContainer>
  );
};

export default LogSources;

LogSources.displayName = 'LogSources';
