import { saveAs } from 'file-saver';
import { createAction } from 'redux-api-middleware';

import { withAuth } from '..';

import {
  DOWNLOAD_ERROR,
  DOWNLOAD_EVENT_FAIL,
  DOWNLOAD_EVENT_SUCCESS,
  DOWNLOAD_PROGRESS,
  DOWNLOAD_START,
  DOWNLOAD_SUCCESS,
  REMOVE_FROM_DOWNLOAD,
} from './actionTypes';

const onProgress = (endpoint, { loaded, totalLength }) => ({
  type: DOWNLOAD_PROGRESS,
  meta: {
    endpoint,
    percent: Number(((loaded / totalLength) * 100).toFixed(1)),
  },
});

const onError = (endpoint, event) => ({
  type: DOWNLOAD_ERROR,
  meta: {
    endpoint,
    event,
  },
});

const onSuccess = (endpoint, file, filename) => {
  saveAs(file, filename);

  return {
    type: DOWNLOAD_SUCCESS,
    meta: { endpoint },
  };
};

export const remove = (endpoint) => ({
  type: REMOVE_FROM_DOWNLOAD,
  meta: { endpoint },
});

export const downloadFile = (endpoint, filename) => (dispatch, getState) => {
  const downloads = getState().fileDownloader.downloadList;
  if (downloads[endpoint] && downloads[endpoint].status !== 'exception') {
    return;
  }

  const action = createAction({
    endpoint,
    method: 'GET',
    headers: withAuth({}),
    types: [
      {
        type: DOWNLOAD_START,
        meta: { endpoint, filename },
      },
      {
        type: DOWNLOAD_EVENT_SUCCESS,
        meta: { endpoint },
      },
      {
        type: DOWNLOAD_EVENT_FAIL,
        meta: { endpoint },
      },
    ],
    fetch: async (url, { headers, method }) => {
      const response = await fetch(url, { method, headers });

      if (response.ok) {
        const type = response.headers.get('Content-Type');
        const totalLength = Number(response.headers.get('Content-Length'));

        const reader = response.clone().body.getReader();

        const stream = new ReadableStream({
          start(controller) {
            let loaded = 0;

            return reader.read().then(function process({ done, value }) {
              if (done) {
                controller.close();
                return;
              }

              loaded += value.length;
              dispatch(onProgress(endpoint, { loaded, totalLength }));

              controller.enqueue(value);
              return reader.read().then(process);
            });
          },
        });

        const result = new Response(stream);
        const blob = await result.blob();
        const file = new Blob([blob], { type });

        dispatch(onSuccess(endpoint, file, filename));
      } else {
        dispatch(onError(endpoint, response));
      }

      return response;
    },
  });

  return dispatch(action);
};
