export function getType(count) {
  const types = ['none', 'low', 'medium', 'high', 'critical'];

  return types[Math.min(count, 4)];
}

export function calculateData(
  mitreMapping = {},
  techniques = {},
  analyticsCounts = {},
) {
  const max = Object.keys(mitreMapping).reduce(
    (res, key) => Math.max(res, mitreMapping[key].length),
    0,
  );
  const mitreData = Array(max);
  for (let i = 0; i < max; i++) {
    mitreData[i] = Array(12).fill(undefined);
  }

  const typeCounts = {
    critical: 0,
    high: 0,
    medium: 0,
    low: 0,
    none: 0,
  };

  Object.keys(mitreMapping).forEach((tactic, j) => {
    mitreMapping[tactic].forEach((techId, i) => {
      const count = analyticsCounts[tactic]?.[techId] || 0;
      mitreData[i][j] = {
        ...techniques[techId],
        count,
      };
      typeCounts[getType(count)]++;
    });
  });

  return { mitreData, typeCounts };
}
