const brandTransform = (next) => (action) => {
  if (
    !process.env.REACT_APP_BRAND_DOMAIN ||
    !process.env.REACT_APP_BRAND_NAME
  ) {
    return next(action);
  }

  if (typeof action.payload !== 'object') {
    return next(action);
  }

  action.payload = JSON.parse(JSON.stringify(action.payload), (key, value) => {
    if (typeof value === 'string') {
      return value
        .replace(/soc.bi.zone/gi, process.env.REACT_APP_BRAND_DOMAIN)
        .replace(
          /(bi.zone|bizone|bi zone)/gi,
          process.env.REACT_APP_BRAND_NAME,
        );
    }
    return value;
  });

  return next(action);
};

export default brandTransform;
