import { connect } from 'react-redux';

import { ArticlePage } from '../components/ArticlePage';
import { currentLocale } from '../store/reducers';
import {
  fetchArticleCard,
  closeInstructionCard,
  submitComment,
  finalizeCommentSuccess,
  fetchComments,
} from '../store/reducers/articleCard/actions';
import { downloadFile } from '../store/reducers/fileDownloader/actions';

const mapStateToProps = (state) => ({
  article: state.articleCard.article,
  fetchError: state.articleCard.error,
  addComment: state.articleCard.addComment,
  comments: state.articleCard.comments,
  locale: currentLocale(state),
});

const mapDispatchToProps = {
  submitComment,
  closeInstructionCard,
  finalizeCommentSuccess,
  fetchComments,
  fetchArticleCard,
  downloadFile,
};

const ArticlePageContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ArticlePage);
export default ArticlePageContainer;
