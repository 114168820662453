import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
  memo,
} from 'react';
import { useForm } from 'react-hook-form';

import styled from 'styled-components';

import {
  InlineEdit,
  InlineEditDropdownPositioner,
} from '@common/soc-react-kit';
import {
  Flex,
  Heading,
  Text,
  useToast,
  OverlaySpinner,
} from 'combinezone/core';
import { useTranslate } from 'combinezone/utils';

import { AssignSelf } from 'components/AssignedEditor/AssignSelf';
import { CUSTOMER_ASSIGNEE } from 'services/api';
import { assign } from 'store/reducers/common/actions/assign';
import { isAssignSuccess } from 'store/reducers/common/actions/assign/actionMatchTypes';

import { useAppDispatch } from '../../hooks';

import { InputComponent } from './InputComponent';
import locales from './locales';
import { AssignedEditorPropsType, ValueType } from './types';

export type Inputs = {
  [CUSTOMER_ASSIGNEE]?: ValueType;
};

export const AssignedEditorContainer = styled(Flex).attrs(() => ({
  direction: 'column',
}))`
  padding: 12px 0 0 20px;
  min-height: 44px;
  position: relative;
  ${InlineEditDropdownPositioner} {
    width: calc(100% + 32px);
  }

  transition: height 0.5s ease-out;
`;

const AssignedEditor: FC<AssignedEditorPropsType> = ({
  keyIncident,
  system,
  user,
}) => {
  const dispatch = useAppDispatch();
  const t = useTranslate(locales);
  const { toastError, toastSuccess } = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const renderLabel = useCallback(
    (label?: ValueType) =>
      label?.content ? <Text>{label.content}</Text> : <Heading>-</Heading>,
    [],
  );

  const value = useMemo(
    () => (user ? { value: `${user.id}`, content: user.username } : undefined),
    [user],
  );

  const action = useCallback(
    (formValue?: ValueType, withLoader = true) => {
      if (withLoader) {
        setIsLoading(true);
      }
      return dispatch(assign(keyIncident, formValue))
        .then((response) => {
          if (isAssignSuccess(response)) {
            toastSuccess({
              title: t('saved'),
            });
            setIsOpen(false);
            return true;
          }

          const message = Object.entries(response.payload.response).reduce(
            (result, [key, errorMessage]) =>
              `${result}${key}: ${errorMessage} \n`,
            '',
          );

          toastError({
            title: `${t('error')}: ${response.payload.status}`,
            message,
          });

          return false;
        })
        .catch((e) => {
          toastError({
            title: t('error'),
            message: `${e}`,
          });
          return false;
        })
        .finally(() => {
          if (withLoader) {
            setIsLoading(false);
          }
        });
    },
    [t, keyIncident],
  );

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = useForm<Inputs>({
    defaultValues: {
      [CUSTOMER_ASSIGNEE]: value,
    },
  });

  const onChange = async (e?: ValueType): Promise<boolean> => {
    let result = true;
    await handleSubmit(
      async (options) => {
        if (!isDirty) {
          return;
        }

        if (!value && !options[CUSTOMER_ASSIGNEE]) {
          return;
        }

        const isSuccessAction = await action(
          options[CUSTOMER_ASSIGNEE] as ValueType,
          false,
        );

        if (isSuccessAction) {
          return;
        }

        result = false;
      },
      () => {
        result = false;
      },
    )();

    return result;
  };

  const onOpenInlineEdit = useCallback(() => setIsOpen(true), []);
  const onCancelInlineEdit = useCallback(() => {
    reset();
    setIsOpen(false);
  }, [reset]);

  useEffect(() => {
    onCancelInlineEdit();
  }, [keyIncident, onCancelInlineEdit]);

  return (
    <AssignedEditorContainer>
      {isLoading && <OverlaySpinner spinnerSize="sm" backdrop="dark" />}
      <InlineEdit
        value={value}
        onChange={onChange}
        testId={`${keyIncident}-assigned-editor`}
        InputComponent={(props) => (
          <InputComponent {...props} system={system} control={control} />
        )}
        renderLabel={renderLabel}
        isHideOnOutsideClick={false}
        onOpen={onOpenInlineEdit}
        onCancel={onCancelInlineEdit}
      />
      {!isOpen && <AssignSelf onClick={action} userId={user?.id} />}
    </AssignedEditorContainer>
  );
};

export default memo(AssignedEditor);
