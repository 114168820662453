import { connect } from 'react-redux';

import DashboardGrid from '../components/DashboardGrid';
import { setGridLayout } from '../store/reducers/dashboard/actions';

function mapStateToProps(state) {
  return {
    layout: state.dashboard.layout,
    widgets: Object.values(state.dashboard.widgets),
    cols: state.dashboard.cols,
  };
}

const mapDispatchToProps = {
  setGridLayout,
};

const DashboardGridContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardGrid);
export default DashboardGridContainer;
