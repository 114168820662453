export enum TechniqueTypes {
  Higher = 'higher',
  High = 'high',
  Medium = 'medium',
  Low = 'low',
  None = 'none',
}

export enum MitreMappingTypes {
  Collection = 'collection',
  CommandAndControl = 'command-and-control',
  CredentialAccess = 'credential-access',
  DefenseEvasion = 'defense-evasion',
  Discovery = 'discovery',
  Execution = 'execution',
  Exfiltration = 'exfiltration',
  Impact = 'impact',
  InitialAccess = 'initial-access',
  LateralMovement = 'lateral-movement',
  Persistence = 'persistence',
  PrivilegeEscalation = 'privilege-escalation',
  Reconnaissance = 'reconnaissance',
  ResourceDevelopment = 'resource-development',
}
