import * as types from './actionTypes';

const initialState = {
  rule: {},
  fetchingRule: false,
  incidents: [],
  nextPage: 2,
  hasMoreIncidents: false,
  fetchingIncidents: false,
};

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.FETCH_RULE_REQUEST: {
      return {
        ...initialState,
        fetchingRule: true,
      };
    }

    case types.FETCH_RULE_SUCCESS: {
      return {
        ...state,
        rule: action.payload,
        fetchingRule: false,
      };
    }

    case types.FETCH_RULE_FAILURE: {
      return {
        ...state,
        fetchingRule: false,
      };
    }

    case types.FETCH_INCIDENTS_REQUEST:
    case types.FETCH_MORE_INCIDENTS_REQUEST: {
      return {
        ...state,
        fetchingIncidents: true,
      };
    }

    case types.FETCH_INCIDENTS_SUCCESS: {
      return {
        ...state,
        fetchingIncidents: false,
        incidents: action.payload.results,
        hasMoreIncidents: Boolean(action.payload.next),
      };
    }

    case types.FETCH_MORE_INCIDENTS_SUCCESS: {
      return {
        ...state,
        fetchingIncidents: false,
        nextPage: state.nextPage + 1,
        incidents: [...state.incidents, ...action.payload.results],
        hasMoreIncidents: Boolean(action.payload.next),
      };
    }

    case types.FETCH_INCIDENTS_FAILURE:
    case types.FETCH_MORE_INCIDENTS_FAILURE: {
      return {
        ...state,
        fetchingIncidents: false,
      };
    }

    default:
      return state;
  }
}

export const getRule = (state) => state.rule;
export const getIsFetchingRule = (state) => state.fetchingRule;
export const getIncidents = (state) => state.incidents;
export const getIsFetchingIncidents = (state) => state.fetchingIncidents;
export const getIncidentsNextPage = (state) => state.nextPage;
export const getHasMoreIncidents = (state) => state.hasMoreIncidents;
