import { connect } from 'react-redux';

import { SortControl } from '../components/Instructions/SortControl';
import { currentLocale, getOrdering } from '../store/reducers';
import { setSort } from '../store/reducers/filters/actions';
import { firstFetch } from '../store/reducers/instructions/actions';

const mapStateToProps = (state) => {
  return {
    locale: currentLocale(state),
    ordering: getOrdering('instructions', state),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setSort: (ordering) =>
      dispatch(setSort('instructions', ordering, firstFetch)),
  };
}

const SortControlContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SortControl);
export default SortControlContainer;
