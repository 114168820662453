import { connect } from 'react-redux';

import FilterControl from '../components/FilterControl';
import { INCIDENTS_ENTITY } from '../services/api';
import {
  currentLocale,
  dateRangeIsChangedSelector,
  getIsShowFiltersPanel,
  getSelectedFilters,
} from '../store/reducers';
import { resetFilters, showFilters } from '../store/reducers/filters/actions';

function mapStateToProps(state, ownProps) {
  const { entity = INCIDENTS_ENTITY } = ownProps;

  return {
    filters: getSelectedFilters(entity, state),
    isShow: getIsShowFiltersPanel(entity, state),
    dateRangeIsChanged: dateRangeIsChangedSelector(state),
    currentLocale,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { entity = INCIDENTS_ENTITY } = ownProps;

  return {
    resetFilters: () => dispatch(resetFilters(entity, ownProps.updateFunction)),
    showFilters: () => dispatch(showFilters(entity)),
  };
}

const filterControlContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FilterControl);
export default filterControlContainer;
