import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import styled, { css } from 'styled-components';

import { ListSection } from '@common/soc-react-kit';
import { Badge, BadgeLink, Flex } from 'combinezone/core';
import { CombinezoneTheme } from 'combinezone/theme';

import { MitreWidgetPanelSummaryProps } from 'components/RulesMitreWidget/MitreWidgetPanel/components/MitreWidgetPanelSummary/types';
import { TechniqueTypes } from 'components/RulesMitreWidget/enums';
import { getTypeRulesMitre } from 'components/RulesMitreWidget/helpers';
import { currentLocale } from 'store/reducers';

const PanelSummaryInfoLink = styled(BadgeLink)<{ $type: string }>`
  ${({ $type }) => css`
    pointer-events: auto;
    color: ${({ theme }: { theme: CombinezoneTheme }) => {
      if ($type === TechniqueTypes.Higher) {
        return theme.basis.colors.text.light;
      }
      if ($type === TechniqueTypes.Low) {
        return theme.basis.colors.text.dark;
      }
      return theme.basis.colors.text.default;
    }};

    &:hover {
      color: ${({ theme }: { theme: CombinezoneTheme }) => {
        if ($type === TechniqueTypes.Higher) {
          return '#000000';
        }
        return theme.basis.colors.link.hover;
      }};
    }

    border: ${({ theme }: { theme: CombinezoneTheme }) =>
      `1px solid ${theme.basis.colors.borders.normal}`};

    background-color: ${({ theme }: { theme: CombinezoneTheme }) => {
      switch ($type) {
        case TechniqueTypes.Higher: {
          return theme.basis.colors.tags.sea;
        }
        case TechniqueTypes.High: {
          return theme.basis.colors.tags.kelly;
        }
        case TechniqueTypes.Medium: {
          return theme.basis.colors.tags.lime;
        }
        case TechniqueTypes.Low: {
          return '#bde4af';
        }
        default: {
          return theme.basis.colors.node.normal;
        }
      }
    }};
  `}
`;

const PanelSummaryBadge = styled(Badge)`
  text-transform: capitalize;
  margin-right: 4px;
  margin-bottom: 4px;
`;

const MitreWidgetPanelSummary: FC<MitreWidgetPanelSummaryProps> = ({
  technique = {},
  testId,
}) => {
  const locale = useSelector(currentLocale);
  const testIdPanelSummary = `${testId}-panel-summary`;

  const mainInfo = useMemo(
    () =>
      technique
        ? {
            [I18n.t('ruleList.mitreWidget.name')]: technique.name,
            [I18n.t('ruleList.mitreWidget.tactics')]: (
              <Flex wrap="wrap" testId={`${testIdPanelSummary}-wrapper`}>
                {technique.tactics?.map((service) => (
                  <PanelSummaryBadge
                    accent="transparent"
                    key={service}
                    testId={`${testIdPanelSummary}-badge`}
                  >
                    {service}
                  </PanelSummaryBadge>
                ))}
              </Flex>
            ),
            [I18n.t('ruleList.mitreWidget.count')]: (
              <PanelSummaryInfoLink
                isExternal
                to={`/docs/rules?mitre_technique__in=${technique.technique_id}`}
                testId={`${testIdPanelSummary}-badge-link`}
                $type={getTypeRulesMitre(technique.count)}
              >
                {`${technique.count}`}
              </PanelSummaryInfoLink>
            ),
          }
        : '',
    [locale, technique],
  );

  return <ListSection width={150} data={mainInfo} />;
};

export default MitreWidgetPanelSummary;

MitreWidgetPanelSummary.displayName = 'MitreWidgetPanelSummary';
