import React from 'react';
import Linkify from 'react-linkify';
import { I18n } from 'react-redux-i18n';

import { Block } from '@bizone/ui-bundle/esm/Block';
import { Header } from '@bizone/ui-bundle/esm/Header';

const MainInfoTab = ({ rule }) => (
  <React.Fragment>
    <Block
      separateHeader
      collapsible
      title={<Header size={16}>{I18n.t('rulePage.description')}</Header>}
    >
      <div className="RulePage-Description">
        {rule[I18n.t('rulePage.descriptionField')] ? (
          <div>{rule[I18n.t('rulePage.descriptionField')]}</div>
        ) : (
          I18n.t('rulePage.noDescription')
        )}
      </div>
    </Block>
    <Block
      separateHeader
      collapsible
      title={<Header size={16}>{I18n.t('rulePage.links')}</Header>}
    >
      <div className="RulePage-Description">
        {rule.links ? (
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <>
                <a target="blank" href={decoratedHref} key={key}>
                  {decoratedText}
                </a>
                <br />
              </>
            )}
          >
            {rule.links}
          </Linkify>
        ) : (
          I18n.t('rulePage.noLinks')
        )}
      </div>
    </Block>
  </React.Fragment>
);

export default MainInfoTab;
