import { AnyAction } from 'redux';
import { ApiError } from 'redux-api-middleware';

import * as types from './actionTypes';

type PatchAlertPropertiesRequestType = {
  type: typeof types.PATCH_ALERT_PROPERTIES_REQUEST;
};

export const patchAlertPropertiesRequest = (
  action: AnyAction,
): action is PatchAlertPropertiesRequestType => {
  return action.type === types.PATCH_ALERT_PROPERTIES_REQUEST;
};

export type PatchAlertPropertiesFailureType = {
  type: typeof types.PATCH_ALERT_PROPERTIES_FAILURE;
  error: true;
  payload: ApiError<{ detail: string }>;
};

export const patchAlertPropertiesFailure = (
  action: AnyAction,
): action is PatchAlertPropertiesFailureType => {
  return action.type === types.PATCH_ALERT_PROPERTIES_FAILURE;
};

export type PatchAlertPropertiesSuccessType = {
  type: typeof types.PATCH_ALERT_PROPERTIES_SUCCESS;
  payload: {
    results: {
      alert: any;
    };
  };
};

export const patchAlertPropertiesSuccess = (
  action: AnyAction,
): action is PatchAlertPropertiesSuccessType => {
  return action.type === types.PATCH_ALERT_PROPERTIES_SUCCESS;
};
