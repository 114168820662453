import * as Sentry from '@sentry/react';

import * as types from '../reducers/auth/actionTypes';

export default function sentryExceptionsMiddleware(store) {
  return (next) => (action) => {
    if (action.type === types.LOGOUT && action.meta.isForcibly) {
      Sentry.captureException(new Error('Forced logout'));
    } else if (action.type === types.TOKEN_FAILURE) {
      Sentry.captureException(new Error('Token failed'));
    }

    return next(action);
  };
}
