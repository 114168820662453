import React, { FC } from "react";

import { Flex, Spinner } from "combinezone/core";

import { SensorCard } from "./components/SensorCard";
import { useSensors } from "./hooks";

type SectionSensorsProps = {
  id: string;
  testId: string;
};

export const SectionSensors: FC<SectionSensorsProps> = ({ id, testId }) => {
  const { data, isFetching } = useSensors(id);

  const currentTestId = `${testId}-sensors`;

  return (
    <Flex direction="column" alignItems="center" gap="16px">
      {isFetching ? (
        <Spinner />
      ) : (
        data.map((sourceGroup, i) => (
          <SensorCard key={i} testId={currentTestId} {...sourceGroup} />
        ))
      )}
    </Flex>
  );
};

SectionSensors.displayName = "SectionSensors";
