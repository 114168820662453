import React, { memo, useState } from 'react';

import { Button } from '@bizone/ui-bundle/esm/Button';
import { Form } from '@bizone/ui-bundle/esm/Form';
import { Icon } from '@bizone/ui-bundle/esm/Icon';

import { Select } from 'antd';

import './EditableInput.scss';

const { Option } = Select;

const EditableInput = ({
  inputValue,
  items,
  onUpdatePriority,
  readInputRender,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [valueSelect, setValueSelect] = useState(inputValue);

  const onPriorityInputClick = () => {
    setIsEdit(true);
  };

  const onPriorityChange = (value) => {
    setValueSelect(value);
  };

  const onCancelClick = () => {
    setIsEdit(false);
    setValueSelect(inputValue);
  };

  const onUpdatePriorityClick = async () => {
    onUpdatePriority(valueSelect);
    setIsEdit(false);
  };

  return (
    <React.Fragment>
      {isEdit ? (
        <Form cls="editableInput">
          {(formProps) => (
            <form onSubmit={formProps.handleSubmit}>
              <Form.Item name="inc-actions" className="Panel-SelectWrap">
                <Select
                  className="priority-select"
                  value={valueSelect}
                  onChange={onPriorityChange}
                >
                  {Object.entries(items).map(([value, content]) => (
                    <Option value={value} key={value}>
                      {content}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <div className="button-wrap">
                <Button key="ok" rich primary onClick={onUpdatePriorityClick}>
                  <div className="icon-button">
                    <Icon glyph="check" />
                  </div>
                </Button>
                <Button
                  className="icon-button"
                  key="no"
                  onClick={onCancelClick}
                >
                  <div className="icon-button">
                    <Icon glyph="close" />
                  </div>
                </Button>
              </div>
            </form>
          )}
        </Form>
      ) : (
        <div onClick={onPriorityInputClick} className="editable-value-wrap">
          <div className="editable-value">
            {readInputRender ? readInputRender(inputValue) : items[inputValue]}
          </div>
          <div className="edit-button">
            <Icon glyph="pencil" />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default memo(EditableInput);
