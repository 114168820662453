import React, { FC } from 'react';

import styled from 'styled-components';

import { useTranslate } from 'combinezone/utils';

import { useUserInfoFromToken } from 'components/AssignedEditor/AssignSelf/useUserInfoFromToken';
import { ValueType } from 'components/AssignedEditor/types';
import { DashedTextButton } from 'components/DashedTextButton';

import locales from '../locales';

const Button = styled(DashedTextButton)`
  margin: 12px 0 0 -8px;
`;

export type AssignSelfProps = {
  userId?: number;
  onClick: (value: ValueType) => unknown;
};

export const AssignSelf: FC<AssignSelfProps> = ({ onClick, userId }) => {
  const t = useTranslate(locales);
  const tokenUser = useUserInfoFromToken();
  if (!tokenUser) {
    return null;
  }

  if (tokenUser.id === userId) {
    return null;
  }

  const onClickHandler = (): unknown =>
    onClick({
      value: `${tokenUser.id}`,
      content: tokenUser.username,
    });

  return <Button text={t('assignToYourself')} onClick={onClickHandler} />;
};
