import { createAction } from 'redux-api-middleware';

import { rulePageIncidentsNextPage, withAuth } from '..';
import { INCIDENTS_ENDPOINT, RULE_ENDPOINT } from '../../../services/api';

import * as types from './actionTypes';

export const fetchRule = (id) =>
  createAction({
    endpoint: `${RULE_ENDPOINT}${id}/`,
    method: 'GET',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      types.FETCH_RULE_REQUEST,
      types.FETCH_RULE_SUCCESS,
      types.FETCH_RULE_FAILURE,
    ],
  });

export const fetchIncidents = (id) =>
  createAction({
    endpoint: `${INCIDENTS_ENDPOINT}?rule_id__in=${id}`,
    method: 'GET',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      types.FETCH_INCIDENTS_REQUEST,
      types.FETCH_INCIDENTS_SUCCESS,
      types.FETCH_INCIDENTS_FAILURE,
    ],
  });

export const fetchMoreIncidents = (id) => (dispatch, getState) => {
  const page = rulePageIncidentsNextPage(getState());

  return dispatch(
    createAction({
      endpoint: `${INCIDENTS_ENDPOINT}?rule_id__in=${id}&page=${page}`,
      method: 'GET',
      headers: withAuth({ 'Content-Type': 'application/json' }),
      types: [
        types.FETCH_MORE_INCIDENTS_REQUEST,
        types.FETCH_MORE_INCIDENTS_SUCCESS,
        types.FETCH_MORE_INCIDENTS_FAILURE,
      ],
    }),
  );
};
