import * as React from 'react';
import { createPortal } from 'react-dom';
import isEqual from 'react-fast-compare';

import { IconButton } from '@bizone/ui-bundle/esm/IconButton';

import './FilterControl.scss';

export default class FilterControl extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  render() {
    const {
      dateRangeIsChanged,
      filters,
      isShow,
      renderTo,
      resetFilters,
      showFilters,
    } = this.props;

    const keys = Object.keys(filters);
    const isActive = keys.length > 0 || isShow;
    const isChanged = keys.length > 0 || dateRangeIsChanged;

    const body = (
      <div
        className={`FilterControl${isChanged ? ' FilterControl_colored' : ''}`}
      >
        {isActive ? (
          <IconButton icon="filter-reset" onClick={resetFilters} />
        ) : (
          <IconButton icon="filter" onClick={showFilters} />
        )}
      </div>
    );

    return renderTo ? createPortal(body, renderTo) : body;
  }
}
