import { RSAA } from 'redux-api-middleware';

import { withAuth } from '..';
import {
  INCIDENTS_ENDPOINT,
  SYSTEMS_ENDPOINT,
  fetchAllPages,
} from '../../../services/api';
import { setDashboardUpdateTimer } from '../dashboard/actions';

import * as types from './actionTypes';

export const cancelCreateIncModal = () => ({
  type: types.CREATE_INC_MODAL_CANCEL,
});

export const openCreateIncModal = () => {
  return (dispatch) => {
    dispatch(fetchSystems());

    return dispatch({
      type: types.CREATE_INC_MODAL_OPEN,
    });
  };
};

export const successCreateIncModal = () => {
  return (dispatch, getState) => {
    const {
      dashboard: { updateTimer },
    } = getState();

    if (updateTimer) {
      dispatch(setDashboardUpdateTimer(5 * 1000));
    }

    return dispatch({
      type: types.CREATE_INC_MODAL_SUCCESS,
    });
  };
};

export const sendCreateInc = (body) => {
  return {
    [RSAA]: {
      endpoint: INCIDENTS_ENDPOINT,
      method: 'POST',
      headers: withAuth({ 'content-type': 'application/json' }),
      types: [types.CREATE_REQUEST, types.CREATE_SUCCESS, types.CREATE_FAIL],
      body: JSON.stringify(body),
    },
  };
};

export const addFile = (key, attachments) => {
  const formData = new FormData();
  formData.append('attachments', attachments);

  return {
    [RSAA]: {
      endpoint: `${INCIDENTS_ENDPOINT}${key}/add_file/`,
      method: 'POST',
      headers: withAuth({}),
      types: [
        types.ADD_FILE_REQUEST,
        types.ADD_FILE_SUCCESS,
        types.ADD_FILE_FAIL,
      ],
      body: formData,
    },
  };
};

export const fetchSystems = () => ({
  [RSAA]: {
    endpoint: `${SYSTEMS_ENDPOINT}?`,
    method: 'GET',
    headers: withAuth({}),
    types: [
      types.FETCH_SYSTEMS_REQUEST,
      types.FETCH_SYSTEMS_SUCCESS,
      types.FETCH_SYSTEMS_FAIL,
    ],
    fetch: fetchAllPages,
  },
});
