export const CREATE_REPORT_MODAL_OPEN =
  '@@createReport/CREATE_REPORT_MODAL_OPEN';
export const CREATE_REPORT_MODAL_CANCEL =
  '@@createReport/CREATE_REPORT_MODAL_CANCEL';

export const CREATE_REPORT_REQUEST = '@@createReport/CREATE_REPORT_REQUEST';
export const CREATE_REPORT_SUCCESS = '@@createReportFCREATE_REPORT_SUCCESS';
export const CREATE_REPORT_FAIL = '@@createReport/CREATE_REPORT_FAIL';

export const CREATE_REPORT_TASK_REQUEST =
  '@@createReport/CREATE_REPORT_TASK_REQUEST';
export const CREATE_REPORT_TASK_SUCCESS =
  '@@createReport/CREATE_REPORT_TASK_SUCCESS';
export const CREATE_REPORT_TASK_FAIL = '@@createReport/CREATE_REPORT_TASK_FAIL';

export const FETCH_SYSTEMS_REQUEST = '@@createReport/FETCH_SYSTEMS_REQUEST';
export const FETCH_SYSTEMS_SUCCESS = '@@createReport/FETCH_SYSTEMS_SUCCESS';
export const FETCH_SYSTEMS_FAIL = '@@createReport/FETCH_SYSTEMS_FAIL';
