import { connect } from 'react-redux';

import Widget from '../components/Widget';
import widgets from '../services/widgets';
import { currentLocale, makeWidgetSelector } from '../store/reducers';
import {
  deleteWidget,
  editWidgetMenuSettings,
} from '../store/reducers/dashboard/actions';

const makeMapStateToProps = () => {
  const widgetSelector = makeWidgetSelector();

  return (state, ownProps) => {
    const widgetUserSettings = ownProps.widget;
    const widget = widgetSelector(state, ownProps);
    const widgetTypes = widgets.getWidgetTypes(widgetUserSettings.id);

    return {
      id: widgetUserSettings.id,
      type: widgetUserSettings.type,
      title: widgetUserSettings.title || widget.title,
      widgetSettings: widget.settings,
      widgetTypes,
      isFetching: state.dashboard.analytics.fetching,
      currentLocale: currentLocale(state),
    };
  };
};

const mapDispatchToProps = {
  deleteWidget,
  setNewForm: editWidgetMenuSettings,
};

const WidgetContainer = connect(
  makeMapStateToProps,
  mapDispatchToProps,
)(Widget);
export default WidgetContainer;
