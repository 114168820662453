import { getQLNameSearch } from '@common/soc-react-kit';
import { AnyAction } from 'redux';
import { ApiError, createAction } from 'redux-api-middleware';

import { TAGS_ENDPOINT } from 'services/api';
import { withAuth } from 'store/reducers';
import { RSAAAsyncActionType } from 'store/types';

import * as types from './actionTypes';

const tagsLimit = 'limit=5';

export type EndpointTagType = {
  name: string;
  color: string;
  created: string;
  created_by: null | {
    id: number;
    username: string;
  };
};

export type FetchTagsFailureType = {
  type: typeof types.FETCH_TAGS_FAILURE;
  error: true;
  payload: ApiError<{ detail: string }>;
};

export const fetchTagsFailure = (
  action: AnyAction,
): action is FetchTagsFailureType => {
  return action.type === types.FETCH_TAGS_FAILURE;
};

export type FetchTagsSuccessType = {
  type: typeof types.FETCH_TAGS_SUCCESS;
  payload: {
    results: EndpointTagType[];
  };
};

export const fetchTagsSuccess = (
  action: AnyAction,
): action is FetchTagsSuccessType => {
  return action.type === types.FETCH_TAGS_SUCCESS;
};

export type TransformedTags = {
  value: string;
  color: string;
};

type ResponseFetchTagsType = FetchTagsSuccessType | FetchTagsFailureType;

const transformTags = (e: ResponseFetchTagsType): TransformedTags[] => {
  if (fetchTagsFailure(e)) {
    return [];
  }

  return e.payload.results.map((tag) => ({
    value: tag.name,
    color: tag.color,
  }));
};

export type FetchTagsType = (
  name?: string,
  selected?: string[],
) => RSAAAsyncActionType<ResponseFetchTagsType, TransformedTags[]>;

export const fetchTags: FetchTagsType =
  (search, selected = []) =>
  (dispatch, getState) => {
    const ql = getQLNameSearch(search, selected);

    const endpoint = `${TAGS_ENDPOINT}?${tagsLimit}&q=${ql}`;

    // @ts-ignore
    return dispatch(
      createAction({
        endpoint,
        method: 'GET',
        headers: withAuth({ 'Content-Type': 'application/json' }),
        types: [
          types.FETCH_TAGS_REQUEST,
          types.FETCH_TAGS_SUCCESS,
          types.FETCH_TAGS_FAILURE,
        ],
        isAbortOldRequests: true,
      }),
    )
      .then(transformTags)
      .catch(() => {
        return [];
      });
  };
