import { isFetchUsersFail } from 'store/reducers/common/actions/fetchUsers/actionMatchTypes';

import { UsersToOptionsType } from './types';

export const usersToOptions: UsersToOptionsType = (response) => {
  if (isFetchUsersFail(response)) {
    return [];
  }

  return response.payload.results.map(({ id, username }) => ({
    value: `${id}`,
    content: username,
    testId: `${id}-${username}`,
  }));
};
