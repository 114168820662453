import { RSAA } from 'redux-api-middleware';

import { withAuth } from '..';
import {
  CREATE_REPORT_ENDPOINT,
  REPORT_TASKS_ENDPOINT,
  FILTERS_ENDPOINT,
} from '../../../services/api';

import * as types from './actionTypes';

export const cancelCreateReportModal = () => ({
  type: types.CREATE_REPORT_MODAL_CANCEL,
});

export const openCreateReportModal = () => {
  return (dispatch) => {
    dispatch(fetchSystems());

    return dispatch({
      type: types.CREATE_REPORT_MODAL_OPEN,
    });
  };
};

export const createReport = (body) => ({
  [RSAA]: {
    endpoint: CREATE_REPORT_ENDPOINT,
    method: 'POST',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      types.CREATE_REPORT_REQUEST,
      types.CREATE_REPORT_SUCCESS,
      types.CREATE_REPORT_FAIL,
    ],
    body: JSON.stringify(body),
  },
});

export const createReportTask = (body) => ({
  [RSAA]: {
    endpoint: REPORT_TASKS_ENDPOINT,
    method: 'POST',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      types.CREATE_REPORT_TASK_REQUEST,
      types.CREATE_REPORT_TASK_SUCCESS,
      types.CREATE_REPORT_TASK_FAIL,
    ],
    body: JSON.stringify(body),
  },
});

export const fetchSystems = () => ({
  [RSAA]: {
    endpoint: FILTERS_ENDPOINT,
    method: 'GET',
    headers: withAuth({}),
    types: [
      types.FETCH_SYSTEMS_REQUEST,
      types.FETCH_SYSTEMS_SUCCESS,
      types.FETCH_SYSTEMS_FAIL,
    ],
  },
});
