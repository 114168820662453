import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { I18n } from 'react-redux-i18n';

import { getFormErrors } from '@common/soc-react-kit';
import { Form, notification, Rate } from 'antd';
import PropTypes from 'prop-types';

import { useTheme } from '../../utils/brending';

import emitter, { resetEvent, submitEvent } from './Assessment.emitter';
import { Comment } from './Comment';

const defaultCommentLayout = {
  labelCol: { span: 8, offset: 16, pull: 16 },
  wrapperCol: { span: 24 },
};

const Assessment = ({ initialValues, itemLayouts, onChangeRate, onSubmit }) => {
  const theme = useTheme();
  const [form] = Form.useForm();
  const { comment: comLayout = defaultCommentLayout, rating: ratLayout = {} } =
    itemLayouts || {};
  const initRating = useMemo(() => {
    if (initialValues.rating !== undefined) {
      return Math.max(Math.min(initialValues.rating, 5), 0);
    }
    return 0;
  }, [initialValues.rating]);
  const initComment = useMemo(
    () => initialValues.comment || '',
    [initialValues.comment],
  );
  const [ratingValue, setRatingValue] = useState(null);

  const onFinish = useCallback(
    (values) => {
      onSubmit(values).then((response) => {
        const { payload } = response;
        if (payload.status === 400) {
          const [errors] = getFormErrors(payload.response);

          const fieldsWithErrors = Object.keys(errors);
          form.setFields(
            fieldsWithErrors.reduce((res, field) => {
              return [
                ...res,
                {
                  name: field,
                  errors: errors[field],
                },
              ];
            }, []),
          );

          notification.error({ message: I18n.t('incCard.rating.error') });
        }
      });
    },
    [form, onSubmit],
  );

  const onFieldsChange = useCallback((newFields) => {
    newFields.forEach((field) => {
      if (field.name[0] === 'rating') {
        setRatingValue(field.value);
      }
    });
  }, []);

  useEffect(() => {
    function resetForm() {
      form.resetFields();
      setRatingValue(initRating);
    }

    emitter.on(submitEvent, form.submit);
    emitter.on(resetEvent, resetForm);

    return () => {
      emitter.off(submitEvent, form.submit);
      emitter.off(resetEvent, resetForm);
    };
  }, [form, initRating, onSubmit]);

  useEffect(() => {
    onChangeRate?.(ratingValue);
  }, [onChangeRate, ratingValue]);

  return (
    <Form
      form={form}
      initialValues={{
        rating: initRating,
        comment: initComment,
      }}
      onFinish={onFinish}
      onFieldsChange={onFieldsChange}
    >
      <Form.Item
        name="rating"
        label={I18n.t('incCard.rating.stars')}
        tooltip={I18n.t('incCard.rating.help')}
        {...ratLayout}
      >
        <Rate style={{ color: theme.basis.colors.base.active }} />
      </Form.Item>
      <Form.Item
        name="comment"
        label={I18n.t('incCard.rating.comment')}
        help={null}
        valuePropName="children"
        {...comLayout}
      >
        <Comment />
      </Form.Item>
    </Form>
  );
};

Assessment.propTypes = {
  initialValues: PropTypes.shape({
    comment: PropTypes.string,
    rating: PropTypes.number,
  }),
  itemLayouts: PropTypes.object,
  onChangeRate: PropTypes.func,
  onSubmit: PropTypes.func,
};

Assessment.defaultProps = {
  initialValues: {},
};

export default Assessment;
