import * as React from 'react';
import { Translate } from 'react-redux-i18n';
import { Link } from 'react-router-dom';

import { Block } from '@bizone/ui-bundle/esm/Block';
import { Header } from '@bizone/ui-bundle/esm/Header';
import { Label } from '@bizone/ui-bundle/esm/Label';

import { Layout } from '@common/soc-react-kit';
import memoize from 'memoize-one';
import shallowEqual from 'shallowequal';

import OldGrid from 'components/OldGrid';

const { Container, Content } = Layout;

export default class ProtectedSystems extends React.Component {
  getColumns = memoize((_contacts) => {
    const headerClassName = 'Text Text_clipped Text_size_14 Grid-ColumnTitle';

    const contacts = (_contacts || []).map(({ key, text }) => ({
      content: text,
      value: key,
    }));

    return [
      {
        headerContent: (
          <Translate value="protectedSystems.id" className={headerClassName} />
        ),
        id: 'is_id',
        minWidth: 100,
        verticalAlign: 'center',
        sortable: true,
        filter: {
          type: 'text',
        },
      },
      {
        headerContent: (
          <Translate
            value="protectedSystems.fullName"
            className={headerClassName}
          />
        ),
        id: 'full_name',
        minWidth: 200,
        verticalAlign: 'center',
        sortable: true,
        filter: {
          type: 'text',
        },
      },
      {
        headerContent: (
          <Translate
            value="protectedSystems.services"
            className={headerClassName}
          />
        ),
        id: 'services',
        minWidth: 150,
        verticalAlign: 'center',
      },
      {
        headerContent: (
          <Translate
            value="protectedSystems.serviceManager"
            className={headerClassName}
          />
        ),
        id: 'service_manager',
        minWidth: 150,
        verticalAlign: 'center',
        sortable: true,
        filter: {
          type: 'multiselect',
          options: contacts,
        },
      },
      {
        headerContent: (
          <Translate
            value="protectedSystems.dedicatedAnalyst"
            className={headerClassName}
          />
        ),
        id: 'dedicated_analyst',
        minWidth: 150,
        verticalAlign: 'center',
        sortable: true,
        filter: {
          type: 'multiselect',
          options: contacts,
        },
      },
    ];
  }, shallowEqual);

  constructor(props) {
    super(props);

    this.state = {
      filters: {},
      sort: {},
      isExpanded: false,
    };
  }

  onExpand = (isExpanded) => {
    this.setState({
      isExpanded,
    });
  };

  getSystems() {
    const { fetchSystems } = this.props;
    const { filters, sort } = this.state;

    fetchSystems?.({ sort, filters });
  }

  componentDidMount() {
    this.getSystems();
  }

  setSort = (sortInfo) => {
    this.setState({ sort: sortInfo }, this.getSystems);
  };

  setFilters = ({ id, value } = {}) => {
    const filters = { ...this.state.filters };

    if ([undefined, ''].includes(value)) {
      delete filters[id];
    } else {
      filters[id] = value;
    }

    this.setState({ filters }, this.getSystems);
  };

  render() {
    const { isLoading = false, locale = 'en', systems } = this.props;
    const columns = this.getColumns(this.props.settings.contacts);

    const title = (
      <Header size={16}>
        <Translate value="protectedSystems.title" />
        <Label offsetLeft={8}>{systems.length}</Label>
      </Header>
    );

    const height = Math.max(600, document.documentElement.clientHeight - 222);

    return (
      <Container>
        <Content style={{ maxWidth: 1694 }}>
          <Block
            title={title}
            separateHeader
            expandable
            fullHeight
            onExpand={this.onExpand}
          >
            <OldGrid
              resizeContentSyncTime={0}
              locale={locale}
              height={this.state.isExpanded ? null : height}
              columns={columns}
              rows={normalizeData(systems)}
              loading={isLoading}
              onSort={({ direction, id }) => {
                if (!direction) {
                  this.setSort({});
                } else {
                  this.setSort({
                    field: id,
                    type: direction,
                  });
                }
              }}
              onFilterChange={this.setFilters}
            />
          </Block>
        </Content>
      </Container>
    );
  }
}

function normalizeData(data) {
  return data.map((row, index) => ({
    id: `${index}-${Date.now()}`,
    cells: {
      is_id: <Link to={`/protected-systems/${row.is_id}`}>{row.is_id}</Link>,
      full_name: row.full_name,
      services: (row.services || []).map((service) => service.name).join(', '),
      service_manager: row.service_manager
        ? getContact(row.service_manager)
        : '',
      dedicated_analyst: row.dedicated_analyst
        ? getContact(row.dedicated_analyst)
        : '',
    },
  }));
}

function getContact({ first_name: firstName, last_name, patronimic }) {
  return `${last_name} ${firstName} ${patronimic}`;
}
