import React, { FC, useEffect, useState, memo } from 'react';
import { I18n } from 'react-redux-i18n';

import { Flex, IconButton, ToggleGroup } from 'combinezone/core';
import { Filter, FilterOff } from 'combinezone/icons';

import FilterPanel from './FilterPanel';
import Search from './Search';
import Sort from './Sort';

import './SearchPanel.scss';
import { SearchPanelProps } from './types';

const SearchPanel: FC<SearchPanelProps> = ({
  buttonGroupItems,
  currentLocale,
  filters,
  isLoading,
  resetFilters,
  searchConfig,
  setFilter,
  settings,
  sortConfig,
}) => {
  const [isFilterButtonPressed, setIsFilterButtonPressed] = useState(false);

  useEffect(() => {
    Object.keys(filters).length && setIsFilterButtonPressed(true);
  }, [filters]);

  const toggleFilterButton = () => {
    isFilterButtonPressed && resetFilters();
    setIsFilterButtonPressed((prev) => !prev);
  };

  return (
    <Flex className="SearchPanel">
      <Flex className="SearchPanel-Sizer">
        <Flex>
          <Search {...searchConfig} />
          <IconButton
            className="FilterButton Filter-Button"
            testId=""
            icon={isFilterButtonPressed ? FilterOff : Filter}
            tooltip={{
              content: I18n.t('filterPanel.filterButton'),
              position: 'top',
            }}
            accent={isFilterButtonPressed ? 'active' : 'default'}
            onClick={toggleFilterButton}
          />
          <Sort {...sortConfig} />
          <ToggleGroup items={buttonGroupItems} />
        </Flex>

        {isFilterButtonPressed && (
          <FilterPanel
            isLoading={isLoading}
            filters={filters}
            settings={settings}
            setFilter={setFilter}
            currentLocale={currentLocale}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default memo(SearchPanel);
