import { createAction } from 'redux-api-middleware';

import { USERS_ENDPOINT } from 'services/api';
import { withAuth } from 'store/reducers';

import * as types from './actionTypes';
import { usersToOptions } from './helpers';
import { FetchUsersType } from './types';

export const usersLimit = 'page_size=10';

export const fetchUsers: FetchUsersType =
  (system, searchString = '') =>
  (dispatch, getState) => {
    const search = searchString.trim() ? `&search=${searchString}` : '';

    const action = createAction({
      endpoint: `${USERS_ENDPOINT}?${usersLimit}&is_active=true&system__in=${system}${search}`,
      method: 'GET',
      headers: withAuth({ 'Content-Type': 'application/json' }),
      types: [types.USERS_REQUEST, types.USERS_SUCCESS, types.USERS_FAIL],
      // @ts-ignore
      isAbortOldRequests: true,
    });

    return dispatch(action)
      .then(usersToOptions)
      .catch((e) => {
        console.error(e);
        return [];
      });
  };
