import React, { FC, useCallback } from 'react';
import { Controller, Control } from 'react-hook-form';

import { SelectSuggestion } from '@common/soc-react-kit';
import { InlineEditInputComponentProps } from 'combinezone/core/InlineEdit/InlineEdit';

import { Inputs } from 'components/AssignedEditor';
import { ValueType } from 'components/AssignedEditor/types';
import { CUSTOMER_ASSIGNEE } from 'services/api';
import { fetchUsers } from 'store/reducers/common';

import { useAppDispatch } from '../../../hooks';

const dropdownProps = {
  usePortal: false,
};

export type FormControlType = Control<Inputs, any>;

export type InputComponentProps = InlineEditInputComponentProps<
  undefined | ValueType
> & {
  system: string;
  control: FormControlType;
  hasError?: boolean;
};

export const InputComponent: FC<InputComponentProps> = ({
  control,
  hasError = false,
  inputRef,
  isLoading,
  onChange,
  system,
  testId,
  value,
}) => {
  const dispatch = useAppDispatch();

  const getSuggestions = useCallback(
    (search = '') => dispatch(fetchUsers(system, search)),
    [system],
  );

  return (
    <Controller
      name={CUSTOMER_ASSIGNEE}
      control={control}
      render={({ field }) => (
        <SelectSuggestion
          {...field}
          ref={inputRef as any}
          onChange={(changedValue) => {
            field.onChange(changedValue);
            onChange(changedValue || undefined);
          }}
          testId={testId}
          value={value}
          getSuggestions={getSuggestions}
          multiple={false}
          options={[]}
          disabled={!!isLoading}
          error={hasError}
          dropdownProps={dropdownProps}
        />
      )}
    />
  );
};
