import React, { useCallback, useState, useEffect } from 'react';

import { FilterControl, FilterPanel } from '@common/soc-react-kit';

import SearchBlock from '../SearchBlock';
import SortControl from '../SortControl';

const emptyObject = {};
const emptyArray = [];

const Header = ({
  filtersConfig: {
    currentLocale,
    filters,
    hasFilters = true,
    resetFilters,
    setFilter,
    settings: filtersSettings,
  } = emptyObject,
  isFetching = false,
  searchConfig: {
    hasSearch = true,
    onChangeSearch,
    placeholder,
    search = '',
  } = emptyObject,
  sortConfig,
  tools = emptyArray,
}) => {
  const [isFiltersShow, setIsFiltersShow] = useState(false);
  const [isShowFilterPanel, setIsShowFilterPanel] = useState(false);

  const _setFilter = useCallback(
    (key, items) => {
      let resultValue = items;
      if (items === undefined || (Array.isArray(items) && items.length === 0)) {
        resultValue = undefined;
      } else {
        setIsFiltersShow(true);
      }
      setFilter(key, resultValue);
    },
    [setFilter],
  );

  const showFilter = useCallback(() => {
    setIsFiltersShow(true);
  }, []);

  const _resetFilters = useCallback(() => {
    setIsFiltersShow(false);
    resetFilters();
  }, [resetFilters]);

  useEffect(() => {
    const isFiltersSelected = Object.keys(filters || {}).length > 0;
    setIsShowFilterPanel(isFiltersShow || isFiltersSelected);
  }, [filters, isFiltersShow]);

  return (
    <div className="ListPageHeader">
      <div className="ListPageHeader-ControlsRow">
        {hasSearch && (
          <div className="ListPageHeader-Search">
            <SearchBlock
              placeholder={placeholder}
              defaultText={search}
              onSearch={onChangeSearch}
            />
          </div>
        )}
        {hasFilters && (
          <FilterControl
            filters={filters}
            isShow={isShowFilterPanel}
            resetFilters={_resetFilters}
            showFilters={showFilter}
            isFetching={isFetching}
          />
        )}
        {sortConfig && <SortControl {...sortConfig} />}
        {tools}
      </div>
      {hasFilters && (
        <div className="ListPageHeader-FiltersRow">
          <FilterPanel
            isFetching={isFetching}
            filters={filters}
            settings={filtersSettings}
            isShow={isShowFilterPanel}
            setFilter={_setFilter}
            currentLocale={currentLocale}
          />
        </div>
      )}
    </div>
  );
};

export default Header;
