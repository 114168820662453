import { connect } from 'react-redux';

import { Card } from '../components/IncidentList/Card';
import {
  currentLocale,
  getMitreTechniques,
  makeIncidentCardSelector,
} from '../store/reducers';
import { select } from '../store/reducers/incidentList/actions';

const makeMapStateToProps = () => {
  const selector = makeIncidentCardSelector();

  return (state, ownProps) => {
    const isSelected = selector(state, ownProps);

    return {
      isSelected,
      mitreTechniques: getMitreTechniques(state),
      locale: currentLocale(state),
    };
  };
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    select: () => dispatch(select(ownProps.incident)),
  };
}

const IncidentListCardContainer = connect(
  makeMapStateToProps,
  mapDispatchToProps,
)(Card);
export default IncidentListCardContainer;
