import * as types from './actionTypes';

const initialState = {
  article: {},
  addComment: {
    submitting: false,
    fetching: false,
  },
  comments: [],
  error: null,
  fetching: false,
};

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.FETCH_ARTICLE_CARD_REQUEST:
      return { ...state, fetching: true, error: false };

    case types.FETCH_ARTICLE_CARD_SUCCESS:
      return { ...state, article: action.payload };

    case types.FETCH_ARTICLE_CARD_FAIL:
      return { ...state, fetching: false, error: true };

    case types.FETCH_ARTICLE_VERSION_SUCCESS:
      return { ...state, versions: action.payload.results };

    case types.FETCH_ARTICLE_COMMENTS_SUCCESS:
      return { ...state, comments: action.payload.results };

    case types.CLOSE_ARTICLE_CARD:
      return { ...initialState };

    case types.ADD_COMMENT_REQUEST: {
      return {
        ...state,
        addComent: {
          ...state.addComment,
          submitting: true,
        },
      };
    }

    case types.ADD_COMMENT_FAIL: {
      return {
        ...state,
        addComent: {
          ...state.comments,
          submitting: false,
        },
      };
    }

    case types.ADD_COMMENT_SUCCESS: {
      return {
        ...state,
        addComent: {
          ...state.comments,
          submitting: false,
        },
        comments: [action.payload, ...state.comments],
      };
    }

    case types.ADD_COMMENT_MODAL_SUCCESS: {
      return {
        ...state,
        addComent: {
          ...initialState.comments,
        },
      };
    }

    default:
      return state;
  }
}
