import { connect } from 'react-redux';

import RulePage from '../components/RulePage';
import {
  getMitreTechniques,
  rulePageData,
  rulePageIsFetchingRule,
} from '../store/reducers';
import { fetchMitreMapping } from '../store/reducers/mitre/actions';
import { fetchRule } from '../store/reducers/rulePage/actions';

const mapStateToProps = (state) => ({
  rule: rulePageData(state),
  fetchingRule: rulePageIsFetchingRule(state),
  mitreTechniques: getMitreTechniques(state),
});

const mapDispatchToProps = {
  fetchRule,
  fetchMitreMapping,
};

const RulePageContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RulePage);

export default RulePageContainer;
