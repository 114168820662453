import { connect } from 'react-redux';

import { RightPanel } from '../components/IncidentPage/RightPanel';
import {
  incCardComments,
  incCardCommentsIsFetching,
  incCardAddCommentSubmitting,
  incCardAddCommentErrors,
} from '../store/reducers';
import {
  fetchIncComments,
  submitComment,
} from '../store/reducers/incCard/actions';

const mapStateToProps = (state, ownProps) => ({
  submitting: incCardAddCommentSubmitting(state),
  errors: incCardAddCommentErrors(state),
  comments: incCardComments(state),
  fetching: incCardCommentsIsFetching(state),
});

const mapDispatchToProps = {
  fetchIncComments,
  submitComment,
};

const IncidentPageRightPanelContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RightPanel);
export default IncidentPageRightPanelContainer;
