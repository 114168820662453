import { api, SOC_URL } from '@soc/kit/api';

import { normalizeSensors } from 'components/RuleListPage/Panel/SectionSensors/actions/helpers';

import { SensorGroupType, FetchSensorsResponseType } from '../types';

export const abortControllerKey = 'soc/fetch/sensors';

type FetchSensorsType = (id: string) => Promise<SensorGroupType[]>;

export const fetchSensors: FetchSensorsType = async (id) => {
  return api<FetchSensorsResponseType>(`${SOC_URL}rules/${id}/`, {
    method: 'get',
    abortControllerKey,
  }).then(normalizeSensors);
};
