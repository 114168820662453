import React, { Component } from 'react';
import ReactGridLayout from 'react-grid-layout';
import { withSize } from 'react-sizeme';

import shallowEqual from 'shallowequal';

import WidgetContainer from '../../containers/WidgetContainer';

import './DashboardGrid.scss';
import { EPS_DISABLED } from '../../utils/brending';

class DashboardGrid extends Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !(
      nextProps.cols === this.props.cols &&
      nextProps.widgets.length === this.props.widgets.length &&
      nextProps.widgets.reduce(
        (res, cur, i) => res && shallowEqual(cur, this.props.widgets[i]),
        true,
      ) &&
      nextProps.layout.length === this.props.layout.length &&
      nextProps.layout.reduce(
        (res, cur, i) => res && shallowEqual(cur, this.props.layout[i]),
        true,
      ) &&
      nextProps.size.width === this.props.size.width
    );
  }

  render() {
    const {
      cols,
      layout,
      setGridLayout,
      size: { width },
      widgets,
    } = this.props;

    const gridProps = {
      onLayoutChange: setGridLayout,
      layout,
      cols,
      margin: [16, 16],
      draggableHandle: '.box .bz-icon-drag',
    };

    return (
      <ReactGridLayout width={width} {...gridProps} rowHeight={30}>
        {widgets
          .filter((widget) => !EPS_DISABLED || widget.id !== 'epsWidget')
          .map((widget) => (
            <div key={widget.id}>
              <WidgetContainer widget={widget} />
            </div>
          ))}
      </ReactGridLayout>
    );
  }
}

export default withSize()(DashboardGrid);
