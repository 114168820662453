import React, { FC, memo } from 'react';
import { Translate } from 'react-redux-i18n';

import { Flex, Text, Tooltip } from 'combinezone/core/index';
import { LinkExternal } from 'combinezone/icons/index';

import { MitreProps } from '../types';

const Mitre: FC<MitreProps> = ({ id, isNeedTooltip, mitres }) => (
  <div className="Mitre">
    <Text className="Caption">
      <Translate value="rulePage.mitre" />
    </Text>
    <Flex className="Tag-Content">
      {mitres.map((mitre) =>
        !mitre.extraLength ? (
          <Tooltip
            key={`${id}_${mitre.technique_id}`}
            content={isNeedTooltip({ text: mitre.fullName, maxLength: 23 })}
            position="top"
          >
            <a
              href={mitre.url}
              target="_blank"
              className="Badge Badge-Link-Color Badge-Border-Link"
              onClick={(e) => e.stopPropagation()}
              rel="noreferrer"
            >
              <Text
                testId={`${id}_${mitre.fullName}`}
                className="Badge Badge-Text Badge-Link-Color"
                isClipped
              >
                {mitre.fullName}
              </Text>
              <LinkExternal
                size="14px"
                className="Badge-Link Badge-Link-Color"
              />
            </a>
          </Tooltip>
        ) : (
          <Text
            key={`${id}_${mitre.technique_id}`}
            testId={`${id}_${mitre.technique_id}`}
            className="Badge Badge-InnerText Badge-Border"
          >
            {mitre.fullName}
          </Text>
        ),
      )}
    </Flex>
  </div>
);

export default memo(Mitre);
