import { connect } from 'react-redux';

import IncidentList from '../components/IncidentList';
import { INCIDENTS_ENTITY } from '../services/api';
import {
  currentLocale,
  getOrdering,
  getSearch,
  getSelectedFilters,
} from '../store/reducers';
import {
  searchChange,
  setFilter,
  setSort,
  localSaveFilters,
} from '../store/reducers/filters/actions';
import { fetchMore, firstFetch } from '../store/reducers/incidentList/actions';
import { fetchMitreMapping } from '../store/reducers/mitre/actions';

const mapStateToProps = (state) => ({
  filters: getSelectedFilters(INCIDENTS_ENTITY, state),
  incidents: state.incidentList.incidents,
  isFetching: state.incidentList.fetching,
  hasMore: state.incidentList.hasMore,
  locale: currentLocale(state),
  search: getSearch(INCIDENTS_ENTITY, state),
  updateFunction: firstFetch,
  ordering: getOrdering(INCIDENTS_ENTITY, state),
});

const mapDispatchToProps = {
  firstFetch,
  fetchMore,
  fetchMitreMapping,
  onChangeSearch: (search) =>
    searchChange(INCIDENTS_ENTITY, search, firstFetch),
  setInitSearch: (search) => searchChange(INCIDENTS_ENTITY, search),
  setInitFilter: (key, value, callback) =>
    setFilter(INCIDENTS_ENTITY, key, value),
  setInitSort: (ordering) => setSort(INCIDENTS_ENTITY, ordering),
  localSaveFilters,
};

const IncTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IncidentList);
export default IncTableContainer;
