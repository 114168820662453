import { ASSIGN_TO_INCIDENT_SUCCESS } from 'store/reducers/common/actions/assign/actionTypes';

import { KEY_FIELD, RATING_COMMENT, RATING_STARS } from '../../../services/api';

import * as types from './actionTypes';

const initialState = {
  incidents: [],
  incidentsCount: 0,
  fetching: false,
  hasMore: true,
  nextPage: 2,
  lastFetchTime: null,
};

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.FIRST_FETCH_REQUEST: {
      return {
        ...state,
        lastFetchTime: action.meta.timestamp,
        nextPage: 2,
        fetching: true,
      };
    }

    case types.FETCH_MORE_REQUEST: {
      return {
        ...state,
        fetching: true,
      };
    }

    case types.FIRST_FETCH_SUCCESS: {
      if (state.lastFetchTime > action.meta.timestamp) {
        return state;
      }

      return {
        ...state,
        fetching: false,
        incidents: action.payload.results,
        incidentsCount: action.payload.count,
        selected: action.payload.results[0],
        hasMore: Boolean(action.payload.next),
      };
    }

    case types.ADD_FEEDBACK_SUCCESS: {
      let selected = {};
      const newIncidents = state.incidents.map((inc) => {
        if (inc[KEY_FIELD] === action.meta.incNumber) {
          const newInc = {
            ...inc,
            [RATING_STARS]: action.meta.body.rating,
            [RATING_COMMENT]: action.meta.body.comment,
          };
          selected = newInc;
          return newInc;
        }

        return inc;
      });

      return {
        ...state,
        incidents: newIncidents,
        selected:
          state.selected[KEY_FIELD] === action.meta.incNumber
            ? selected
            : state.selected,
      };
    }

    case types.FETCH_MORE_SUCCESS: {
      const newItems = action.payload.results.filter(
        (item) => state.incidents.findIndex((inc) => inc.id === item.id) === -1,
      );

      return {
        ...state,
        fetching: false,
        nextPage: state.nextPage + 1,
        incidents: [...state.incidents, ...newItems],
        hasMore: Boolean(action.payload.next),
      };
    }

    case types.FIRST_FETCH_FAIL:
    case types.FETCH_MORE_FAIL: {
      return {
        ...state,
        fetching: false,
      };
    }

    case types.SELECT_INC: {
      return {
        ...state,
        selected: action.meta.incident,
      };
    }

    case ASSIGN_TO_INCIDENT_SUCCESS: {
      const renewIncidents = state.incidents.map((incident) =>
        incident.key === action.meta ? action.payload : incident,
      );

      return {
        ...state,
        incidents: renewIncidents,
        selected: action.payload,
      };
    }

    default:
      return state;
  }
}

export function getNextPage(state) {
  return state.nextPage;
}

export function getSelectedIncident(state) {
  return state.selected;
}
