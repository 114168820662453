import * as types from './actionTypes';

const initialState = {
  instructions: [],
  instructionsCount: 0,
  fetching: false,
  hasMore: true,
  nextPage: 2,
  lastFetchTime: null,
};

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.FIRST_FETCH_REQUEST: {
      return {
        ...state,
        nextPage: 2,
        lastFetchTime: action.meta.timestamp,
        fetching: true,
      };
    }

    case types.FETCH_MORE_REQUEST: {
      return {
        ...state,
        fetching: true,
      };
    }

    case types.FIRST_FETCH_SUCCESS: {
      if (state.lastFetchTime > action.meta.timestamp) {
        return state;
      }

      return {
        ...state,
        fetching: false,
        instructions: action.payload.results,
        instructionsCount: action.payload.count,
        selected: action.payload.results[0],
        hasMore: Boolean(action.payload.next),
      };
    }

    case types.FETCH_MORE_SUCCESS: {
      if (state.lastFetchTime > action.meta.timestamp) {
        return state;
      }

      return {
        ...state,
        fetching: false,
        instructions: [...state.instructions, ...action.payload.results],
        nextPage: state.nextPage + 1,
        hasMore: Boolean(action.payload.next),
      };
    }

    case types.FIRST_FETCH_FAIL:
    case types.FETCH_MORE_FAIL: {
      return {
        ...state,
        fetching: false,
      };
    }

    case types.SELECT_INSTRUCTION: {
      return {
        ...state,
        selected: action.meta.instruction,
      };
    }

    default:
      return state;
  }
}

export function getNextPage(state) {
  return state.nextPage;
}
