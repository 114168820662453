import React from 'react';
import { I18n } from 'react-redux-i18n';

import { Button } from '@bizone/ui-bundle/esm/Button';
import { Input } from '@bizone/ui-bundle/esm/Input';

export default class SearchBlock extends React.Component {
  state = {
    searchText: '',
  };

  componentWillMount() {
    const { defaultText = '' } = this.props;
    this.setState({
      searchText: defaultText,
    });
  }

  onChangeSearch = (value) => {
    const { searchText } = this.state;
    const { defaultText, onSearch } = this.props;

    if (!value.length && searchText.length && defaultText.length) {
      onSearch(value);
    }

    this.setState({
      searchText: value,
    });
  };

  onSearch = () => {
    const { onSearch } = this.props;
    const { searchText } = this.state;

    const trimmedText = searchText.trim();

    this.setState({
      searchText: trimmedText,
    });

    onSearch(trimmedText);
  };

  render() {
    const { placeholder } = this.props;
    const { searchText } = this.state;

    return (
      <div className="ListPageHeader-Search">
        <Input
          fluid
          placeholder={placeholder}
          clearable
          icon="search"
          value={searchText}
          onChange={this.onChangeSearch}
          onEnter={this.onSearch}
        />
        <Button onClick={this.onSearch}>
          {I18n.t('@@commonComponents.ListPage.SearchBlock.button')}
        </Button>
      </div>
    );
  }
}
