/**
 * @noflow
 */
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  MutableRefObject,
} from 'react';
import { Translate } from 'react-redux-i18n';

import { Collapse } from 'antd';
import {
  IconButton,
  Heading,
  Tabs,
  Tab,
  Flex,
  Progress,
  Text,
} from 'combinezone/core';
import { AngleLeft, AngleUp, AngleAllUp, DotResizer } from 'combinezone/icons';

import { isPanelSection, isPanelTabArray, PanelProps } from '../types';
import { useResize } from '../useResize';

import '../Panel.scss';

const changeSize = (
  resizableContainerElement: any,
  width: any,
  offset: any,
  min: any,
  max: any,
) => {
  const newValue = width - offset;
  if (newValue > min && newValue < max) {
    resizableContainerElement.style['flex-basis'] = `${newValue}px`;
    return newValue;
  }
  return width;
};
type DefaultPanelProps<Entity> = PanelProps<Entity> & {
  width?: number;
  isResizingWidth?: boolean;
  panelRef?: MutableRefObject<HTMLDivElement | null>;
  resizeMakerRef?: MutableRefObject<HTMLDivElement | null>;
};

export default function DefaultPanel<Entity>({
  className,
  content,
  controls,
  entity,
  isResizingWidth = false,
  loading = false,
  panelRef,
  resizable = false,
  resizeMakerRef,
  title,
  width = 400,
}: DefaultPanelProps<Entity>) {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [opened, setOpened] = useState<string[] | string>([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const currentPanelContent = isPanelTabArray(content)
    ? content[activeTabIndex].content
    : content;

  const onClickAllSections = useCallback(() => {
    if (typeof currentPanelContent !== 'function') {
      setOpened(opened.length === 0 ? Object.keys(currentPanelContent) : []);
    }
  }, [setOpened, opened, currentPanelContent]);

  const toggleSection = useCallback(() => {
    setIsOpen(!isOpen);
  }, [setIsOpen, isOpen]);

  const panelIsEmpty =
    !entity ||
    (typeof entity === 'object' &&
      Object.keys(entity).length === 0 &&
      !Array.isArray(entity));

  const panelClassName = useMemo(() => {
    const incidentPanelClass = ['ListPagePanelSoc'];
    if (!isOpen) {
      incidentPanelClass.push('ListPagePanelSoc_closed');
    }

    return incidentPanelClass.join(' ');
  }, [entity, isOpen]);

  useEffect(() => {
    if (currentPanelContent) {
      setOpened(Object.keys(currentPanelContent));
    }
  }, [entity]);

  return (
    <div
      className={`${panelClassName} ${className || ''}`}
      style={{ flexBasis: width }}
      ref={panelRef}
    >
      {resizable && (
        <div
          className="ListPagePanelSoc-ResizeMarker"
          ref={resizeMakerRef}
          style={{ display: isOpen ? 'flex' : 'none' }}
        >
          <DotResizer />
        </div>
      )}
      <div className="ListPagePanelSoc-Content">
        <div className="ListPagePanelSocHeader">
          <IconButton
            testId="ListPage_Panel_open"
            icon={AngleLeft}
            tooltip={null}
            className={`PanelIcon${isOpen ? ' PanelIcon_active' : ''}`}
            onClick={toggleSection}
          />
          <Text isClipped={isOpen} numOfLines={1} className="Header">
            {!panelIsEmpty ? title(entity) : 'Нет данных'}{' '}
          </Text>
          <div className="ListPagePanelSocHeader-Controls">
            {!panelIsEmpty && controls && controls(entity, isOpen)}
            {isOpen && typeof currentPanelContent === 'object' && (
              <IconButton
                testId="ListPage_Panel_openAllSections"
                tooltip={null}
                icon={AngleAllUp}
                onClick={onClickAllSections}
                className={`PanelIcon${
                  opened.length === 0 ? ' PanelIcon_active' : ''
                }`}
              />
            )}
          </div>
        </div>
        {!panelIsEmpty && isOpen && isPanelTabArray(content) && (
          <div className="ListPagePanelSoc-Tabs">
            <Tabs
              type="horizontal"
              onChange={(tabIndex) => setActiveTabIndex(tabIndex)}
              selectedTabIndex={activeTabIndex}
            >
              {content.map(({ name }, index) => (
                <Tab testId="test" key={`${name}${index}`} index={index}>
                  {name}
                </Tab>
              ))}
            </Tabs>
          </div>
        )}
        <Collapse
          className="ListPagePanelSoc-Body"
          bordered={false}
          expandIcon={({ isActive, ...rest }) => (
            <IconButton
              testId="ListPage_Panel_openAllSections"
              className={`PanelIcon${!isActive ? ' PanelIcon_active' : ''}`}
              icon={AngleUp}
              tooltip={null}
            />
          )}
          activeKey={opened}
          expandIconPosition="right"
          onChange={setOpened}
        >
          {!panelIsEmpty ? (
            <>
              {currentPanelContent &&
                (typeof currentPanelContent !== 'function'
                  ? Object.entries(currentPanelContent)
                      .filter(
                        ([_, item]) =>
                          isPanelSection(item) &&
                          (typeof item.visible !== 'function' ||
                            item.visible(entity)),
                      )
                      .map(
                        ([sectionKey, section]) =>
                          isPanelSection(section) && (
                            <Collapse.Panel
                              header={
                                <Heading className="Header">
                                  {section.title}
                                </Heading>
                              }
                              key={sectionKey}
                            >
                              {section.renderer(entity)}
                            </Collapse.Panel>
                          ),
                      )
                  : currentPanelContent(entity, width, isResizingWidth))}
            </>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'calc(100vh - 150px)',
              }}
            >
              <Heading variant="placeholder">
                <Translate value="panel.noData" />
              </Heading>{' '}
            </div>
          )}
          {loading && <Progress />}
        </Collapse>
      </div>
    </div>
  );
}
