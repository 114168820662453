import React, { FC } from 'react';
import { I18n } from 'react-redux-i18n';

import styled from 'styled-components';

import { Flex, TextLink } from 'combinezone/core';
import { CombinezoneTheme } from 'combinezone/theme';

import LogSourcesEpsInfo from 'components/LogSources/LogSourcesCard/components/LogSourcesCardEpsInfo';
import LogSourcesCardHeader from 'components/LogSources/LogSourcesCard/components/LogSourcesCardHeader';
import LogSourcesInfo from 'components/LogSources/LogSourcesCard/components/LogSourcesCardInfo';
import LogSourcesCardReason from 'components/LogSources/LogSourcesCard/components/LogSourcesCardReason';
import { LogSourcesCardProps } from 'components/LogSources/LogSourcesCard/types';

import { PARSED_ACTIVITY_DETECTED } from '../../../services/api';
import { getFilterQueryStr } from '../../../utils/helpers';

const LogSourcesCardWrapper = styled(Flex)`
  background-color: ${({ theme }: { theme: CombinezoneTheme }) =>
    theme.basis?.colors.base.block};
  border-radius: 4px;
  padding: 16px 24px;
  margin-bottom: 16px;
  box-shadow: ${({ theme }: { theme: CombinezoneTheme }) =>
    `0 0 4px 0 ${theme.basis?.colors.shadows.default}`};
  max-width: 1920px;
  min-width: 1280px;
  width: 100%;
`;

const LogSourcesCardLeftInfo = styled(Flex)`
  flex-direction: column;
  flex: 1;
`;

const LogSourcesCardRightInfo = styled(Flex)`
  width: 500px;
  justify-content: flex-end;
`;

const LogSourcesLink = styled(TextLink)`
  text-decoration: none;
  display: flex;
  justify-content: right;
  width: 100%;
  align-self: flex-start;
  color: ${({ theme }: { theme: CombinezoneTheme }) =>
    theme.basis.colors.link.normal};
`;

const LogSourcesCard: FC<LogSourcesCardProps> = ({ source }) => {
  const testId = 'log-source-card';

  return (
    <LogSourcesCardWrapper testId={testId}>
      <LogSourcesCardLeftInfo testId={`${testId}-left-block`}>
        <LogSourcesCardHeader source={source} testId={testId} />
        {source.is_active && (
          <LogSourcesEpsInfo source={source} testId={testId} />
        )}
        <LogSourcesInfo source={source} testId={testId} />
        {!source.is_active && (
          <LogSourcesCardReason source={source} testId={testId} />
        )}
      </LogSourcesCardLeftInfo>
      <LogSourcesCardRightInfo testId={`${testId}-right-block`}>
        <LogSourcesLink
          testId={`${testId}-link`}
          newTab
          to={`/incidents/${getFilterQueryStr({
            [PARSED_ACTIVITY_DETECTED]: source.id,
          })}`}
        >
          {I18n.t('logSourcesCard.incList')}
        </LogSourcesLink>
      </LogSourcesCardRightInfo>
    </LogSourcesCardWrapper>
  );
};

export default LogSourcesCard;

LogSourcesCard.displayName = 'LogSourcesCard';
