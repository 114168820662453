import React from 'react';

import './index.scss';

export default function GeneralBlock({ title, value }) {
  return (
    <div className="GeneralBlock">
      <div className="GeneralBlock-Value">{value}</div>
      <div className="GeneralBlock-Title">{title}</div>
    </div>
  );
}
