import { AnyAction } from 'redux';

import * as types from './actionTypes';
import {
  AssignFailureType,
  AssignRequestType,
  AssignSuccessType,
} from './types';

export const isAssignRequest = (
  action: AnyAction,
): action is AssignRequestType => {
  return action.type === types.ASSIGN_TO_INCIDENT_REQUEST;
};

export const isAssignFail = (
  action: AnyAction,
): action is AssignFailureType => {
  return action.type === types.ASSIGN_TO_INCIDENT_FAIL;
};

export const isAssignSuccess = (
  action: AnyAction,
): action is AssignSuccessType => {
  return action.type === types.ASSIGN_TO_INCIDENT_SUCCESS;
};
