import * as React from 'react';

import { bemCls } from '@common/soc-react-kit';
import { OverlaySpinner } from 'combinezone/core/index';
import marked from 'marked';

import { API_URL } from '../../../services/api';
import { apiFetch } from '../../../services/apiFetch';
import Markdown from '../../Markdown';

import './Description.scss';

export default class Description extends React.PureComponent {
  constructor(props) {
    super(props);

    this.images = [];
    this.urls = {};

    this.container = React.createRef();
    this.markdownElem = React.createRef();

    this.renderer = new marked.Renderer();
    this.renderer.image = (src, title, alt) => {
      const exec = /=\s*(\d*)\s*x\s*(\d*)\s*$/.exec(title);
      this.images.push(src);

      return `<img alt="${alt}" height=${exec && exec[1]} width=${
        exec && exec[2]
      }>`;
    };

    this.state = {
      isExpanded: false,
      isExpandable: true,
      value: marked(props.value, { renderer: this.renderer }),
      isLoading: false,
      maxHeight: '28.2em',
    };
  }

  toggleExpanded = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  componentWillUnmount() {
    Object.values(this.urls).forEach((url) => URL.revokeObjectURL(url));
  }

  componentDidMount() {
    const { value } = this.props;

    if (value?.length > 0) {
      this.loadImages();
      this.checkCollapsible();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props;

    if (prevProps.value !== value) {
      this.setState({
        value: marked(value, { renderer: this.renderer }),
      });
    }

    if (prevState.value !== this.state.value && this.state.value?.length > 0) {
      this.loadImages();
      this.checkCollapsible();
    }
  }

  checkCollapsible = () => {
    const { collapsible = true, initialIsExpanded = true } = this.props;

    if (collapsible) {
      const container = this.container.current;

      this.setState({
        isExpandable: container.scrollHeight > container.clientHeight,
        isExpanded: initialIsExpanded,
      });
    } else {
      this.setState({
        isExpandable: false,
        isExpanded: true,
      });
    }
  };

  loadImages = () => {
    this.images.forEach(async (fileUrl, index) => {
      const currentImgElement =
        this.markdownElem.current.getElementsByTagName('img')[index];

      if (fileUrl.charAt(0) !== '/') {
        currentImgElement?.setAttribute('src', fileUrl);
        return;
      }

      if (this.urls[fileUrl]) {
        currentImgElement?.setAttribute('src', this.urls[fileUrl]);
        return;
      }

      const response = await apiFetch.get(
        `${API_URL}${fileUrl.replace('/api/v0', '')}`,
        {
          fullResponse: true,
          showNotification: false,
        },
      );

      if (response.ok) {
        const type = response.headers.get('Content-Type');
        const blob = await response.blob();
        const file = new File([blob], index, { type });
        const source = URL.createObjectURL(file);
        currentImgElement?.setAttribute('src', source);
        this.urls[fileUrl] = source;
      }
    });
    this.images = [];
  };

  render() {
    const { less, more } = this.props;
    const { isExpandable, isExpanded, isLoading, maxHeight, value } =
      this.state;
    const className = bemCls('DescriptionContainer', {
      collapsed: !this.state.isExpanded,
    });

    return isLoading ? (
      <OverlaySpinner />
    ) : (
      <React.Fragment>
        <div
          className={className}
          style={!isExpanded ? { maxHeight } : {}}
          ref={this.container}
        >
          <Markdown ref={this.markdownElem} value={value} />
        </div>
        {isExpandable && (
          <div className="ReadMoreBtn" onClick={this.toggleExpanded}>
            <span>{isExpanded ? less : more}</span>
          </div>
        )}
      </React.Fragment>
    );
  }
}
