import { connect } from 'react-redux';

import Versions from '../components/InstructionPage/VersionsModal';
import { fetchVersions } from '../store/reducers/responseInstructionCard/actions';

const mapStateToProps = (state) => ({
  versions: state.responseInstructionCard.versions,
});

const mapDispatchToProps = {
  fetchVersions,
};

const VersionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Versions);
export default VersionsContainer;
