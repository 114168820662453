import React from 'react';
import { I18n } from 'react-redux-i18n';

import { Card, Text, Heading, Tooltip } from 'combinezone/core';
import { ClockSmall } from 'combinezone/icons';
import moment from 'moment';

import './index.scss';
import { UserIcon } from '../../Icons/UserIcon';
import { ChatRowPropsType } from '../types';

export const ChatRow = ({ author, body, created }: ChatRowPropsType) => {
  return (
    <Card
      className="IncidentCommentCard"
      testId="ChatRowCard"
      headerContent={
        <>
          <Heading>
            {author?.includes('@cert.gov.ru')
              ? I18n.t('incCard.nccci')
              : author}
          </Heading>
          <div className="IncidentCommentCard-Time">
            <Tooltip content={moment(created).format('LLL')}>
              <div className="RowWithIcon">
                <ClockSmall className="icon" />
                <Text variant="secondary">
                  {moment(created).format('HH:mm')}
                </Text>
              </div>
            </Tooltip>
          </div>
        </>
      }
      icons={[<UserIcon className="IncidentCommentCard-UserIcon" />]}
    >
      <div
        style={{
          minWidth: '160px',
        }}
      >
        <Text>
          <span dangerouslySetInnerHTML={{ __html: body }} />
        </Text>
      </div>
    </Card>
  );
};
