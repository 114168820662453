import { AnyAction } from 'redux';

import * as types from './actionTypes';

export type FailureType<T> = {
  error: true;
  type: T;
  payload: {
    message: string;
    name: string;
    stack: string;
  };
};

export type SuccessType<T, R, M = undefined> = {
  error: undefined;
  type: T;
  payload: R;
  meta: M;
};

// Incidents

export type FetchAssetCardIncidentsRequestType = {
  type: typeof types.FETCH_ASSET_CARD_INCIDENTS_REQUEST;
};

export const fetchAssetCardIncidentsRequest = (
  action: AnyAction,
): action is FetchAssetCardIncidentsRequestType => {
  return action.type === types.FETCH_ASSET_CARD_INCIDENTS_REQUEST;
};

export type FetchAssetCardIncidentsSuccessType = SuccessType<
  typeof types.FETCH_ASSET_CARD_INCIDENTS_SUCCESS,
  any,
  any
>;

export const fetchAssetCardIncidentsSuccess = (
  action: AnyAction,
): action is FetchAssetCardIncidentsSuccessType => {
  return action.type === types.FETCH_ASSET_CARD_INCIDENTS_SUCCESS;
};

export type FetchAssetCardIncidentsFailureType = FailureType<
  typeof types.FETCH_ASSET_CARD_INCIDENTS_FAILURE
>;

export const fetchAssetCardIncidentsFailure = (
  action: AnyAction,
): action is FetchAssetCardIncidentsFailureType => {
  return action.type === types.FETCH_ASSET_CARD_INCIDENTS_FAILURE;
};

// More Incidents
export type FetchAssetCardMoreIncidentsRequestType = {
  type: typeof types.FETCH_ASSET_CARD_MORE_INCIDENTS_REQUEST;
};

export const fetchAssetCardMoreIncidentsRequest = (
  action: AnyAction,
): action is FetchAssetCardMoreIncidentsRequestType => {
  return action.type === types.FETCH_ASSET_CARD_MORE_INCIDENTS_REQUEST;
};

export type FetchAssetCardMoreIncidentsSuccessType = SuccessType<
  typeof types.FETCH_ASSET_CARD_MORE_INCIDENTS_SUCCESS,
  any,
  any
>;

export const fetchAssetCardMoreIncidentsSuccess = (
  action: AnyAction,
): action is FetchAssetCardMoreIncidentsSuccessType => {
  return action.type === types.FETCH_ASSET_CARD_MORE_INCIDENTS_SUCCESS;
};

export type FetchAssetCardMoreIncidentsFailureType = FailureType<
  typeof types.FETCH_ASSET_CARD_MORE_INCIDENTS_FAILURE
>;

export const fetchAssetCardMoreIncidentsFailure = (
  action: AnyAction,
): action is FetchAssetCardMoreIncidentsFailureType => {
  return action.type === types.FETCH_ASSET_CARD_MORE_INCIDENTS_FAILURE;
};
