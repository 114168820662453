import React, { useCallback, useEffect, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useDispatch } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';

import { Button } from '@bizone/ui-bundle/esm/Button';
import { Form } from '@bizone/ui-bundle/esm/Form';
import { Header } from '@bizone/ui-bundle/esm/Header';
import { Message } from '@bizone/ui-bundle/esm/Message';
import { Text } from '@bizone/ui-bundle/esm/Text';
import { Textarea } from '@bizone/ui-bundle/esm/Textarea';

import { FocusButton, getFormErrors } from '@common/soc-react-kit';
import { Modal, Switch } from 'antd';
import { useTranslate } from 'combinezone/utils';

import {
  fetchIncCard,
  fetchIncComments,
  updateActionIncident,
} from '../../../store/reducers/incCard/actions';

import locales from './locales';

const FORM_ERROR = 'FINAL_FORM/form-error';

function StatusActions({ actions, incActionUpdated, incNumber, params }) {
  const t = useTranslate(locales);
  const [visible, setVisible] = useState(false);
  const [commentValue, setCommentValue] = useState('');
  const [resolutionSelectValue, setResolutionSelectValue] = useState(true);
  const [isReassign, setIsReassign] = useState(true);
  const [currentAction, setCurrentAction] = useState('');
  const dispatch = useDispatch();
  const formRef = useRef();

  const onError = useCallback((result) => {
    const form = formRef.current;
    const errors = result.payload.response;
    const finalFormErrors = {};

    if (errors && Object.keys(errors).length) {
      const [fields, nonFields] = getFormErrors(errors);

      if (fields) {
        Object.keys(fields).forEach((item) => {
          finalFormErrors[item] = [
            ...(form.errors[item] || []),
            ...fields[item],
          ]
            .filter(Boolean)
            .map((text, i) => (
              <Text key={i} size={12} paragraph>
                {text}
              </Text>
            ));
        });
      }
      if (nonFields) {
        finalFormErrors[FORM_ERROR] = JSON.stringify(nonFields);
      }
    } else if (typeof errors === 'undefined') {
      finalFormErrors[FORM_ERROR] = 'Потеряно соединение';
    }

    return finalFormErrors;
  }, []);

  const onAddComment = useCallback(async () => {
    if (currentAction === 'confirm') {
      return dispatch(
        updateActionIncident(
          { comment: commentValue, change_customer_assignee: isReassign },
          'confirm',
        ),
      ).then((result) => result.error && onError(result));
    }

    if (currentAction === 'close') {
      return dispatch(
        updateActionIncident(
          {
            comment: commentValue,
            resolution_name: resolutionSelectValue
              ? 'True Positive'
              : 'False Positive',
            change_customer_assignee: isReassign,
          },
          'close',
        ),
      ).then((result) => result.error && onError(result));
    }

    if (currentAction === 'reopen') {
      return dispatch(
        updateActionIncident(
          { comment: commentValue, change_customer_assignee: isReassign },
          'reopen',
        ),
      ).then((result) => result.error && onError(result));
    }
  }, [
    currentAction,
    commentValue,
    dispatch,
    resolutionSelectValue,
    onError,
    isReassign,
  ]);

  const onCancelClick = useCallback(() => {
    setVisible(false);
    setCommentValue('');
  }, [setVisible, setCommentValue]);

  const onModalClose = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const onActionCardClick = useCallback(
    (status) => {
      setVisible(true);
      setCurrentAction(status);
    },
    [setVisible, setCurrentAction],
  );

  const setResolution = useCallback((action) => {
    if (['close', 'confirm', 'reopen'].includes(action)) {
      setVisible(true);
      setCurrentAction(action);
    }
  }, []);

  const onClick = useCallback(() => {
    return formRef.current.handleSubmit();
  }, []);

  const saveFormRef = useCallback((ref) => {
    formRef.current = ref;
  }, []);

  useEffect(() => {
    if (params.actions && actions.includes(params.actions)) {
      setResolution(params.actions);
    }
  }, [params.actions, setResolution, actions]);

  useEffect(() => {
    if (incActionUpdated) {
      dispatch(fetchIncCard(incNumber));
      dispatch(fetchIncComments(incNumber));
    }
  }, [incActionUpdated, incNumber, dispatch]);

  return (
    <React.Fragment>
      <div className="actionIncWrap">
        {actions.map((item, index) => (
          <div
            key={index}
            className="actionIncItem"
            onClick={() => onActionCardClick(item)}
          >
            <Translate value={`incCard.statusActions.${item}`} />
          </div>
        ))}
      </div>
      <Form cls="createInc" onSubmit={onAddComment}>
        {(form) => {
          saveFormRef(form);
          const {
            hasSubmitErrors,
            hasValidationErrors,
            submitErrors,
            validating,
          } = form;

          const isInvalid =
            hasValidationErrors ||
            (hasSubmitErrors &&
              !Object.keys(submitErrors).every(
                (key) => form.form.getFieldState(key).dirtySinceLastSubmit,
              ));
          return (
            <Modal
              title={
                <Header size={20}>
                  <Translate
                    value={`incCard.statusActions.header.${currentAction}`}
                  />
                </Header>
              }
              visible={visible}
              onCancel={onModalClose}
              width={600}
              className="change-inc-action"
              footer={[
                <Switch
                  onChange={setIsReassign}
                  style={{
                    float: 'left',
                    marginTop: 10,
                  }}
                  defaultChecked
                  checkedChildren={t('assign')}
                  unCheckedChildren={t('assign')}
                />,
                <React.Fragment>
                  {currentAction === 'close' && (
                    <Switch
                      onChange={setResolutionSelectValue}
                      style={{
                        float: 'left',
                        marginTop: 10,
                      }}
                      checkedChildren="TP"
                      unCheckedChildren="FP"
                      defaultChecked
                    />
                  )}
                </React.Fragment>,
                <FocusButton
                  key="save"
                  rich
                  primary
                  disabled={validating || isInvalid}
                  onClick={onClick}
                >
                  <Translate
                    value={`incCard.statusActions.ok.${currentAction}`}
                  />
                </FocusButton>,
                <Button key="cancel" onClick={onCancelClick}>
                  <Translate
                    value={`incCard.statusActions.cancel.${currentAction}`}
                  />
                </Button>,
              ]}
            >
              <Form.Item
                required
                requiredTitle={I18n.t('incCard.addCommentComponent.isRequired')}
                label={I18n.t('incCard.statusActions.commentLabel')}
                name="comment"
                onChange={setCommentValue}
              >
                <Textarea
                  className="Panel-Textarea"
                  placeholder={I18n.t(
                    'incCard.statusActions.commentPlaceholder',
                  )}
                  value={commentValue}
                />
              </Form.Item>
              <div style={{ margin: '10px 0px' }}>
                {(currentAction === 'close' || currentAction === 'confirm') && (
                  <Message icon="warning" warning>
                    <Translate
                      value={`incCard.statusActions.message.${currentAction}`}
                    />
                  </Message>
                )}
              </div>
            </Modal>
          );
        }}
      </Form>
    </React.Fragment>
  );
}

export default React.memo(StatusActions, isEqual);
