import React, { useEffect, useMemo, useState } from 'react';
import Helmet from 'react-helmet';
import Linkify from 'react-linkify';
import { useParams } from 'react-router-dom';

import { Block } from '@bizone/ui-bundle/esm/Block';
import { Header } from '@bizone/ui-bundle/esm/Header';
import { Icon } from '@bizone/ui-bundle/esm/Icon';

import { Layout, ListSection } from '@common/soc-react-kit';
import { Divider } from 'antd';

import { API_URL } from '../../services/api';
import { apiFetch } from '../../services/apiFetch';
import { priorityIconProps } from '../../utils/helpers';
import Description from '../InstructionPage/Description';

import './CyberPolygonRule.scss';

export default function CyberPolygonRule() {
  const [rule, setRule] = useState();
  const [criticalityRow, setCriticalityRow] = useState(null);
  const [confidenceRow, setConfidenceRow] = useState(null);
  const { name: huntName } = useParams();

  const {
    confidence,
    description_eng: description,
    links: references,
    mitre_tactics_to_techniques: tactics,
    rule_caption_eng: name,
    severity: criticality,
  } = rule || {};

  useEffect(() => {
    const getRule = async () => {
      if (huntName) {
        const rule = await apiFetch.get(
          `${API_URL}/soc/cyberpolygon/hunt/${huntName}/`,
        );
        setRule(rule);
      }
    };

    getRule();
  }, [huntName]);

  useEffect(() => {
    setConfidenceRow(
      confidence && (
        <div className="RowWithIcon">
          <Icon size={20} {...priorityIconProps[confidence.toLowerCase()]} />
          {confidence}
        </div>
      ),
    );
  }, [confidence]);

  useEffect(() => {
    setCriticalityRow(
      criticality && (
        <div className="RowWithIcon">
          <Icon size={20} {...priorityIconProps[criticality.toLowerCase()]} />
          {criticality}
        </div>
      ),
    );
  }, [criticality]);

  const listData = {
    Severity: criticalityRow,
    Confidence: confidenceRow,
    Description: <div className="RuleDescription">{description}</div>,
    References: (
      <Linkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
          <div key={key}>
            <a target="blank" href={decoratedHref}>
              {decoratedText}
            </a>
          </div>
        )}
      >
        {references}
      </Linkify>
    ),
  };
  const formatter = (key, value, index) => {
    if (index === 0) {
      return {
        key: <span style={{ color: '#6983a2' }}>{key}</span>,
        value: <span style={{ color: '#6983a2' }}>{value}</span>,
      };
    }
    return {
      key: <span style={{ color: '#c8d4e1' }}>{key}</span>,
      value: <span>{value}</span>,
    };
  };

  const mitreListData = useMemo(
    () =>
      tactics &&
      Object.entries(tactics).reduce(
        (obj, [name, technicians]) => ({
          ...obj,
          [name]: technicians.map(({ description, link, name }) => (
            <div className="TechniqueInfo" key={name}>
              <div className="TechniqueInfo-Name">{name}</div>
              <div className="TechniqueInfo-Link">
                <a href={link}>More about this technique</a>
              </div>
              <div className="TechniqueInfo-Description">
                <Description
                  rows={3}
                  initialIsExpanded={false}
                  less="Collapse"
                  more="View full description"
                  value={description}
                />
              </div>
            </div>
          )),
        }),
        {},
      ),
    [tactics],
  );

  return (
    <React.Fragment>
      <Helmet>
        <title>Indicator of Attack Description</title>
      </Helmet>
      <Layout.BreadCrumb style={{ backgroundColor: '#131c26' }}>
        <div className="RuleHeader">
          <Header size={16}>Indicator of Attack Description</Header>
        </div>
      </Layout.BreadCrumb>
      <Layout.Container id="IncCardContainer" className="Rule">
        <Layout.Content>
          <Block cls="Rule-Block">
            <Block.Content cls="Rule-BlockContent">
              <div className="Rule-BlockHeader">
                <Header size={16}>{name}</Header>
              </div>
              <ListSection data={listData} />
              <Divider className="Rule-BlockDivider" />
              <ListSection.Table
                customFormatter={formatter}
                titles={['Tactics', 'Techniques']}
                header="MITRE ATT&CK"
                data={mitreListData}
              />
            </Block.Content>
          </Block>
        </Layout.Content>
      </Layout.Container>
    </React.Fragment>
  );
}
