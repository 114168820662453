import { AnyAction } from 'redux';

import * as match from './actionMatchTypes';
import { AssetsPageType } from './types';

const initialState: AssetsPageType = {
  incidents: {
    fetching: false,
    fetchingMore: false,
    data: [],
    next: null,
  },
};

export default function reduce(
  state = initialState,
  action: AnyAction,
): AssetsPageType {
  if (match.fetchAssetCardIncidentsRequest(action)) {
    return {
      ...state,
      incidents: {
        ...state.incidents,
        fetching: true,
      },
    };
  }

  if (match.fetchAssetCardIncidentsFailure(action)) {
    return {
      ...state,
      incidents: {
        ...state.incidents,
        fetching: false,
      },
    };
  }

  if (match.fetchAssetCardIncidentsSuccess(action)) {
    return {
      ...state,
      incidents: {
        ...state.incidents,
        data: action.payload.results || [],
        next: action.payload.next,
        fetching: false,
      },
    };
  }

  if (match.fetchAssetCardMoreIncidentsRequest(action)) {
    return {
      ...state,
      incidents: {
        ...state.incidents,
        fetchingMore: true,
      },
    };
  }

  if (match.fetchAssetCardMoreIncidentsFailure(action)) {
    return {
      ...state,
      incidents: {
        ...state.incidents,
        fetchingMore: false,
      },
    };
  }

  if (match.fetchAssetCardMoreIncidentsSuccess(action)) {
    return {
      ...state,
      incidents: {
        ...state.incidents,
        data: [...state.incidents.data, ...(action.payload.results || [])],
        next: action.payload.next,
        fetchingMore: false,
      },
    };
  }
  return state;
}

export const incidents = (state: AssetsPageType): any[] => state.incidents.data;
export const incidentsFetching = (state: AssetsPageType): boolean =>
  state.incidents.fetching;
export const incidentsCount = (state: AssetsPageType): number =>
  state.incidents?.data?.length;
export const incidentsGetNext = (state: AssetsPageType): string | null =>
  state.incidents?.next;
export const incidentsHasMore = (state: AssetsPageType): boolean =>
  !!state.incidents?.next;
export const incidentsIsFetchingMore = (state: AssetsPageType): boolean =>
  state.incidents?.fetchingMore;
