import React, { useCallback, useEffect, useRef, useState } from 'react';

export default function ImagePreview({ src }) {
  const [isZoomed, setZoom] = useState(false);
  const [canBeZoomed, setCanBeZoomed] = useState(false);

  const imgRef = useRef(null);

  const checkZoom = useCallback(() => {
    setCanBeZoomed(imgRef.current.naturalWidth > window.innerWidth);
  }, []);

  const zoom = useCallback(() => {
    if (canBeZoomed) {
      setZoom(!isZoomed);
    }
  }, [canBeZoomed, isZoomed]);

  useEffect(() => {
    checkZoom();
    window.addEventListener('resize', checkZoom);

    return () => window.removeEventListener('resize', checkZoom);
  }, [checkZoom]);

  let className = '';
  if (canBeZoomed) {
    className = isZoomed ? 'can_zoom_out' : 'can_zoom';
  }

  return (
    <div className="ImagePreview">
      <img
        ref={imgRef}
        className={className}
        src={src}
        alt=""
        onLoad={checkZoom}
        onClick={zoom}
      />
    </div>
  );
}
