import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { Breadcrumbs } from '@bizone/ui-bundle/esm/Breadcrumbs';
import { Header } from '@bizone/ui-bundle/esm/Header';
import { Icon } from '@bizone/ui-bundle/esm/Icon';
import { IconButton } from '@bizone/ui-bundle/esm/IconButton';

import { bemCls, Layout, ListSection, TagGroup } from '@common/soc-react-kit';
import { Collapse } from 'antd';
import { Tag, Text } from 'combinezone/core';
import moment from 'moment';

import { RULES_CREATED } from '../../../services/api';
import {
  currentLocale,
  getMitreTactics,
  getMitreTechniques,
} from '../../../store/reducers';
import {
  priorityIconProps,
  tooLongStringFormatter,
} from '../../../utils/helpers';

import './Panel.scss';
import { SectionSensors } from 'components/RuleListPage/Panel';

const allSections = ['mainInfo', 'mitre', 'sensors'];
const testId = "panel";

const Panel = ({ rule }) => {
  const allTactics = useSelector(getMitreTactics);
  const allTechniques = useSelector(getMitreTechniques);

  const locale = useSelector(currentLocale);

  const [opened, setOpened] = useState(allSections);

  const isRequested = rule.is_requested
    ? {
        [I18n.t('rulePage.isRequested')]: {
          text: <Icon size={24} color="#57ab37" glyph="check-circle-outline" />,
          help: I18n.t('rulePage.isRequestedTooltip'),
        },
      }
    : {};

  const mainInfo = useMemo(
    () => ({
      [I18n.t('rulePage.created')]: moment(rule[RULES_CREATED]).format('LLL'),
      [I18n.t('rulePage.availableForServices')]: (
        <TagGroup>
          {rule.available_for_services?.map((service, i) => (
            <Tag key={service}>{service}</Tag>
          ))}
        </TagGroup>
      ),
      [I18n.t('rulePage.confidence')]: {
        text: (
          <div className="RowWithIcon">
            <Icon
              size={24}
              {...priorityIconProps[rule.confidence?.toLowerCase()]}
            />
            {I18n.t(`rulePage.priorityTypes.${rule.confidence?.toLowerCase()}`)}
          </div>
        ),
        help: I18n.t('rulePage.confidenceHelp'),
      },
      [I18n.t('rulePage.severity')]: {
        text: (
          <div className="RowWithIcon">
            <Icon
              size={24}
              {...priorityIconProps[rule.severity?.toLowerCase()]}
            />
            {I18n.t(`rulePage.priorityTypes.${rule.severity?.toLowerCase()}`)}
          </div>
        ),
        help: I18n.t('rulePage.severityHelp'),
      },
      [I18n.t('rulePage.category')]: (
        <TagGroup>
          {rule.category?.map((category, i) => (
            <Tag key={i}>{category}</Tag>
          ))}
        </TagGroup>
      ),
      ...isRequested,
      [I18n.t('rulePage.mode')]: {
        text: (
          <Text>{I18n.t(`rulePage.modeTypes.${rule.activation_mode}`)}</Text>
        ),
        help: I18n.t('rulePage.modeTooltip'),
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }),
    [locale, rule],
  );

  const mitreData = useMemo(() => {
    return rule.mitre_cov
      ? Object.entries(rule.mitre_cov).reduce(
          (acc, [tactic, techniques = []]) => {
            const tacticTitle =
              allTactics[tactic]?.name || tacticTitleFromId(tactic);

            return {
              ...acc,
              [tacticTitle]: (
                <TagGroup>
                  {techniques.map((tech, i) => (
                    <TagGroup.Tag key={i}>
                      {tech}
                      {allTechniques[tech] && `: ${allTechniques[tech]?.name}`}
                    </TagGroup.Tag>
                  ))}
                </TagGroup>
              ),
            };
          },
          {},
        )
      : {};
  }, [allTactics, allTechniques, rule.mitre_cov]);

  return (
    <div className="RulePagePanel">
      <Layout.BreadCrumb>
        <Breadcrumbs
          path={[
            {
              icon: 'soc',
              url: '/dashboard',
            },
            {
              text: I18n.t('titles.ruleList'),
              url: '/docs/rules',
              color: 'red',
            },
            {
              text:
                tooLongStringFormatter(
                  rule[I18n.t('rulePage.captionField')],
                  35,
                ) || ' ',
            },
          ]}
        />
      </Layout.BreadCrumb>
      <div className="RulePagePanel-Header">
        <Header size={16}>
          {tooLongStringFormatter(rule[I18n.t('rulePage.captionField')], 42)}
        </Header>
        <IconButton
          basic
          icon="multi-angle-up"
          onClick={() =>
            setOpened((opened) => (opened.length === 0 ? allSections : []))
          }
          cls={bemCls('PanelIcon', { active: opened.length === 0 })}
        />
      </div>
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <IconButton
            cls={bemCls('PanelIcon', { active: isActive })}
            basic
            icon="angle-down"
          />
        )}
        activeKey={opened}
        expandIconPosition="right"
        onChange={setOpened}
        className="RulePagePanel-Body"
      >
        <Collapse.Panel
          header={<Header size={14}>{I18n.t('rulePage.mainInfo')}</Header>}
          key="mainInfo"
        >
          <ListSection data={mainInfo} />
        </Collapse.Panel>
        <Collapse.Panel
          header={<Header size={14}>{I18n.t('incCard.mitre.title')}</Header>}
          key="mitre"
        >
          <ListSection.Table
            data={mitreData}
            titles={[
              I18n.t('incCard.mitre.tactics'),
              I18n.t('incCard.mitre.techniques'),
            ]}
          />
        </Collapse.Panel>

        <Collapse.Panel
          header={<Header size={14}>{I18n.t("rulePage.sensors")}</Header>}
          key="sensors"
        >
          <SectionSensors id={rule.id} testId={testId} />
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

function tacticTitleFromId(tactic) {
  return tactic
    .split('-')
    .map((word) => `${word[0].toUpperCase()}${word.slice(1)}`)
    .join(' ');
}

export default Panel;
