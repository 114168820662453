import React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import { Button } from '@bizone/ui-bundle/esm/Button';
import { Form } from '@bizone/ui-bundle/esm/Form';
import { Header } from '@bizone/ui-bundle/esm/Header';
import { Input } from '@bizone/ui-bundle/esm/Input';

import notification from '../Notification';

import './ChangePassword.scss';

export default class ChangePassword extends React.Component {
  handleSubmit = async (values) => {
    await this.props.changePassword(
      values.oldPassword,
      values.password,
      values.confirm,
    );
    const { errors, isChanged, onSuccess } = this.props;
    if (isChanged) {
      onSuccess();
    }
    return errors;
  };

  handleValidate = (values) => {
    const errors = {};

    errors.oldPassword = values.oldPassword
      ? undefined
      : I18n.t('changePassword.required');
    errors.confirm = values.confirm
      ? undefined
      : I18n.t('changePassword.required');

    if (values.confirm !== values.password) {
      errors.confirm = I18n.t('changePassword.mustMatch');
    }
    if (!values.password) {
      errors.password = I18n.t('changePassword.required');
    } else if (values.password.length < 9) {
      errors.password = I18n.t('changePassword.passwordLengthInvalid');
    } else if (!/^(?=.*[\W_A-Za-z])[\W\w]{9,}$/.test(values.password)) {
      errors.password = I18n.t('changePassword.passwordInvalid');
    }
    return errors;
  };

  componentDidUpdate() {
    const { history, isChanged } = this.props;
    if (isChanged) {
      notification.success({ message: I18n.t('changePassword.success') });
      history.goBack();
    }
  }

  render() {
    const { history } = this.props;

    return (
      <div className="change-password-form-wrapper">
        <Form
          className="change-password-form"
          onSubmit={this.handleSubmit}
          initialValues={{
            password: '',
            oldPassword: '',
            confirm: '',
          }}
          validate={this.handleValidate}
        >
          {({
            form,
            handleSubmit,
            hasSubmitErrors,
            hasValidationErrors,
            submitErrors,
            submitting,
          }) => {
            const isIinvalid =
              hasValidationErrors ||
              (hasSubmitErrors &&
                !Object.keys(submitErrors).every(
                  (key) => form.getFieldState(key).dirtySinceLastSubmit,
                ));
            return (
              <div>
                <Header size={24}>
                  <Translate value="changePassword.header" />
                </Header>
                <Form.Item
                  name="oldPassword"
                  label={<Translate value="changePassword.oldPassword" />}
                >
                  <Input type="password" value="" />
                </Form.Item>
                <Form.Item
                  name="password"
                  label={<Translate value="changePassword.password" />}
                  validateField={['password']}
                >
                  <Input type="password" value="" />
                </Form.Item>
                <Form.Item
                  name="confirm"
                  label={<Translate value="changePassword.confirm" />}
                >
                  <Input type="password" value="" />
                </Form.Item>
                <Button
                  primary
                  rich
                  loading={submitting}
                  disabled={isIinvalid}
                  onClick={handleSubmit}
                >
                  {I18n.t('changePassword.submit')}
                </Button>
                <Button onClick={history.goBack} default>
                  {I18n.t('changePassword.cancel')}
                </Button>
              </div>
            );
          }}
        </Form>
      </div>
    );
  }
}
