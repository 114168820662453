import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import { Breadcrumbs } from '@bizone/ui-bundle/esm/Breadcrumbs';
import { Button } from '@bizone/ui-bundle/esm/Button';
import { Header } from '@bizone/ui-bundle/esm/Header';
import { Icon } from '@bizone/ui-bundle/esm/Icon';
import { IconButton } from '@bizone/ui-bundle/esm/IconButton';
import { List } from '@bizone/ui-bundle/esm/List';
import { Loader } from '@bizone/ui-bundle/esm/Loader';
import { Text } from '@bizone/ui-bundle/esm/Text';
import { Tooltip } from '@bizone/ui-bundle/esm/Tooltip';

import { FocusButton, Layout, ListSection } from '@common/soc-react-kit';
import { Collapse, Modal } from 'antd';
import Select from 'antd/es/select';
import moment from 'moment';

import { ID_FIELD, INCIDENTS_ENDPOINT, KEY_FIELD } from '../../../services/api';
import { incidentFormatter } from '../../../utils/helpers';
import AssessmentBlock from '../../IncidentList/Panel/AssessmentBlock';
import EditableInput from '../EditableInput';
import StatusActions from '../StatusActions';

import '../../IncidentList/Panel/Panel.scss';
import { ListSectionWithOverflowValue } from 'components/ListSectionWithOverflowValue';

const { Option } = Select;
const { mainInfoFormatter } = incidentFormatter;
const allSections = ['summary', 'main', 'mitre', 'ipAndActors', 'rating'];

const Priority = ({ color, glyph, label }) => (
  <div className="icon-option-wrap">
    <Icon glyph={glyph} color={color} />
    <div className="text-option">{label}</div>
  </div>
);

const priorityItems = {
  Low: <Priority glyph="arrow-down" color="#57ad37" label="Low" />,
  Medium: (
    <Priority glyph="false-positive-not" color="#ebaf09" label="Medium" />
  ),
  High: <Priority glyph="arrow-up" color="#eb6f1c" label="High" />,
};

const priorityItemsWithCritical = {
  ...priorityItems,
  Critical: (
    <Priority glyph="multi-angle-up" color="#e0281b" label="Critical" />
  ),
};
export class Panel extends React.Component {
  state = {
    opened: allSections,
    priorityModalVisible: false,
    currentAction: '',
    newPriority: '',
    commentValue: '',
    resolutionSelectValue: '',
    modalSelectValue: '',
  };

  componentDidMount() {
    const {
      getAvailableActions,
      match: {
        params: { incNumber },
      },
      params: { updatePriority },
    } = this.props;
    getAvailableActions(incNumber);

    if (updatePriority) {
      this.setState({
        priorityModalVisible: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      getAvailableActions,
      incActionUpdated,
      match: {
        params: { incNumber },
      },
      params: { updatePriority },
    } = this.props;

    if (prevProps.params.updatePriority !== updatePriority && updatePriority) {
      this.setState({
        priorityModalVisible: true,
      });
    }

    if (prevProps.incActionUpdated !== incActionUpdated && incActionUpdated) {
      getAvailableActions(incNumber);
    }
  }

  onModalClose = () => {
    this.setState({ priorityModalVisible: false });
  };

  onModalPrioritySelectChange = (value) => {
    this.setState({ modalSelectValue: value });
  };

  onEditPriority = () => {
    const { modalSelectValue } = this.state;

    this.onUpdatePriority(modalSelectValue);
    this.setState({ priorityModalVisible: false });
  };

  onUpdatePriority = (priority) => {
    const {
      match: {
        params: { incNumber },
      },
      updateIncCard,
    } = this.props;

    updateIncCard(incNumber, { priority });
  };

  downloadIncident = (e) => {
    e.stopPropagation();
    const { downloadFile, incident = {} } = this.props;
    const id = incident[KEY_FIELD];

    downloadFile(`${INCIDENTS_ENDPOINT}${id}/get_pdf_inc/`, `${id}.pdf`);
  };

  render() {
    const {
      availableActions,
      className = '',
      incActionUpdated,
      incident = {},
      match: {
        params: { incNumber },
      },
      mitreTactics,
      mitreTechniques,
      params,
      sendFeedback,
      sendToGossopkaTime,
    } = this.props;
    const { modalSelectValue, newPriority, opened, priorityModalVisible } =
      this.state;
    const listData = mainInfoFormatter(incident, {
      mitreTactics,
      mitreTechniques,
    });

    const copyInfoForIncPage = { ...listData.mainInfoForIncPage };
    const copyDetailsInfoForIncPage = { ...listData.detailsInfoForIncPage };

    const actionsInc = {
      [I18n.t('incCard.actions')]:
        availableActions.data.length > 0 ? (
          <StatusActions
            incNumber={incNumber}
            incActionUpdated={incActionUpdated}
            actions={availableActions.data}
            params={params}
          />
        ) : (
          '-'
        ),
    };

    const editablePriority = {
      [I18n.t('incCard.priority')]: (
        <EditableInput
          items={priorityItems}
          readInputRender={(inputValue) =>
            priorityItemsWithCritical[inputValue]
          }
          inputValue={newPriority || incident.priority}
          onUpdatePriority={this.onUpdatePriority}
        />
      ),
    };
    const infoForIncPage = {
      ...editablePriority,
      [I18n.t('incCard.status')]: listData.sla[I18n.t('incCard.status')],
      ...actionsInc,
      ...copyInfoForIncPage,
    };
    const moreInfoForIncPage = {
      ...copyDetailsInfoForIncPage,
    };

    const commentProp = I18n.t('incCard.rating.comment');
    const { [commentProp]: comment, ...withoutComment } = listData.rating || {};
    const gossopkaTime = sendToGossopkaTime
      ? moment(sendToGossopkaTime).format('DD MMMM YYYY, HH:mm')
      : '';

    return (
      <div className={`IncidentListPanel IncidentPagePanel ${className}`}>
        <Layout.BreadCrumb>
          {incident && (
            <Breadcrumbs
              path={[
                {
                  icon: 'soc',
                  url: '/dashboard',
                },
                {
                  text: I18n.t('incCard.header.incidents'),
                  url: '/incidents',
                  color: '#1896cc',
                },
                {
                  text: incident.key || ' ',
                },
              ]}
            />
          )}
        </Layout.BreadCrumb>
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => (
            <IconButton
              cls={`PanelIcon${isActive ? ' PanelIcon_active' : ''}`}
              basic
              icon="angle-down"
            />
          )}
          activeKey={opened}
          expandIconPosition="right"
          onChange={(opened) => this.setState({ opened })}
        >
          <Collapse.Panel
            header={
              <React.Fragment>
                <Tooltip
                  content={I18n.t(`incidentList.panel.downloadIncident`)}
                  delay={200}
                  inverted
                >
                  <IconButton
                    basic
                    icon="file-download"
                    onClick={this.downloadIncident}
                  />
                </Tooltip>
                <Header size={16}>{incident.key}</Header>
              </React.Fragment>
            }
            key="main"
          >
            {sendToGossopkaTime && (
              <div className="aboutNCCCIWrap">
                <div className="iconMonitoringWrap">
                  <Icon glyph="monitoring-ok" />
                </div>
                <div className="aboutNCCCIText">
                  {I18n.t('incCard.NCCCI.stamp')} {gossopkaTime}
                </div>
              </div>
            )}
            {Object.keys(incident).length ? (
              <>
                <ListSection
                  width={120}
                  header={<Translate value="incCard.mainInfo" />}
                  data={infoForIncPage}
                />
                <ListSectionWithOverflowValue
                  width={120}
                  data={listData.assignRows}
                />
                <ListSection
                  width={120}
                  header={<Translate value="incCard.moreInfo" />}
                  data={moreInfoForIncPage}
                />
              </>
            ) : (
              <Loader size={32} />
            )}
          </Collapse.Panel>
          <Collapse.Panel
            header={<Header size={16}>{I18n.t('incCard.mitre.title')}</Header>}
            key="mitre"
          >
            {listData.mitre ? (
              <ListSection.Table
                width={120}
                data={listData.mitre}
                titles={[
                  I18n.t('incCard.mitre.tactics'),
                  I18n.t('incCard.mitre.techniques'),
                ]}
              />
            ) : (
              <div className="noData">{I18n.t('incCard.mitre.noData')}</div>
            )}
          </Collapse.Panel>
          <Collapse.Panel
            key="rating"
            header={<Header size={16}>{I18n.t('incCard.rating.title')}</Header>}
          >
            {listData.rating ? (
              <div className="Panel-RatingSection">
                <List data={withoutComment} keysWidth={120} />
                <Text size={14}>{commentProp}</Text>
                <div>{comment}</div>
              </div>
            ) : (
              <AssessmentBlock
                key={incident[ID_FIELD]}
                onSubmit={sendFeedback}
              />
            )}
          </Collapse.Panel>
        </Collapse>
        <Modal
          title={<Translate value="incCard.updatePriority" />}
          visible={priorityModalVisible}
          onCancel={this.onModalClose}
          width={600}
          className="change-inc-priority"
          footer={[
            <FocusButton key="save" rich primary onClick={this.onEditPriority}>
              <Translate value="dashboard.save" />
            </FocusButton>,
            <Button key="cancel" onClick={this.onModalClose}>
              <Translate value="dashboard.cancel" />
            </Button>,
          ]}
        >
          <Select
            className="priority-select"
            value={modalSelectValue}
            onChange={this.onModalPrioritySelectChange}
          >
            {Object.entries(priorityItems).map(([value, content]) => (
              <Option value={value} key={value}>
                {content}
              </Option>
            ))}
          </Select>
        </Modal>
      </div>
    );
  }
}
