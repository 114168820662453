import React from 'react';

import { bemCls } from '@common/soc-react-kit';

import './LoadingBar.scss';

export default function LoadingBar({ percent }) {
  return (
    <div className="LoadingBar-Container">
      <div
        className={bemCls('LoadingBar', { done: percent === 100 })}
        style={{ width: `${percent}%` }}
      />
    </div>
  );
}
