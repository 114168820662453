import * as types from './actionTypes';

const initialState = {
  isLoading: true,
  user: null,
  errors: {},
};

export default function auth(state = initialState, action = {}) {
  switch (action.type) {
    case types.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case types.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isChanged: true,
        isLoading: false,
        errors: {},
      };

    case types.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isChanged: false,
        isLoading: false,
        errors: action.payload.response,
      };

    case types.ON_SUCCESS:
      return {
        ...state,
        isChanged: false,
      };

    default:
      return state;
  }
}
