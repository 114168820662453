import React from 'react';
import { Translate } from 'react-redux-i18n';
import { Switch, useParams, Redirect } from 'react-router-dom';

import { Layout } from '@common/soc-react-kit';

import Articles from '../../containers/ArticlesContainer';
import Instructions from '../../containers/InstructionsContainer';
import ResponseInstructions from '../../containers/ResponseInstructionsContainer';
import RuleList from '../../containers/RuleListContainer';
import { RulesIcon } from '../RuleListPage/Icons';

import Panel from './Panel';

import './Documentation.scss';

const { Container, Content } = Layout;

const config = {
  instructions: {
    tab: <Translate value="documents.instructions" />,
    component: <Instructions />,
    icon: 'file-text-outline',
  },
  responseInstructions: {
    tab: <Translate value="documents.responseInstructions" />,
    component: <ResponseInstructions />,
    icon: 'manager-rules',
  },
  articles: {
    tab: <Translate value="documents.articles" />,
    component: <Articles />,
    icon: 'newspaper',
  },
  rules: {
    tab: <Translate value="documents.rules" />,
    component: <RuleList />,
    icon: () => <RulesIcon className="RulesIcon" size="22px" />,
  },
};

export default function Documentation() {
  const { tab = 'instructions' } = useParams();

  return (
    <Container panel className="DocumentationContainer">
      <Panel config={config} selectedTab={tab} setTab={tab} />
      <Content className="DocumentationContent">
        <Switch>
          {config[tab] ? config[tab].component : <Redirect to="/dashboard" />}
        </Switch>
      </Content>
    </Container>
  );
}
