import { connect } from 'react-redux';

import { MitreWidget } from '../components/MitreWidget';
import {
  getMitreMapping,
  getMitreTactics,
  getMitreTacticsIds,
  mitreDataSelector,
} from '../store/reducers';
import { fetchMitreMapping } from '../store/reducers/mitre/actions';

function mapStateToProps(state) {
  const { mitreData, typeCounts } = mitreDataSelector(state);

  return {
    mitreMapping: getMitreMapping(state),
    mitreData,
    typeCounts,
    tactics: getMitreTactics(state),
    tacticsIds: getMitreTacticsIds(state),
  };
}

const mapDispatchToProps = {
  fetchMitreMapping,
};

const MitreWidgetContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MitreWidget);
export default MitreWidgetContainer;
