import { connect } from 'react-redux';

import ReportsShedules from '../components/ReportsShedules';
import { currentLocale } from '../store/reducers';
import {
  fetchMore,
  firstFetch,
} from '../store/reducers/reportsShedules/actions';

const mapStateToProps = (state) => {
  return {
    reportsShedules: state.reportsShedules.reportsShedules,
    isFetching: state.reportsShedules.fetching,
    hasMore: state.reportsShedules.hasMore,
    locale: currentLocale(state),
  };
};

const mapDispatchToProps = {
  firstFetch,
  fetchMore,
};

const InstructionsTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportsShedules);
export default InstructionsTableContainer;
