import {
  SubTechnique,
  Technique,
} from 'components/RulesMitreWidget/TechniqueTable/components/TechniqueCell/types';
import {
  COUNT_FOR_HIGH_TYPE_RULES_MITRE,
  COUNT_FOR_HIGHER_TYPE_RULES_MITRE,
  COUNT_FOR_MEDIUM_TYPE_RULES_MITRE,
  COUNT_FOR_NONE_TYPE_RULES_MITRE,
  COUNT_TYPE_RULES_MITRE,
} from 'components/RulesMitreWidget/constants';
import { TechniqueTypes } from 'components/RulesMitreWidget/enums';
import {
  CurrentOpenedTechniques,
  MitreDataTableTactic,
  MitreDataTacticTechnique,
  MitreMapping,
  TechniqueTableCell,
} from 'components/RulesMitreWidget/types';

export const getTypeRulesMitre = (
  count: number = COUNT_FOR_NONE_TYPE_RULES_MITRE,
  total = COUNT_TYPE_RULES_MITRE,
): TechniqueTypes => {
  if (count === COUNT_FOR_NONE_TYPE_RULES_MITRE) {
    return TechniqueTypes.None;
  }
  if (count > COUNT_FOR_HIGHER_TYPE_RULES_MITRE * total) {
    return TechniqueTypes.Higher;
  }
  if (count > COUNT_FOR_HIGH_TYPE_RULES_MITRE * total) {
    return TechniqueTypes.High;
  }
  if (count > COUNT_FOR_MEDIUM_TYPE_RULES_MITRE * total) {
    return TechniqueTypes.Medium;
  }

  return TechniqueTypes.Low;
};

export const calculateData = (
  mitreMapping: MitreMapping = {},
  techniques: { [key: string]: SubTechnique } = {},
  { tactics: mitreData }: { tactics: MitreDataTableTactic[] } = { tactics: [] },
): MitreDataTacticTechnique[][] => {
  const tactics = Object.keys(mitreData || {});

  const mtx = mitreData?.map((tactic, key) =>
    tactic?.techniques?.map((item) => {
      item.sub_techniques = item?.sub_techniques?.map((elem) => ({
        ...techniques[item.technique_id],
        // @ts-ignore
        key: `technique-${elem.technique_id}-${key}`,
        ...elem,
      }));

      return {
        ...techniques[item.technique_id],
        key: `technique-${item.technique_id}-${key}`,
        ...item,
      } as MitreDataTacticTechnique;
    }),
  );

  const maxLengthStr = Math.max(...mtx?.map((item) => item.length));
  const mitreDataTransp: MitreDataTacticTechnique[][] = [];

  for (let i = 0; i < maxLengthStr; i++) {
    mitreDataTransp[i] = Array(tactics.length).fill(undefined);
    for (let j = 0; j < tactics.length; j++) {
      mitreDataTransp[i][j] = mtx[j][i];
    }
  }

  return mitreDataTransp;
};

export const recalculateIJ = (
  i: number,
  j: number,
  currentOpenedTechniques: CurrentOpenedTechniques | null,
): [number, number] => {
  if (!currentOpenedTechniques) {
    return [i, j];
  }

  const subTechniques = currentOpenedTechniques?.technique?.sub_techniques;

  if (!subTechniques) {
    return [i, j];
  }

  const [str, col] = currentOpenedTechniques.coords;

  if (j === col && i > str && i < str + subTechniques.length) {
    return [-1, -1];
  }
  if (j === col && i >= str + subTechniques.length) {
    return [i - subTechniques.length + 1, j];
  }
  if (j === col + 1 && i >= str && i < str + subTechniques.length) {
    return [-1, -1];
  }
  if (j === col + 1) {
    return [-1, -1];
  }
  if (j > col + 1) {
    return [i, j - 1];
  }
  return [i, j];
};

export const addSubTechniques = (
  mitreData: TechniqueTableCell[][],
  technique: Technique,
  str: number,
  col: number,
): TechniqueTableCell[][] => {
  if (!mitreData || !mitreData[0]) {
    return mitreData;
  }
  if (!technique) {
    return mitreData;
  }

  const subTechniques = technique.sub_techniques;

  if (!subTechniques) {
    return mitreData;
  }

  const newMitreData: TechniqueTableCell[][] = [];
  for (let i = 0; i < mitreData.length + subTechniques.length - 1; i++) {
    for (let j = 0; j < mitreData[0].length + 1; j++) {
      if (!newMitreData[i]) {
        newMitreData[i] = [];
      }
      if (j === col && i > str && i < str + subTechniques.length) {
        newMitreData[i][j] = undefined;
      } else if (j === col && i >= str + subTechniques.length) {
        newMitreData[i][j] = mitreData[i - subTechniques.length + 1][j];
      } else if (j === col + 1 && i >= str && i < str + subTechniques.length) {
        newMitreData[i][j] = subTechniques[i - str];
      } else if (j === col + 1) {
        newMitreData[i][j] = undefined;
      } else if (j > col + 1 && i < mitreData.length) {
        newMitreData[i][j] = mitreData[i][j - 1];
      } else if (i < mitreData.length) {
        newMitreData[i][j] = mitreData[i][j];
      }
    }
  }

  return newMitreData;
};

export const hiddenWithBlankCounts = (
  mitreMapping: MitreMapping,
  mitreDataPrev: TechniqueTableCell[][],
  hideEmpty: boolean,
): TechniqueTableCell[][] => {
  const mitreData = structuredClone(mitreDataPrev);

  for (let k = 0; k < Object.keys(mitreMapping).length; k++) {
    for (let i = 0; i < mitreData.length; i++) {
      for (let j = 0; j < mitreData.length; j++) {
        if (mitreData[j] && mitreData[j]) {
          if (
            mitreData[j][k] &&
            hideEmpty &&
            getTypeRulesMitre(
              mitreData[j][k]?.count,
              COUNT_TYPE_RULES_MITRE,
            ) === TechniqueTypes.None
          ) {
            mitreData[j][k] = undefined;
          }
          if (
            mitreData[i][k] &&
            hideEmpty &&
            getTypeRulesMitre(
              mitreData[i][k]?.count,
              COUNT_TYPE_RULES_MITRE,
            ) === TechniqueTypes.None
          ) {
            mitreData[i][k] = undefined;
          }
          if (!mitreData[j][k] && mitreData[i][k]) {
            [mitreData[i][k], mitreData[j][k]] = [
              mitreData[j][k],
              mitreData[i][k],
            ];
          }
        }
      }
    }
  }

  for (let k = 0; k < Object.keys(mitreMapping).length; k++) {
    for (let i = 0; i < mitreData.length; i++) {
      if (mitreData[i][k]) {
        mitreData[i][k] = structuredClone(mitreData[i][k]);
        // @ts-ignore
        mitreData[i][k].sub_techniques = mitreData[i][
          k
        ]?.sub_techniques?.filter(
          (subTechnique: { count: number }) =>
            !(
              hideEmpty &&
              getTypeRulesMitre(subTechnique.count, COUNT_TYPE_RULES_MITRE) ===
                TechniqueTypes.None
            ),
        );

        if (!mitreData[i][k]?.sub_techniques?.length) {
          // @ts-ignore
          mitreData[i][k].sub_techniques = undefined;
        }
      }
    }
  }

  return mitreData;
};
