import React, { useEffect, useState } from 'react';
import { Translate } from 'react-redux-i18n';

import PropTypes from 'prop-types';

import OldGrid from 'components/OldGrid';

import '../IncidentPage.scss';

const headerClassName = 'Text Text_clipped Text_size_14 Grid-ColumnTitle';
const baseColumns = [
  {
    headerContent: (
      <Translate value="incCard.iocs.ioc" className={headerClassName} />
    ),
    id: 'ioc',
    minWidth: 130,
    verticalAlign: 'center',
  },
  {
    headerContent: (
      <Translate value="incCard.iocs.type" className={headerClassName} />
    ),
    id: 'ioc_type',
    minWidth: 100,
    verticalAlign: 'center',
  },
  {
    headerContent: (
      <Translate value="incCard.iocs.category" className={headerClassName} />
    ),
    id: 'category',
    minWidth: 100,
    verticalAlign: 'center',
  },
];

const types = {
  bankcard: 'Bank Card',
  certificate: 'Certificate',
  custom: 'Custom',
  device: 'Device',
  email: 'Email',
  file: 'File',
  fqdn: 'FQDN',
  ipv4: 'IPv4',
  ipv6: 'IPv6',
  ja3: 'JA3',
  message: 'Message',
  process: 'Process',
  registrykey: 'Registry Key',
  subscriber: 'Subscriber',
  url: 'URL',
  useraccount: 'Account',
};

export default function IOCTable({ data, locale }) {
  const [columns, setColumns] = useState(baseColumns);

  useEffect(() => {
    setColumns(baseColumns);
  }, [data]);

  return (
    <OldGrid
      resizeContentSyncTime={0}
      height={556}
      columns={columns}
      rows={normalizeData(data)}
      locale={locale}
    />
  );
}

IOCTable.propTypes = {
  data: PropTypes.array,
  locale: PropTypes.string,
};

IOCTable.defaultProps = {
  data: [],
  locale: 'en',
};

function normalizeData(data) {
  return data.map((row, i) => ({
    id: i.toString(),
    cells: {
      ...row,
      ioc_type: types[row.ioc_type],
      category: (
        <div>
          {(row.category || []).map((category) => (
            <div key={category}>{category}</div>
          ))}
        </div>
      ),
    },
  }));
}
