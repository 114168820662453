import { connect } from 'react-redux';

import CreateReport from '../components/CreateReport';
import {
  createReportModalSystems,
  isCreateReportModalOpen,
  isCreateReportModalLoading,
  currentLocale,
} from '../store/reducers';
import {
  openCreateReportModal,
  cancelCreateReportModal,
  fetchSystems,
  createReport,
  createReportTask,
} from '../store/reducers/createReport/actions';
import { createPeriodAndCrontab, validateEmail } from '../utils/helpers';

function mapStateToProps(state, ownProps) {
  return {
    systems: createReportModalSystems(state),
    isOpen: isCreateReportModalOpen(state),
    isLoading: isCreateReportModalLoading(state),
    locale: currentLocale(state),
    button: ownProps.children,
    createPeriodAndCrontab,
    validateEmail,
  };
}

const mapDispatchToProps = {
  onOpen: openCreateReportModal,
  onCancel: cancelCreateReportModal,
  createReport,
  createReportTask,
  fetchSystems,
};

const CreateReportFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateReport);
export default CreateReportFormContainer;
