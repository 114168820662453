import React, { useState, useRef, useEffect } from 'react';

const changeSizeDefault = (
  resizableContainerElement,
  width,
  offset,
  min,
  max,
) => {
  const newValue = width + offset;
  if (newValue > min && newValue < max) {
    resizableContainerElement &&
      (resizableContainerElement.style.width = newValue);
    return newValue;
  }
  return width;
};

const disableSelect = (e) => {
  return false;
};

export const useResize = ({
  changeSize = changeSizeDefault,
  defaultWidth = 410,
  draggableElement,
  max = 750,
  min = 410,
  resizableContainerElement,
} = {}) => {
  const [width, setWidth] = useState(defaultWidth);
  const [isResizingWidth, setIsResizingWidth] = useState(false);
  const widthRef = useRef(defaultWidth);
  const currentX = useRef(0);

  const handleMouseMove = (e) => {
    const offset = e.clientX - currentX.current;
    widthRef.current = changeSize(
      resizableContainerElement,
      widthRef.current,
      offset,
      min,
      max,
    );
    currentX.current += offset;
  };

  const handleMouseDown = (e) => {
    currentX.current = e.clientX;
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('selectstart', disableSelect);
    document.body.style['-webkit-user-select'] = 'none';
    document.body.style['-moz-user-select'] = '-moz-none';
    document.body.style['-ms-user-select'] = 'none';
    document.body.style['-user-select'] = 'none';
    setIsResizingWidth(true);
  };

  const handleMouseMouseUp = () => {
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('selectstart', disableSelect);
    document.body.style['-webkit-user-select'] = 'inherit';
    document.body.style['-moz-user-select'] = 'inherit';
    document.body.style['-ms-user-select'] = 'inherit';
    document.body.style['-user-select'] = 'inherit';
    setWidth(widthRef.current);
    setIsResizingWidth(false);
  };

  useEffect(() => {
    draggableElement?.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mouseup', handleMouseMouseUp);
    return () => {
      draggableElement?.removeEventListener('mousedown', handleMouseMouseUp);
      window.removeEventListener('mouseup', handleMouseDown);
    };
  }, [draggableElement]);

  return {
    width,
    isResizingWidth,
    handleMouseMove,
    handleMouseDown,
    handleMouseMouseUp,
  };
};
