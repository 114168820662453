import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { LogSourcesDownloadProps } from 'components/LogSources/LogSourcesDownload/types';
import { RootState } from 'components/LogSources/LogSourcesList/types';
import {
  LOGSOURCES_CSV_ASYNC_ENDPOINT,
  LOGSOURCES_CSV_ENDPOINT,
} from 'services/api';
import DownloadControl from 'shared/components/DownloadControl';
import { DownloadFormat } from 'shared/components/DownloadControl/enums';
import { urlEncodedLogSourcesFiltersSelector } from 'store/reducers';

const LogSourcesDownload: FC<LogSourcesDownloadProps> = ({ testId }) => {
  const testIdDownload = `${testId}-download`;
  const fileName = `log-sources.${DownloadFormat.Csv}`;
  const query = useSelector(urlEncodedLogSourcesFiltersSelector);
  const { activeForEndpoint, fetching, sources } = useSelector(
    (state: RootState) => state.logSources,
  );

  return (
    <DownloadControl
      testId={testIdDownload}
      format={DownloadFormat.Csv}
      endpoint={`${LOGSOURCES_CSV_ENDPOINT}${query}${activeForEndpoint}`}
      endpointAsync={`${LOGSOURCES_CSV_ASYNC_ENDPOINT}${query}${activeForEndpoint}`}
      fileName={fileName}
      isDisabled={fetching || !sources.length}
    />
  );
};

export default LogSourcesDownload;

LogSourcesDownload.displayName = 'LogSourcesDownload';
