import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { __RouterContext } from 'react-router';

import { parse } from 'qs';

import { updateQuery } from './url';

function useQueryParams() {
  const { history, location } = useContext(__RouterContext);
  const historyRef = useRef(history);

  const params = useMemo(
    () => parse(location.search, { ignoreQueryPrefix: true }),
    [location.search],
  );

  const handleUpdate = useCallback((params) => {
    const history = historyRef.current;

    if (typeof params === 'undefined') {
      history.push({ search: '' });
    } else {
      history.push({
        search: updateQuery(history.location.search, params),
      });
    }
  }, []);

  useEffect(() => {
    historyRef.current = history;
  }, [history]);

  return [params, handleUpdate];
}

export default useQueryParams;
