import React, { useEffect, useRef, useMemo } from 'react';
import { I18n } from 'react-redux-i18n';
import { useParams } from 'react-router-dom';

import { Layout } from '@common/soc-react-kit';
import { Tabs } from 'antd';
import { OverlaySpinner } from 'combinezone/core';

import { TitleComponent } from '../TitleComponent';

import Panel from './Panel';
import IncidentsTab from './Tabs/IncidentsTab';
import MainInfoTab from './Tabs/MainInfoTab';

import './RulePage.scss';

const { TabPane } = Tabs;

const RulePage = ({
  fetchMitreMapping,
  fetchRule,
  fetchingRule,
  mitreTechniques,
  rule,
}) => {
  const { id } = useParams();
  const ruleRef = useRef(rule);

  useEffect(() => {
    fetchMitreMapping();
  }, [fetchMitreMapping]);

  useEffect(() => {
    if (id !== ruleRef.current?.id) {
      fetchRule(id);
    }
  }, [fetchRule, id]);

  const ruleId = useMemo(() => rule.rule_id, [rule]);

  return (
    <>
      {fetchingRule && <OverlaySpinner />}
      <TitleComponent title={rule[I18n.t('rulePage.captionField')]} />
      <Layout.Container id="RulePageContainer" className="RulePage">
        <Layout.Content className="RulePage-Content">
          <Panel rule={rule} />
          <div className="RulePage-Tabs">
            <Tabs>
              <TabPane
                tab={<div>{I18n.t('rulePage.mainInfo')}</div>}
                key="mainInfo"
              >
                <MainInfoTab rule={rule} />
              </TabPane>
              <TabPane
                tab={<div>{I18n.t('rulePage.relatedIncidents')}</div>}
                key="incidents"
              >
                <IncidentsTab
                  mitreTechniques={mitreTechniques}
                  ruleId={ruleId}
                />
              </TabPane>
            </Tabs>
          </div>
        </Layout.Content>
      </Layout.Container>
    </>
  );
};

export default RulePage;
