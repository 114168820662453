import React from 'react';

import { Text } from 'combinezone/core/index';

export function isEqual(object1: any, object2: any) {
  const props1 = Object.getOwnPropertyNames(object1);
  const props2 = Object.getOwnPropertyNames(object2);

  if (props1.length !== props2.length) {
    return false;
  }

  for (let i = 0; i < props1.length; i += 1) {
    const prop = props1[i];
    const bothAreObjects =
      typeof object1[prop] === 'object' && typeof object2[prop] === 'object';

    if (
      (!bothAreObjects && object1[prop] !== object2[prop]) ||
      (bothAreObjects && !isEqual(object1[prop], object2[prop]))
    ) {
      return false;
    }
  }
  return true;
}

export const highlightSearch = (text: string, searchString: string) => {
  return <Text markedText={searchString}>{text}</Text>;
};
