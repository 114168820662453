import React, { FC, useMemo, useState, useEffect, useCallback } from "react";
import { I18n, Translate } from "react-redux-i18n";

import { api } from "@soc/kit/api";
import {
  Node,
  Checkbox,
  Dropdown,
  DebouncedInput,
  Caption,
  Text,
  Spinner,
} from "combinezone/core";
import { Search } from "combinezone/icons";

import { highlightSearch, isEqual } from "../../../helpers";
import { MultiSelectProps } from "../../../types";
import Tag from "../Tag";

type SuggestProps = MultiSelectProps & {
  getEndpoint: (search: string) => string;
  handleResult: (result: unknown) => { key: string; text: string }[];
};

const Suggest: FC<SuggestProps> = ({
  filter,
  getEndpoint,
  handleResult,
  initIsOpen = false,
  locale,
  setFilter,
  settings: { choices = [], label },
  value = [],
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [options, setOptions] = useState(choices);
  const [selected, setSelected] = useState<Record<string, boolean>>(
    value.reduce<Record<string, boolean>>((acc, el) => {
      acc[el] = true;
      return acc;
    }, {})
  );
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    setSelected((prev) =>
      options.reduce<Record<string, boolean>>(
        (selected, { key }) => {
          selected[key] = value.includes(key);
          return selected;
        },
        { ...prev }
      )
    );
  }, [options]);

  const onHideDropdown = () => {
    const result = Object.keys(selected).filter((key) => selected[key]);
    if (!isEqual(result, value)) {
      setFilter(filter, result);
    }
    setSearchString("");
    setOptions([]);
  };

  const fetchSuggest = useCallback(
    (search: string) => {
      setIsFetching(true);
      api(getEndpoint(search), {
        method: "get",
        isMainInstallation: true,
        abortControllerKey: `soc/suggest/filter/${filter}`,
      })
        .then(handleResult)
        .then(setOptions)
        .catch(() => setOptions([]))
        .finally(() => setIsFetching(false));
    },
    [getEndpoint, handleResult]
  );

  const onOpen = (): void => {
    fetchSuggest(searchString);
  };

  const onSelect = (key: string, isSelected: boolean): void => {
    setSelected((prev) => ({
      ...prev,
      [key]: isSelected,
    }));
  };

  const selectAll = (isSelected: boolean): void => {
    setSelected((prev) =>
      options.reduce<Record<string, boolean>>(
        (selected, { key }) => {
          selected[key] = isSelected;
          return selected;
        },
        { ...prev }
      )
    );
  };

  const selectedCount = options.reduce(
    (acc, { key }) => acc + Number(selected[key]),
    0
  );

  const suggestMenuInput = useMemo(
    () => (
      <DebouncedInput
        className="Panel-Filter-Search-Input"
        testId={`Panel_Filter_Search_Input_${label}`}
        value={searchString}
        placeholder={I18n.t("filterPanel.filter.searchPlaceholder")}
        LeftIcon={Search}
        onChange={(value) => setSearchString(value.toLowerCase())}
        onClear={() => setSearchString("")}
        changeDelayMs={300}
      />
    ),
    [locale, searchString]
  );

  const suggestMenu = (
    <div className="Panel-Popover-Container">
      <div className="Panel-Filter-Search">
        {suggestMenuInput}
        {options.length !== 0 && (
          <>
            <Caption
              className="Panel-Filter-Search-SelectAll"
              onClick={() => selectAll(!selectedCount)}
            >
              <Translate
                value={`filterPanel.filter.${
                  selectedCount ? "clearAll" : "chooseAll"
                }`}
              />
            </Caption>
            <div className="Panel-Filter-Search-Separate" />
          </>
        )}
      </div>
      {isFetching ? (
        <Spinner size="sm" />
      ) : options.length === 0 ? (
        <Node testId="Node_NotFound" className="Dropdown-List">
          <Text>
            <Translate value="filterPanel.filter.notFound" />
          </Text>
        </Node>
      ) : (
        <div className="Dropdown-List">
          {options.map(({ key, text }) => (
            <Node
              key={key}
              className={`Dropdown-List-Node${selected[key] && "-Selected"}`}
              testId={`Node_MultiSelect_${key}`}
              onClick={() => onSelect(key, !selected[key])}
            >
              <Checkbox
                testId={`Node_MultiSelect_Checkbox_${key}`}
                isChecked={selected[key]}
                label={highlightSearch(text, searchString)}
                onClick={(e) => e.stopPropagation()}
              />
            </Node>
          ))}
        </div>
      )}
    </div>
  );

  const tagText = useMemo(() => {
    const valueToText = value.map(
      (key) => options.find((choice) => choice.key === key)?.text || key
    );

    if (!valueToText.length) {
      return <Translate value="filterPanel.filter.notSelected" />;
    }

    return valueToText.length < 4
      ? valueToText.join(", ")
      : `${valueToText.slice(0, 3).join(", ")}... (+${valueToText.length - 3})`;
  }, [value, options]);

  useEffect(() => {
    fetchSuggest(searchString);
  }, [searchString]);

  return (
    <Dropdown
      content={suggestMenu}
      position="bottom-left"
      defaultIsOpen={initIsOpen}
      onClose={onHideDropdown}
      onOpen={onOpen}
    >
      {({ isOpen }) => (
        <Tag
          label={label}
          filter={filter}
          text={tagText}
          isActive={isOpen}
          setFilter={setFilter}
        />
      )}
    </Dropdown>
  );
};

export default Suggest;
