import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Translate } from 'react-redux-i18n';
import { Link } from 'react-router-dom';

import { Spin } from 'antd';
import PropTypes from 'prop-types';

import {
  DOCUMENTATION_ENDPOINTS,
  INSTRUCTIONS_ID,
  INSTRUCTIONS_LANGUAGE,
} from '../../services/api';
import { apiFetch } from '../../services/apiFetch';

const DocumentAnotherLanguages = ({
  currentId,
  document,
  setHasSecondDocument,
  type,
}) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOk, setIsOk] = useState(true);
  const [retryKey, setRetryKey] = useState(0);
  const [savedDocumentId, setSavedDocumentId] = useState(document);

  if (document && savedDocumentId !== document) {
    setSavedDocumentId(document);
  }

  useEffect(() => {
    if (savedDocumentId) {
      setIsLoading(true);

      apiFetch
        .get(`${DOCUMENTATION_ENDPOINTS[type]}?document=${savedDocumentId}`)
        .catch(() => setIsOk(false))
        .then((json) => {
          setIsOk(true);
          setData(json.results);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [savedDocumentId, type, retryKey]);

  useEffect(() => {
    if (data.length > 0) {
      setHasSecondDocument(data.length > 1);
    }
  }, [data, setHasSecondDocument]);

  const secondDocument = useMemo(
    () => data.filter(({ id }) => id !== currentId)[0],
    [currentId, data],
  );

  const reload = useCallback(() => {
    setRetryKey((key) => key + 1);
  }, []);

  return isLoading || secondDocument ? (
    <Spin spinning={isLoading}>
      <div className="AnotherLanguages">
        {!isLoading && !isOk && <div onClick={reload}>Повторить загрузку</div>}
        {secondDocument && (
          <div>
            <Link to={`/${type}/${secondDocument[INSTRUCTIONS_ID]}`}>
              <Translate
                value={`documentationCardBase.${secondDocument[INSTRUCTIONS_LANGUAGE]}Version`}
              />
            </Link>
          </div>
        )}
      </div>
    </Spin>
  ) : null;
};

DocumentAnotherLanguages.propTypes = {
  currentId: PropTypes.number,
  document: PropTypes.number,
  setHasSecondDocument: PropTypes.func,
  type: PropTypes.string,
};

export default memo(DocumentAnotherLanguages);
