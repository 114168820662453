import { connect } from 'react-redux';

import Factoids from '../components/Factoids';
import {
  getAnalytics,
  getIncidentsDateRange,
  currentLocale,
  getAnalyticsFetching,
} from '../store/reducers';

const mapStateToProps = (state) => ({
  analytics: getAnalytics(state),
  dateRange: getIncidentsDateRange(state),
  loading: getAnalyticsFetching(state),
  currentLocale: currentLocale(state),
});

const FactoidsContainer = connect(mapStateToProps)(Factoids);
export default FactoidsContainer;
