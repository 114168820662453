import { schema, normalize } from 'normalizr';

import * as types from './actionTypes';

const initialState = {
  isLoading: false,
  mapping: {},
  tactics: {
    keys: [],
    data: [],
  },
  techniques: {
    keys: [],
    data: [],
  },
  platforms: [],
};

const tactics = new schema.Entity('tactics', {}, { idAttribute: 'short_name' });
const techniques = new schema.Entity(
  'techniques',
  {},
  {
    idAttribute: 'technique_id',
    processStrategy: (technique) => ({
      ...technique,
      fullName: `${technique.technique_id}: ${technique.name}`,
    }),
  },
);

export const responseSchema = {
  tactics: [tactics],
  techniques: [techniques],
};

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.FETCH_MAPPING_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case types.FETCH_MAPPING_SUCCESS: {
      const normalized = normalize(action.payload, responseSchema);

      const mapping = Object.fromEntries(
        normalized.result.tactics.map((key) => [key, []]),
      );

      normalized.result.techniques.forEach((techniqueId) => {
        normalized.entities.techniques[techniqueId].tactics.forEach(
          (tactic) => {
            mapping[tactic].push(techniqueId);
          },
        );
      });

      return {
        ...state,
        mapping,
        isLoading: false,
        tactics: {
          keys: normalized.result.tactics,
          data: normalized.entities.tactics,
        },
        techniques: {
          keys: normalized.result.techniques,
          data: normalized.entities.techniques,
        },
        platforms: action.payload.platforms?.map(({ name }) => name) || [],
      };
    }

    case types.FETCH_MAPPING_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
}

export const getMapping = (state) => state.mapping;
export const getTechniques = (state) => state.techniques.data;
export const getTechniquesIds = (state) => state.techniques.keys;
export const getTactics = (state) => state.tactics.data;
export const getTacticsIds = (state) => state.tactics.keys;
export const getIsLoading = (state) => state.isLoading;
export const getPlatforms = (state) => state.platforms;
export const getTacticsIdsCoverage = ({ tactics: { data, keys } }) =>
  keys.filter((id) => data[id] && !data[id].is_hidden);
