import React, { useMemo } from 'react';
import { I18n } from 'react-redux-i18n';
import { Route } from 'react-router-dom';

import PropTypes from 'prop-types';

import { TitleComponent } from '../../TitleComponent';

function CustomRoute({ component: Component, pageName, ...rest }) {
  const renderProps = useMemo(() => {
    return pageName !== undefined
      ? {
          render: (props) => {
            return (
              <React.Fragment>
                {pageName !== undefined && (
                  <TitleComponent
                    title={I18n.t(`titles.${pageName}`, {
                      ...props.match.params,
                    })}
                  />
                )}
                <Component
                  user={rest?.user}
                  locale={rest?.locale}
                  {...rest}
                  {...props}
                />
              </React.Fragment>
            );
          },
        }
      : {
          component: Component,
        };
  }, [Component, pageName]);

  return <Route {...renderProps} {...rest} />;
}

CustomRoute.propTypes = {
  component: PropTypes.elementType,
  pageName: PropTypes.string,
};

export { CustomRoute };
