import React, { useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { Translate } from 'react-redux-i18n';

import { Heading, OverlaySpinner, Spinner } from 'combinezone/core';

import { Card } from 'components/IncidentList/Card';
import {
  getMitreTechniques,
  getCmdbAssetPageIncidents,
  getCmdbAssetPageIncidentsFetching,
  getCmdbAssetPageIncidentsHasMore,
  getCmdbAssetPageIncidentsIsFetchingMore,
} from 'store/reducers';
import { fetchMoreIncidents } from 'store/reducers/cmdbAssetPage/actions';

import { LayoutList, List, NoData, OverlayBox, SpinnerBox } from './components';

export const IncidentsTab = () => {
  const mitreTechniques = useSelector((state) => getMitreTechniques(state));
  const incidents = useSelector((state) => getCmdbAssetPageIncidents(state));
  const hasMore = useSelector((state) =>
    getCmdbAssetPageIncidentsHasMore(state),
  );
  const isFetchingMore = useSelector((state) =>
    getCmdbAssetPageIncidentsIsFetchingMore(state),
  );
  const isFetching = useSelector((state) =>
    getCmdbAssetPageIncidentsFetching(state),
  );
  const dispatch = useDispatch();

  const fetchMoreData = useCallback(() => {
    return dispatch(fetchMoreIncidents);
  }, [fetchMoreIncidents]);

  if (!incidents.length && isFetching) {
    return (
      <OverlayBox>
        <OverlaySpinner />
      </OverlayBox>
    );
  }

  return (
    <LayoutList>
      <List>
        <InfiniteScroll
          pageStart={1}
          initialLoad={false}
          loadMore={fetchMoreData}
          hasMore={isFetchingMore ? false : hasMore}
          useWindow={false}
        >
          {incidents.map((incident) => (
            <Card
              incident={incident}
              key={incident?.id}
              mitreTechniques={mitreTechniques}
              notСlickable
            />
          ))}
        </InfiniteScroll>
        {isFetchingMore && (
          <SpinnerBox>
            <Spinner size="sm" />
          </SpinnerBox>
        )}
        {incidents.length === 0 && !hasMore && (
          <NoData>
            <Heading size="lg" variant="placeholder">
              <Translate value="cmdbAssets.noData" />
            </Heading>
          </NoData>
        )}
      </List>
    </LayoutList>
  );
};
