import { getQLNameSearch } from '@common/soc-react-kit';
import { createAction } from 'redux-api-middleware';

import { RESPONSE_TEAM_ENDPOINT } from 'services/api';
import { RSAAAsyncActionType } from 'store/types';

import {
  fetchResponseTeamFailure,
  FetchResponseTeamFailureType,
  FetchResponseTeamSuccessType,
} from './actionMatchTypes';
import * as types from './actionTypes';

const responseTeamLimit = 'limit=5';

type TransformedTeams = { value: string; content: string; testId: string };

type ResponseType = FetchResponseTeamFailureType | FetchResponseTeamSuccessType;

const transformResponseTeams = (response: ResponseType): TransformedTeams[] => {
  if (fetchResponseTeamFailure(response)) {
    return [];
  }

  return response.payload.map(({ id, name }) => ({
    value: `${id}`,
    content: name,
    testId: `${id}-${name}`,
  }));
};

export type FetchResponseTeamType = (
  search: string,
) => RSAAAsyncActionType<ResponseType, TransformedTeams[]>;

export const fetchResponseTeams: FetchResponseTeamType =
  (search) => (dispatch) => {
    const ql = getQLNameSearch(search);

    const endpoint = `${RESPONSE_TEAM_ENDPOINT}?${responseTeamLimit}&ql=${ql}`;

    return dispatch(
      createAction({
        endpoint,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        types: [
          types.FETCH_RESPONSE_TEAM_REQUEST,
          types.FETCH_RESPONSE_TEAM_SUCCESS,
          types.FETCH_RESPONSE_TEAM_FAILURE,
        ],
        isAbortOldRequests: true,
      }),
    )
      .then(transformResponseTeams)
      .catch(() => {
        return [];
      });
  };
