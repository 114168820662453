import React from 'react';

import { SvgIcon } from 'combinezone/icons';

export function UserIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 0C11.7625 0 14 2.2375 14 5C14 7.7625 11.7625 10 9 10C6.2375 10 4 7.7625 4 5C4 2.2375 6.2375 0 9 0ZM9 2C10.6575 2 12 3.3425 12 5C12 6.6575 10.6575 8 9 8C7.3425 8 6 6.6575 6 5C6 3.3425 7.3425 2 9 2ZM9 11C13.9725 11 18 13.0883 18 15.6667V18H0V15.6667C0 13.0883 4.0275 11 9 11ZM15.9918 16H2.00816C2.00816 16 0.842857 13 9 13C17.1571 13 15.9918 16 15.9918 16Z"
        fill="black"
        fillOpacity="0.141176"
      />
    </SvgIcon>
  );
}
