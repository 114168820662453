import { connect } from 'react-redux';

import { Card } from '../components/Instructions/Card';
import { currentLocale, makeInstructionsCardSelector } from '../store/reducers';
import { select } from '../store/reducers/instructions/actions';

const makeMapStateToProps = () => {
  const selector = makeInstructionsCardSelector();

  return (state, ownProps) => {
    const isSelected = selector(state, ownProps);

    return {
      isSelected,
      locale: currentLocale(state),
    };
  };
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    select: () => dispatch(select(ownProps.instruction)),
  };
}

const InstructionsCardContainer = connect(
  makeMapStateToProps,
  mapDispatchToProps,
)(Card);
export default InstructionsCardContainer;
