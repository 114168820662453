import React, { FC, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { Translate } from 'react-redux-i18n';

import styled from 'styled-components';

import { AssetShortCard } from '@soc/assets/src';
import { Flex, OverlaySpinner, Heading, Spinner } from 'combinezone/core';

import {
  incPageCmdbAssets,
  incPageCmdbAssetsFetching,
  incPageCmdbAssetsIsFetchingMore,
  incPageCmdbAssetsHasMore,
  currentLocale,
  getUser,
} from 'store/reducers';
import {
  fetchCmdbAssetIncList,
  fetchMoreCmdbAssetIncList,
} from 'store/reducers/incCard/actions';

import './CmdbAssetsTab.scss';
import { AssetSnapshotType, CmdbAssetsTabProps } from './types';

const Layout = styled(Flex).attrs(() => ({
  fillWidth: true,
  fillHeight: true,
  justify: 'space-between',
}))`
  align-self: center;
  width: 100%;
  overflow: auto;
`;

const SpinnerBox = styled.div`
  margin: 10px auto;
`;

const ContentListWrapper = styled(Flex).attrs(() => ({
  direction: 'column',
}))`
  position: relative;
  width: 100%;
  min-width: fit-content;
`;

const NoData = styled(Flex).attrs(() => ({
  justify: 'center',
  alignItems: 'center',
}))`
  height: calc(100vh - 150px);
`;

const CmdbAssetsTab: FC<CmdbAssetsTabProps> = ({ incNumber }) => {
  const dispatch = useDispatch();
  const assets: AssetSnapshotType[] = useSelector(incPageCmdbAssets);
  const isFetching = useSelector(incPageCmdbAssetsFetching);
  const isFetchingMore = useSelector(incPageCmdbAssetsIsFetchingMore);
  const hasMore = useSelector(incPageCmdbAssetsHasMore);
  const locale = useSelector(currentLocale);
  const user = useSelector(getUser);
  useEffect(() => {
    dispatch(fetchCmdbAssetIncList(incNumber));
  }, []);
  return (
    <Layout>
      <ContentListWrapper>
        {isFetching && <OverlaySpinner />}
        {!assets.length && !isFetching ? (
          <NoData>
            <Heading size="lg" variant="placeholder">
              <Translate value="panel.noData" />
            </Heading>
          </NoData>
        ) : (
          <>
            <InfiniteScroll
              className="IncPageCmdbAssetsTabScroll"
              pageStart={1}
              initialLoad={false}
              loadMore={() => {
                dispatch(fetchMoreCmdbAssetIncList);
              }}
              hasMore={isFetchingMore ? false : hasMore}
              useWindow={false}
            >
              {assets.map((asset: AssetSnapshotType, i: number) => (
                <AssetShortCard
                  key={`${asset.cmdb_id}-${i}`}
                  locale={locale}
                  user={user}
                  asset={asset}
                />
              ))}
            </InfiniteScroll>
            {isFetchingMore && (
              <SpinnerBox>
                <Spinner size="md" />
              </SpinnerBox>
            )}
          </>
        )}
      </ContentListWrapper>
    </Layout>
  );
};

export default CmdbAssetsTab;
