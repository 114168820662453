import React, { FC } from 'react';
import { I18n } from 'react-redux-i18n';

import styled from 'styled-components';

import { Flex, Text } from 'combinezone/core';

import { LogSourcesCardReasonProps } from 'components/LogSources/LogSourcesCard/components/LogSourcesCardReason/types';

const LogSourcesReason = styled(Flex).attrs(({ children }) => children)`
  align-items: center;
  gap: 5px;
  margin-top: 10px;
`;

const LogSourcesCardReason: FC<LogSourcesCardReasonProps> = ({
  source,
  testId,
}) => {
  const testIdReason = `${testId}-reason`;

  return (
    <LogSourcesReason testId={testIdReason}>
      <Text variant="secondary" testId={`${testIdReason}-title`}>
        {I18n.t('logSourcesCard.reason')}:
      </Text>
      <Text testId={`${testIdReason}-value`}>
        {I18n.t(
          `logSourcesCard.reasonValue.${source.archiving_details.reason}`,
        )}
      </Text>
    </LogSourcesReason>
  );
};

export default LogSourcesCardReason;

LogSourcesCardReason.displayName = 'LogSourcesCardReason';
