import React, { FC } from "react";

import styled from "styled-components";

import { Flex, Text, Caption } from "combinezone/core";
import { useTranslate } from "combinezone/utils";

import locales from "../locales";
import { SensorGroupType } from "../types";

const SourceCardContainer = styled(Flex).attrs(() => ({
  gap: "12px",
}))`
  padding: 8px;
  border: ${({ theme }) => `1px solid ${theme.basis.colors.borders.divider}`};
  border-radius: 4px;
`;

const TypeContainer = styled(Flex).attrs(() => ({
  gap: "8px",
  direction: "column",
}))`
  width: 105px;
`;

const SensorsContainer = styled(Flex).attrs(() => ({
  gap: "8px",
  direction: "column",
}))`
  width: 91px;
`;

const ModulesContainer = styled(Flex).attrs(() => ({
  gap: "8px",
  direction: "column",
}))`
  width: 132px;
`;

const ListContainer = styled(Flex).attrs(() => ({
  gap: "4px",
  direction: "column",
}))``;

const Badge = styled(Caption).attrs(() => ({
  isClipped: true,
  variant: "default",
}))`
  border: ${({ theme }) => `1px solid  ${theme.basis.colors.borders.normal}`};
  border-radius: 3px;
  padding: 0 8px;
  font-weight: 500;
  height: 24px;
  align-content: center;
  width: fit-content;
`;

type SensorCardProps = SensorGroupType & {
  testId: string;
};

export const SensorCard: FC<SensorCardProps> = ({
  modules,
  sensors,
  testId,
  types,
}) => {
  const t = useTranslate(locales);

  return (
    <SourceCardContainer>
      <TypeContainer>
        <Text variant="secondary">{t("type")}</Text>
        <ListContainer>
          {types.map((el, i) => (
            <Badge key={i} testId={`${testId}-type-${el}-${i}`}>
              {el}
            </Badge>
          ))}
        </ListContainer>
      </TypeContainer>

      <SensorsContainer>
        <Text variant="secondary">{t("sensors")}</Text>
        <ListContainer>
          {sensors.map((el, i) => (
            <Badge key={i} testId={`${testId}-sensor-${el}-${i}`}>
              {el}
            </Badge>
          ))}
        </ListContainer>
      </SensorsContainer>

      <ModulesContainer>
        <Text variant="secondary">{t("module")}</Text>
        <ListContainer>
          {modules.map((el, i) => (
            <Badge key={i} testId={`${testId}-module-${el}-${i}`}>
              {el}
            </Badge>
          ))}
        </ListContainer>
      </ModulesContainer>
    </SourceCardContainer>
  );
};

SensorCard.displayName = "SensorCard";
