import React, { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { I18n } from 'react-redux-i18n';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { Header } from '@bizone/ui-bundle/esm/Header';
import { Icon } from '@bizone/ui-bundle/esm/Icon';
import { List } from '@bizone/ui-bundle/esm/List';
import { Tooltip } from '@bizone/ui-bundle/esm/Tooltip';

import { bemCls } from '@common/soc-react-kit';
import Tags from '@soc/alerts/src/components/DataGrid/Column/Tags/Cell/index';
import { Flex } from 'combinezone/core';
import { ProfileSmall, Tag } from 'combinezone/icons';
import moment from 'moment';

import {
  UPDATE_FIELD,
  PRIORITY_FIELD,
  STATUS_FIELD,
  SYSTEM_FIELD,
  TITLE_FIELD,
  CREATE_FIELD,
  GOSSOPKA_SEND_TIME,
  GOSSOPKA_ID,
  KEY_FIELD,
  MITRE_FIELD,
  CUSTOMER_ASSIGNEE,
  CUSTOMER_ASSIGNEE_LAST_TIME,
  TAGS,
  ID_FIELD,
} from '../../../services/api';
import { incidentFormatter, getMitre } from '../../../utils/helpers';

import './Card.scss';

const TagsContainer = styled(Flex).attrs(() => ({
  fillWidth: true,
  gap: '8px',
  alignItems: 'center',
}))`
  margin-top: 8px;
`;

const { getCategories, getPriorityRow, getRulesTags, getStatusRow } =
  incidentFormatter;

export const Card = React.memo(
  ({ incident, isSelected, mitreTechniques, notСlickable = false, select }) => {
    const [className, setClassName] = useState('IncidentCard');
    const currentPriority = incident[PRIORITY_FIELD];

    useEffect(() => {
      setClassName(
        bemCls('IncidentCard', {
          selected: isSelected,
          notСlickable,
          [currentPriority]: true,
        }),
      );
    }, [isSelected, currentPriority, notСlickable]);

    return (
      <div className={className} onClick={isSelected ? undefined : select}>
        <div className="IncidentCard-Header">
          <Header size={14}>
            <Link target="_blank" to={`/inc/${incident[KEY_FIELD]}`}>
              [{incident[KEY_FIELD]}] {incident[TITLE_FIELD]}
            </Link>
          </Header>
          <div className="IncidentCard-Date">
            <Tooltip inverted content={I18n.t('incCard.editTooltip')}>
              <div>
                <Icon glyph="edit" size={14} />
                {moment(incident[CREATE_FIELD]).format('LLL')}
              </div>
            </Tooltip>
            <Tooltip inverted content={I18n.t('incCard.lastUpdateTooltip')}>
              <div>
                <Icon glyph="refresh" size={14} />
                {moment(incident[UPDATE_FIELD]).format('LLL')}
              </div>
            </Tooltip>
            {incident[GOSSOPKA_ID] && (
              <Tooltip inverted content={I18n.t('incCard.NCCCI.tooltip')}>
                <div>
                  <Icon glyph="shield" size={14} />
                  {moment(incident[GOSSOPKA_SEND_TIME]).format('LLL')}
                </div>
              </Tooltip>
            )}
            {incident[CUSTOMER_ASSIGNEE] && (
              <Tooltip
                inverted
                content={moment(incident[CUSTOMER_ASSIGNEE_LAST_TIME]).format(
                  'LLL',
                )}
              >
                <Flex gap="4px">
                  <ProfileSmall />
                  {incident[CUSTOMER_ASSIGNEE].username}
                </Flex>
              </Tooltip>
            )}
          </div>
        </div>
        {!!incident[TAGS]?.length && (
          <TagsContainer>
            <Tag size="20px" />
            <Tags testId={`${incident[ID_FIELD]}-tags`} tags={incident[TAGS]} />
          </TagsContainer>
        )}
        <div className="IncidentCard-Body">
          <List
            data={{
              [I18n.t('incCard.priority')]: getPriorityRow(
                incident[PRIORITY_FIELD],
              ),
              [I18n.t('incCard.status')]: getStatusRow(incident[STATUS_FIELD]),
              [I18n.t('incCard.infoSystem')]: incident[SYSTEM_FIELD],
            }}
            cls="IncidentCard-FirstList"
            keysWidth={120}
          />
          <List
            data={{
              [I18n.t('incCard.category')]: getCategories(
                incident,
                {},
                {},
                true,
              ),
              [`MITRE ATT&CK ${I18n.t('incCard.mitre.techniques')}`]: getMitre(
                incident[MITRE_FIELD] || {},
                mitreTechniques,
              ),
              [I18n.t('incCard.rules')]: getRulesTags(
                incident,
                undefined,
                true,
              ),
            }}
            formatter={mitreFormatter}
            cls="IncidentCard-SecondList"
          />
        </div>
      </div>
    );
  },
  isEqual,
);

function mitreFormatter(key, value) {
  if (value !== null) {
    return {
      key,
      value,
    };
  }
}
