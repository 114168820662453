import * as Sentry from '@sentry/react';

import { currentLocale, getUser } from '../reducers';
import { LOGIN_SUCCESSFUL, TOKEN_RECEIVED } from '../reducers/auth/actionTypes';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    if ([LOGIN_SUCCESSFUL, TOKEN_RECEIVED].includes(action.type)) {
      return {
        ...action,
        payload: null,
      };
    }
  },
  stateTransformer: (state) => ({
    ...state,
    auth: {
      ...state.auth,
      access: null,
      refresh: null,
    },
    i18n: {
      locale: currentLocale(state),
    },
  }),
  configureScopeWithState: (scope, state) => {
    scope.setUser(getUser(state));
  },
});

export { sentryReduxEnhancer };
