import React from 'react';
import { I18n } from 'react-redux-i18n';

import { bemCls } from '@common/soc-react-kit';
import { OverlaySpinner } from 'combinezone/core/index';
import moment from 'moment';
import PropTypes from 'prop-types';

import { EPS_DISABLED } from '../../utils/brending';
import './Factoids.scss';

export default class Factoids extends React.Component {
  getDynamClass = (value, isPos = true) => {
    const [positive, negative, dynamics] = ['Positive', 'Negative', 'Dynamics'];
    value = isPos ? value : -1 * value;
    if (value === 0) {
      return dynamics;
    }
    if (value > 0) {
      return `${dynamics} ${bemCls(dynamics, positive)}`;
    }
    return `${dynamics} ${bemCls(dynamics, negative)}`;
  };

  getDynamStr = (num) => (num < 0 ? '' : '+') + num;

  render() {
    const {
      analytics,
      dateRange: [start, end],
      loading,
    } = this.props;
    const {
      EPS: {
        average: averageEps = 0,
        contracted: contractedEps = 0,
        max: maxEps = 0,
      } = {},
      count = 0,
      critical_count: criticalCount = 0,
      previous,
      sla_breached: sla = 0,
      waiting_for_customer: waitingForCustomer = 0,
    } = analytics;

    const days = `${Math.ceil(
      moment.duration(end.diff(start)).asDays(),
    )}${I18n.t('dashboard.factoids.days')}`;

    const dynamCount = previous ? count - previous.count : 0;
    const dynamSla = previous ? sla - previous.sla_breached : 0;
    const dynamCriticalCount = previous
      ? criticalCount - previous.critical_count
      : 0;
    const dynamWaitingForCustomer = previous
      ? waitingForCustomer - previous.waiting_for_customer
      : 0;

    return (
      <div className="Factoids">
        {loading && <OverlaySpinner />}
        <div className="Factoid">
          <div className="Factoid-InfoSection">
            <div className="Factoid-Data">
              {count.toLocaleString()} / {days}{' '}
              <sup className={this.getDynamClass(dynamCount)}>
                {this.getDynamStr(dynamCount)}
              </sup>
            </div>
            <div className="Factoid-Description">
              {I18n.t('dashboard.factoids.totalCount')}
            </div>
          </div>
          <div className="Factoid-ExtraInfo">
            <div className="Factoid-ExtraData__top">{waitingForCustomer}</div>
            <div className="Factoid-ExtraDescription">
              {I18n.t('dashboard.factoids.waitingForCustomer')}
            </div>
            <div className="Factoid-ExtraData__bottom">
              {criticalCount.toLocaleString()}
            </div>
            <div className="Factoid-ExtraDescription">
              {I18n.t('dashboard.factoids.criticalCount')}
            </div>
          </div>
        </div>
        <div className="Factoid">
          <div className="Factoid-InfoSection">
            <div className="Factoid-Data">
              {sla.toLocaleString()}%{' '}
              <sup className={this.getDynamClass(dynamSla, false)}>
                {this.getDynamStr(dynamSla)}%
              </sup>
            </div>
            <div className="Factoid-Description">
              {I18n.t('dashboard.factoids.breached')}
            </div>
          </div>
        </div>
        {!EPS_DISABLED && (
          <div className="Factoid">
            <div className="Factoid-InfoSection">
              <div className="Factoid-Data">{averageEps.toLocaleString()}</div>
              <div className="Factoid-Description">
                {I18n.t('dashboard.factoids.eps')}
              </div>
            </div>
            <div className="Factoid-ExtraInfo">
              <div className="Factoid-ExtraData__top">
                {maxEps.toLocaleString()}
              </div>
              <div className="Factoid-ExtraDescription">
                {I18n.t('dashboard.factoids.maxEps')}
              </div>
              <div className="Factoid-ExtraData__bottom">
                {contractedEps.toLocaleString()}
              </div>
              <div className="Factoid-ExtraDescription">
                {I18n.t('dashboard.factoids.contractedEps')}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

Factoids.propTypes = {
  analytics: PropTypes.object,
  dateRange: PropTypes.array,
  loading: PropTypes.bool,
};

Factoids.defaultProps = {
  analytics: {},
  loading: false,
  dateRange: [],
};
