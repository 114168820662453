import * as types from './actionTypes';

const initialState = () => {
  const lcCurrentTheme = localStorage
    .getItem('currentTheme')
    ?.replace(/['"]/g, '');
  return {
    theme: ['light', 'dark'].includes(lcCurrentTheme)
      ? lcCurrentTheme
      : 'light',
  };
};

export default function reduce(state = initialState(), action = {}) {
  switch (action.type) {
    case types.SET_THEME: {
      return {
        ...state,
        theme: action.payload.theme,
      };
    }

    default:
      return state;
  }
}

export function getTheme(state) {
  return state.theme;
}
