import React from 'react';
import { Translate } from 'react-redux-i18n';
import { Switch, useParams, Redirect } from 'react-router-dom';

import { Layout } from '@common/soc-react-kit';

import ReportsList from '../../containers/ReportsListContainer';
import ReportsShedules from '../../containers/ReportsShedulesContainer';

import Panel from './Panel';

import './Reports.scss';

const { Container, Content } = Layout;

const config = {
  reportsList: {
    tab: <Translate value="reports.title" />,
    component: <ReportsList />,
    icon: 'paperclip',
  },
  reportShedules: {
    tab: <Translate value="reports.reportShedules" />,
    component: <ReportsShedules />,
    icon: 'calendar',
  },
};

export default function Reports() {
  const { tab = 'reportsList' } = useParams();

  return (
    <Container panel className="ReportsContainer">
      <Panel config={config} selectedTab={tab} setTab={tab} />
      <Content className="ReportsContent">
        <Switch>
          {config[tab] ? config[tab].component : <Redirect to="/dashboard" />}
        </Switch>
      </Content>
    </Container>
  );
}
