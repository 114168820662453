import { createAction, RSAAAction } from 'redux-api-middleware';

import { RSAActionProp } from 'store/reducers/types';

import { withAuth } from '..';
import {
  DASHBOARD_EPS_ENDPOINT,
  encodeIncidentsFilter,
} from '../../../services/api';

import * as types from './actionTypes';

const fetchEpsAnalytic = (
  params: string,
): RSAAAction<unknown, unknown, unknown> => {
  return createAction({
    endpoint: `${DASHBOARD_EPS_ENDPOINT}${params}&get_prev=True`,
    method: 'GET',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      types.FETCH_EPS_ANALYTICS_REQUEST,
      types.FETCH_EPS_ANALYTICS_SUCCESS,
      types.FETCH_EPS_ANALYTICS_FAILURE,
    ],
    isAbortOldRequests: true,
  } as RSAActionProp);
};

export const fetchEpsDashboardData = () => {
  return (dispatch, getState) => {
    const state = getState();
    const {
      incidents: { dateRange, ordering, selected: filter },
    } = state.filters;
    const startTimestamp = dateRange[0].startOf('day').format('x');
    const endTimestamp = dateRange[1].endOf('day').format('x');

    const params = encodeIncidentsFilter(
      filter,
      startTimestamp,
      endTimestamp,
      ordering,
    );

    return dispatch(fetchEpsAnalytic(params));
  };
};
