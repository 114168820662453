import { createAction } from 'redux-api-middleware';

import { INCIDENTS_ENDPOINT } from 'services/api';
import { withAuth } from 'store/reducers';
import { transformToBody } from 'store/reducers/common/actions/assign/helpers';

import * as types from './actionTypes';
import { AssignType } from './types';

export const assign: AssignType =
  (keyIncident, formValue) => (dispatch, getState) => {
    const action = createAction({
      endpoint: `${INCIDENTS_ENDPOINT}${keyIncident}/assign_to_customer/`,
      method: 'POST',
      body: transformToBody(formValue),
      headers: withAuth({ 'Content-Type': 'application/json' }),
      types: [
        types.ASSIGN_TO_INCIDENT_REQUEST,
        { type: types.ASSIGN_TO_INCIDENT_SUCCESS, meta: keyIncident },
        types.ASSIGN_TO_INCIDENT_FAIL,
      ],
    });

    return dispatch(action);
  };
