import { createAction } from 'redux-api-middleware';

import * as types from './actionTypes';

export const patchAlerts = (
  data,
  action,
  {
    classification = '',
    classificationDetails = '',
    issueKey = '',
    source,
  } = {},
) => {
  const endpoint = source.replace(/alerts\/.*/, 'alerts/bulk/');

  const body = createBodyForAlertsPatch(
    data,
    action,
    issueKey,
    classification,
    classificationDetails,
  );
  return createAction({
    endpoint,
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    types: [
      types.PATCH_ALERTS_REQUEST,
      types.PATCH_ALERTS_SUCCESS,
      types.PATCH_ALERTS_FAIL,
    ],
  });
};

function createBodyForAlertsPatch(
  data,
  action,
  issueKey,
  classification,
  classificationDetails,
) {
  const alerts = [];
  const groups = [];

  if (Array.isArray(data)) {
    data.forEach((id) => {
      if (typeof id === 'number') {
        groups.push(id);
      } else {
        alerts.push(id);
      }
    });
  } else if (typeof data === 'number') {
    groups.push(data);
  } else {
    alerts.push(data);
  }

  const body = {
    groups,
    alerts,
    action,
    classification,
    classification_details: classificationDetails,
  };

  if (issueKey) {
    body.issue = issueKey;
  }

  return body;
}
