import { Middleware, AnyAction } from 'redux';

type ControllerKeyType = string;
type ControllerType = AbortController;
type GetAbortControllerProps = {
  key: ControllerKeyType;
};
type SetAbortControllerProps = {
  key: ControllerKeyType;
  controller: ControllerType;
};
type GetControllerKeyProps = {
  action: AnyAction;
};

const reduxApiMiddleware = '@@redux-api-middleware/RSAA';

const abortControllers: Record<ControllerKeyType, ControllerType> = {};

const getAbortController = ({ key }: GetAbortControllerProps) =>
  abortControllers[key];

const setAbortController = ({ controller, key }: SetAbortControllerProps) => {
  abortControllers[key] = controller;
};

const getControllerKey = ({ action }: GetControllerKeyProps) => {
  if (action.type) {
    return action.type;
  }
  if (action[reduxApiMiddleware].types[0].type) {
    return action[reduxApiMiddleware].types[0].type;
  }
  return action[reduxApiMiddleware].types[0];
};

const abortOldRequests: Middleware<{}, {}> = () => (next) => (action) => {
  if (action[reduxApiMiddleware]?.isAbortOldRequests) {
    const controllerKey = getControllerKey({ action });
    getAbortController({ key: controllerKey })?.abort();
    const newController = new AbortController();
    const { signal } = newController;
    setAbortController({ key: controllerKey, controller: newController });

    action[reduxApiMiddleware].options = {
      ...action[reduxApiMiddleware].options,
      signal,
    };

    const { isAbortOldRequests, ...rsaaAction } = action[reduxApiMiddleware];

    action[reduxApiMiddleware] = rsaaAction;
  }

  return next(action);
};

export default abortOldRequests;
