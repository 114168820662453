import { connect } from 'react-redux';

import RuleListPage from '../components/RuleListPage';
import { RULES_ENTITY } from '../services/api';
import {
  currentLocale,
  getSearch,
  getOrdering,
  getSelectedFilters,
  getMitreTechniques,
  getMitreTactics,
  getMitreMapping,
  getFiltersSettings,
  getMitrePlatforms,
} from '../store/reducers';
import { fetchDashboardData } from '../store/reducers/dashboard/actions';
import {
  localSaveFilters,
  resetFilters,
  searchChange,
  setFilter,
  setSort,
} from '../store/reducers/filters/actions';
import { fetchMitreMapping } from '../store/reducers/mitre/actions';
import {
  fetchMore,
  firstFetch,
  fetchAnalytics,
  fetchAnalyticsMitre,
} from '../store/reducers/ruleList/actions';

const mapStateToProps = (state) => ({
  rules: state.ruleList.rules,
  isFetching: state.ruleList.fetching,
  hasMore: state.ruleList.hasMore,
  search: getSearch(RULES_ENTITY, state),
  filters: getSelectedFilters(RULES_ENTITY, state),
  ordering: getOrdering(RULES_ENTITY, state),
  currentLocale: currentLocale(state),
  settings: getFiltersSettings(RULES_ENTITY, state),
  techniques: getMitreTechniques(state),
  tactics: getMitreTactics(state),
  mapping: getMitreMapping(state),
  platforms: getMitrePlatforms(state),
});

const mapDispatchToProps = {
  firstFetch,
  fetchMore,
  onChangeSearch: (search) =>
    searchChange(RULES_ENTITY, search, [
      firstFetch,
      fetchAnalytics,
      fetchAnalyticsMitre,
    ]),
  setFilter: (key, item) =>
    setFilter(RULES_ENTITY, key, item, [
      firstFetch,
      fetchAnalytics,
      fetchAnalyticsMitre,
    ]),
  resetFilters: () =>
    resetFilters(RULES_ENTITY, [
      firstFetch,
      fetchAnalytics,
      fetchAnalyticsMitre,
    ]),
  setSort: (ordering) =>
    setSort(RULES_ENTITY, ordering, [
      firstFetch,
      fetchAnalytics,
      fetchAnalyticsMitre,
    ]),
  setInitSearch: (search) => searchChange(RULES_ENTITY, search),
  setInitFilter: (key, item) => setFilter(RULES_ENTITY, key, item),
  setInitSort: (ordering) => setSort(RULES_ENTITY, ordering),
  fetchMitreMapping,
  localSaveFilters,
  fetchAnalytics,
  fetchAnalyticsMitre,
  fetchDashboardData,
};

const RuleListContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RuleListPage);
export default RuleListContainer;
