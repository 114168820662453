import * as React from 'react';
import { Translate } from 'react-redux-i18n';

import { notification } from 'antd';

import {
  DELETE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  FIRST_FAILURE,
  FIRST_REQUEST,
  FIRST_SUCCESS,
  MORE_FAILURE,
  MORE_REQUEST,
  MORE_SUCCESS,
} from './actionTypes';

const initialState = {
  isLoading: false,
  hasMore: true,
  reports: [],
};

export default function reports(state = initialState, action = {}) {
  switch (action.type) {
    case FIRST_REQUEST:
    case MORE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FIRST_SUCCESS:
      return {
        ...state,
        reports: action.payload.results,
        hasMore: Boolean(action.payload.next),
        isLoading: false,
      };

    case MORE_SUCCESS:
      return {
        ...state,
        reports: [...state.reports, ...action.payload.results],
        hasMore: Boolean(action.payload.next),
        isLoading: false,
      };

    case FIRST_FAILURE:
    case MORE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case DELETE_REQUEST: {
      const newReports = state.reports.reduce((acc, { ...report }) => {
        if (action.meta.ids.includes(report.id)) {
          report.isDeleting = true;
        }
        acc.push(report);
        return acc;
      }, []);

      return {
        ...state,
        reports: newReports,
      };
    }

    case DELETE_FAILURE: {
      const newReports = state.reports.reduce((acc, { ...report }) => {
        if (action.meta.ids.includes(report.id)) {
          delete report.isDeleting;
        }
        acc.push(report);
        return acc;
      }, []);

      // TODO: remove
      notification.error({
        message: <Translate value="reports.deleteError" />,
      });

      return {
        ...state,
        reports: newReports,
      };
    }

    case DELETE_SUCCESS: {
      const newReports = state.reports.reduce((acc, { ...report }) => {
        if (action.meta.ids.includes(report.id)) {
          return acc;
        }
        acc.push(report);
        return acc;
      }, []);

      return {
        ...state,
        reports: newReports,
      };
    }

    default:
      return state;
  }
}

export function reportsList(state) {
  return state.reports;
}

export function isLoadingReports(state) {
  return state.isLoading;
}

export function hasMore(state) {
  return state.hasMore;
}
