import { RSAA } from 'redux-api-middleware';

import { withAuth } from '..';
import { ARTICLE_ENDPOINT, ARTICLES_ENDPOINT } from '../../../services/api';

import * as types from './actionTypes';

export const fetchArticleCard = (id) => {
  return {
    [RSAA]: {
      endpoint: `${ARTICLES_ENDPOINT}${id}/`,
      method: 'GET',
      headers: withAuth({ 'Content-Type': 'application/json' }),
      types: [
        types.FETCH_ARTICLE_CARD_REQUEST,
        types.FETCH_ARTICLE_CARD_SUCCESS,
        types.FETCH_ARTICLE_CARD_FAIL,
      ],
    },
  };
};

export const fetchVersions = (id) => {
  return {
    [RSAA]: {
      endpoint: `${ARTICLES_ENDPOINT}${id}/history/`,
      method: 'GET',
      headers: withAuth({ 'Content-Type': 'application/json' }),
      types: [
        types.FETCH_ARTICLE_VERSION_REQUEST,
        types.FETCH_ARTICLE_VERSION_SUCCESS,
        types.FETCH_ARTICLE_VERSION_FAIL,
      ],
    },
  };
};

export const submitComment = (id, text) => {
  const formData = new FormData();
  formData.append('text', text);
  return {
    [RSAA]: {
      endpoint: `${ARTICLES_ENDPOINT}${id}/comments/`,
      method: 'POST',
      body: formData,
      headers: withAuth({}),
      types: [
        {
          type: types.ADD_COMMENT_REQUEST,
          meta: { id },
        },
        {
          type: types.ADD_COMMENT_SUCCESS,
          meta: { id },
        },
        {
          type: types.ADD_COMMENT_FAIL,
          meta: { id },
        },
      ],
    },
  };
};

export const fetchComments = (id) => {
  return {
    [RSAA]: {
      endpoint: `${ARTICLE_ENDPOINT}${id}/comments/`,
      method: 'GET',
      headers: withAuth({ 'Content-Type': 'application/json' }),
      types: [
        types.FETCH_ARTICLE_COMMENTS_REQUEST,
        types.FETCH_ARTICLE_COMMENTS_SUCCESS,
        types.FETCH_ARTICLE_COMMENTS_FAIL,
      ],
    },
  };
};

export const closeInstructionCard = () => ({
  type: types.CLOSE_ARTICLE_CARD,
});

export const finalizeCommentSuccess = () => ({
  type: types.ADD_COMMENT_MODAL_SUCCESS,
});
