import React, { FC } from 'react';

import styled, { css } from 'styled-components';

import { Flex } from 'combinezone/core';
import { CombinezoneTheme } from 'combinezone/theme';

import { MitreColorsPanelProps } from 'components/RulesMitreWidget/MitreColorsPanel/types';
import { TechniqueTypes } from 'components/RulesMitreWidget/enums';

const MitreColorsElemTop = styled(Flex)<{
  $type: string;
  $isLeft?: boolean;
  $isRight?: boolean;
}>`
  ${({ $isLeft, $isRight, $type }) => css`
    width: 36px;
    height: 9px;
    margin-top: 3px;
    background-color: ${({ theme }: { theme: CombinezoneTheme }) => {
      switch ($type) {
        case TechniqueTypes.Higher: {
          return theme.basis.colors.tags.sea;
        }
        case TechniqueTypes.High: {
          return theme.basis.colors.tags.kelly;
        }
        case TechniqueTypes.Medium: {
          return theme.basis.colors.tags.lime;
        }
        case TechniqueTypes.Low: {
          return '#bde4af';
        }
        default: {
          return theme.basis.colors.text.placeholder;
        }
      }
    }};
    border-radius: ${() => {
      if ($isLeft) {
        return '3px 0px 0px 3px;';
      }
      if ($isRight) {
        return '0px 3px 3px 0px;';
      }
      return 'none';
    }};
  `}
`;

const MitreColorsElemBottom = styled(Flex)`
  font-size: 10px;
  font-weight: 500;
  color: ${({ theme }: { theme: CombinezoneTheme }) =>
    theme.basis.colors.text.default};
`;

const MitreColorsPanel: FC<MitreColorsPanelProps> = ({ testId }) => {
  const testIdColorsPanel = `${testId}-colors-panel`;

  return (
    <Flex gap="10px" alignItems="flex-start">
      <Flex>
        <Flex direction="column">
          <MitreColorsElemTop $type={TechniqueTypes.None} $isLeft>
            &nbsp;
          </MitreColorsElemTop>
          <MitreColorsElemBottom justify="center">0</MitreColorsElemBottom>
        </Flex>
        <Flex direction="column">
          <MitreColorsElemTop $type={TechniqueTypes.Low}>
            &nbsp;
          </MitreColorsElemTop>
          <MitreColorsElemBottom justify="center">1-13</MitreColorsElemBottom>
        </Flex>
        <Flex direction="column">
          <MitreColorsElemTop $type={TechniqueTypes.Medium}>
            &nbsp;{' '}
          </MitreColorsElemTop>
          <MitreColorsElemBottom justify="center">14-15</MitreColorsElemBottom>
        </Flex>
        <Flex direction="column">
          <MitreColorsElemTop $type={TechniqueTypes.High}>
            &nbsp;
          </MitreColorsElemTop>
          <MitreColorsElemBottom justify="center">16-17</MitreColorsElemBottom>
        </Flex>
        <Flex direction="column">
          <MitreColorsElemTop $type={TechniqueTypes.Higher} $isRight>
            &nbsp;
          </MitreColorsElemTop>
          <MitreColorsElemBottom justify="center">18+</MitreColorsElemBottom>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default MitreColorsPanel;

MitreColorsPanel.displayName = 'MitreColorsPanel';
