export const FIRST_FETCH_REQUEST = '@@responseInstructions/FIRST_FETCH_REQUEST';
export const FIRST_FETCH_SUCCESS = '@@responseInstructions/FIRST_FETCH_SUCCESS';
export const FIRST_FETCH_FAIL = '@@responseInstructions/FIRST_FETCH_FAIL';

export const FETCH_MORE_REQUEST = '@@responseInstructions/FETCH_MORE_REQUEST';
export const FETCH_MORE_SUCCESS = '@@responseInstructions/FETCH_MORE_SUCCESS';
export const FETCH_MORE_FAIL = '@@responseInstructions/FETCH_MORE_FAIL';

export const SET_SEARCH = '@@responseInstructions/SET_SEARCH';

export const SET_FILTERS = '@@responseInstructions/SET_FILTERS';
export const SET_SORT = '@@responseInstructions/SET_SORT';
