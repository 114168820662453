import React, { useEffect, useMemo, useState } from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import { getFormErrors } from '@common/soc-react-kit';
import { IconButton, useToast } from 'combinezone/core';
import { Search } from 'combinezone/icons';
import moment from 'moment';

import { mergeClassNames } from '../../../utils/helpers';
import { Chat } from '../../Chat';
import {
  ChatMessageListPageType,
  SubmittingMessageType,
} from '../../Chat/types';
import Panel from '../../ListPage/Panel';
import './index.scss';
import { IncidentPageCommentType, RightPanelPropsType } from '../types';

export const RightPanel = ({
  className,
  comments,
  errors,
  fetching,
  incNumber,
  onSuccess,
  submitComment,
  submitting,
}: RightPanelPropsType) => {
  const [searchVisible, setSearchVisible] = useState(false);
  const [search, setSearch] = useState('');

  const { toastError, toastSuccess } = useToast();
  const commentsSplitedByDate = useMemo(() => {
    return comments
      ?.filter(
        (comment) =>
          search.length === 0 ||
          comment.body.toLowerCase().includes(search.toLowerCase()),
      )
      ?.reduce(
        (acc: ChatMessageListPageType[], comment: IncidentPageCommentType) => {
          const lastDate = acc.length > 0 ? acc[acc.length - 1].date : null;

          const currentDate = moment(comment.created).startOf('day');
          if (currentDate.isSame(moment(lastDate))) {
            acc[acc.length - 1].data.push(comment);
          } else {
            acc.push({
              date: currentDate.toISOString(),
              data: [comment],
            });
          }
          return acc;
        },
        [],
      );
  }, [comments, search]);

  const onOk = () => {
    onSuccess && onSuccess();
  };

  const addComment = (value: SubmittingMessageType) => {
    const data = { body: value.message, attachments: value.files };

    submitComment(incNumber, data).then((result) => !result.error && onOk());
  };

  useEffect(() => {
    const onError = (errors: any) => {
      const [fields, nonFields] = getFormErrors(errors);
      if (fields) {
        Object.keys(fields).forEach((item) => {
          toastError({ title: JSON.stringify(item) });
        });
      }
      if (nonFields) {
        toastError({ title: JSON.stringify(nonFields) });
      }
    };

    if (!errors || Object.keys(errors).length < 0) {
      return;
    }
    onError(errors);
  }, [errors]);

  return (
    <Panel
      className={mergeClassNames('RightPanel', className)}
      resizable
      title={() => <Translate value="incCard.comments" />}
      controls={(_, isOpen) =>
        Boolean(isOpen) && (
          <IconButton
            testId="IncidentPage-RightPanelSearchButton"
            icon={Search}
            tooltip="Search"
            isPressed={searchVisible}
            onClick={() => setSearchVisible((v) => !v)}
          />
        )
      }
      entity={comments}
      content={(_e, width = 400, isResizingWidth = false) => (
        <Chat
          fetching={fetching}
          width={width}
          isResizingWidth={isResizingWidth}
          onSubmit={addComment}
          data={commentsSplitedByDate}
          searchVisible={searchVisible}
          submitting={submitting}
          onChangeSearch={setSearch}
        />
      )}
    />
  );
};
