import { connect } from 'react-redux';

import { App } from '../components/App';
import {
  loadUser,
  handleSSOToken,
  setMounted,
} from '../store/reducers/auth/actions';
import { setLocale } from '../utils/i18n';

function mapStateToProps(state, ownProps) {
  return {
    auth: state.auth,
    locale: state.i18n.locale,
    ...ownProps,
  };
}

const mapDispatchToProps = {
  loadUser,
  handleSSOToken,
  setLocale,
  setMounted,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
