import React from 'react';

import {
  Axis,
  AxisDataItem,
  AxisRendererY,
  ColumnSeries,
  XYChart,
} from '@amcharts/amcharts4/charts';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import { Color } from '@amcharts/amcharts4/core';

import { BLUE_COLOR, RED_COLOR } from 'components/EpsWidget/helpers/constants';
import {
  getColumnTooltipHTML,
  getTooltipHTML,
} from 'components/EpsWidget/helpers/tooltip';
import {
  AmchartEpsData,
  ExcessBuckets,
  RangeEvent,
  TooltipHTMLRange,
} from 'components/EpsWidget/helpers/types';

const createAxis = (
  value = 1,
  color: Color,
  valueAxis?: Axis<AxisRendererY>,
): void => {
  if (!valueAxis) {
    return;
  }

  const range = valueAxis.axisRanges.create();

  range.setValue('value', value);
  range.label.text = '{value}';
  range.label.fill = color;
  range.grid.stroke = color;
};

export const drawGird = (chart: XYChart, data: AmchartEpsData[]): void => {
  if (!data.length) {
    return;
  }

  const [first] = data;
  const { contracted_eps: contractedEps, excess_max_value: max } =
    first.extData!;

  const lineSeries = chart.series.getIndex(2) ?? { data: [] };
  const valueAxis = chart.yAxes.getIndex(0);
  valueAxis?.axisRanges.clear();
  let step =
    contractedEps % 1500 === 0 ? 1500 : contractedEps % 1000 === 0 ? 1000 : 500;

  if (max / step > 9) {
    step = Math.round(max / 1000) * 100;
  }
  const line = max + step + step - (max % step);
  lineSeries.data = [
    {
      date: chart.data[0].date,
      line: line + 1,
    },
    {
      date: chart.data[chart.data.length - 1].date,
      line: line + 1,
    },
  ];

  for (let i = 0; i <= line; i += step) {
    let color = am4core.color('');
    if (contractedEps) {
      if (i > contractedEps) {
        color = am4core.color(RED_COLOR);
      } else if (i >= contractedEps) {
        color = am4core.color(BLUE_COLOR);
      }
    }
    createAxis(i, color, valueAxis);
  }
};

export const createRange = (
  chart: XYChart,
  date: Date,
  endDate: Date,
  value: ExcessBuckets,
  rangeOver: (events: RangeEvent) => void,
  rangeOut: (events: RangeEvent) => void,
): void => {
  const { data, series, xAxes } = chart;

  if (!data.length) {
    return;
  }

  const lineSeries = series.getIndex(2);
  const range = xAxes.getIndex(0)!.createSeriesRange(lineSeries!);

  (range as AxisDataItem & { date: Date }).date = date;
  (range as AxisDataItem & { endDate: Date }).endDate = endDate;
  (range as AxisDataItem & { excessDuration: number }).excessDuration =
    value.excess_duration;
  (range as AxisDataItem & { maxValue: number }).maxValue = value.max_value;
  (range as AxisDataItem & { excessCount: number }).excessCount =
    value.excess_count;
  range.contents.strokeWidth = 2;
  range.contents.strokeWidth = 0;
  range.axisFill.fill = new Color({ r: 88, g: 16, b: 11 }); // #e0281b;
  range.axisFill.fillOpacity = 0;
  range.axisFill.above = true;
  range.grid.strokeWidth = 0;
  range.axisFill.tooltip = new am4core.Tooltip();

  if (data[5].date >= value.timeline_buckets[0]) {
    range.axisFill.tooltip.pointerOrientation = 'left';
  } else {
    range.axisFill.tooltip.pointerOrientation = 'right';
  }

  range.axisFill.tooltip.dataItem = range;
  range.axisFill.tooltip.getFillFromObject = false;
  range.axisFill.tooltip.background.fill = new Color({ r: 20, g: 20, b: 20 }); // #333333
  range.axisFill.tooltip.background.fillOpacity = 0.9;
  range.axisFill.tooltip.background.strokeWidth = 0;
  range.axisFill.tooltipHTML = getTooltipHTML(chart, range as TooltipHTMLRange);
  range.axisFill.isMeasured = true;
  range.axisFill.events.on('over', rangeOver);
  range.axisFill.events.on('out', rangeOut);
  range.axisFill.interactionsEnabled = true;
  // @ts-ignore
  range.values = {
    ...range.values,
    ...value,
    range,
  };
};

export const setupSeries = (chart: XYChart): void => {
  const seriesExcess = new am4charts.ColumnSeries();
  seriesExcess.dataFields.dateX = 'date';
  seriesExcess.dataFields.valueY = 'excess';
  seriesExcess.stacked = true;
  seriesExcess.cursorOverStyle = am4core.MouseCursorStyle.pointer;
  seriesExcess.fill = new Color({ r: 224, g: 40, b: 27 }); // #e0281b;
  seriesExcess.fillOpacity = 0.5;
  seriesExcess.strokeWidth = 0;
  chart.series.push(seriesExcess);

  const lineSeries = new am4charts.LineSeries();
  lineSeries.dataFields.dateX = 'date';
  lineSeries.dataFields.valueY = 'line';
  lineSeries.strokeOpacity = 0.5;
  lineSeries.stroke = new Color({ r: 224, g: 40, b: 27 }); // #e0281b;
  lineSeries.strokeWidth = 0;
  chart.series.push(lineSeries);
  const seriesVal = chart.series.getIndex(0)! as ColumnSeries;
  seriesVal.columns.template.tooltipHTML = getColumnTooltipHTML();
  const hoverState = seriesVal.columns.template.states.create('hover');
  hoverState.properties.fillOpacity = 0.8;
  const overState = seriesVal.columns.template.states.create('over');
  overState.properties.fillOpacity = 0.8;
  const overState2 = seriesExcess.columns.template.states.create('over');
  overState2.properties.fillOpacity = 0.8;
};

export const getColumnSizePx = (
  data: AmchartEpsData[] = [],
): [number, number] => {
  const isMoreOrEqualOne = data.length >= 61;
  const isMoreOrEqualTwo = data.length >= 31;

  const graphColumnSizePxOne = 8;
  const graphColumnSizePxTwo = isMoreOrEqualTwo ? 14 : 20;
  const graphColumnSizePx = isMoreOrEqualOne
    ? graphColumnSizePxOne
    : graphColumnSizePxTwo;

  const graphBorderRadiusPxOne = 2;
  const graphBorderRadiusPxTwo = isMoreOrEqualTwo ? 3 : 4;
  const graphBorderRadiusPx = isMoreOrEqualOne
    ? graphBorderRadiusPxOne
    : graphBorderRadiusPxTwo;

  return [graphColumnSizePx, graphBorderRadiusPx];
};

export const setColumnSize = (chart: XYChart): void => {
  const seriesVal = chart.series.getIndex(0)! as ColumnSeries;
  const seriesExcess = chart.series.getIndex(1)! as ColumnSeries;

  const [graphColumnSizePx, graphBorderRadiusPx] = getColumnSizePx(
    chart.data as AmchartEpsData[],
  );
  seriesVal.columns.template.maxWidth = graphColumnSizePx;
  seriesExcess.columns.template.maxWidth = graphColumnSizePx;
  seriesExcess.columns.template.column.cornerRadiusTopLeft =
    graphBorderRadiusPx;
  seriesExcess.columns.template.column.cornerRadiusTopRight =
    graphBorderRadiusPx;

  seriesVal.columns.template.column.adapter.add(
    'cornerRadiusTopRight',
    (radius, target) => {
      // @ts-ignore
      return !target.dataItem?.dataContext.excess > 0
        ? graphBorderRadiusPx
        : radius;
    },
  );
  seriesVal.columns.template.column.adapter.add(
    'cornerRadiusTopLeft',
    (radius, target) =>
      // @ts-ignore
      !target.dataItem.dataContext.excess > 0 ? graphBorderRadiusPx : radius,
  );
  seriesExcess.columns.template.column.cornerRadiusTopRight =
    graphBorderRadiusPx;
  seriesExcess.columns.template.column.cornerRadiusTopLeft =
    graphBorderRadiusPx;
};
