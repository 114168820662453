import React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import { Block } from '@bizone/ui-bundle/esm/Block';
import { Header } from '@bizone/ui-bundle/esm/Header';
import { IconButton } from '@bizone/ui-bundle/esm/IconButton';
import { Label } from '@bizone/ui-bundle/esm/Label';

import { getPlural, Layout } from '@common/soc-react-kit';
import memoize from 'memoize-one';
import moment from 'moment';
import shallowEqual from 'shallowequal';

import OldGrid from 'components/OldGrid';

import CreateReportContainer from '../../containers/CreateReportContainer';
import { API_URL } from '../../services/api';

import './ReportsList.scss';

export default class ReportsList extends React.Component {
  getColumns = memoize((systems = []) => {
    const headerClassName = 'Text Text_clipped Text_size_14 Grid-ColumnTitle';

    const options = systems.map(({ key, text }) => ({
      content: text,
      value: key,
    }));

    return [
      {
        headerContent: (
          <Translate value="reports.filename" className={headerClassName} />
        ),
        id: 'filename',
        sortable: true,
        minWidth: 200,
        verticalAlign: 'center',
        filter: {
          type: 'text',
        },
      },
      {
        headerContent: (
          <Translate value="reports.createDate" className={headerClassName} />
        ),
        id: 'created',
        sortable: true,
        minWidth: 175,
        verticalAlign: 'center',
        filter: {
          type: 'date',
          align: 'right',
        },
      },
      {
        headerContent: (
          <Translate value="reports.time" className={headerClassName} />
        ),
        id: 'time',
        minWidth: 250,
        verticalAlign: 'center',
        filter: {
          type: 'date',
        },
      },
      {
        headerContent: (
          <Translate value="reports.system" className={headerClassName} />
        ),
        id: 'system',
        width: 150,
        verticalAlign: 'center',
        filter: {
          type: 'multiselect',
          options,
        },
      },
      {
        headerContent: (
          <Translate value="reports.size" className={headerClassName} />
        ),
        id: 'size',
        sortable: true,
        minWidth: 100,
        verticalAlign: 'center',
        filter: {
          type: 'range',
        },
      },
    ];
  }, shallowEqual);

  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false,
      filters: {},
      sort: {},
    };
  }

  componentDidMount() {
    this.fetchReports();
  }

  fetchReports = () => {
    const { filters, sort } = this.state;

    this.props.firstFetch({ filters, sort });
  };

  fetchMore = (page) => {
    const { filters, sort } = this.state;

    return this.props.fetchMore({ filters, sort }, page);
  };

  onExpand = (isExpanded) => {
    this.setState({
      isExpanded,
    });
  };

  delete = ({ rowIndex }) => {
    const { deleteReport, reports } = this.props;

    deleteReport({ ids: [reports[rowIndex].id] });
  };

  groupDelete = ({ selectedRows }) => {
    const { deleteReport, reports } = this.props;

    const keys = selectedRows.map((rowId) => Number(rowId.split('-')[0]));

    const ids = keys.map((key) => reports[key].id);

    deleteReport({ ids });
  };

  startDownload = ({ filename, link }) => {
    const { downloadReport } = this.props;

    downloadReport(`${API_URL}/soc${link}/download/`, filename);
  };

  singleDownload = ({ rowId, rowIndex }) => {
    const { reports } = this.props;

    this.startDownload(reports[rowIndex]);
  };

  groupDownload = ({ selectedRows }) => {
    const { reports } = this.props;
    const keys = selectedRows.map((rowId) => Number(rowId.split('-')[0]));

    keys.forEach((key) => this.startDownload(reports[key]));
  };

  setFilters = ({ id, value } = {}) => {
    const filters = { ...this.state.filters };

    if ([undefined, ''].includes(value)) {
      if (id === 'time') {
        delete filters.start;
        delete filters.finish;
      } else if (id === 'created') {
        delete filters.created__lte;
        delete filters.created__gte;
      } else {
        delete filters[id];
      }
    } else if (id === 'time') {
      filters.start = moment.unix(value.from).format('YYYY-MM-DD');
      filters.finish = moment.unix(value.to).format('YYYY-MM-DD');
    } else if (id === 'created') {
      filters.created__gte = moment.unix(value.from).format('YYYY-MM-DD');
      filters.created__lte = moment.unix(value.to).format('YYYY-MM-DD');
    } else {
      filters[id] = value;
    }

    if (!shallowEqual(filters, this.state.filters)) {
      this.setState({ filters }, this.fetchReports);
    }
  };

  setSort = ({ direction, id }) => {
    const sort = !direction ? {} : { field: id, type: direction };
    this.setState({ sort }, this.fetchReports);
  };

  render() {
    const {
      hasMore,
      isLoading,
      locale = 'en',
      reports = [],
      systems,
    } = this.props;
    const columns = this.getColumns(systems);

    const title = (
      <Header size={16}>
        <Translate value="reports.title" />
        <Label offsetLeft={8}>{reports.length}</Label>
      </Header>
    );

    const height = Math.max(600, document.documentElement.clientHeight - 222);

    const batchSize = 100;

    return (
      <Layout.Container>
        <Layout.Content style={{ maxWidth: 1694 }}>
          <Block
            title={title}
            separateHeader
            expandable
            onExpand={this.onExpand}
            fullHeight
            headerControls={
              <CreateReportContainer>
                <IconButton basic icon="plus">
                  <Translate value="main.incident" />
                </IconButton>
              </CreateReportContainer>
            }
          >
            <OldGrid
              resizeContentSyncTime={0}
              height={this.state.isExpanded ? null : height}
              columns={columns}
              rows={normalizeData(reports)}
              loading={isLoading}
              locale={locale}
              allRowsLoaded={!hasMore}
              batchSize={batchSize}
              onInfiniteScroll={({ stopIndex }) =>
                this.fetchMore(Math.ceil(stopIndex / batchSize))
              }
              multiSelect
              actions={[
                {
                  icon: 'delete',
                  action: this.delete,
                  confirm: I18n.t('reports.actions.confirmDelete'),
                  strict: true,
                  tooltip: I18n.t('reports.actions.delete'),
                },
                {
                  icon: 'file-download',
                  action: this.singleDownload,
                  tooltip: I18n.t('reports.actions.download'),
                },
              ]}
              groupActions={[
                {
                  icon: 'file-download',
                  text: I18n.t('reports.groupActions.download'),
                  action: this.groupDownload,
                  rich: true,
                  primary: true,
                },
                {
                  icon: 'delete',
                  text: I18n.t('reports.groupActions.delete'),
                  action: this.groupDelete,
                  confirm: ({ selectedCount: count }) =>
                    I18n.t(
                      `reports.groupActions.confirmDelete${getPlural(count)}`,
                      { count },
                    ),
                  strict: true,
                },
              ]}
              onFilterChange={this.setFilters}
              onSort={this.setSort}
            />
          </Block>
        </Layout.Content>
      </Layout.Container>
    );
  }
}

function normalizeData(data) {
  return data.map((row, index) => ({
    id: `${index}-${Date.now()}`,
    selectable: !row.isDeleting,
    hasActions: !row.isDeleting,
    cells: {
      ...row,
      created: moment(row.created).format('ll'),
      time: row.start
        ? `${moment(row.start).format('ll')}-${moment(row.finish).format('ll')}`
        : '',
    },
  }));
}
