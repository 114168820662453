import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { Message } from '@bizone/ui-bundle/esm/Message';

import { CheckCircleOutlined } from '@ant-design/icons';
import { bemCls } from '@common/soc-react-kit';

import './NotificationBlock.scss';

function NotificationBlock({ children, placement = 'bottomRight' }) {
  const [className, setClassName] = useState('NotificationBlock');

  useEffect(() => {
    setClassName(bemCls('NotificationBlock', { [placement]: true }));
  }, [placement]);

  return (
    <div className={className}>
      {placement === 'topLeft' || placement === 'topRight'
        ? children
        : children.reverse()}
    </div>
  );
}

class NotificationQueue extends React.Component {
  state = {
    bottomRight: [],
    bottomLeft: [],
    topRight: [],
    topLeft: [],
  };

  success = ({ inverted = true, message, ...messageProps }) => {
    this.pushMessage({
      message: (
        <span>
          <CheckCircleOutlined
            style={{
              fontSize: '20px',
              color: '#66BB86',
              verticalAlign: 'middle',
              marginRight: 10,
              marginLeft: -8,
            }}
          />
          {message}
        </span>
      ),
      inverted,
      ...messageProps,
    });
  };

  pushMessage = (messageProps) => {
    const {
      lifeTime = 5000,
      message,
      placement = 'bottomRight',
      ...rest
    } = messageProps;
    const { [placement]: messages } = this.state;

    const messageKey = `${messages}-${new Date().getTime()}`;
    const messageComponent = (
      <Message
        key={messageKey}
        onClose={() => this.closeMessage(messageProps, messageKey)}
        {...rest}
      >
        {message}
      </Message>
    );

    this.setState({ [placement]: [...messages, messageComponent] });
    setTimeout(this.closeMessage, lifeTime, messageProps, messageKey);
  };

  closeMessage = (messageProps, key) => {
    const { placement = 'bottomRight' } = messageProps;
    const { [placement]: messages } = this.state;

    this.setState({ [placement]: messages.filter((item) => item.key !== key) });
  };

  render() {
    const placements = ['bottomRight', 'bottomLeft', 'topRight', 'topLeft'];

    return (
      <React.Fragment>
        {placements.map((placement) =>
          this.state[placement].length === 0 ? null : (
            <NotificationBlock key={placement} placement={placement}>
              {this.state[placement]}
            </NotificationBlock>
          ),
        )}
      </React.Fragment>
    );
  }
}

const ref = React.createRef();
const div = document.createElement('div');

ReactDOM.render(<NotificationQueue ref={ref} />, div);
document.body.appendChild(div);
export default ref.current;
