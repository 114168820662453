import { createTheme } from 'combinezone/theme/default/createTheme';
import { brand as globalBrand } from 'combinezone/theme/generated/global';

import {
  a14,
  a40,
  a6,
  a60,
  a8,
  constant,
  legend,
} from 'branding/themes/global';

import { basisLight, defaultLight } from './defaultLight';

export const brand = {
  ...globalBrand,
  hsl: {
    ...globalBrand.hsl,
    active: 216,
  },
};

export const neutral = {
  base: `hsl(${brand.hsl.neutral}, 20%, 20%)`,
  primary: `hsl(${brand.hsl.neutral}, 20%, 90%)`,
  secondary: `hsl(${brand.hsl.neutral}, 20%, 60%)`,
  faded: `hsl(${brand.hsl.neutral}, 20%, 40%)`,
  divider: `hsl(${brand.hsl.neutral}, 20%, 8%)`,
  pastel: {
    saturated: `hsl(${brand.hsl.neutral}, 20%, 26%)`,
    disabled: `hsl(${brand.hsl.neutral}, 5%, 25%)`,
    zebra: `hsl(${brand.hsl.neutral}, 20%, 16%)`,
    smoke: `hsl(${brand.hsl.neutral}, 20%, 16%)`,
  },
  alpha: {
    saturated: `hsla(${brand.hsl.zero}, 0%, 100%, ${a60})`,
    base: `hsla(${brand.hsl.zero}, 0%, 100%, ${a40})`,
    faded: `hsla(${brand.hsl.zero}, 0%, 100%, ${a14})`,
    'smoke-saturated': `hsla(${brand.hsl.zero}, 0%, 100%, ${a8})`,
    'smoke-base': `hsla(${brand.hsl.zero}, 0%, 100%, ${a6})`,
  },
  gradient: {
    saturated: `linear-gradient(90deg, hsla(${brand.hsl.neutral}, 20%, 26%, 0.001) 0%, hsl(${brand.hsl.neutral}, 20%, 26%) 100%)`,
    base: `linear-gradient(90deg, hsla(${brand.hsl.neutral}, 20%, 20%, 0.001) 0%, hsl(${brand.hsl.neutral}, 20%, 20%) 100%)`,
    zebra: `linear-gradient(90deg, hsla(${brand.hsl.neutral}, 20%, 16%, 0.001) 0%, hsl(${brand.hsl.neutral}, 20%, 16%) 100%)`,
  },
};

export const active = {
  faded:
    process.env.REACT_APP_DISABLED_PRIMARY_COLOR ||
    `hsl(${brand.hsl.active}, 55%, 73%)`,
  pastel: {
    saturated:
      process.env.REACT_APP_SELECTED_HOVER_COLOR ||
      `hsl(${brand.hsl.active}, 40%, 30%)`,
    base:
      process.env.REACT_APP_SELECT_COLOR ||
      `hsl(${brand.hsl.active}, 40%, 25%)`,
  },
  alpha: {
    base:
      process.env.REACT_APP_PRIMARY_COLOR ||
      `hsla(${brand.hsl.active}, 73%, 47%, ${a60})`,
  },
  gradient: {
    saturated: `linear-gradient(90deg, hsla(${brand.hsl.active}, 40%, 35%, 0.001) 0%, hsl(${brand.hsl.active}, 40%, 35%) 100%)`,
    base: `linear-gradient(90deg, hsla(${brand.hsl.active}, 40%, 30%, 0.001) 0%, hsl(${brand.hsl.active}, 40%, 30%) 100%)`,
  },
};

export const positive = {
  pastel: {
    saturated: `hsl(${brand.hsl.positive}, 40%, 35%)`,
    base: `hsl(${brand.hsl.positive}, 40%, 30%)`,
  },
  gradient: {
    saturated: `linear-gradient(90deg, hsla(${brand.hsl.positive}, 40%, 35%, 0.001) 0%, hsl(${brand.hsl.positive}, 40%, 35%) 100%)`,
    base: `linear-gradient(90deg, hsla(${brand.hsl.positive}, 40%, 30%, 0.001) 0%, hsl(${brand.hsl.positive}, 40%, 30%) 100%)`,
  },
};

export const attention = {
  pastel: {
    saturated: `hsl(${brand.hsl.attention}, 40%, 50%)`,
    base: `hsl(${brand.hsl.attention}, 40%, 45%)`,
    smoke: `hsl(${brand.hsl.attention}, 40%, 40%)`,
  },
  gradient: {
    saturated: `linear-gradient(90deg, hsla(${brand.hsl.attention}, 40%, 35%, 0.001) 0%, hsl(${brand.hsl.attention}, 40%, 35%) 100%)`,
    base: `linear-gradient(90deg, hsla(${brand.hsl.attention}, 40%, 30%, 0.001) 0%, hsl(${brand.hsl.attention}, 40%, 30%) 100%)`,
  },
};

export const warning = {
  pastel: {
    saturated: `hsl(${brand.hsl.warning}, 40%, 35%)`,
    base: `hsl(${brand.hsl.warning}, 40%, 30%)`,
  },
  gradient: {
    saturated: `linear-gradient(90deg, hsla(${brand.hsl.warning}, 40%, 35%, 0.001) 0%, hsl(${brand.hsl.warning}, 40%, 35%) 100%)`,
    base: `linear-gradient(90deg, hsla(${brand.hsl.warning}, 40%, 30%, 0.001) 0%, hsl(${brand.hsl.warning}, 40%, 30%) 100%)`,
  },
};

export const critical = {
  faded: `hsl(${brand.hsl.critical}, 10%, 30%)`,
  pastel: {
    saturated: `hsl(${brand.hsl.critical}, 40%, 35%)`,
    base: `hsl(${brand.hsl.critical}, 40%, 30%)`,
    smoke: `hsl(${brand.hsl.critical}, 40%, 25%)`,
  },
  alpha: {
    base: `hsla(${brand.hsl.critical}, 40%, 30%, ${a60})`,
  },
  gradient: {
    saturated: `linear-gradient(90deg, hsla(${brand.hsl.critical}, 40%, 35%, 0.001) 0%, hsl(${brand.hsl.critical}, 40%, 35%) 100%)`,
    base: `linear-gradient(90deg, hsla(${brand.hsl.critical}, 40%, 30%, 0.001) 0%, hsl(${brand.hsl.critical}, 40%, 30%) 100%)`,
  },
};

export const basisDark: typeof basisLight = {
  ...basisLight,
  colors: {
    ...basisLight.colors,
    base: {
      ...basisLight.colors.base,
      block: neutral.base,
      desk: neutral.pastel.smoke,
    },
    borders: {
      normal: neutral.faded,
      disabled: neutral.faded,
      divider: neutral.divider,
    },
    shadows: {
      ...basisLight.colors.shadows,
      focus: active.alpha.base,
    },
    text: {
      ...basisLight.colors.text,
      default: neutral.primary,
      secondary: neutral.secondary,
      placeholder: neutral.faded,
      inverted: constant.faded,
      light: constant.faded,
      dark: constant.base,
    },
    link: {
      normal: basisLight.colors.link.hover,
      hover: basisLight.colors.link.normal,
      disable: active.faded,
    },
    button: {
      ...basisLight.colors.button,
      disable: active.faded,
      secondary: {
        normal: neutral.pastel.saturated,
        disable: neutral.pastel.saturated,
        hover: `hsl(${brand.hsl.neutral}, 20%, 36%)`,
        pressedFocus: `hsl(${brand.hsl.neutral}, 20%, 36%)`,
      },
      transparent: {
        normal: neutral.alpha.saturated,
        hover: neutral.alpha.faded,
        pressedFocus: neutral.alpha.faded,
      },
    },
    icon: {
      ...basisLight.colors.icon,
      normal: neutral.alpha.base,
      hover: neutral.alpha.saturated,
      disable: neutral.alpha.faded,
      contrast: {
        normal: neutral.alpha.base,
        hover: neutral.alpha.saturated,
        disable: neutral.alpha.faded,
      },
      active: {
        ...basisLight.colors.icon.active,
        disable: active.faded,
      },
      critical: {
        ...basisLight.colors.icon.critical,
        disable: critical.faded,
      },
    },
    node: {
      normal: neutral.base,
      hover: neutral.pastel.saturated,
      zebra: neutral.pastel.zebra,
      select: active.pastel.base,
      selectHover: active.pastel.saturated,
      doubleSelected: active.pastel.saturated,
      doubleSelectedHover: active.pastel.saturated,
      critical: critical.pastel.base,
      criticalHover: critical.pastel.saturated,
      attention: attention.pastel.base,
      attentionHover: attention.pastel.saturated,
      warning: warning.pastel.base,
      warningHover: warning.pastel.saturated,
      positive: positive.pastel.base,
      positiveHover: positive.pastel.saturated,
    },
    tags: {
      plum: legend.plum,
      kelly: legend.kelly,
      stone: legend.stone,
      cornflower: legend.cornflower,
      lime: legend.lime,
      iron: legend.iron,
      olympic: legend.olympic,
      gold: legend.gold,
      steel: legend.steel,
      sky: legend.sky,
      tiger: legend.tiger,
      sea: legend.sea,
      candy: legend.candy,
      smoke: neutral.alpha['smoke-base'],
      smokeHover: neutral.alpha['smoke-saturated'],
    },
  },
};

export const defaultDark = createTheme(basisDark, true);
defaultDark.components.input.colors.background.disabled =
  neutral.pastel.disabled;
defaultDark.components.input.colors.shadow.focus =
  defaultLight.components.input.colors.shadow.focus;
defaultDark.components.input.colors.shadow.critical =
  defaultLight.components.input.colors.shadow.critical;
defaultDark.components.input.colors.background.critical = critical.pastel.smoke;

defaultDark.components.textarea.colors.background.disabled =
  neutral.pastel.disabled;
defaultDark.components.textarea.colors.shadow.focus =
  defaultLight.components.textarea.colors.shadow.focus;
defaultDark.components.textarea.colors.shadow.critical =
  defaultLight.components.textarea.colors.shadow.critical;
defaultDark.components.textarea.colors.background.critical =
  critical.pastel.smoke;

defaultDark.components.pinInput.colors.background.disabled =
  neutral.pastel.disabled;
defaultDark.components.pinInput.colors.shadow.focus =
  defaultLight.components.pinInput.colors.shadow.focus;
defaultDark.components.pinInput.colors.shadow.critical =
  defaultLight.components.pinInput.colors.shadow.critical;
defaultDark.components.pinInput.colors.background.critical =
  critical.pastel.smoke;

defaultDark.components.select.input.colors.background.disabled =
  neutral.pastel.disabled;
defaultDark.components.select.input.colors.shadow.focus =
  defaultLight.components.select.input.colors.shadow.focus;
defaultDark.components.select.input.colors.shadow.critical =
  defaultLight.components.select.input.colors.shadow.critical;
defaultDark.components.select.input.colors.background.critical =
  critical.pastel.smoke;

defaultDark.components.overlay.colors.dark = constant.alpha.smoke;
