import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { Dropdown } from '@bizone/ui-bundle/esm/Dropdown';
import { Icon } from '@bizone/ui-bundle/esm/Icon';
import { Node } from '@bizone/ui-bundle/esm/Node';
import { Tooltip } from '@bizone/ui-bundle/esm/Tooltip';

import {
  CREATE_FIELD,
  PRIORITY_FIELD,
  STATUS_FIELD,
  UPDATE_FIELD,
} from '../../services/api';

import './SortControl.scss';

export class SortControl extends React.PureComponent {
  constructor(props) {
    super(props);

    const [type = CREATE_FIELD, order = 'DESC'] = Object.entries(
      props.ordering,
    )[0];

    this.state = {
      type,
      order,
      isOpen: false,
    };

    this.sortTypes = [CREATE_FIELD, UPDATE_FIELD, STATUS_FIELD, PRIORITY_FIELD];

    this.dropdownProps = {
      fitted: 320,
      onShow: () => this.setState({ isOpen: true }),
      onHide: () => this.setState({ isOpen: false }),
    };
  }

  onSelect = (params) => {
    this.setState(
      {
        ...params,
      },
      () => this.props.setSort({ [this.state.type]: this.state.order }),
    );
  };

  render() {
    const [orderType = 'created', order = 'DESC'] = Object.entries(
      this.props.ordering,
    )[0];
    const dropdownContent = this.sortTypes.map((type) => (
      <Node
        selected={orderType === type}
        key={type}
        onClick={this.onSelect.bind(null, { type })}
      >
        {I18n.t(`incidentList.sort.${type}.helpText`)}
      </Node>
    ));

    return (
      <div className="SortControl">
        <Tooltip
          content={I18n.t(`incidentList.sort.${orderType}.${order}`)}
          delay={500}
          inverted
        >
          <div
            className="SortControl-Toggle IconButton"
            onClick={this.onSelect.bind(null, {
              order: order === 'DESC' ? 'ASC' : 'DESC',
            })}
          >
            <Icon
              cls="IconButton"
              glyph={`reverse-sort-${order === 'DESC' ? 'asc' : 'desc'}`}
              size={24}
            />
          </div>
        </Tooltip>
        <Dropdown content={dropdownContent} {...this.dropdownProps}>
          <div className="SortControl-Dropdown IconButton">
            <Icon glyph="sort-down" size={16} />
          </div>
        </Dropdown>
      </div>
    );
  }
}
