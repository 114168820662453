import { I18n } from 'react-redux-i18n';

import { message } from 'antd';
import moment from 'moment';

import { encodeIncsChangesFilter, INCIDENTS_ENDPOINT } from './api';
import { apiFetch } from './apiFetch';

class IncidentMessagesService {
  updateTimer = null;

  updateInterval = 30 * 1000;

  mutedIncidents = [];

  start = () => {
    this.lastUpdateTime = moment();
    this.stop();
    this.updateTimer = setInterval(() => this.main(), this.updateInterval);
  };

  main = () => {
    const lastReceivedIncTimestamp = this.lastUpdateTime.format('x');
    const startTimestamp = moment()
      .subtract(1, 'days')
      .startOf('day')
      .format('x');
    const endTimestamp = moment().endOf('day').format('x');
    const incidentParams = encodeIncsChangesFilter(
      {},
      startTimestamp,
      endTimestamp,
      lastReceivedIncTimestamp,
    );

    this.fetchIncidents(incidentParams).then((body) => {
      for (const incident of body.results) {
        const mutedIncidentIndex = this.mutedIncidents.indexOf(incident.key);
        if (mutedIncidentIndex === -1) {
          message.warning(
            I18n.t('newIncidentMessage.message', { key: incident.key }),
          );
        } else {
          this.mutedIncidents.slice(mutedIncidentIndex, 1);
        }
      }
      this.lastUpdateTime = moment();
    });
  };

  fetchIncidents = (params) =>
    apiFetch.get(`${INCIDENTS_ENDPOINT}${params}`, {});

  stop = () => clearInterval(this.updateTimer);

  pushMutedIncident = (incidentKey) => {
    this.mutedIncidents.push(incidentKey);
  };
}

export default new IncidentMessagesService();
