import { INSTRUCTIONS_UPDATED } from '../../../services/api';

import * as types from './actionTypes';

const initialState = {
  articles: [],
  articlesCount: 0,
  fetching: false,
  hasMore: true,
  nextPage: 2,
  lastFetchTime: null,
  filters: {
    search: '',
    ordering: { [INSTRUCTIONS_UPDATED]: 'DESC' },
    selected: {},
  },
};

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.FIRST_FETCH_REQUEST: {
      return {
        ...state,
        nextPage: 2,
        lastFetchTime: action.meta.timestamp,
        fetching: true,
      };
    }

    case types.FETCH_MORE_REQUEST: {
      return {
        ...state,
        fetching: true,
      };
    }

    case types.FIRST_FETCH_SUCCESS: {
      if (state.lastFetchTime > action.meta.timestamp) {
        return state;
      }

      return {
        ...state,
        fetching: false,
        articles: action.payload.results,
        articlesCount: action.payload.count,
        selected: action.payload.results[0],
        hasMore: Boolean(action.payload.next),
      };
    }

    case types.FETCH_MORE_SUCCESS: {
      if (state.lastFetchTime > action.meta.timestamp) {
        return state;
      }

      return {
        ...state,
        fetching: false,
        articles: [...state.articles, ...action.payload.results],
        nextPage: state.nextPage + 1,
        hasMore: Boolean(action.payload.next),
      };
    }

    case types.FIRST_FETCH_FAIL:
    case types.FETCH_MORE_FAIL: {
      return {
        ...state,
        fetching: false,
      };
    }

    case types.SET_SORT: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ordering: action.payload,
        },
      };
    }

    case types.SET_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          selected: action.payload,
        },
      };
    }

    case types.SET_SEARCH: {
      return {
        ...state,
        filters: {
          ...state.filters,
          search: action.payload,
        },
      };
    }

    default:
      return state;
  }
}

export function getNextPage(state) {
  return state.nextPage;
}
