export const FIRST_FETCH_REQUEST = '@@logSources/FIRST_FETCH_REQUEST';
export const FIRST_FETCH_SUCCESS = '@@logSources/FIRST_FETCH_SUCCESS';
export const FIRST_FETCH_FAIL = '@@logSources/FIRST_FETCH_FAIL';

export const FETCH_MORE_REQUEST = '@@logSources/FETCH_MORE_REQUEST';
export const FETCH_MORE_SUCCESS = '@@logSources/FETCH_MORE_SUCCESS';
export const FETCH_MORE_FAIL = '@@logSources/FETCH_MORE_FAIL';

export const LOAD_CSV_REQUEST = '@@logSources/LOAD_CSV_REQUEST';
export const LOAD_CSV_SUCCESS = '@@logSources/LOAD_CSV_SUCCESS';
export const LOAD_CSV_FAIL = '@@logSources/LOAD_CSV_FAIL';

export const SELECT_SOURCE = '@@logSources/SELECT_SOURCE';
export const ACTIVE_FOR_ENDPONT = '@@logSources/ACTIVE_FOR_ENDPONT';

export const FETCH_ACTIVE_TOTAL_COUNT_REQUEST =
  '@@logSources/FETCH_ACTIVE_TOTAL_COUNT_REQUEST';
export const FETCH_ACTIVE_TOTAL_COUNT_SUCCESS =
  '@@logSources/FETCH_ACTIVE_TOTAL_COUNT_SUCCESS';
export const FETCH_ACTIVE_TOTAL_COUNT_FAIL =
  '@@logSources/FETCH_ACTIVE_TOTAL_COUNT_FAIL';
