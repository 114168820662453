import React, { FC } from 'react';
import { I18n } from 'react-redux-i18n';

import styled from 'styled-components';

import { Caption, Flex, Heading, Tooltip } from 'combinezone/core';
import {
  ClockSmall,
  RefreshSmall,
  History,
  ArchiveAddSmall,
} from 'combinezone/icons';

import { LogSourcesCardHeaderProps } from 'components/LogSources/LogSourcesCard/components/LogSourcesCardHeader/types';
import { handleRequestItem } from 'components/LogSources/LogSourcesCard/helpers';
import { useTheme } from 'utils/brending';

const LogSourcesHeader = styled(Flex)`
  gap: 10px;
  align-items: center;
`;

const LogSourcesMeta = styled(Flex)`
  align-items: center;
  gap: 15px;
  margin-top: 5px;
`;

const LogSourcesCardHeader: FC<LogSourcesCardHeaderProps> = ({
  source,
  testId,
}) => {
  const {
    basis: {
      colors: {
        text: { secondary },
      },
    },
  } = useTheme();

  const testIdHeader = `${testId}-header`;

  return (
    <>
      <LogSourcesHeader testId={testIdHeader}>
        <Heading size="md" testId={`${testId}-title`}>
          {source.name}
        </Heading>
      </LogSourcesHeader>
      <LogSourcesMeta testId={`${testId}-meta`}>
        <Flex gap="5px">
          <ClockSmall color={secondary} />
          <Tooltip
            content={I18n.t('logSourcesCard.created')}
            testId={`${testId}-tooltip-created`}
          >
            <Flex>
              <Caption
                isClipped
                variant="secondary"
                testId={`${testId}-created`}
              >
                {handleRequestItem(source.created)}
              </Caption>
            </Flex>
          </Tooltip>
        </Flex>
        <Flex gap="5px">
          <RefreshSmall color={secondary} />
          <Tooltip
            content={I18n.t('logSourcesCard.lastSynced')}
            testId={`${testId}-tooltip-last-synced`}
          >
            <Flex>
              <Caption
                isClipped
                variant="secondary"
                testId={`${testId}-last-synced`}
              >
                {handleRequestItem(source.last_synced)}
              </Caption>
            </Flex>
          </Tooltip>
        </Flex>
        <Flex gap="5px">
          <History size="16px" color={secondary} />
          <Tooltip
            content={I18n.t('logSourcesCard.lastEventTime')}
            testId={`${testId}-tooltip-last-event-time`}
          >
            <Flex>
              <Caption
                isClipped
                variant="secondary"
                testId={`${testId}-last-event-time`}
              >
                {handleRequestItem(source.eps_metrics?.last_event_time)}
              </Caption>
            </Flex>
          </Tooltip>
        </Flex>
        {!source.is_active && (
          <Flex gap="5px">
            <ArchiveAddSmall color={secondary} />
            <Tooltip
              content={I18n.t('logSourcesCard.archived')}
              testId={`${testId}-tooltip-archived`}
            >
              <Flex>
                <Caption
                  isClipped
                  variant="secondary"
                  testId={`${testId}-archived`}
                >
                  {handleRequestItem(source.archiving_details.date)}
                </Caption>
              </Flex>
            </Tooltip>
          </Flex>
        )}
      </LogSourcesMeta>
    </>
  );
};

export default LogSourcesCardHeader;

LogSourcesCardHeader.displayName = 'LogSourcesCardHeader';
