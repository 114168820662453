import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import styled from 'styled-components';

import { Flex } from 'combinezone/core';
import { CombinezoneTheme } from 'combinezone/theme';

import LogSourcesDownload from 'components/LogSources/LogSourcesDownload';
import SearchBlock from 'components/LogSources/LogSourcesFilters/components/SearchBlock';
import { LogSourcesFiltersProps } from 'components/LogSources/LogSourcesFilters/types';
import {
  LogSourcesLocalSaveFiltersProps,
  RootState,
} from 'components/LogSources/LogSourcesList/types';
import FilterControlContainer from 'containers/FilterControlContainer';
import FilterPanelContainer from 'containers/FilterPanelContainer';
import SortControl from 'shared/components/SortControl';
import {
  localSaveFilters,
  searchChange,
  setFilter,
  setSort,
} from 'store/reducers/filters/actions';
import { setFilterFromQuerysringGeneral } from 'utils/helpers';
import { setLocale } from 'utils/i18n';

//  TODO удалить когда будет в ответе
const data = {
  created: {
    type: 'datetime',
    options: null,
  },
  archived_date: {
    type: 'datetime',
    options: null,
  },
  'eps_metrics.last_event_time': {
    type: 'datetime',
    options: null,
  },
  'eps_metrics.avg_last_hour': {
    type: 'datetime',
    options: null,
  },
};

const ArchiveListFiltersWrapper = styled(Flex)`
  flex: 1;
  flex-direction: column;
  gap: 10px;
  padding: 16px 0;
  background-color: ${({ theme }: { theme: CombinezoneTheme }) =>
    theme.basis?.colors.base.block};
`;

const ArchiveListFiltersControls = styled(Flex)`
  flex: 1;
  gap: 10px;
`;

const ArchiveListFiltersSearch = styled(Flex)`
  flex: 1;
`;

const LogSourcesFilters: FC<LogSourcesFiltersProps> = ({
  entity,
  firstFetch,
  testId,
}) => {
  const testIdLogSourcesFilters = `${testId}-filters-block`;
  const dispatch = useDispatch();
  const { ordering, search } = useSelector(
    (state: RootState) => state.filters[entity],
  );

  const onChangeSearch = useCallback(
    (searchText) => dispatch(searchChange(entity, searchText, firstFetch)),
    [dispatch, entity, firstFetch],
  );
  const setInitSearch = useCallback(
    (searchText) => dispatch(searchChange(entity, searchText)),
    [dispatch, entity],
  );
  const setInitFilter = useCallback(
    (key, value) => dispatch(setFilter(entity, key, value)),
    [dispatch, entity],
  );
  const setInitSort = useCallback(
    (orderingSort) => dispatch(setSort(entity, orderingSort)),
    [dispatch, entity],
  );
  const archiveLocalSaveFilters = useCallback(
    (prop: LogSourcesLocalSaveFiltersProps) => dispatch(localSaveFilters(prop)),
    [dispatch],
  );

  const history = useHistory();
  const { pathname, search: querystring } = useLocation();

  useEffect(() => {
    setFilterFromQuerysringGeneral({
      querystring,
      setFilter: setInitFilter,
      setSort: setInitSort,
      setSearch: setInitSearch,
      setLocale,
    });
  }, []);

  useEffect(() => {
    archiveLocalSaveFilters({
      entity,
      history,
      pathname,
    } as LogSourcesLocalSaveFiltersProps);
  }, [archiveLocalSaveFilters, history, pathname, search, ordering, entity]);

  return (
    <ArchiveListFiltersWrapper>
      <ArchiveListFiltersControls>
        <ArchiveListFiltersSearch>
          <SearchBlock
            defaultText={search}
            onSearch={onChangeSearch}
            testId={testIdLogSourcesFilters}
          />
        </ArchiveListFiltersSearch>
        <FilterControlContainer updateFunction={firstFetch} entity={entity} />
        <SortControl
          testId={testIdLogSourcesFilters}
          entity={entity}
          data={data}
          updateFunction={firstFetch}
        />
        <LogSourcesDownload testId={testIdLogSourcesFilters} />
      </ArchiveListFiltersControls>
      <FilterPanelContainer updateFunction={firstFetch} entity={entity} />
    </ArchiveListFiltersWrapper>
  );
};

export default LogSourcesFilters;

LogSourcesFilters.displayName = 'LogSourcesFilters';
