export const SET_FILTER = '@@filters/SET_FILTER';
export const RESET_FILTERS = '@@filters/RESET_FILTERS';
export const SHOW_FILTERS = '@@filters/SHOW_FILTERS';
export const FETCH_FILTERS_REQUEST = '@@filters/FETCH_FILTERS_REQUEST';
export const FETCH_FILTERS_SUCCESS = '@@filters/FETCH_FILTERS_SUCCESS';
export const FETCH_FILTERS_FAIL = '@@filters/FETCH_FILTERS_FAIL';

export const SET_SORT = '@@filters/SET_SORT';
export const NEW_DAY_STARTED = '@@filters/NEW_DAY_STARTED';

export const SEARCH_CHANGE = '@@filters/INCIDENTLIST_SEARCH_CHANGE';
