import { connect } from 'react-redux';

import ResponseInstructions from '../components/ResponseInstructions';
import {
  RESPONSE_INSTRUCTIONS_ENDPOINT,
  RESPONSE_INSTRUCTIONS_ENTITY,
} from '../services/api';
import {
  currentLocale,
  getSearch,
  getOrdering,
  getSelectedFilters,
} from '../store/reducers';
import { downloadFile } from '../store/reducers/fileDownloader/actions';
import {
  localSaveFilters,
  setFilter,
  searchChange,
  setSort,
  resetFilters,
} from '../store/reducers/filters/actions';
import {
  fetchMore,
  firstFetch,
} from '../store/reducers/responseInstructions/actions';

const mapStateToProps = (state) => {
  return {
    instructions: state.responseInstructions.instructions,
    isFetching: state.responseInstructions.fetching,
    hasMore: state.responseInstructions.hasMore,
    search: getSearch(RESPONSE_INSTRUCTIONS_ENTITY, state),
    filters: getSelectedFilters(RESPONSE_INSTRUCTIONS_ENTITY, state),
    ordering: getOrdering(RESPONSE_INSTRUCTIONS_ENTITY, state),
    currentLocale: currentLocale(state),
  };
};

const mapDispatchToProps = {
  firstFetch,
  fetchMore,
  onChangeSearch: (search) =>
    searchChange(RESPONSE_INSTRUCTIONS_ENTITY, search, firstFetch),
  setFilter: (key, item) =>
    setFilter(RESPONSE_INSTRUCTIONS_ENTITY, key, item, firstFetch),
  resetFilters: () => resetFilters(RESPONSE_INSTRUCTIONS_ENTITY, firstFetch),
  setSort: (ordering) =>
    setSort(RESPONSE_INSTRUCTIONS_ENTITY, ordering, firstFetch),
  setInitSearch: (search) => searchChange(RESPONSE_INSTRUCTIONS_ENTITY, search),
  setInitFilter: (key, item) =>
    setFilter(RESPONSE_INSTRUCTIONS_ENTITY, key, item),
  setInitSort: (ordering) => setSort(RESPONSE_INSTRUCTIONS_ENTITY, ordering),
  downloadPDF: (id, name) =>
    downloadFile(
      `${RESPONSE_INSTRUCTIONS_ENDPOINT}${id}/download_pdf/`,
      `${name}.pdf`,
    ),
  localSaveFilters,
};

const ResponseInstructionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResponseInstructions);
export default ResponseInstructionsContainer;
