import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Translate } from 'react-redux-i18n';

import { List } from '@bizone/ui-bundle/esm/List';

import { Popover } from 'antd';
import { Heading, LayoutContentBlock, OverlaySpinner } from 'combinezone/core';
import {
  AngleAllLeft,
  AngleAllRight,
  CalendarPlanner,
  UserEditor,
} from 'combinezone/icons';

import { RulesIcon } from '../Icons';
import './index.scss';

function MiniDashboard({ data, fetchTotalCount, fetching, total }) {
  const {
    mitre_coverage_by_platform: coverageByPlatform = {},
    mitre_coverage_percent: coveragePercent = 0,
    mitre_total_techniques_by_platforms: totalTechniquesByPlatform = {},
  } = data || {};

  const dashboardRef = useRef();
  const [isOverflowed, setIsOverflowed] = useState();
  const [offset, setOffset] = useState(0);

  const platformsCoverage = useMemo(() => {
    if (
      !Object.keys(coverageByPlatform).length ||
      !Object.keys(totalTechniquesByPlatform).length
    ) {
      return [];
    }

    return Object.keys(coverageByPlatform).reduce((coverage, platformName) => {
      coverage[platformName] = `${(
        (coverageByPlatform[platformName] /
          totalTechniquesByPlatform[platformName]) *
        100
      ).toFixed(1)} %`;
      return coverage;
    }, {});
  }, [coverageByPlatform, totalTechniquesByPlatform]);

  useEffect(() => {
    fetchTotalCount();
  }, []);

  useEffect(() => {
    const updateSize = () => {
      const element = dashboardRef.current;
      if (!element) {
        return;
      }

      const isOverflowed = element.offsetWidth < element.scrollWidth;
      if (!isOverflowed) {
        setOffset(0);
      }
      setIsOverflowed(isOverflowed);
    };
    updateSize();
    window.addEventListener('resize', updateSize);
    const resizeObserver = new ResizeObserver(updateSize);
    resizeObserver.observe(dashboardRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  const maxOffsetToScroll = dashboardRef.current
    ? dashboardRef.current.offsetWidth - dashboardRef.current.scrollWidth
    : 0;

  return (
    <div ref={dashboardRef} className="RuleListDashboard">
      {fetching && <OverlaySpinner />}
      {isOverflowed && offset < 0 && (
        <div
          className="RuleListDashboard-ScrollLeft"
          onClick={() =>
            setOffset((offset) => (offset + 100 < 0 ? offset + 100 : 0))
          }
        >
          <AngleAllLeft />
        </div>
      )}
      {isOverflowed && (offset > maxOffsetToScroll || offset === 0) && (
        <div
          className="RuleListDashboard-ScrollRight"
          onClick={() =>
            setOffset((offset) =>
              offset - 100 <
              dashboardRef.current.offsetWidth -
                dashboardRef.current.scrollWidth
                ? offset - 100
                : dashboardRef.current.offsetWidth -
                  dashboardRef.current.scrollWidth,
            )
          }
        >
          <AngleAllRight />
        </div>
      )}
      <div
        style={{
          left: isOverflowed ? offset : 0,
        }}
        className="RuleListDashboard-Container"
      >
        <LayoutContentBlock className="RuleFactoid">
          <div className="RuleFactoid-Icon RuleFactoid-Icon__count">
            <RulesIcon size="24px" />
          </div>
          <div className="RuleFactoid-Info">
            <div className="Factoid-Body">
              <Heading size="lg">
                {data.count || 0}
                {data.count !== total && total && (
                  <span className="LightFactoid">/{total}</span>
                )}
              </Heading>
            </div>
            <div className="RuleFactoid-Title">
              <Translate value="ruleList.miniDashboard.count" />
            </div>
          </div>
        </LayoutContentBlock>
        <LayoutContentBlock className="RuleFactoid">
          <div className="RuleFactoid-Icon RuleFactoid-Icon__last">
            <CalendarPlanner size="24px" />
          </div>
          <div className="RuleFactoid-Info">
            <div className="RuleFactoid-Body">
              <Heading size="lg">{data.count_last_month || 0}</Heading>
            </div>
            <div className="RuleFactoid-Title">
              <Translate value="ruleList.miniDashboard.countLastMonth" />
            </div>
          </div>
        </LayoutContentBlock>
        <LayoutContentBlock className="RuleFactoid">
          <div className="RuleFactoid-Icon RuleFactoid-Icon__client">
            <UserEditor size="24px" />
          </div>
          <div className="RuleFactoid-Info">
            <div className="RuleFactoid-Body">
              <Heading size="lg">{data.count_is_requested || 0}</Heading>
            </div>
            <div className="RuleFactoid-Title">
              <span>
                <Translate value="ruleList.miniDashboard.countIsRequested" />
              </span>
            </div>
          </div>
        </LayoutContentBlock>
        <Popover
          content={
            Object.keys(platformsCoverage).length > 0 && (
              <List data={platformsCoverage} position="bottom" />
            )
          }
        >
          <LayoutContentBlock className="RuleFactoid">
            <div className="MitreCoverageFactoid-Data">
              <Heading size="lg">{coveragePercent}%</Heading>
              <div className="MitreCoverageFactoid-Bar">
                <div
                  style={{ flex: `${coveragePercent / 100} 1 auto` }}
                  className="MitreCoverageFactoid-Back"
                />
                <div
                  style={{ flex: `${1 - coveragePercent / 100} 1 auto` }}
                  className="MitreCoverageFactoid-Front"
                />
              </div>
              <span>
                <Translate value="ruleList.coverageWidget.title" />
              </span>
            </div>
          </LayoutContentBlock>
        </Popover>
      </div>
    </div>
  );
}

MiniDashboard.defaultProps = {
  fetching: false,
};

export default MiniDashboard;
