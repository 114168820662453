import { AnyAction } from 'redux';
import { ApiError } from 'redux-api-middleware';

import * as types from './actionTypes';

export type ResponseTeamType = {
  id: number;
  name: string;
};

type FetchResponseTeamRequestType = {
  type: typeof types.FETCH_RESPONSE_TEAM_REQUEST;
};

export const fetchResponseTeamRequest = (
  action: AnyAction,
): action is FetchResponseTeamRequestType => {
  return action.type === types.FETCH_RESPONSE_TEAM_REQUEST;
};

export type FetchResponseTeamFailureType = {
  type: typeof types.FETCH_RESPONSE_TEAM_FAILURE;
  error: true;
  payload: ApiError<{ detail: string }>;
};

export const fetchResponseTeamFailure = (
  action: AnyAction,
): action is FetchResponseTeamFailureType => {
  return action.type === types.FETCH_RESPONSE_TEAM_FAILURE;
};

export type FetchResponseTeamSuccessType = {
  type: typeof types.FETCH_RESPONSE_TEAM_SUCCESS;
  payload: ResponseTeamType[];
};

export const fetchResponseTeamSuccess = (
  action: AnyAction,
): action is FetchResponseTeamSuccessType => {
  return action.type === types.FETCH_RESPONSE_TEAM_SUCCESS;
};
