import React, { FC, useMemo, useState, useEffect } from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import {
  Node,
  Checkbox,
  Dropdown,
  Input,
  Caption,
  Text,
} from 'combinezone/core';
import { Search } from 'combinezone/icons';

import { highlightSearch, isEqual } from '../../../helpers';
import { MultiSelectProps } from '../../../types';
import Tag from '../Tag';

const MultiSelect: FC<MultiSelectProps> = ({
  filter,
  initIsOpen = false,
  locale,
  setFilter,
  settings: { choices = [], label },
  value = [],
}) => {
  const [selected, setSelected] = useState<Record<string, boolean>>({});
  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    setSelected(
      choices.reduce<Record<string, boolean>>((selected, { key }) => {
        selected[key] = value.includes(key);
        return selected;
      }, {}),
    );
  }, [choices]);

  const onHideDropdown = () => {
    const result = Object.keys(selected).filter((key) => selected[key]);
    if (!isEqual(result, value)) {
      setFilter(filter, result);
    }
  };

  const onSelect = (key: string, isSelected: boolean) => {
    setSelected((prev) => ({
      ...prev,
      [key]: isSelected,
    }));
  };

  const selectAll = (isSelected: boolean) => {
    setSelected(
      choices.reduce<Record<string, boolean>>((selected, { key }) => {
        selected[key] = isSelected;
        return selected;
      }, {}),
    );
  };

  const filteredChoices = useMemo(() => {
    const re = new RegExp(searchString, 'i');
    return choices.filter((item) => item.text.search(re) !== -1);
  }, [choices, searchString]);

  const selectedCount = filteredChoices.reduce(
    (acc, { key }) => acc + Number(selected[key]),
    0,
  );

  const multiSelectMenuInput = useMemo(
    () => (
      <Input
        className="Panel-Filter-Search-Input"
        testId={`Panel_Filter_Search_Input_${label}`}
        value={searchString}
        placeholder={I18n.t('filterPanel.filter.searchPlaceholder')}
        LeftIcon={Search}
        onChange={(value) => setSearchString(value.toLowerCase())}
        onClear={() => setSearchString('')}
      />
    ),
    [locale, searchString],
  );

  const multiSelectMenu = (
    <div className="Panel-Popover-Container">
      <div className="Panel-Filter-Search">
        {multiSelectMenuInput}
        {filteredChoices.length !== 0 && (
          <>
            <Caption
              className="Panel-Filter-Search-SelectAll"
              onClick={() => selectAll(!selectedCount)}
            >
              <Translate
                value={`filterPanel.filter.${
                  selectedCount ? 'clearAll' : 'chooseAll'
                }`}
              />
            </Caption>
            <div className="Panel-Filter-Search-Separate" />
          </>
        )}
      </div>
      {filteredChoices.length === 0 ? (
        <Node testId="Node_NotFound" className="Dropdown-List">
          <Text>
            <Translate value="filterPanel.filter.notFound" />
          </Text>
        </Node>
      ) : (
        <div className="Dropdown-List">
          {filteredChoices.map(({ key, text }) => (
            <Node
              key={key}
              className={`Dropdown-List-Node${selected[key] && '-Selected'}`}
              testId={`Node_MultiSelect_${key}`}
              onClick={() => onSelect(key, !selected[key])}
            >
              <Checkbox
                testId={`Node_MultiSelect_Checkbox_${key}`}
                isChecked={selected[key]}
                label={highlightSearch(text, searchString)}
                onClick={(e) => e.stopPropagation()}
              />
            </Node>
          ))}
        </div>
      )}
    </div>
  );

  const tagText = useMemo(() => {
    const valueToText = value.map(
      (key) => choices.find((choice) => choice.key === key)?.text || key,
    );

    if (!valueToText.length) {
      return <Translate value="filterPanel.filter.notSelected" />;
    }

    return valueToText.length < 4
      ? valueToText.join(', ')
      : `${valueToText.slice(0, 3).join(', ')}... (+${valueToText.length - 3})`;
  }, [value, choices]);

  return (
    <Dropdown
      content={multiSelectMenu}
      position="bottom-left"
      defaultIsOpen={initIsOpen}
      onClose={onHideDropdown}
    >
      {({ isOpen }) => (
        <Tag
          label={label}
          filter={filter}
          text={tagText}
          isActive={isOpen}
          setFilter={setFilter}
        />
      )}
    </Dropdown>
  );
};

export default MultiSelect;
