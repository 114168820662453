import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Link } from 'react-router-dom';

import { List } from '@bizone/ui-bundle/esm/List';

import './index.scss';

export function RuleContent({ ruleData }) {
  const { category, severity } = ruleData;

  return (
    <div className="RuleList">
      <List
        data={{
          [I18n.t('incCard.RuleList.rule')]:
            ruleData[I18n.t('incCard.RuleList.ruleField')],
          [I18n.t('incCard.RuleList.severity')]: severity,
          [I18n.t('incCard.RuleList.category')]: (category || []).join('; '),
        }}
      />
      <Link
        className="RuleList-Link"
        target="_blank"
        to={`/rule/${ruleData.id}`}
      >
        {I18n.t('incCard.RuleList.more')}
      </Link>
    </div>
  );
}
