export const EDIT_REPORT_MODAL_OPEN = '@@editReport/EDIT_REPORT_MODAL_OPEN';
export const EDIT_REPORT_MODAL_CANCEL = '@@editReport/EDIT_REPORT_MODAL_CANCEL';

export const EDIT_REPORT_REQUEST = '@@editReport/EDIT_REPORT_REQUEST';
export const EDIT_REPORT_SUCCESS = '@@editReportFEDIT_REPORT_SUCCESS';
export const EDIT_REPORT_FAIL = '@@editReport/EDIT_REPORT_FAIL';

export const DELETE_REPORT_REQUEST = '@@editReport/DELETE_REPORT_REQUEST';
export const DELETE_REPORT_SUCCESS = '@@editReport/DELETE_REPORT_SUCCESS';
export const DELETE_REPORT_FAIL = '@@editReport/DELETE_REPORT_FAIL';

export const FETCH_SYSTEMS_REQUEST = '@@editReport/FETCH_SYSTEMS_REQUEST';
export const FETCH_SYSTEMS_SUCCESS = '@@editReport/FETCH_SYSTEMS_SUCCESS';
export const FETCH_SYSTEMS_FAIL = '@@editReport/FETCH_SYSTEMS_FAIL';
