import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Translate } from 'react-redux-i18n';

import { Header } from '@bizone/ui-bundle/esm/Header';

import { Layout } from '@common/soc-react-kit';
import { OverlaySpinner } from 'combinezone/core/index';

import SheduleCard from '../../containers/ReportsShedules-CardContainer';
import EditModal from '../../containers/ReportsShedules-EditModalContainer';

import './ReportsShedules.scss';

const { Container, Content } = Layout;

export default function ReportsShedules({
  fetchMore,
  firstFetch,
  hasMore,
  isFetching,
  reportsShedules = [],
}) {
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    firstFetch();
  }, [firstFetch]);

  const loadMore = useCallback(() => {
    if (hasMore) {
      setLoading(true);
      fetchMore().then(() => setLoading(false));
    }
  }, [hasMore, fetchMore]);

  return (
    <Container className="ReportsShedules">
      <EditModal />
      <Content className="ReportsShedulesContainer">
        <div
          className={`ReportsShedulesBody${
            isFetching ? ' ReportsShedulesBody_fetching' : ''
          }`}
        >
          {isFetching && <OverlaySpinner />}
          <div className="ReportsShedulesBody-Cards">
            <InfiniteScroll
              pageStart={1}
              initialLoad={false}
              loadMore={loadMore}
              hasMore={isLoading ? false : hasMore}
              useWindow={false}
            >
              {reportsShedules.map((shedule) => (
                <SheduleCard shedule={shedule} key={shedule.id} />
              ))}
            </InfiniteScroll>
            {reportsShedules.length === 0 && !hasMore && (
              <div className="ReportsShedulesBody-NoData">
                <Header size={24}>
                  <Translate value="ReportsShedules.noData" />
                </Header>
              </div>
            )}
          </div>
        </div>
      </Content>
    </Container>
  );
}
