import * as types from './actionTypes';

const initialState = {
  isLoading: false,
  systems: [],
};

export default function protectedSystems(state = initialState, action = {}) {
  switch (action.type) {
    case types.FETCH_PROTECTED_SYSTEMS_REQUEST:
      return { ...state, isLoading: true };

    case types.FETCH_PROTECTED_SYSTEMS_SUCCESS:
      return { ...state, systems: action.payload, isLoading: false };

    case types.FETCH_PROTECTED_SYSTEMS_FAIL:
      return { ...state, isLoading: false };

    default:
      return state;
  }
}

export function systems(state) {
  return state.systems;
}

export function isLoading(state) {
  return state.isLoading;
}
