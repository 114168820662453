import * as types from './actionTypes';

const initialState = {
  isOpen: false,
  loading: 0,
  systems: [],
};

export default function editReport(state = initialState, action = {}) {
  switch (action.type) {
    case types.EDIT_REPORT_MODAL_OPEN:
      return {
        ...state,
        isOpen: true,
      };

    case types.EDIT_REPORT_MODAL_CANCEL:
      return {
        ...state,
        isOpen: false,
      };

    case types.FETCH_SYSTEMS_REQUEST:
    case types.EDIT_REPORT_REQUEST:
      return {
        ...state,
        loading: state.loading + 1,
      };

    case types.FETCH_SYSTEMS_FAIL:
    case types.EDIT_REPORT_FAIL:
      return {
        ...state,
        loading: state.loading - 1,
      };

    case types.FETCH_SYSTEMS_SUCCESS:
      return {
        ...state,
        systems: action.payload.systems_id,
        loading: state.loading - 1,
      };

    case types.EDIT_REPORT_SUCCESS: {
      return {
        ...state,
        loading: state.loading - 1,
      };
    }

    default:
      return state;
  }
}

export function isLoading(state) {
  return state.loading !== 0;
}

export function isModalOpen(state) {
  return state.isOpen;
}

export function systems(state) {
  return state.systems;
}
