import React, { FC, memo } from 'react';
import { Translate } from 'react-redux-i18n';

import { Tooltip } from 'combinezone/core/index';
import { CircleAutoupdate, UserEditor } from 'combinezone/icons/index';

import { useTheme } from 'utils/brending';

import { IconsProps } from '../types';

const Icons: FC<IconsProps> = ({ activationMode, isRequested }) => {
  const {
    basis: {
      colors: {
        link: { normal },
      },
    },
  } = useTheme();

  return (
    <div className="Icons">
      {activationMode === 'auto' && (
        <Tooltip
          content={<Translate value="rulePage.activationMode" />}
          position="top-left"
        >
          <CircleAutoupdate color={normal} size="24px" />
        </Tooltip>
      )}
      {isRequested && (
        <Tooltip
          content={<Translate value="rulePage.isRequested" />}
          position="top-left"
        >
          <UserEditor color="#EBAF09" size="24px" />
        </Tooltip>
      )}
    </div>
  );
};

export default memo(Icons);
