import React, { useEffect, useState } from 'react';

import { OverlaySpinner } from 'combinezone/core/index';

import BrowserPreview from '../BrowserPreview';
import NoPreview from '../NoPreview';

export default function TextPreview({
  backgroundDownload,
  file,
  filename,
  src,
}) {
  const [canPreview, setCanPreview] = useState(true);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const MAX_CHARACTERS = (100 * (1024 * 1024)) / 16; // 100mb characters

    const fr = new FileReader();

    fr.onload = (e) => {
      const text = e.target.result;
      if (text.length >= MAX_CHARACTERS) {
        setCanPreview(false);
        backgroundDownload();
      }
      setIsChecked(true);
    };

    fr.readAsText(file);
  }, [backgroundDownload, file]);

  return !isChecked ? (
    <OverlaySpinner />
  ) : canPreview ? (
    <BrowserPreview src={src} filename={filename} />
  ) : (
    <NoPreview />
  );
}
