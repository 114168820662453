import { connect } from 'react-redux';

import Articles from '../components/Articles';
import { ARTICLES_ENDPOINT, ARTICLES_ENTITY } from '../services/api';
import {
  currentLocale,
  getSearch,
  getOrdering,
  getSelectedFilters,
} from '../store/reducers';
import { fetchMore, firstFetch } from '../store/reducers/articles/actions';
import { downloadFile } from '../store/reducers/fileDownloader/actions';
import {
  localSaveFilters,
  resetFilters,
  searchChange,
  setFilter,
  setSort,
} from '../store/reducers/filters/actions';

const mapStateToProps = (state) => {
  return {
    articles: state.articles.articles,
    isFetching: state.articles.fetching,
    hasMore: state.articles.hasMore,
    search: getSearch(ARTICLES_ENTITY, state),
    filters: getSelectedFilters(ARTICLES_ENTITY, state),
    ordering: getOrdering(ARTICLES_ENTITY, state),
    currentLocale: currentLocale(state),
  };
};

const mapDispatchToProps = {
  firstFetch,
  fetchMore,
  onChangeSearch: (search) => searchChange(ARTICLES_ENTITY, search, firstFetch),
  setFilter: (key, item) => setFilter(ARTICLES_ENTITY, key, item, firstFetch),
  resetFilters: () => resetFilters(ARTICLES_ENTITY, firstFetch),
  setSort: (ordering) => setSort(ARTICLES_ENTITY, ordering, firstFetch),
  setInitSearch: (search) => searchChange(ARTICLES_ENTITY, search),
  setInitFilter: (key, item) => setFilter(ARTICLES_ENTITY, key, item),
  setInitSort: (ordering) => setSort(ARTICLES_ENTITY, ordering),
  downloadPDF: (id, name) =>
    downloadFile(`${ARTICLES_ENDPOINT}${id}/download_pdf/`, `${name}.pdf`),
  localSaveFilters,
};

const ArticlesContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Articles);
export default ArticlesContainer;
