import React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import { Button } from '@bizone/ui-bundle/esm/Button';
import { Header } from '@bizone/ui-bundle/esm/Header';
import { Text } from '@bizone/ui-bundle/esm/Text';

import { Modal } from 'antd';
import PropTypes from 'prop-types';

import './NCCCI.scss';

const MAX_SENDING_TIME = 5 * 1000 * 60;

export default class NCCCI extends React.Component {
  timer = null;

  timeout = null;

  state = {
    time: 0,
    confirmVisible: false,
  };

  componentDidMount() {
    if (Number.isInteger(this.props.plannedSendTime)) {
      this.start();
    }
  }

  componentWillUnmount() {
    this.stop();
    clearTimeout(this.timeout);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.plannedSendTime !== this.props.plannedSendTime &&
      this.timer === null
    ) {
      this.start();
    }
  }

  tick = () => {
    const { plannedSendTime, updateFunction } = this.props;
    if (Number.isInteger(plannedSendTime)) {
      const time = plannedSendTime - Date.now();

      if (time <= 0) {
        this.stop();
        this.timeout = setTimeout(updateFunction, MAX_SENDING_TIME + time);
      }

      this.setState({ time });
    }
  };

  start = () => {
    this.tick();
    if (this.props.plannedSendTime - Date.now() > 0) {
      this.timer = setInterval(this.tick, 1000);
    }
  };

  stop = () => {
    clearInterval(this.timer);
    this.timer = null;
  };

  openConfirm = () => {
    this.setState({ confirmVisible: true });
  };

  onOk = () => {
    this.props.onClick();
    this.setState({ confirmVisible: false });
  };

  onCancel = () => {
    this.setState({ confirmVisible: false });
  };

  render() {
    const { isSending, onClick, plannedSendTime } = this.props;
    const { time } = this.state;

    const buttonProps = {
      primary: true,
      basic: true,
      loading: isSending,
      children: I18n.t('incCard.NCCCI.sendButtonText'),
      onClick: this.openConfirm,
    };

    return (
      <div className="NCCCI">
        {Number.isInteger(plannedSendTime) ? (
          time > 0 ? (
            <React.Fragment>
              <div className="NCCCI-TimerLabel">
                <span>{I18n.t('incCard.NCCCI.timer')}</span>
                {getTimeString(time)}
              </div>
              <Button {...buttonProps} />
            </React.Fragment>
          ) : time > -MAX_SENDING_TIME ? (
            <Button basic loading>
              {I18n.t('incCard.NCCCI.sendButtonProcessText')}
            </Button>
          ) : (
            <React.Fragment>
              <div className="NCCCI-ErrorLabel">
                {I18n.t('incCard.NCCCI.sendError')}
              </div>
              <Button {...buttonProps} />
            </React.Fragment>
          )
        ) : (
          <Button {...buttonProps} />
        )}
        <Modal
          title={
            <Header size={20}>{I18n.t('incCard.NCCCI.confirm.title')}</Header>
          }
          visible={this.state.confirmVisible}
          onOk={this.onOk}
          onCancel={this.onCancel}
          footer={[
            <Button key="submit" primary onClick={this.onOk}>
              <Translate value="incCard.NCCCI.confirm.ok" />
            </Button>,
            <Button key="back" onClick={this.onCancel}>
              <Translate value="incCard.NCCCI.confirm.cancel" />
            </Button>,
          ]}
        >
          <Text>
            <Translate value="incCard.NCCCI.confirm.body" />
          </Text>
        </Modal>
      </div>
    );
  }
}

function format(strings, ...nums) {
  const res = [strings[0]];
  nums.forEach((num, i) =>
    res.push(num.toString().padStart(2, '0'), strings[i + 1]),
  );
  return res.join('');
}

function getTimeString(ms) {
  let seconds = ms / 1000;
  const hours = Math.trunc(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.trunc(seconds / 60);
  seconds = Math.trunc(seconds % 60);

  return format`${hours}:${minutes}:${seconds}`;
}

NCCCI.propTypes = {
  isSending: PropTypes.bool,
  onClick: PropTypes.func,
  plannedSendTime: PropTypes.number,
  updateFunction: PropTypes.func,
};

NCCCI.defaultProps = {
  updateFunction: () => {},
  isSending: false,
};
