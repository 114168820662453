import { theme, darkTheme, createTheme } from 'combinezone/theme';
import { DefaultBasis } from 'combinezone/theme/default/types';

const HEADER_COLOR = process.env.REACT_APP_HEADER_COLOR;
const PRIMARY_COLOR = process.env.REACT_APP_PRIMARY_COLOR;
const PRIMARY_HOVER_COLOR = process.env.REACT_APP_PRIMARY_HOVER_COLOR;
const PRIMARY_COLOR_DISABLED = process.env.REACT_APP_DISABLED_PRIMARY_COLOR;
const SELECT_COLOR = process.env.REACT_APP_SELECT_COLOR;
const SELECTED_HOVER_COLOR = process.env.REACT_APP_SELECTED_HOVER_COLOR;
const INPUT_SHADOW_BOX = process.env.REACT_APP_INPUT_SHADOW_BOX;

const FONT_FAMILY = process.env.REACT_APP_FONT_FAMILY;
const FONT_FAMILY_ACCENT = process.env.REACT_APP_FONT_FAMILY_ACCENT;

const addBrandingToBasis = (basis: DefaultBasis): DefaultBasis => {
  basis.colors.base.active = PRIMARY_COLOR || basis.colors.base.active;
  basis.colors.link.normal = PRIMARY_COLOR || basis.colors.link.normal;
  basis.colors.link.hover = PRIMARY_HOVER_COLOR || basis.colors.link.hover;
  basis.colors.link.disable =
    PRIMARY_COLOR_DISABLED || basis.colors.link.disable;

  basis.colors.button.normal = PRIMARY_COLOR || basis.colors.button.normal;
  basis.colors.button.hover = PRIMARY_HOVER_COLOR || basis.colors.button.hover;
  basis.colors.button.disable =
    PRIMARY_COLOR_DISABLED || basis.colors.button.disable;

  basis.colors.icon.active.normal =
    PRIMARY_COLOR || basis.colors.icon.active.normal;
  basis.colors.icon.active.hover =
    PRIMARY_HOVER_COLOR || basis.colors.icon.active.hover;
  basis.colors.icon.active.disable =
    PRIMARY_COLOR_DISABLED || basis.colors.icon.active.disable;

  basis.colors.node.select = SELECT_COLOR || basis.colors.node.select;
  basis.colors.node.selectHover =
    SELECTED_HOVER_COLOR || basis.colors.node.selectHover;

  basis.colors.shadows.default =
    INPUT_SHADOW_BOX || basis.colors.shadows.default;
  basis.colors.shadows.focus = INPUT_SHADOW_BOX || basis.colors.shadows.focus;

  basis.colors.base.navbar.default =
    HEADER_COLOR || basis.colors.base.navbar.default;

  basis.colors.base.navbar.active =
    PRIMARY_COLOR || basis.colors.base.navbar.active;

  basis.typography.fonts.default =
    FONT_FAMILY || basis.typography.fonts.default;
  basis.typography.fonts.mono = FONT_FAMILY || basis.typography.fonts.mono;
  basis.typography.fonts.accent =
    FONT_FAMILY_ACCENT || basis.typography.fonts.accent;

  return basis;
};

const getBrandingTheme = (
  basis: DefaultBasis,
  isDark = false,
): ReturnType<typeof createTheme> => {
  const basisCloned = structuredClone<DefaultBasis>(basis);
  const basisWithBranding = addBrandingToBasis(basisCloned);
  return createTheme(basisWithBranding, isDark);
};

export const lightThemeCustom = getBrandingTheme(theme.basis);
export const darkThemeCustom = getBrandingTheme(darkTheme.basis, true);
