import * as React from 'react';

import './Markdown.scss';

const markdown = React.forwardRef(({ value }, ref) => (
  <div
    ref={ref}
    className="Markdown"
    dangerouslySetInnerHTML={{ __html: value }}
  />
));

export default markdown;
