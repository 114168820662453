import React from 'react';
import { I18n } from 'react-redux-i18n';

import { Link } from '@bizone/ui-bundle/esm/Link';
import { List } from '@bizone/ui-bundle/esm/List';
import { Loader } from '@bizone/ui-bundle/esm/Loader';

import { bemCls } from '@common/soc-react-kit';
import { Popover } from 'antd';
import PropTypes from 'prop-types';

import { MITRE_TECHNIQUES, MITRE_TACTICS } from '../../../services/api';
import { getFilterQueryStr } from '../../../utils/helpers';
import { getType } from '../helper';

export class TechniqueCell extends React.Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();

    this.state = {
      type: getType(props.technique.count),
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.technique.count !== prevProps.technique.count) {
      this.setState({
        type: getType(this.props.technique.count),
      });
    }
  }

  render() {
    const { tactic, technique } = this.props;
    const { type } = this.state;

    const content = (
      <div style={{ minWidth: 250 }}>
        <List
          keysWidth={80}
          data={{
            [I18n.t('mitreWidget.technique.name')]: technique.name,
            [I18n.t('mitreWidget.technique.id')]: technique.technique_id,
            [I18n.t('mitreWidget.technique.link')]: (
              <Link to={technique.url} newTab>
                {technique.url}
              </Link>
            ),
            [I18n.t('mitreWidget.technique.description')]: (
              <div
                style={{ maxWidth: 390, maxHeight: 400, overflow: 'scroll' }}
              >
                {technique.description}
              </div>
            ),
          }}
        />
      </div>
    );

    return (
      <td style={{ height: '' }}>
        {technique.name && (
          <Popover
            content={content}
            trigger="click"
            getPopupContainer={() => this.ref.current}
            className={bemCls('Technique', { [type]: true })}
          >
            <div className="Technique-Title">{technique.name}</div>
            <div className="Technique-Count">{technique.count}</div>
          </Popover>
        )}
        <div ref={this.ref} />
      </td>
    );
  }
}

TechniqueCell.propTypes = {
  technique: PropTypes.object,
};

TechniqueCell.defaultProps = {
  technique: {
    count: 0,
  },
};
