import React, { Component } from 'react';
import { withSize } from 'react-sizeme';
import Truncate from 'react-truncate';

import PropTypes from 'prop-types';

import './ReadMore.scss';

class ReadMore extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      expanded: false,
      truncated: false,
    };

    this.handleTruncate = this.handleTruncate.bind(this);
    this.toggleLines = this.toggleLines.bind(this);
  }

  componentDidMount() {
    if (this.props.isExpanded) {
      this.setState({
        expanded: this.props.isExpanded,
      });
    }
  }

  handleTruncate(truncated) {
    if (this.state.truncated !== truncated) {
      this.setState({
        truncated,
      });
    }
  }

  toggleLines(event) {
    event.preventDefault();

    this.setState({
      expanded: !this.state.expanded,
    });
  }

  render() {
    const { children, less, lines, more } = this.props;

    const { expanded, truncated } = this.state;

    return (
      <div>
        <Truncate
          lines={!expanded && lines}
          trimWhitespace
          ellipsis={
            <React.Fragment>
              ...
              <div className="read-more-btn">
                <span onClick={this.toggleLines}>{more}</span>
              </div>
            </React.Fragment>
          }
          onTruncate={this.handleTruncate}
          width={this.props.size.width}
        >
          {children.split('\n').map((nextLine, i, arr) => {
            const line = <span key={i}>{nextLine}</span>;

            if (i === arr.length - 1) {
              return line;
            }
            return [line, <br key={`${i}br`} />];
          })}
        </Truncate>
        {!truncated && expanded && (
          <div className="read-more-btn">
            <span onClick={this.toggleLines}>{less}</span>
          </div>
        )}
      </div>
    );
  }
}

ReadMore.defaultProps = {
  lines: 3,
  more: 'Read more',
  less: 'Show less',
};

ReadMore.propTypes = {
  children: PropTypes.node.isRequired,
  less: PropTypes.string,
  lines: PropTypes.number,
  more: PropTypes.string,
};

export default withSize()(ReadMore);
