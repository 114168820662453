import { connect } from 'react-redux';

import { IncidentPage } from '../components/IncidentPage';
import {
  displayedIncident,
  incCardFetchError,
  gossopkaLoading,
  incCardComments,
  incCardCommentsIsFetching,
  getMitreMapping,
  currentLocale,
  incCardAlertsTotalCount,
} from '../store/reducers';
import {
  fetchIncCard,
  fetchIncComments,
  fetchAlerts,
  closeIncCard,
  submitComment,
  finalizeCommentSuccess,
  sendToGossopka,
  sendFeedback,
  fetchIncCardAlertsFirstPage,
} from '../store/reducers/incCard/actions';
import { fetchMitreMapping } from '../store/reducers/mitre/actions';

const mapStateToProps = (state, ownProps) => ({
  incident: displayedIncident(state),
  incNumber: ownProps.match.params.incNumber,
  fetchError: incCardFetchError(state),
  gossopkaLoading: gossopkaLoading(state),
  comments: incCardComments(state),
  commentsFetching: incCardCommentsIsFetching(state),
  mitreMapping: getMitreMapping(state),
  locale: currentLocale(state),
  alertsTotalCount: incCardAlertsTotalCount(state),
});

const mapDispatchToProps = {
  fetchIncCard,
  fetchIncComments,
  sendFeedback,
  submitComment,
  closeIncCard,
  finalizeCommentSuccess,
  sendToGossopka,
  fetchMitreMapping,
  fetchAlerts,
  fetchFirstPage: fetchIncCardAlertsFirstPage,
};

const IncidentPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IncidentPage);
export default IncidentPageContainer;
