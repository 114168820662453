import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';

import { useResize } from '@common/soc-react-kit';
import classNames from 'classnames';
import {
  Heading,
  IconButton,
  Input,
  OverlaySpinner,
  Text,
  Wysiwyg,
} from 'combinezone/core';
import { Search, LetterSend, DotResizer } from 'combinezone/icons';
import moment from 'moment';

import attachIconSvg from '../../static/images/icons/attach-icon.svg';
import { currentLocale } from '../../store/reducers';
import { getFileDataUrlPromise } from '../../utils/helpers';

import { ChatFile } from './ChatFile';
import { ChatRow } from './ChatRow';
import { ChatPropsType } from './types';
import './index.scss';
import ChatEventEmitter, { submitEvent } from './ChatEventEmitter';

type ChatFileType = {
  internalId: string;
  file: File;
};

const isFile = (file: any): file is File => file instanceof File;

const imgMimeTypes = [
  'image/jpeg',
  'image/apng',
  'image/png',
  'image/avif',
  'image/gif',
  'image/svg+xml',
  'image/webp',
];

const filesToChatFileListItems = (files: File[]) => {
  return Promise.all(
    files
      .filter((file) => file !== null)
      .map(async (file) => {
        const internalId = file.name + new Date().getTime().toString();

        if (imgMimeTypes.includes(file.type)) {
          const thumbnailUrl = await getFileDataUrlPromise(file);
          return {
            internalId,
            file,
            thumbnailUrl,
          };
        }
        return {
          internalId,
          file,
        };
      }),
  );
};

const MIN_CHAT_HEIGHT = 118;
const CHAT_EDITOR_PANEL_HEIGHT = 32;
const CHAT_EDITOR_HEIGHT_DURING_RESIZING = 'calc(100% - 26px)';
const CHAT_EDITOR_WIDTH = 'calc(100% - 2px)';

export const Chat = ({
  data,
  fetching = false,
  isResizerShow = true,
  onChangeSearch,
  onSubmit,
  searchVisible,
  submitting,
  width,
}: ChatPropsType) => {
  const [search, setSearch] = useState('');
  const [value, setValue] = useState('');
  const [files, setFiles] = useState<ChatFileType[]>([]);
  const [isDragOver, setIsDragOver] = useState(false);
  const commentListRef = useRef<HTMLDivElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);
  const resizableContainerRef = useRef<HTMLDivElement>(null);
  const draggableElementRef = useRef<HTMLDivElement>(null);
  const locale = useSelector(currentLocale);
  const hasValue = Boolean(value && value !== '');

  const { height, isResizingHeight } = useResize({
    resizableContainerRef,
    draggableElementRef,
    parentContainerRef: panelRef,
    resizeBase: 'height',
    min: MIN_CHAT_HEIGHT + CHAT_EDITOR_PANEL_HEIGHT,
  });

  const handleSubmit = useCallback(() => {
    setValue('');
    setFiles([]);
    const flattFiles = files.map((file) => file.file);
    onSubmit({ message: value, files: flattFiles });
  }, [value, files, onSubmit]);
  const chatWidth = CHAT_EDITOR_WIDTH;
  const chatHeight = isResizingHeight
    ? CHAT_EDITOR_HEIGHT_DURING_RESIZING
    : height - CHAT_EDITOR_PANEL_HEIGHT;
  const customConfig = useMemo(
    () => ({
      language: locale,
      height: chatHeight,
      minHeight: MIN_CHAT_HEIGHT,
      maxHeight: chatHeight,
      minWidth: chatWidth,
      maxWidth: chatWidth,
      width: chatWidth,
      toolbarAdaptive: false,
      defaultActionOnPaste: 'insert_only_text',
      defaultActionOnPasteFromWord: 'insert_only_text',
      askBeforePasteFromWord: false,
      askBeforePasteHTML: false,
      buttons: [
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'eraser',
        'ul',
        'ol',
        {
          iconURL: attachIconSvg,
          exec() {
            const input = document.createElement('input');
            input.multiple = true;
            input.type = 'file';

            input.onchange = async () => {
              if (input.files === null) {
                return;
              }
              const newFiles = await filesToChatFileListItems(
                Array.from(input.files),
              );
              setFiles((files) => [...files, ...newFiles]);
            };

            input.click();
          },
        },
      ],
      events: {
        change: (val: any) => setValue(val),
        keydown: (e: any) => {
          if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
            ChatEventEmitter.emit(submitEvent);
          }
        },
      },
      toolbar: true,
      statusbar: false,
      enter: 'br',
    }),
    [locale, chatWidth, isResizingHeight],
  );

  const removeFile = useCallback((internalId) => {
    setFiles((files) => files.filter((file) => internalId !== file.internalId));
  }, []);

  const dragOverHandler = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true);
  };
  const dragLeaveHandler = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
  };

  const handleDrop = async (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    setIsDragOver(false);

    if (e.dataTransfer === null) {
      return;
    }

    let files: File[] = [];
    if (e.dataTransfer.items) {
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        const item = e.dataTransfer.items[i].getAsFile();
        if (isFile(item)) {
          files.push(item);
        }
      }
    } else {
      files = [...e.dataTransfer.files];
    }
    const newFiles = await filesToChatFileListItems(files);

    if (newFiles === undefined) {
      return;
    }
    setFiles((files) => [...files, ...newFiles]);
  };

  const handleChangeSearch = (search: string) => {
    setSearch(search);
    onChangeSearch(search);
  };

  useLayoutEffect(() => {
    if (commentListRef.current) {
      commentListRef.current.scrollTop = commentListRef.current.scrollHeight;
    }
  }, [data]);

  useEffect(() => {
    const handleEnterEvent = () => {
      if (hasValue) {
        setTimeout(handleSubmit);
      }
    };

    ChatEventEmitter.addListener(submitEvent, handleEnterEvent);
    return () => {
      ChatEventEmitter.removeListener(submitEvent, handleEnterEvent);
    };
  }, [hasValue, handleSubmit]);
  return (
    <div
      onDragOver={dragOverHandler}
      onDragEnter={dragOverHandler}
      ref={panelRef}
      className="Chat"
    >
      {isDragOver && (
        <div
          onDragLeave={dragLeaveHandler}
          onDrop={handleDrop}
          className="Chat-DropzoneOverlay"
        >
          <Heading>Перетащите сюда и отпустите</Heading>
        </div>
      )}
      {searchVisible && (
        <div className="CommentsSearch">
          <Input
            testId="Chat-SearchInput"
            onChange={handleChangeSearch}
            value={search}
            LeftIcon={Search}
          />
        </div>
      )}
      <div ref={commentListRef} className="CommentList">
        {fetching && <OverlaySpinner backdrop="light" align="center" />}
        {data && data.length > 0 ? (
          data.map((page) => (
            <>
              <div className="CommentList-Date">
                <Text variant="secondary">
                  {moment(page.date).calendar({
                    lastDay: `[${I18n.t('general.date.yesterday')}]`,
                    sameDay: `[${I18n.t('general.date.today')}]`,
                    lastWeek: `dddd`,
                    sameElse: 'L',
                  })}
                </Text>
              </div>
              {page.data.map((chatRow) => (
                <ChatRow key={chatRow.created} search={search} {...chatRow} />
              ))}
            </>
          ))
        ) : (
          <div className="Chat-NoMessages">
            <Heading>
              <Translate value="chat.noData" />
            </Heading>
          </div>
        )}
      </div>
      {isResizerShow && (
        <div className="ChatFooter-Shadow">
          <div className="ChatFooter-Resizer" ref={draggableElementRef}>
            <DotResizer />
          </div>
        </div>
      )}
      {files?.length > 0 && (
        <div className="ChatFooter-FilesWrapper">
          <div className="ChatFooter-Files">
            {files.map((item) => (
              <ChatFile
                removeFile={removeFile}
                key={item.internalId}
                {...item}
              />
            ))}
          </div>
        </div>
      )}
      <div
        className={classNames('ChatFooter', {
          'ChatFooter-Resizing': isResizingHeight,
        })}
        ref={resizableContainerRef}
      >
        <div className="ChatFooter-MessageInput">
          <div
            className={classNames('ChatFooter-InputWrapper', {
              'ChatFooter-Resizing': isResizingHeight,
            })}
          >
            <Wysiwyg
              testId="ChatFooter-MessageInput"
              value={value}
              config={customConfig}
            />
          </div>
          <IconButton
            testId="ChatFooter-SendMessageButton"
            className="ChatFooter-SendMessageButton"
            accent={hasValue ? 'active' : 'default'}
            isDisabled={!hasValue}
            icon={LetterSend}
            onClick={handleSubmit}
            isLoading={submitting}
            tooltip={I18n.t('chat.sendButton')}
          />
        </div>
      </div>
    </div>
  );
};
