import { connect } from 'react-redux';

import Attachments from '../components/IncidentPage/Attachments';
import { downloadFile } from '../store/reducers/fileDownloader/actions';

const mapDispatchToProps = {
  downloadFile,
};

const AttachmentsContainer = connect(null, mapDispatchToProps)(Attachments);
export default AttachmentsContainer;
