import { connect } from 'react-redux';

import { EditShedule } from '../components/ReportsShedules/EditModal';
import {
  currentLocale,
  editReportModalSystems,
  isEditReportModalLoading,
  isEditReportModalOpen,
  selectedShedule,
} from '../store/reducers';
import {
  cancelEditReportModal,
  editReport,
} from '../store/reducers/editReport/actions';
import { firstFetch } from '../store/reducers/reportsShedules/actions';
import {
  getPeriodFromCrontab,
  createPeriodAndCrontab,
  validateEmail,
} from '../utils/helpers';

function mapStateToProps(state) {
  return {
    systems: editReportModalSystems(state),
    isOpen: isEditReportModalOpen(state),
    isLoading: isEditReportModalLoading(state),
    locale: currentLocale(state),
    shedule: selectedShedule(state),
    createPeriodAndCrontab,
    getPeriodFromCrontab,
    validateEmail,
  };
}

const mapDispatchToProps = {
  onCancel: cancelEditReportModal,
  editReport,
  firstFetch,
};

const EditReportContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditShedule);
export default EditReportContainer;
