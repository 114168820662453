import React, { FC, memo } from 'react';

import {
  DateValueType,
  FilterProps,
  MultiSelectValueType,
  SelectValueType,
} from '../../types';

import MultiSelect from './MultiSelect';
import RangePicker from './RangePicker';
import Select from './Select';
import Suggest from './Suggest';

const Filter: FC<FilterProps> = ({ ...rest }) => {
  switch (rest.type) {
    case 'date':
      return <RangePicker {...rest} value={rest.value as DateValueType} />;

    case 'select':
      return <Select {...rest} value={rest.value as SelectValueType} />;

    case 'multiselect':
      return (
        <MultiSelect {...rest} value={rest.value as MultiSelectValueType} />
      );

    case "suggest":
      return (
        <Suggest
          {...rest}
          getEndpoint={rest.settings.getEndpoint}
          handleResult={rest.settings.handleResult}
        />
      );

    default:
      return null;
  }
};

export default memo(Filter);
