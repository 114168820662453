import { connect } from 'react-redux';

import Instructions from '../components/Instructions';
import { INSTRUCTIONS_ENTITY } from '../services/api';
import {
  currentLocale,
  getOrdering,
  getSearch,
  getSelectedFilters,
} from '../store/reducers';
import {
  localSaveFilters,
  searchChange,
  setFilter,
  setSort,
} from '../store/reducers/filters/actions';
import { fetchMore, firstFetch } from '../store/reducers/instructions/actions';

const mapStateToProps = (state) => {
  return {
    instructions: state.instructions.instructions,
    isFetching: state.instructions.fetching,
    hasMore: state.instructions.hasMore,
    search: getSearch(INSTRUCTIONS_ENTITY, state),
    updateFunction: firstFetch,
    currentLocale: currentLocale(state),
    filters: getSelectedFilters(INSTRUCTIONS_ENTITY, state),
    ordering: getOrdering(INSTRUCTIONS_ENTITY, state),
  };
};

const mapDispatchToProps = {
  firstFetch,
  fetchMore,
  onChangeSearch: (search) =>
    searchChange(INSTRUCTIONS_ENTITY, search, firstFetch),
  setInitSearch: (search) => searchChange(INSTRUCTIONS_ENTITY, search),
  setInitFilter: (key, value) => setFilter(INSTRUCTIONS_ENTITY, key, value),
  setInitSort: (ordering) => setSort(INSTRUCTIONS_ENTITY, ordering),
  localSaveFilters,
};

const InstructionsTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Instructions);
export default InstructionsTableContainer;
