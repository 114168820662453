import { RULES_CREATED } from '../../../services/api';

import * as types from './actionTypes';

const initialState = {
  rules: [],
  rulesCount: 0,
  fetching: false,
  hasMore: true,
  nextPage: 2,
  lastFetchTime: null,
  totalCount: 0,
  filters: {
    search: '',
    ordering: { [RULES_CREATED]: 'DESC' },
    selected: {},
  },
  analytics: {
    data: {},
    dataMitre: {},
    fetching: false,
    fetchingMitre: false,
  },
  mitre: {
    selectedTechnique: {},
  },
};

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_TOTAL_COUNT_REQUEST: {
      return {
        ...state,
        totalCount: 0,
      };
    }

    case types.GET_TOTAL_COUNT_SUCCESS: {
      return {
        ...state,
        totalCount: action.payload.count,
      };
    }

    case types.FIRST_FETCH_REQUEST: {
      return {
        ...state,
        nextPage: 2,
        lastFetchTime: action.meta.timestamp,
        fetching: true,
      };
    }

    case types.FETCH_MORE_REQUEST: {
      return {
        ...state,
        fetching: true,
      };
    }

    case types.FIRST_FETCH_SUCCESS: {
      if (state.lastFetchTime > action.meta.timestamp) {
        return state;
      }

      return {
        ...state,
        fetching: false,
        rules: action.payload.results,
        rulesCount: action.payload.count,
        selected: action.payload.results[0],
        hasMore: Boolean(action.payload.next),
      };
    }

    case types.FETCH_MORE_SUCCESS: {
      if (state.lastFetchTime > action.meta.timestamp) {
        return state;
      }

      return {
        ...state,
        fetching: false,
        rules: [...state.rules, ...action.payload.results],
        nextPage: state.nextPage + 1,
        hasMore: Boolean(action.payload.next),
      };
    }

    case types.FIRST_FETCH_FAIL:
    case types.FETCH_MORE_FAIL: {
      return {
        ...state,
        fetching: false,
      };
    }

    case types.FETCH_ANALYTICS_REQUEST: {
      return {
        ...state,
        analytics: {
          ...state.analytics,
          data: {},
          fetching: true,
        },
      };
    }

    case types.FETCH_ANALYTICS_SUCCESS: {
      return {
        ...state,
        analytics: {
          ...state.analytics,
          data: action.payload,
          fetching: false,
        },
      };
    }

    case types.FETCH_ANALYTICS_FAIL: {
      return {
        ...state,
        analytics: {
          ...state.analytics,
          data: {},
          fetching: false,
        },
      };
    }

    case types.FETCH_ANALYTICS_MITRE_REQUEST: {
      return {
        ...state,
        mitre: {
          ...state.analytics,
          data: {},
          fetchingMitre: true,
        },
      };
    }

    case types.FETCH_ANALYTICS_MITRE_SUCCESS: {
      const filteredTactics = action.payload.tactics.map((element) => {
        const techniques = element.techniques.filter(
          (elem) => !elem.is_revoked && !elem.is_deprecated,
        );

        return {
          ...element,
          techniques,
        };
      });

      return {
        ...state,
        mitre: {
          ...state.analytics,
          data: {
            ...action.payload,
            tactics: filteredTactics,
          },
          fetchingMitre: false,
        },
      };
    }

    case types.FETCH_ANALYTICS_MITRE_FAIL: {
      return {
        ...state,
        mitre: {
          ...state.analytics,
          data: {},
          fetchingMitre: false,
        },
      };
    }

    case types.SET_SORT: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ordering: action.payload,
        },
      };
    }

    case types.SET_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          selected: action.payload,
        },
      };
    }

    case types.SET_SEARCH: {
      return {
        ...state,
        filters: {
          ...state.filters,
          search: action.payload,
        },
      };
    }

    case types.SELECT_TECHNIQUE: {
      return {
        ...state,
        mitre: {
          ...state.mitre,
          selectedTechnique: action.payload,
        },
      };
    }

    default:
      return state;
  }
}

export function getNextPage(state) {
  return state.nextPage;
}

export function getSelectedMitreTechnique(state) {
  return state.mitre.selectedTechnique;
}

export function getMitreCoverage(state) {
  return state.mitre.data;
}

export function getRulesTotalCount(state) {
  return state.totalCount;
}
