import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '@bizone/ui-bundle/esm/Icon';
import { IconButton } from '@bizone/ui-bundle/esm/IconButton';
import { Tooltip } from '@bizone/ui-bundle/esm/Tooltip';

import './Panel.scss';

const Panel = ({ config, selectedTab, setTab }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSection = useCallback(() => {
    setIsOpen(!isOpen);
  }, [setIsOpen, isOpen]);

  const getInstructionsPanelClass = useCallback(() => {
    const incidentPanelClass = ['DocumentationPanel'];
    if (!isOpen) {
      incidentPanelClass.push('DocumentationPanel_closed');
    }
    return incidentPanelClass.join(' ');
  }, [isOpen]);

  return (
    <div className={getInstructionsPanelClass()}>
      <div className="DocumentationPanelHeader">
        <IconButton
          basic
          icon="angle-right"
          cls={`PanelIcon${isOpen ? ' PanelIcon_active' : ''}`}
          onClick={toggleSection}
        />
      </div>
      <div>
        <div className="TabItems-Container">
          {Object.entries(config).map(([key, { icon, tab }]) => (
            <Link
              key={key}
              to={`/docs/${key}`}
              className={`TabItem${
                selectedTab === key ? ' TabItem_active' : ''
              }`}
            >
              <div className="RowWithIcon">
                <Tooltip content={tab} position="right">
                  {typeof icon === 'string' ? (
                    <Icon className="TabItem-Icon" size={24} glyph={icon} />
                  ) : (
                    icon()
                  )}
                </Tooltip>
                <span className="TabItem-Text">{tab}</span>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Panel;
