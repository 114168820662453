import React, { FC } from 'react';
import Linkify from 'react-linkify';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import styled from 'styled-components';

import { ReadMarkdown } from '@common/soc-react-kit';
import { IconButton } from 'combinezone/core';
import { LinkExternal } from 'combinezone/icons';

import MitreWidgetPanelSummary from 'components/RulesMitreWidget/MitreWidgetPanel/components/MitreWidgetPanelSummary';
import { MitreWidgetPanelProps } from 'components/RulesMitreWidget/MitreWidgetPanel/types';

import { ruleListSelectedMitreTechnique } from '../../../store/reducers';
import Panel from '../../ListPage/Panel';

const ContentContainer = styled.div`
  padding: 12px 16px 0;
`;

const MitreWidgetPanel: FC<MitreWidgetPanelProps> = ({ testId }) => {
  const selectedTechnique: { url: string; technique_id: string; name: string } =
    useSelector(ruleListSelectedMitreTechnique);
  const testIdMitreWidgetPanel = `${testId}-mitre-widget-panel`;

  const onLinkClickHandle = (url): void => {
    window.open(url, '_blank');
  };

  return (
    <Panel
      className="RulesMitrePanel"
      entity={selectedTechnique}
      title={(technique) => technique?.fullName}
      controls={(technique = {}) => (
        <IconButton
          testId={`${testIdMitreWidgetPanel}-link`}
          onClick={() => onLinkClickHandle(technique.url)}
          icon={LinkExternal}
          tooltip={I18n.t('incCard.mitre.tooltip.goToMitre')}
        />
      )}
      content={{
        summary: {
          title: I18n.t('ruleList.mainInfo'),
          renderer: (technique = {}) => (
            <MitreWidgetPanelSummary
              technique={technique}
              testId={testIdMitreWidgetPanel}
            />
          ),
        },
        description: {
          title: I18n.t('ruleList.mitreWidget.description'),
          renderer: (technique = {}) => (
            <ContentContainer>
              <ReadMarkdown value={technique.description} />
            </ContentContainer>
          ),
        },
        links: {
          title: I18n.t('rulePage.links'),
          renderer: (technique = {}) => (
            <ContentContainer>
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a target="blank" href={technique.url} key={key}>
                    {decoratedText}
                  </a>
                )}
              >
                {technique.url}
              </Linkify>
            </ContentContainer>
          ),
        },
      }}
    />
  );
};

export default MitreWidgetPanel;

MitreWidgetPanel.displayName = 'MitreWidgetPanel';
