import React, { useCallback, useState } from 'react';
import isEqual from 'react-fast-compare';
import { I18n, Translate } from 'react-redux-i18n';

import { Button } from '@bizone/ui-bundle/esm/Button';
import { Header } from '@bizone/ui-bundle/esm/Header';

import { Modal, Spin } from 'antd';
import moment from 'moment';

import Description from '../Description';

import { Card } from './Card';

import './VersionModal.scss';

function VersionsModal({ fetchVersions, id, versions }) {
  const [isLoading, setLoading] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [selectedVersion, setVersion] = useState(false);

  const onCancel = useCallback(() => {
    setVisible(false);
  }, []);

  const clearSelectedVersion = useCallback(() => {
    setVersion(false);
  }, []);

  const onShow = useCallback(() => {
    setLoading(true);
    fetchVersions(id).then(() => setLoading(false));
    setVisible(true);
  }, [fetchVersions, id]);

  return (
    <div className="VersionSection">
      <Button basic leftIcon="history" onClick={onShow}>
        {I18n.t('instructionsCard.versions.title')}
      </Button>
      <Modal
        title={
          <Header size={20}>{I18n.t('instructionsCard.versions.title')}</Header>
        }
        visible={isVisible}
        onCancel={onCancel}
        width={720}
        bodyStyle={{ padding: '25px 0 45px 0' }}
        footer={null}
      >
        <Spin spinning={isLoading}>
          {versions?.length > 0 ? (
            versions.map((version) => (
              <Card version={version} onClick={setVersion} key={version.id} />
            ))
          ) : (
            <div className="VersionSection-noData">
              <Header size={24}>
                <Translate value="instructionsCard.versions.noData" />
              </Header>
            </div>
          )}
          <Modal
            title={
              <Header size={20}>
                {I18n.t('instructionsCard.versions.version', {
                  version: moment(selectedVersion.date_created).format(
                    'DD.MM.YYYY h:mm',
                  ),
                })}
              </Header>
            }
            visible={Boolean(selectedVersion)}
            onCancel={clearSelectedVersion}
            width={720}
            footer={[
              <Button key="back" onClick={clearSelectedVersion}>
                <Translate value="instructionsCard.versions.cancel" />
              </Button>,
            ]}
          >
            <Description
              more={I18n.t('instructionsCard.more')}
              less={I18n.t('instructionsCard.less')}
              value={selectedVersion.data?.description || ''}
              collapsible={false}
            />
            <div className="VersionSection-CommentSection">
              <div className="VersionSection-CommentHeader">
                <Translate value="instructionsCard.versions.comment" />
              </div>
              {selectedVersion.comment}
            </div>
          </Modal>
        </Spin>
      </Modal>
    </div>
  );
}

export default React.memo(VersionsModal, isEqual);
