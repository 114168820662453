import * as types from './actionTypes';

const initialState = {
  sources: [],
  sourcesCount: 0,
  fetching: false,
  fetchingTotal: false,
  hasMore: true,
  nextPage: 2,
  lastFetchTime: null,
  csvLoadSuccess: false,
  csvLoadFail: false,
  totalCount: [],
  activeForEndpoint: '',
};

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.FIRST_FETCH_REQUEST: {
      return {
        ...state,
        nextPage: 2,
        lastFetchTime: action.meta.timestamp,
        fetching: true,
        sources: [],
        totalCount: [],
      };
    }

    case types.FETCH_MORE_REQUEST: {
      return {
        ...state,
        fetching: true,
      };
    }

    case types.FIRST_FETCH_SUCCESS: {
      if (state.lastFetchTime > action.meta.timestamp) {
        return state;
      }

      return {
        ...state,
        fetching: false,
        sources: action.payload.results,
        sourcesCount: action.payload.count,
        selected: action.payload.results[0],
        hasMore: Boolean(action.payload.next),
      };
    }

    case types.FETCH_MORE_SUCCESS: {
      return {
        ...state,
        fetching: false,
        sources: [...state.sources, ...action.payload.results],
        nextPage: state.nextPage + 1,
        hasMore: Boolean(action.payload.next),
      };
    }

    case types.FIRST_FETCH_FAIL:
    case types.FETCH_MORE_FAIL: {
      return {
        ...state,
        fetching: false,
      };
    }
    case types.LOAD_CSV_SUCCESS: {
      return {
        ...state,
        csvLoadSuccess: true,
        csvLoadFail: false,
      };
    }

    case types.LOAD_CSV_REQUEST: {
      return {
        ...state,
        csvLoadSuccess: false,
        csvLoadFail: false,
      };
    }

    case types.LOAD_CSV_FAIL: {
      return {
        ...state,
        csvLoadSuccess: false,
        csvLoadFail: true,
      };
    }

    case types.SELECT_SOURCE: {
      return {
        ...state,
        selected: action.meta.source,
      };
    }

    case types.FETCH_ACTIVE_TOTAL_COUNT_REQUEST: {
      return {
        ...state,
        fetchingTotal: true,
      };
    }

    case types.FETCH_ACTIVE_TOTAL_COUNT_SUCCESS: {
      return {
        ...state,
        fetchingTotal: false,
        totalCount: action.payload.results,
      };
    }

    case types.FETCH_ACTIVE_TOTAL_COUNT_FAIL: {
      return {
        ...state,
        fetchingTotal: false,
      };
    }

    case types.ACTIVE_FOR_ENDPONT: {
      return {
        ...state,
        activeForEndpoint: action.payload,
      };
    }

    default:
      return state;
  }
}

export function getNextPage(state) {
  return state.nextPage;
}

export function getTotalCount(state) {
  return state.totalCount;
}

export function getActiveForEndpoint(state) {
  return state.activeForEndpoint;
}
