export type PanelProps<Entity> = {
  className?: string;
  entity?: Entity;
  content: PanelTab<Entity>[] | PanelContent<Entity>;
  title: (entity: Record<string, any>) => JSX.Element | string;
  controls?: (
    entity?: Record<string, any>,
    isOpen?: boolean,
  ) => JSX.Element | string | boolean;
  loading?: boolean;
  fetchEntity?: () => void;
  resizable?: boolean;
};

export type PanelSection<Entity> = {
  renderer: (a?: Entity) => JSX.Element | string;
  title: JSX.Element | string;
  visible?: (a?: Entity) => boolean;
};

export function isPanelSection<Entity>(x: any): x is PanelSection<Entity> {
  return typeof x.title === 'string' && typeof x.renderer === 'function';
}

export function isPanelTabRenderer<Entity>(
  x: any,
): x is (a?: Entity, width?: number) => JSX.Element | string | null {
  return typeof x === 'function';
}

export function isPanelTabArray<Entity>(x: any): x is PanelTab<Entity>[] {
  return Array.isArray(x);
}

export type PanelTab<Entity> = {
  name: string;
  content: PanelContent<Entity>;
};
export type PanelContent<Entity> =
  | { [key: string]: PanelSection<Entity> }
  | ((
      a?: Entity,
      width?: number,
      isResizingWidth?: boolean,
    ) => JSX.Element | string | null);
