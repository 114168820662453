import moment from 'moment';

const isUTC = (item: string): boolean => {
  const result = item?.match(
    '^((19|20)[0-9][0-9])[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])[T][0-9]{2}[:]([0-5][0-9])[:]([0-5][0-9])\\.{0,1}[0-9]{0,6}([+|-]([01][0-9]|[2][0-3])[:]([0-5][0-9])){0,1}$',
  );

  if (result === null) {
    return false;
  }

  return true;
};

export const handleRequestItem = (
  item: string | number | null | undefined,
): string | number => {
  if (item === null) {
    return '—';
  }

  if (typeof item === 'number') {
    return item;
  }

  if (typeof item === 'string') {
    return isUTC(item) ? moment(item).format('lll') : item;
  }

  return '—';
};
