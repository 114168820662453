import { useMemo } from 'react';

import { UseActionsProps } from '@soc/alerts/src/hooks/useAlertsActions';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getUser } from '../../../store/reducers';
import { checkAlertAssign, patchAlerts } from '.';

export const useActionsProps = (): UseActionsProps => {
  const dispatch = useAppDispatch();
  const { username } = useAppSelector(getUser);

  return useMemo(
    () => ({
      checkAssign: (props) => dispatch(checkAlertAssign(props)),
      fetchCounts: () => {
        return undefined;
      },
      patch: ({ action, data, ...options }) =>
        dispatch(patchAlerts(data, action, options)),
      username,
    }),
    [dispatch, username],
  );
};
