import React, { useMemo } from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import { Empty, Spin } from 'antd';
import { Badge, Flex, Spacer, Text } from 'combinezone/core';
import { LinkExternal } from 'combinezone/icons';

import './ReadMitre.scss';

const getTechniqueTags = (techniques) => {
  return techniques.map((technique) => (
    <>
      <a
        onClick={(e) => e.stopPropagation()}
        target="_blank"
        style={{ pointerEvents: 'auto' }}
        href={technique.url}
        rel="noreferrer"
      >
        <Badge
          className="LinkBadge"
          accent="transparent"
          RightIcon={LinkExternal}
          key={technique.technique_id}
        >
          {technique.fullName}
        </Badge>
      </a>
      <Spacer width={4} />
    </>
  ));
};

export const ReadMitre = ({
  loading = false,
  mitre: { tactics, techniques },
  noData,
  value,
  withLinks = false,
}) => {
  const currentValue = useMemo(
    () =>
      Object.entries(value).reduce((result, [tacId, techIds]) => {
        if (!tactics[tacId]) {
          return result;
        }

        const finedTechIds = techIds.filter((id) => techniques[id]);

        if (!finedTechIds.length) {
          return result;
        }

        result[tacId] = finedTechIds;

        return result;
      }, {}),
    [value, techniques, tactics],
  );

  const { platforms, readMitre } = useMemo(() => {
    if (!Object.keys(techniques).length) {
      return {
        readMitre: null,
        platforms: null,
      };
    }

    let platforms = new Set();
    Object.entries(currentValue).map(([tacId, techIds]) => {
      techIds.map((id) => {
        platforms = new Set([
          ...Array.from(platforms),
          ...techniques[id]?.platforms.map(({ name }) => name),
        ]);
      });
    });
    const readMitre = Object.entries(currentValue).map(([tacId, techIds]) => (
      <React.Fragment key={tacId}>
        <div className="ReadMitreSoc-Cell">{tactics[tacId]?.name}</div>
        <Flex className="ReadMitreSoc-Cell">
          {getTechniqueTags(
            techIds.map((id) => techniques[id]),
            { withLinks },
          )}
        </Flex>
      </React.Fragment>
    ));

    return {
      readMitre,
      platforms: Array.from(platforms),
    };
  }, [currentValue, techniques, tactics, withLinks]);

  return Object.keys(currentValue).length > 0 ? (
    <Spin spinning={loading}>
      <div className="ReadMitreSoc">
        <div className="ReadMitreSoc-Cell">
          <Text fontWeight="medium">
            {I18n.t('@@commonComponents.mitre.tactics')}
          </Text>
        </div>
        <div className="ReadMitreSoc-Cell">
          <Text fontWeight="medium">
            {I18n.t('@@commonComponents.mitre.techniques')}
          </Text>
        </div>
        {readMitre}
      </div>
      <Spacer height={16} />
      <Flex direction="column">
        <Text fontWeight="medium">
          <Translate value="rulePage.platforms" />
        </Text>
        <Spacer height={8} />
        <Flex wrap="wrap" gap="4px">
          {platforms?.map((platform) => (
            <Badge accent="transparent" key={platform}>
              {platform}
            </Badge>
          ))}
        </Flex>
      </Flex>
    </Spin>
  ) : (
    <Empty description={I18n.t('@@commonComponents.mitre.noData')} />
  );
};

ReadMitre.defaultProps = {
  value: {},
  mitre: {},
};
