import { connect } from 'react-redux';

import { Panel } from '../components/Instructions/Panel';
import { INSTRUCTIONS_ENDPOINT } from '../services/api';
import { downloadFile } from '../store/reducers/fileDownloader/actions';

const mapStateToProps = (state) => ({
  instruction: state.instructions.selected,
});

const mapDispatchToProps = {
  downloadPDF: (id, name) =>
    downloadFile(`${INSTRUCTIONS_ENDPOINT}${id}/download_pdf/`, `${name}.pdf`),
};

const PanelContainer = connect(mapStateToProps, mapDispatchToProps)(Panel);
export default PanelContainer;
