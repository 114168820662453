export const FETCH_INSTRUCTION_CARD_REQUEST =
  '@@instractionCard/FETCH_INSTRUCTION_CARD_REQUEST';
export const FETCH_INSTRUCTION_CARD_SUCCESS =
  '@@instractionCard/FETCH_INSTRUCTION_CARD_SUCCESS';
export const FETCH_INSTRUCTION_CARD_FAIL =
  '@@instractionCard/FETCH_INSTRUCTION_CARD_FAIL';

export const FETCH_INSTRUCTION_VERSION_REQUEST =
  '@@instractionCard/FETCH_INSTRUCTION_VERSION_REQUEST';
export const FETCH_INSTRUCTION_VERSION_SUCCESS =
  '@@instractionCard/FETCH_INSTRUCTION_VERSION_SUCCESS';
export const FETCH_INSTRUCTION_VERSION_FAIL =
  '@@instractionCard/FETCH_INSTRUCTION_VERSION_FAIL';

export const FETCH_INSTRUCTION_COMMENTS_REQUEST =
  '@@instractionCard/FETCH_INSTRUCTION_COMMENTS_REQUEST';
export const FETCH_INSTRUCTION_COMMENTS_SUCCESS =
  '@@instractionCard/FETCH_INSTRUCTION_COMMENTS_SUCCESS';
export const FETCH_INSTRUCTION_COMMENTS_FAIL =
  '@@instractionCard/FETCH_INSTRUCTION_COMMENTS_FAIL';

export const ADD_COMMENT_REQUEST = '@@instractionCard/ADD_COMMENT_REQUEST';
export const ADD_COMMENT_SUCCESS = '@@instractionCard/ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_FAIL = '@@instractionCard/ADD_COMMENT_FAIL';

export const CLOSE_INSTRUCTION_CARD =
  '@@instractionCard/CLOSE_INSTRUCTION_CARD';
export const ADD_COMMENT_MODAL_SUCCESS =
  '@@instractionCard/ADD_COMMENT_MODAL_SUCCESS';
