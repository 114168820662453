import * as types from './actionTypes';

const initialState = {
  reportsShedules: [],
  reportsShedulesCount: 0,
  fetching: false,
  hasMore: true,
  nextPage: 2,
  lastFetchTime: null,
  selected: {},
};

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.FIRST_FETCH_REQUEST: {
      return {
        ...state,
        nextPage: 2,
        lastFetchTime: action.meta.timestamp,
        fetching: true,
      };
    }

    case types.FETCH_MORE_REQUEST: {
      return {
        ...state,
        fetching: true,
      };
    }

    case types.FIRST_FETCH_SUCCESS: {
      if (state.lastFetchTime > action.meta.timestamp) {
        return state;
      }
      return {
        ...state,
        fetching: false,
        reportsShedules: action.payload.results,
        reportsShedulesCount: action.payload.count,
        selected: action.payload.results[0] || {},
        hasMore: Boolean(action.payload.next),
      };
    }

    case types.FETCH_MORE_SUCCESS: {
      return {
        ...state,
        fetching: false,
        reportsShedules: [...state.reportsShedules, ...action.payload.results],
        nextPage: state.nextPage + 1,
        hasMore: Boolean(action.payload.next),
      };
    }

    case types.FIRST_FETCH_FAIL:
    case types.FETCH_MORE_FAIL: {
      return {
        ...state,
        fetching: false,
      };
    }

    case types.SELECT_REPORTSHEDULE: {
      return {
        ...state,
        selected: action.meta.reportShedule,
      };
    }

    default:
      return state;
  }
}

export function getNextPage(state) {
  return state.nextPage;
}

export function getSelectedShedule(state) {
  return state.selected;
}
