import React, {
  FC,
  KeyboardEventHandler,
  memo,
  useEffect,
  useState,
} from 'react';
import { Translate } from 'react-redux-i18n';

import { Button, Flex, Input } from 'combinezone/core';
import { Search as SearchIcon } from 'combinezone/icons';

import { SearchProps } from '../types';

const Search: FC<SearchProps> = ({
  defaultSearch = '',
  isLoading,
  onChangeSearch,
  placeholder,
}) => {
  const [search, setSearch] = useState('');

  const onSearch: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      onChangeSearch(search?.trim() ?? '');
    }
  };

  useEffect(() => {
    defaultSearch && setSearch(defaultSearch);
  }, [defaultSearch]);

  return (
    <Flex className="Search" gap="8px">
      <Input
        testId="Search_Input"
        LeftIcon={SearchIcon}
        placeholder={placeholder}
        onChange={setSearch}
        onKeyUp={onSearch}
        onClear={() => onChangeSearch('')}
        value={search}
      />
      <Button
        className="Search-Button"
        testId="Search_Button"
        isDisabled={isLoading}
        onClick={() => onChangeSearch(search?.trim())}
        variant="transparentWithBorder"
      >
        <Translate value="filterPanel.searchButton" />
      </Button>
    </Flex>
  );
};

export default memo(Search);
