import React, { Component } from 'react';

import { Layout } from '@common/soc-react-kit';

import FilterPanelContainer from '../../containers/FilterPanelContainer';
import { CREATE_FIELD } from '../../services/api';

import './Dashboard.scss';
import FactoidsContainer from 'containers/FactoidsContainer';

import DashboardGridContainer from '../../containers/DashboardGridContainer';

import EpsWidget from 'components/EpsWidget';
import MitreWidgetContainer from 'containers/MitreWidgetContainer';

const { Container, Content } = Layout;

export default class Dashboard extends Component {
  componentDidMount() {
    const { dispatchShowFilters, fetchDashboardData, getDashboardLayout } =
      this.props;
    getDashboardLayout();
    fetchDashboardData();
    dispatchShowFilters();
  }

  componentWillUnmount() {
    this.props.unsetDashboardUpdateTimer();
  }

  render() {
    const { dateRange, updateFunction } = this.props;

    return (
      <React.Fragment>
        <Container className="DashboardHeader">
          <Content className="DashboardHeader-Content">
            <FilterPanelContainer
              defaultFilters={{ [CREATE_FIELD]: dateRange }}
              updateFunction={updateFunction}
            />
          </Content>
        </Container>
        <Container className="DashboardContainer">
          <Content className="DashboardContent">
            <FactoidsContainer />
            <DashboardGridContainer />
            <EpsWidget />
            <MitreWidgetContainer />
          </Content>
        </Container>
      </React.Fragment>
    );
  }
}
