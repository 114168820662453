import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import styled from 'styled-components';

import { Block, Heading, Flex, Checkbox } from 'combinezone/core';
import { BlockHeader } from 'combinezone/core/Block/Block';
import { CombinezoneTheme } from 'combinezone/theme';

import MitreColorsPanel from 'components/RulesMitreWidget/MitreColorsPanel';
import MitreWidgetPanel from 'components/RulesMitreWidget/MitreWidgetPanel';
import TechniqueTable from 'components/RulesMitreWidget/TechniqueTable';
import {
  getMitreMapping,
  getMitreTactics,
  getMitreTacticsIdsCoverage,
  rulesMitreDataSelector,
} from 'store/reducers';
import { fetchMitreMapping } from 'store/reducers/mitre/actions';
import { fetchAnalyticsMitre as fetchData } from 'store/reducers/ruleList/actions';

import { addSubTechniques, hiddenWithBlankCounts } from './helpers';

const BlockWrapper = styled(Flex)`
  margin-top: 16px !important;
  height: calc(100% - 16px);
  overflow-y: auto;
`;
const BlockContainer = styled(Block)`
  background-color: ${({ theme }: { theme: CombinezoneTheme }) =>
    theme.basis.colors.base.desk};
  display: flex;

  ${BlockHeader} {
    background-color: ${({ theme }: { theme: CombinezoneTheme }) =>
      theme.basis.colors.node.normal};
  }
`;

const HeaderWrapper = styled(Flex)`
  margin-right: 10px;
`;

const testId = 'mitre-widget';

const MitreWidget = () => {
  const dispatch = useDispatch();
  const mitreMapping = useSelector(getMitreMapping);
  const rawData = useSelector(rulesMitreDataSelector);
  const tactics = useSelector(getMitreTactics);
  const tacticsIds = useSelector(getMitreTacticsIdsCoverage);
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentOpenedTechniques, setOpenedTechniques] = useState(null);
  const [mitreData, setMitreData] = useState([]);
  const [hideEmpty, setHideEmpty] = useState(true);

  const closeTechnique = (): void => {
    setOpenedTechniques(null);
  };

  useEffect((): void => {
    let mitreDataChange = hiddenWithBlankCounts(
      mitreMapping,
      rawData,
      hideEmpty,
    );
    if (
      currentOpenedTechniques &&
      currentOpenedTechniques.technique.sub_techniques
    ) {
      mitreDataChange = addSubTechniques(
        mitreData,
        currentOpenedTechniques.technique,
        currentOpenedTechniques.coords[0],
        currentOpenedTechniques.coords[1],
      );
    } else {
      setOpenedTechniques(null);
    }

    setMitreData(mitreDataChange);
  }, [rawData, mitreMapping, hideEmpty, currentOpenedTechniques]);

  useEffect(() => {
    closeTechnique();
  }, [rawData]);

  const isLoadingMapping = !Object.keys(mitreMapping).length;

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchData()).finally(() => setIsLoading(false));
  }, [fetchData, fetchMitreMapping]);

  const onExpandedClickHandle = (hasExpanded: boolean): void => {
    setIsExpanded(hasExpanded);
  };

  return (
    <BlockWrapper testId={`${testId}-block-wrapper`} fillWidth>
      <BlockContainer
        testId={`${testId}-block`}
        isCollapsible={false}
        isHeaderSeparated
        headerContent={
          <HeaderWrapper
            fillWidth
            justify="space-between"
            testId={`${testId}-header-wrapper`}
            alignItems="center"
          >
            <Flex gap="32px">
              <Heading size="md" testId={`${testId}-header`}>
                {I18n.t('mitreWidget.title')}
              </Heading>
              <MitreColorsPanel testId={testId} />
            </Flex>
            <Checkbox
              isChecked={hideEmpty}
              onChange={setHideEmpty}
              label={I18n.t('ruleList.mitreWidget.hideNone')}
              testId={`${testId}-switcher`}
            />
          </HeaderWrapper>
        }
        isLoading={isLoading || isLoadingMapping}
        onExpand={onExpandedClickHandle}
      >
        <Flex fillWidth fillHeight testId={`${testId}-inner-block`}>
          <Flex fillHeight testId={`${testId}-table-wrapper`}>
            <TechniqueTable
              fetchData={fetchData}
              fetchMitreMapping={fetchMitreMapping}
              mitreData={mitreData}
              mitreMapping={mitreMapping}
              tactics={tactics}
              tacticsIds={tacticsIds}
              testId={testId}
              hideEmpty={hideEmpty}
            />
          </Flex>

          {isExpanded && (
            <Flex fillHeight testId={`${testId}-panel-wrapper`}>
              <MitreWidgetPanel testId={`${testId}-panel`} />
            </Flex>
          )}
        </Flex>
      </BlockContainer>
    </BlockWrapper>
  );
};

export default MitreWidget;

MitreWidget.displayName = 'MitreWidget';
