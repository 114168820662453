import { connect } from 'react-redux';

import Login from '../components/Login';
import { authErrorsSelector } from '../store/reducers';
import {
  login,
  checkSmsCode,
  sendSmsCode,
  resetMfa,
} from '../store/reducers/auth/actions';

const mapStateToProps = (state) => ({
  errors: authErrorsSelector(state),
  isAuthenticated: state.auth.isAuthenticated,
  isLoading: state.auth.isLoading,
  hasMfa: state.auth.hasMfa,
  timeToGetNextMfaToken: state.auth.timeToGetNextMfaToken,
});

const mapDispatchToProps = {
  login,
  checkSmsCode,
  sendSmsCode,
  resetMfa,
};

const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(Login);
export default LoginContainer;
