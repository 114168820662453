import React, { useCallback, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { Translate } from 'react-redux-i18n';

import { Header } from '@bizone/ui-bundle/esm/Header';

import { OverlaySpinner } from 'combinezone/core/index';

import { ID_FIELD } from '../../../../services/api';
import {
  currentLocale,
  rulePageHasMoreIncidents,
  rulePageIncidents,
  rulePageIsFetchingIncidents,
} from '../../../../store/reducers';
import {
  fetchIncidents,
  fetchMoreIncidents,
} from '../../../../store/reducers/rulePage/actions';
import { Card } from '../../../IncidentList/Card';

import './IncidentsTab.scss';

const IncidentsTab = ({ mitreTechniques, ruleId }) => {
  const dispatch = useDispatch();

  const locale = useSelector(currentLocale);
  const incidents = useSelector(rulePageIncidents);
  const isFetching = useSelector(rulePageIsFetchingIncidents);
  const hasMore = useSelector(rulePageHasMoreIncidents);

  const loadMore = useCallback(() => {
    if (hasMore) {
      dispatch(fetchMoreIncidents(ruleId));
    }
  }, [dispatch, hasMore, ruleId]);

  useEffect(() => {
    dispatch(fetchIncidents(ruleId));
  }, [dispatch, ruleId]);

  return (
    <div className="IncidentsTab">
      {isFetching && <OverlaySpinner />}
      <div className="IncidentsTab-Cards">
        <InfiniteScroll
          pageStart={1}
          initialLoad={false}
          loadMore={loadMore}
          hasMore={isFetching ? false : hasMore}
          useWindow={false}
        >
          {incidents.map((incident) => (
            <Card
              locale={locale}
              incident={incident}
              key={incident[ID_FIELD]}
              mitreTechniques={mitreTechniques}
            />
          ))}
        </InfiniteScroll>
        {incidents.length === 0 && !hasMore && (
          <div className="IncidentsTab-NoData">
            <Header size={24}>
              <Translate value="incidentList.noData" />
            </Header>
          </div>
        )}
      </div>
    </div>
  );
};

export default IncidentsTab;
