import { FC, memo, ReactElement, useEffect, useRef, useState } from 'react';

export type CountdownProps = {
  startValueSec: number;
  onFinish?: () => void | Promise<void>;
  children: (result: { remainMin: number; remainSec: number }) => ReactElement;
};

export const Countdown: FC<CountdownProps> = memo(
  ({ children, onFinish, startValueSec }) => {
    const [startDate] = useState(Date.now());
    const codeTimeoutTimer = useRef<number | undefined>(undefined);
    const [forceUpdateKey, forceUpdate] = useState(Date.now());
    const diffSec = Math.floor((Date.now() - startDate) / 1000);
    const remainMin = Math.floor((startValueSec - diffSec) / 60);
    const remainSec = Math.floor((startValueSec - diffSec) % 60);
    const isFinished = diffSec >= startValueSec;

    useEffect(() => {
      if (isFinished) {
        clearTimeout(codeTimeoutTimer.current);
        onFinish?.();

        return;
      }

      codeTimeoutTimer.current = window.setTimeout(() => {
        forceUpdate(Date.now());
      }, 300);
    }, [isFinished, forceUpdateKey, onFinish]);

    useEffect(() => {
      return () => {
        clearTimeout(codeTimeoutTimer.current);
      };
    }, []);

    return children({ remainMin, remainSec });
  },
);
Countdown.displayName = 'Countdown';
