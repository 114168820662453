import { connect } from 'react-redux';

import { InstructionPage } from '../components/InstructionPage';
import {
  instructionCardDisp,
  instructionCardAddCommentModal,
  instructionCardFetchError,
  instructionCardComments,
  currentLocale,
} from '../store/reducers';
import { downloadFile } from '../store/reducers/fileDownloader/actions';
import {
  fetchInstructionCard,
  closeInstructionCard,
  submitComment,
  finalizeCommentSuccess,
  fetchComments,
} from '../store/reducers/instuctionCard/actions';

const mapStateToProps = (state) => ({
  instruction: instructionCardDisp(state),
  fetchError: instructionCardFetchError(state),
  addComment: instructionCardAddCommentModal(state),
  comments: instructionCardComments(state),
  locale: currentLocale(state),
});

const mapDispatchToProps = {
  fetchInstructionCard,
  submitComment,
  closeInstructionCard,
  finalizeCommentSuccess,
  fetchComments,
  downloadFile,
};

const InstructionPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InstructionPage);
export default InstructionPageContainer;
