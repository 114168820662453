export const fonts = {
  default:
    process.env.REACT_APP_FONT_FAMILY || 'Roboto, Helvetica, Arial, sans-serif',
  mono:
    process.env.REACT_APP_FONT_FAMILY ||
    'Roboto Mono, Helvetica, Arial, sans-serif',
  accent:
    process.env.REACT_APP_FONT_FAMILY_ACCENT ||
    'Ubuntu, Helvetica, Arial, sans-serif',
};

export const weights = {
  normal: 400,
  medium: 500,
  bold: 700,
};

export const typography = {
  fonts,
  weights,
};

export const paragraphIndent = [0];
export const fontFamilies = {
  ubuntu: 'Ubuntu',
  roboto: 'Roboto',
  'roboto-mono': 'Roboto Mono',
};

export const lineHeights = {
  '0': 40,
  '1': 32,
  '2': 28,
  '3': 24,
  '4': 20,
  '5': 16,
  '6': 36,
};

export const fontWeights = {
  'ubuntu-0': 'Regular',
  'ubuntu-1': 'Medium',
  'roboto-2': 'Medium',
  'roboto-3': 'Regular',
  'roboto-mono-4': 'Regular',
};

export const fontSize = {
  '0': 12,
  '1': 14,
  '2': 16,
  '3': 20,
  '4': 24,
  '5': 28,
  '6': 32,
};

export const letterSpacing = {
  '0': 0,
  '1': -0.5,
  '2': 0.5,
};

export const paragraphSpacing = {
  '0': 0,
  '1': 8,
};

export const textCase = {
  none: 'none',
  uppercase: 'uppercase',
};

export const textDecoration = {
  none: 'none',
};

export const brand = {
  hsl: {
    neutral: '230deg',
    active: '198deg',
    positive: '104deg',
    attention: '44deg',
    warning: '24deg',
    critical: '4deg',
    zero: '0deg',
    full: '100deg',
  },
};

export const a0 = 0;

export const a100 = 1;

export const a80 = 0.8;

export const a60 = 0.6;

export const a40 = 0.4;

export const a30 = 0.3;

export const a14 = 0.14;

export const a8 = 0.08;

export const a6 = 0.06;

export const a4 = 0.04;

export const constant = {
  saturated: process.env.REACT_APP_HEADER_COLOR || 'hsl(216, 30%, 30%)',
  base: process.env.REACT_APP_PRIMARY_COLOR || 'hsl(216, 30%, 25%)',
  faded: `hsl(${brand.hsl.neutral}, 20%, 95%)`,
  alpha: {
    saturated: `hsla(${brand.hsl.neutral}, 0%, 100%, ${a60})`,
    base: `hsla(${brand.hsl.neutral}, 0%, 100%, ${a40})`,
    smoke: `hsla(${brand.hsl.neutral}, 0%, 0%, ${a60})`,
  },
};

export const FactoidL = {
  value: {
    fontFamily: `${fontFamilies.ubuntu}`,
    fontWeight: `${fontWeights['ubuntu-0']}`,
    lineHeight: `${lineHeights[0]}`,
    fontSize: `${fontSize[6]}`,
    letterSpacing: `${letterSpacing[0]}`,
    paragraphSpacing: `${paragraphSpacing[1]}`,
    paragraphIndent: `${paragraphIndent[0]}`,
    textCase: `${textCase.none}`,
    textDecoration: `${textDecoration.none}`,
  },
  type: 'typography',
  description: 'Акцентный текст фактоида. Основное внимание',
};

export const FactoidM = {
  value: {
    fontFamily: `${fontFamilies.ubuntu}`,
    fontWeight: `${fontWeights['ubuntu-1']}`,
    lineHeight: `${lineHeights[1]}`,
    fontSize: `${fontSize[4]}`,
    letterSpacing: `${letterSpacing[0]}`,
    paragraphSpacing: `${paragraphSpacing[1]}`,
    paragraphIndent: `${paragraphIndent[0]}`,
    textCase: `${textCase.none}`,
    textDecoration: `${textDecoration.none}`,
  },
  type: 'typography',
};

export const HeadingL = {
  value: {
    fontFamily: `${fontFamilies.ubuntu}`,
    fontWeight: `${fontWeights['ubuntu-1']}`,
    lineHeight: `${lineHeights[2]}`,
    fontSize: `${fontSize[3]}`,
    letterSpacing: `${letterSpacing[0]}`,
    paragraphSpacing: `${paragraphSpacing[1]}`,
    paragraphIndent: `${paragraphIndent[0]}`,
    textCase: `${textCase.none}`,
    textDecoration: `${textDecoration.none}`,
  },
  type: 'typography',
};

export const HeadingM = {
  value: {
    fontFamily: `${fontFamilies.ubuntu}`,
    fontWeight: `${fontWeights['ubuntu-1']}`,
    lineHeight: `${lineHeights[3]}`,
    fontSize: `${fontSize[2]}`,
    letterSpacing: `${letterSpacing[0]}`,
    paragraphSpacing: `${paragraphSpacing[1]}`,
    paragraphIndent: `${paragraphIndent[0]}`,
    textCase: `${textCase.none}`,
    textDecoration: `${textDecoration.none}`,
  },
  type: 'typography',
};

export const Title = {
  value: {
    fontFamily: `${fontFamilies.roboto}`,
    fontWeight: `${fontWeights['roboto-2']}`,
    lineHeight: `${lineHeights[4]}`,
    fontSize: `${fontSize[1]}`,
    letterSpacing: `${letterSpacing[0]}`,
    paragraphSpacing: `${paragraphSpacing[1]}`,
    paragraphIndent: `${paragraphIndent[0]}`,
    textCase: `${textCase.none}`,
    textDecoration: `${textDecoration.none}`,
  },
  type: 'typography',
};

export const Text = {
  value: {
    fontFamily: `${fontFamilies.roboto}`,
    fontWeight: `${fontWeights['roboto-3']}`,
    lineHeight: `${lineHeights[4]}`,
    fontSize: `${fontSize[1]}`,
    letterSpacing: `${letterSpacing[0]}`,
    paragraphSpacing: `${paragraphSpacing[1]}`,
    paragraphIndent: `${paragraphIndent[0]}`,
    textCase: `${textCase.none}`,
    textDecoration: `${textDecoration.none}`,
  },
  type: 'typography',
  description: 'Базовый текст',
};

export const Caption = {
  value: {
    fontFamily: `${fontFamilies.roboto}`,
    fontWeight: `${fontWeights['roboto-3']}`,
    lineHeight: `${lineHeights[5]}`,
    fontSize: `${fontSize[0]}`,
    letterSpacing: `${letterSpacing[0]}`,
    paragraphSpacing: `${paragraphSpacing[1]}`,
    paragraphIndent: `${paragraphIndent[0]}`,
    textCase: `${textCase.none}`,
    textDecoration: `${textDecoration.none}`,
  },
  type: 'typography',
};

export const Code = {
  value: {
    fontFamily: `${fontFamilies['roboto-mono']}`,
    fontWeight: `${fontWeights['roboto-mono-4']}`,
    lineHeight: `${lineHeights[4]}`,
    fontSize: `${fontSize[1]}`,
    letterSpacing: `${letterSpacing[1]}`,
    paragraphSpacing: `${paragraphSpacing[1]}`,
    paragraphIndent: `${paragraphIndent[0]}`,
    textCase: `${textCase.none}`,
    textDecoration: `${textDecoration.none}`,
  },
  type: 'typography',
};

export const Button = {
  value: {
    fontFamily: `${fontFamilies.roboto}`,
    fontWeight: `${fontWeights['roboto-2']}`,
    lineHeight: `${lineHeights[3]}`,
    fontSize: `${fontSize[1]}`,
    letterSpacing: `${letterSpacing[2]}`,
    paragraphSpacing: `${paragraphSpacing[0]}`,
    paragraphIndent: `${paragraphIndent[0]}`,
    textCase: `${textCase.uppercase}`,
    textDecoration: `${textDecoration.none}`,
  },
  type: 'typography',
};

export const Pin = {
  value: {
    fontFamily: `${fontFamilies.roboto}`,
    fontWeight: `${fontWeights['roboto-3']}`,
    lineHeight: `${lineHeights[6]}`,
    fontSize: `${fontSize[5]}`,
    letterSpacing: `${letterSpacing[0]}`,
    paragraphSpacing: `${paragraphSpacing[1]}`,
    paragraphIndent: `${paragraphIndent[0]}`,
    textCase: `${textCase.none}`,
    textDecoration: `${textDecoration.none}`,
  },
  type: 'typography',
};

export const BadgeText = {
  value: {
    fontFamily: `${fontFamilies.roboto}`,
    fontWeight: `${fontWeights['roboto-2']}`,
    lineHeight: `${lineHeights[5]}`,
    fontSize: `${fontSize[0]}`,
    letterSpacing: `${letterSpacing[0]}`,
    paragraphSpacing: `${paragraphSpacing[1]}`,
    paragraphIndent: `${paragraphIndent[0]}`,
    textCase: `${textCase.none}`,
    textDecoration: `${textDecoration.none}`,
  },
  type: 'typography',
};

export const legend = {
  plum: 'hsl(292, 42%, 52%)',
  cornflower: 'hsl(231, 48%, 48%)',
  olympic: 'hsl(198, 73%, 47%)',
  sky: 'hsl(199, 98%, 48%)',
  sea: 'hsl(174, 100%, 29%)',
  kelly: 'hsl(122, 40%, 49%)',
  lime: 'hsl(74, 55%, 55%)',
  gold: 'hsl(44, 93%, 48%)',
  tiger: 'hsl(24, 84%, 52%)',
  candy: 'hsl(4, 72%, 56%)',
  stone: 'hsl(16, 24%, 48%)',
  iron: 'hsl(0, 0%, 30%)',
  steel: 'hsl(200, 18%, 46%)',
};
