export const FETCH_INSTRUCTION_CARD_REQUEST =
  '@@responseInstrCard/FETCH_INSTRUCTION_CARD_REQUEST';
export const FETCH_INSTRUCTION_CARD_SUCCESS =
  '@@responseInstrCard/FETCH_INSTRUCTION_CARD_SUCCESS';
export const FETCH_INSTRUCTION_CARD_FAIL =
  '@@responseInstrCard/FETCH_INSTRUCTION_CARD_FAIL';

export const FETCH_INSTRUCTION_VERSION_REQUEST =
  '@@responseInstrCard/FETCH_INSTRUCTION_VERSION_REQUEST';
export const FETCH_INSTRUCTION_VERSION_SUCCESS =
  '@@responseInstrCard/FETCH_INSTRUCTION_VERSION_SUCCESS';
export const FETCH_INSTRUCTION_VERSION_FAIL =
  '@@responseInstrCard/FETCH_INSTRUCTION_VERSION_FAIL';

export const FETCH_INSTRUCTION_COMMENTS_REQUEST =
  '@@responseInstrCard/FETCH_INSTRUCTION_COMMENTS_REQUEST';
export const FETCH_INSTRUCTION_COMMENTS_SUCCESS =
  '@@responseInstrCard/FETCH_INSTRUCTION_COMMENTS_SUCCESS';
export const FETCH_INSTRUCTION_COMMENTS_FAIL =
  '@@responseInstrCard/FETCH_INSTRUCTION_COMMENTS_FAIL';

export const ADD_COMMENT_REQUEST = '@@responseInstrCard/ADD_COMMENT_REQUEST';
export const ADD_COMMENT_SUCCESS = '@@responseInstrCard/ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_FAIL = '@@responseInstrCard/ADD_COMMENT_FAIL';

export const CLOSE_INSTRUCTION_CARD =
  '@@responseInstrCard/CLOSE_INSTRUCTION_CARD';
export const ADD_COMMENT_MODAL_SUCCESS =
  '@@responseInstrCard/ADD_COMMENT_MODAL_SUCCESS';
