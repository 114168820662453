import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';

import { Flex } from 'combinezone/core';
import { CombinezoneTheme } from 'combinezone/theme';

import TechniqueCell from 'components/RulesMitreWidget/TechniqueTable/components/TechniqueCell';
import { Technique } from 'components/RulesMitreWidget/TechniqueTable/components/TechniqueCell/types';
import { TechniqueTableProps } from 'components/RulesMitreWidget/TechniqueTable/types';
import { MitreMappingTypes } from 'components/RulesMitreWidget/enums';
import {
  addSubTechniques,
  hiddenWithBlankCounts,
  recalculateIJ,
} from 'components/RulesMitreWidget/helpers';
import {
  CurrentOpenedTechniques,
  TechniqueTableCell,
} from 'components/RulesMitreWidget/types';

const TrTech = styled.tr`
  height: 100%;
`;

const TechniqueTableTh = styled.th`
  min-width: 176px;
  height: 48px;
  font-size: 14px;
  font-weight: 400;
  vertical-align: center;
  text-align: left;
  top: 0;
  position: sticky;
  padding: 12px 14px;
  color: ${({ theme }: { theme: CombinezoneTheme }) =>
    theme.basis.colors.text.default};
  background-color: ${({ theme }: { theme: CombinezoneTheme }) =>
    theme.basis.colors.node.normal};
  border-bottom: ${({ theme }: { theme: CombinezoneTheme }) =>
    `1px solid ${theme.basis.colors.borders.divider}`};
`;

const TechniqueTable: FC<TechniqueTableProps> = ({
  fetchData,
  fetchMitreMapping,
  hideEmpty,
  mitreData: rawData,
  mitreMapping,
  tactics,
  tacticsIds,
  testId,
}) => {
  const [currentOpenedTechniques, setOpenedTechniques] =
    useState<CurrentOpenedTechniques | null>(null);
  const [mousedTechniqueId, setMousedTechniqueId] = useState<string | null>(
    null,
  );
  const [mitreData, setMitreData] = useState<TechniqueTableCell[][]>([]);
  const testIdTechniqueTable = `${testId}-technique-table`;

  const openTechnique = (
    technique: Technique,
    str: number,
    col: number,
  ): void => {
    const supplemented_technique = structuredClone(technique);

    if (supplemented_technique?.sub_techniques?.length) {
      const arr = supplemented_technique?.sub_techniques?.map((elem) => {
        return {
          ...elem,
          isParentSelected: true,
        };
      });

      supplemented_technique.sub_techniques = arr;
    }

    setOpenedTechniques({
      coords: [str, col],
      technique: supplemented_technique,
    });
  };

  const closeTechnique = (): void => {
    setOpenedTechniques(null);
  };

  useEffect(() => {
    let hiddenWithBlankCountsMitreData = hiddenWithBlankCounts(
      mitreMapping,
      rawData,
      hideEmpty,
    );
    if (
      currentOpenedTechniques &&
      currentOpenedTechniques.technique.sub_techniques
    ) {
      hiddenWithBlankCountsMitreData = addSubTechniques(
        hiddenWithBlankCountsMitreData,
        currentOpenedTechniques.technique,
        currentOpenedTechniques.coords[0],
        currentOpenedTechniques.coords[1],
      );
    } else {
      setOpenedTechniques(null);
    }

    setMitreData(hiddenWithBlankCountsMitreData);
  }, [rawData, mitreMapping, hideEmpty, currentOpenedTechniques]);

  useEffect(() => {
    closeTechnique();
  }, [rawData]);

  useEffect((): void => {
    fetchData();
  }, [fetchData, fetchMitreMapping]);

  return (
    <Flex fillWidth testId={`${testIdTechniqueTable}-wrapper`}>
      <table>
        <thead>
          <tr>
            {tacticsIds.map((id, i) =>
              currentOpenedTechniques?.coords[1] === i ? (
                <React.Fragment key={id}>
                  <TechniqueTableTh>
                    {tactics[id as MitreMappingTypes]?.name}
                  </TechniqueTableTh>
                  <TechniqueTableTh />
                </React.Fragment>
              ) : (
                <TechniqueTableTh key={id}>
                  {tactics[id as MitreMappingTypes]?.name}
                </TechniqueTableTh>
              ),
            )}
          </tr>
        </thead>

        <tbody>
          {mitreData.map((row, i) => (
            <tr>
              {row.map((technique, j) => {
                const [newI, newJ] = recalculateIJ(
                  i,
                  j,
                  currentOpenedTechniques,
                );

                return (
                  <TechniqueCell
                    key={technique ? technique.key : `${i}-${j}`}
                    isOpen={
                      currentOpenedTechniques?.coords[0] === i &&
                      currentOpenedTechniques?.coords[1] === j
                    }
                    onOpenTechnique={openTechnique}
                    onCloseTechnique={closeTechnique}
                    technique={technique}
                    str={newI}
                    col={newJ}
                    mousedTechniqueId={mousedTechniqueId}
                    setMousedTechniqueId={setMousedTechniqueId}
                    testId={testIdTechniqueTable}
                  />
                );
              })}
            </tr>
          ))}
          <TrTech />
        </tbody>
      </table>
    </Flex>
  );
};

export default TechniqueTable;

TechniqueTable.displayName = 'TechniqueTable';
