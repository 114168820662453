import { connect } from 'react-redux';

import { Card } from '../components/ReportsShedules/Card';
import {
  currentLocale,
  makeReportsShedulesCardSelector,
  isEditReportModalOpen,
} from '../store/reducers';
import {
  openEditReportModal,
  deleteReport,
} from '../store/reducers/editReport/actions';
import { select, firstFetch } from '../store/reducers/reportsShedules/actions';
import { getPeriodFromCrontab } from '../utils/helpers';

const makeMapStateToProps = () => {
  const selector = makeReportsShedulesCardSelector();

  return (state, ownProps) => {
    const isSelected = selector(state, ownProps);

    return {
      isSelected,
      locale: currentLocale(state),
      isOpen: isEditReportModalOpen,
      getPeriodFromCrontab,
    };
  };
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    select: () => dispatch(select(ownProps.shedule)),
    firstFetch: () => dispatch(firstFetch()),
    openEditReportModal: () => dispatch(openEditReportModal()),
    deleteReport: (id) => dispatch(deleteReport(id)),
  };
}

const ReportsShedulesCardContainer = connect(
  makeMapStateToProps,
  mapDispatchToProps,
)(Card);
export default ReportsShedulesCardContainer;
