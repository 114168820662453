import React, { useEffect, useRef, useState } from 'react';
import Helmet from 'react-helmet';

import { Block } from '@bizone/ui-bundle/esm/Block';
import { Header } from '@bizone/ui-bundle/esm/Header';

import { Layout } from '@common/soc-react-kit';
import { OverlaySpinner } from 'combinezone/core/index';

import { API_URL } from '../../services/api';
import { apiFetch } from '../../services/apiFetch';
import Description from '../InstructionPage/Description';

import EventsPanel from './EventsPanel';

import './CyberPolygonEvent.scss';

export default function CyberPolygonEvent() {
  const [event, setEvent] = useState();
  const [events, setEvents] = useState([]);
  const [isOpenPanel, setIsOpenPanel] = useState(true);
  const [fetching, setFetching] = useState(false);

  const [contentWidth, setContentWidth] = useState();
  const [contentHeight, setContentHeight] = useState();
  const [contentLeft, setContentLeft] = useState();
  const contentRef = useRef();

  const { description = '', name } = event || {};

  useEffect(() => {
    const getEvents = async () => {
      try {
        setFetching(true);
        const events = [];
        let data = {};
        let page = 1;
        do {
          data = await apiFetch.get(
            `${API_URL}/soc/cyberpolygon/eventype/?page=${page}`,
          );
          if (Array.isArray(data.results)) {
            events.push(...data.results);
          }
          page++;
        } while (data && data.next);
        setEvents(events);
        setFetching(false);
      } catch (e) {
        setFetching(false);
      }
    };

    getEvents();
  }, []);

  useEffect(() => {
    if (contentRef.current) {
      const setter = () => {
        if (contentRef.current) {
          const { height, width, x } =
            contentRef.current.getBoundingClientRect();
          setContentWidth(width);
          setContentHeight(height);
          setContentLeft(x);
        }
      };

      const resizeObserver = new ResizeObserver(setter);
      document.addEventListener('scroll', setter);

      resizeObserver.observe(contentRef.current);

      return () => {
        resizeObserver.disconnect();
        document.removeEventListener('scroll', setter);
      };
    }
  }, [fetching]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Event Documentation</title>
      </Helmet>
      <Layout.BreadCrumb style={{ backgroundColor: '#131c26' }}>
        <div className="EventHeader">
          <Header size={16}>Event Documentation</Header>
        </div>
      </Layout.BreadCrumb>
      <Layout.Container panel className="Event">
        <EventsPanel
          setActiveEvent={setEvent}
          events={events}
          isOpen={isOpenPanel}
          setIsOpen={setIsOpenPanel}
        />
        <Layout.Content>
          {fetching && <OverlaySpinner />}
          <div
            ref={contentRef}
            style={{
              '--content-width': contentWidth ? `${contentWidth}px` : undefined,
              '--content-height': contentHeight
                ? `${contentHeight}px`
                : undefined,
              '--content-left': contentLeft ? `${contentLeft}px` : undefined,
              '--panel-width': isOpenPanel ? '400px' : '65px',
            }}
          >
            <Block cls="Event-Block">
              {!fetching && (
                <Block.Content cls="Event-BlockContent">
                  <div className="Event-BlockHeader">
                    <Header size={32}>{name}</Header>
                  </div>
                  {!event && (
                    <div className="Event-noData">
                      <Header size={24}>404</Header>
                    </div>
                  )}
                  <Description
                    collapsible={false}
                    more="more"
                    less="less"
                    rows={5}
                    value={description}
                  />
                </Block.Content>
              )}
            </Block>
          </div>
        </Layout.Content>
      </Layout.Container>
    </React.Fragment>
  );
}
