import { connect } from 'react-redux';

import ReportsList from '../components/ReportsList';
import {
  reportsList,
  isLoadingReports,
  currentLocale,
  hasMoreReports,
} from '../store/reducers';
import { downloadFile } from '../store/reducers/fileDownloader/actions';
import {
  firstFetch,
  fetchMore,
  deleteReport,
} from '../store/reducers/reports/actions';

const mapStateToProps = (state) => {
  return {
    isLoading: isLoadingReports(state),
    hasMore: hasMoreReports(state),
    reports: reportsList(state),
    locale: currentLocale(state),
    systems: state.filters.incidents.settings.system.choices,
  };
};

const mapDispatchToProps = {
  firstFetch,
  fetchMore,
  downloadReport: downloadFile,
  deleteReport,
};

const ReportsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportsList);
export default ReportsContainer;
