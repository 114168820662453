import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '@bizone/ui-bundle/esm/Icon';
import { IconButton } from '@bizone/ui-bundle/esm/IconButton';

import './Panel.scss';
import { Tooltip } from 'antd';

export default function Panel({ config, selectedTab }) {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSection = useCallback(() => {
    setIsOpen(!isOpen);
  }, [setIsOpen, isOpen]);

  const getReportsPanelClass = useCallback(() => {
    const reportsPanelClass = ['ReportsPanel'];
    if (!isOpen) {
      reportsPanelClass.push('ReportsPanel_closed');
    }
    return reportsPanelClass.join(' ');
  }, [isOpen]);

  return (
    <div className={getReportsPanelClass()}>
      <div className="ReportsPanelHeader">
        <IconButton
          basic
          icon="angle-right"
          cls={`PanelIcon${isOpen ? ' PanelIcon_active' : ''}`}
          onClick={toggleSection}
        />
      </div>
      <div>
        <div className="TabItems-Container">
          {Object.entries(config).map(([key, { icon, tab }]) => (
            <Link
              key={key}
              to={`/reports/${key}`}
              className={`TabItem${
                selectedTab === key ? ' TabItem_active' : ''
              }`}
            >
              <div className="Row">
                <Tooltip title={!isOpen && tab}>
                  <div className="RowWithIcon">
                    <Icon className="TabItem-Icon" size={24} glyph={icon} />
                  </div>
                </Tooltip>
                <span className="TabItem-Text">{tab}</span>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
