import React, { FC, useCallback, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { useHistory, useLocation } from 'react-router-dom';

import styled from 'styled-components';

import { OverlaySpinner, Heading, Flex } from 'combinezone/core';

import LogSourcesCard from 'components/LogSources/LogSourcesCard';
import {
  LogSourcesLocalSaveFiltersProps,
  RootState,
  LogSourcesListProps,
} from 'components/LogSources/LogSourcesList/types';
import { fetchMore } from 'store/reducers/logSources/actions';

import { localSaveFilters } from '../../../store/reducers/filters/actions';

const InfiniteScrollWrapper = styled(InfiniteScroll)`
  max-width: 1920px;
  min-width: 1280px;
  width: 100%;
  padding-top: 2px;
`;

const NoData = styled(Flex)`
  padding: 96px 0 16px;
`;

const LogSourcesList: FC<LogSourcesListProps> = ({ entity }) => {
  const dispatch = useDispatch();

  const { fetching, fetchingTotal, hasMore, sources } = useSelector(
    (state: RootState) => state.logSources,
  );
  const { ordering, search, selected } = useSelector(
    (state: RootState) => state.filters[entity],
  );

  const archiveLocalSaveFilters = useCallback(
    (prop: LogSourcesLocalSaveFiltersProps) => dispatch(localSaveFilters(prop)),
    [dispatch],
  );

  const archiveFetchMore = useCallback(() => dispatch(fetchMore()), [dispatch]);

  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    archiveLocalSaveFilters({
      entity,
      history,
      pathname,
    });
  }, [
    archiveLocalSaveFilters,
    history,
    pathname,
    selected,
    search,
    ordering,
    entity,
  ]);

  const loadMore = useCallback(() => {
    if (hasMore) {
      archiveFetchMore();
    }
  }, [archiveFetchMore, hasMore]);

  return (
    <>
      {(fetching || fetchingTotal) && <OverlaySpinner isFullscreen={false} />}

      {!!sources.length && (
        <InfiniteScrollWrapper
          pageStart={1}
          initialLoad={false}
          loadMore={loadMore}
          hasMore={fetching ? false : hasMore}
          useWindow={false}
        >
          {sources.map((source) => (
            <LogSourcesCard source={source} key={source.id} />
          ))}
        </InfiniteScrollWrapper>
      )}

      {!sources.length && !hasMore && (
        <NoData>
          <Heading size="md">{I18n.t('logSources.noData')}</Heading>
        </NoData>
      )}
    </>
  );
};

export default LogSourcesList;

LogSourcesList.displayName = 'LogSourcesList';
