import React, { FC, useEffect, useState } from 'react';
import { Translate } from 'react-redux-i18n';

import { Node, Dropdown, Text } from 'combinezone/core';

import { SelectProps } from '../../../types';
import Tag from '../Tag';

const Select: FC<SelectProps> = ({
  filter,
  initIsOpen = false,
  setFilter,
  settings: { choices = [], label },
  value = [],
}) => {
  const [select, setSelect] = useState({ key: '', text: '' });

  const onSelect = ({ key, text }: { key: string; text: string }) => {
    if (key !== select.key) {
      setSelect({ key, text });
      setFilter(filter, key);
    }
  };

  useEffect(() => {
    if (value !== undefined && choices?.length !== 0) {
      const valueText = Array.isArray(value) ? value[0] : value;
      const initValue = choices.find((el) => el.key === valueText) || {
        key: '',
        text: valueText,
      };
      setSelect(initValue);
    }
  }, [choices]);

  const menu = ({ onClose }: { onClose: () => void }) =>
    choices.length === 0 ? (
      <Node testId="Node_NotFound" className="Dropdown-List">
        <Text>
          <Translate value="filterPanel.filter.notFound" />
        </Text>
      </Node>
    ) : (
      <div className="Dropdown-List">
        {choices.map(({ key, text }) => (
          <Node
            key={key}
            testId={`Node_Choice_${key}`}
            accent={key === select.key ? 'active' : undefined}
            onClick={() => {
              onSelect({ key, text });
              onClose();
            }}
          >
            <Text>{text}</Text>
          </Node>
        ))}
      </div>
    );

  const tagText = select.text || (
    <Translate value="filterPanel.filter.notSelected" />
  );

  return (
    <Dropdown content={menu} position="bottom-left" defaultIsOpen={initIsOpen}>
      {({ isOpen }) => (
        <Tag
          label={label}
          filter={filter}
          text={tagText}
          setFilter={setFilter}
          isActive={isOpen}
        />
      )}
    </Dropdown>
  );
};

export default Select;
