import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { Redirect, useParams } from 'react-router-dom';

import { Block } from '@bizone/ui-bundle/esm/Block';
import { Button } from '@bizone/ui-bundle/esm/Button';
import { Header } from '@bizone/ui-bundle/esm/Header';

import { Layout, ListSection } from '@common/soc-react-kit';

import Versions from '../../containers/InstructionPage-VersionsContainer';
import {
  INSTRUCTIONS_DESCRIPTION,
  INSTRUCTIONS_ENDPOINT,
  INSTRUCTIONS_NAME,
} from '../../services/api';
import { instructionFormatter } from '../../utils/helpers';
import Anchors from '../Anchors';
import Back from '../Back';
import DocumentAnotherLanguages from '../DocumentAnotherLanguages';
import { TitleComponent } from '../TitleComponent';

import Description from './Description';

import './InstructionPage.scss';

const { getAttachments, mainInfoFormatter } = instructionFormatter;

export function InstructionPage({
  closeInstructionCard,
  downloadFile,
  fetchError,
  fetchInstructionCard,
  instruction,
  locale,
}) {
  const { id } = useParams();
  const [hasSecondDocument, setHasSecondDocument] = useState(true);

  const name =
    instruction[INSTRUCTIONS_NAME] || I18n.t('instructionsCard.noTitle');

  const anchors = [
    {
      title: <Translate value="instructionsCard.mainBlockTitle" />,
      id: 'ins-main-info',
    },
    {
      title: <Translate value="instructionsCard.instruction" />,
      id: 'ins-instruction',
    },
  ];

  const listData = useMemo(
    () => ({
      mainInfo: {
        ...mainInfoFormatter(instruction).mainInfo,
        [I18n.t('instructionsCard.attachments')]: getAttachments(instruction),
        [I18n.t('documentationCardBase.inAnotherLanguage')]:
          hasSecondDocument ? (
            <DocumentAnotherLanguages
              type="instruction"
              currentId={Number(id)}
              document={instruction.document}
              setHasSecondDocument={setHasSecondDocument}
            />
          ) : null,
      },
      locale,
    }),
    [hasSecondDocument, id, instruction, locale],
  );

  const fetchInstruction = useCallback(() => {
    fetchInstructionCard(id);
  }, [fetchInstructionCard, id]);

  const downloadPDF = useCallback(() => {
    downloadFile(`${INSTRUCTIONS_ENDPOINT}${id}/download_pdf/`, `${name}.pdf`);
  }, [downloadFile, id, name]);

  useEffect(() => {
    fetchInstruction();
  }, [fetchInstruction]);

  useEffect(() => {
    return closeInstructionCard;
  }, [closeInstructionCard]);

  if (fetchError) {
    return <Redirect to="/docs/instructions" />;
  }

  return (
    <React.Fragment>
      <TitleComponent title={name} />
      <Layout.BreadCrumb>
        <Back to="/docs/instructions"> {name}</Back>
        <Button basic leftIcon="file-download" onClick={downloadPDF}>
          <Translate value="documentationCardBase.downloadPDF" />
        </Button>
      </Layout.BreadCrumb>
      <Layout.Container
        dual
        id="InstructionCardContainer"
        className="instruction-card"
      >
        <Layout.Content>
          <Anchors
            key={anchors.length}
            anchors={anchors}
            containerId="InstructionCardContainer"
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flexBasis: '100%',
              marginLeft: 250,
            }}
          >
            <section id="ins-main-info">
              <Block
                collapsible
                title={
                  <Header size={16}>
                    <Translate value="instructionsCard.mainBlockTitle" />
                  </Header>
                }
              >
                <Block.Content cls="mainInfo">
                  <div>
                    <ListSection
                      header={<Translate value="instructionsCard.mainInfo" />}
                      data={listData.mainInfo}
                    />
                  </div>
                </Block.Content>
              </Block>
            </section>
            <section id="ins-instruction">
              <Block
                collapsible
                title={
                  <Header size={16}>
                    <Translate value="instructionsCard.instruction" />
                  </Header>
                }
                headerControls={
                  <div>
                    <Versions id={id} />
                  </div>
                }
              >
                <Block.Content>
                  {instruction[INSTRUCTIONS_DESCRIPTION] ? (
                    <Description
                      more={I18n.t('instructionsCard.more')}
                      less={I18n.t('instructionsCard.less')}
                      value={instruction[INSTRUCTIONS_DESCRIPTION]}
                    />
                  ) : (
                    I18n.t('instructionsCard.noDescription')
                  )}
                </Block.Content>
              </Block>
            </section>
          </div>
        </Layout.Content>
      </Layout.Container>
    </React.Fragment>
  );
}
