import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';

import { IconButton } from '@bizone/ui-bundle/esm/IconButton';

import { bemCls } from '@common/soc-react-kit';

import { getIsMounted } from '../../store/reducers';

import './Back.scss';

export default function BackComponent({ children, to, uppercase = false }) {
  const { goBack } = useBack(to);

  return (
    <div className={bemCls('Back', { uppercase })}>
      <IconButton
        basic
        icon="angle-left"
        color="#999999"
        size={24}
        onClick={goBack}
      />
      <span>{children}</span>
    </div>
  );
}

export function useBack(defaultUrl = '/') {
  const lastLocation = useLastLocation();
  const history = useHistory();
  const isMounted = useSelector(getIsMounted);

  const canGoBack = !isMounted && lastLocation;

  const goBack = useCallback(() => {
    if (canGoBack) {
      history.goBack();
    } else {
      history.push(defaultUrl);
    }
  }, [canGoBack, history, defaultUrl]);

  return { goBack, canGoBack };
}
