import * as React from 'react';

import { Dropdown } from '@bizone/ui-bundle/esm/Dropdown';
import { Icon } from '@bizone/ui-bundle/esm/Icon';
import { Node } from '@bizone/ui-bundle/esm/Node';
import { Tooltip } from '@bizone/ui-bundle/esm/Tooltip';

import './SortControl.scss';

export default class SortControl extends React.Component {
  constructor(props) {
    super(props);
    const [type, order] = Object.entries(props.ordering)[0];

    this.state = {
      type,
      order,
      isOpen: false,
    };

    this.dropdownProps = {
      position: 'right',
      fitted: 200,
      onShow: () => this.setState({ isOpen: true }),
      onHide: () => this.setState({ isOpen: false }),
    };
  }

  onSelect = (params) => {
    this.setState(
      {
        ...params,
      },
      () => this.props.setSort({ [this.state.type]: this.state.order }),
    );
  };

  render() {
    const { settings } = this.props;
    const { order, type } = this.state;
    const dropdownContent = Object.entries(settings).map(
      ([type, { label }]) => (
        <Node
          selected={this.state.type === type}
          key={type}
          onClick={this.onSelect.bind(null, { type })}
        >
          {label}
        </Node>
      ),
    );

    return (
      <div className="SortControl">
        <Tooltip
          content={settings[type] ? settings[type][order] : ''}
          delay={500}
          inverted
        >
          <div
            className="SortControl-Toggle IconButton"
            onClick={this.onSelect.bind(null, {
              order: order === 'DESC' ? 'ASC' : 'DESC',
            })}
          >
            <Icon
              cls="IconButton"
              glyph={`reverse-sort-${order === 'DESC' ? 'asc' : 'desc'}`}
              size={24}
            />
          </div>
        </Tooltip>
        <Dropdown content={dropdownContent} {...this.dropdownProps}>
          <div className="SortControl-Dropdown IconButton">
            <Icon glyph="sort-down" size={16} />
          </div>
        </Dropdown>
      </div>
    );
  }
}
