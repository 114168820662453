import React, { FC } from 'react';
import { I18n } from 'react-redux-i18n';

import styled from 'styled-components';

import { Flex, Text } from 'combinezone/core';

import { LogSourcesCardEpsInfoTooltipProps } from 'components/LogSources/LogSourcesCard/components/LogSourcesCardEpsInfo/components/LogSourcesCardEpsInfoTooltip/types';

const TextTooltip = styled(Text).attrs(({ theme }) => ({
  color: theme.components.tooltip.colors.default.text,
}))``;

const LogSourcesCardEpsInfoTooltip: FC<LogSourcesCardEpsInfoTooltipProps> = ({
  avgValue,
  maxValue,
  part,
  testId,
}) => {
  const testIdTooltip = `${testId}-tooltip`;

  return (
    <Flex direction="column" gap="5px" testId={`${testIdTooltip}-node-${part}`}>
      <TextTooltip fontWeight="medium">
        {I18n.t(`logSourcesCard.value.${part}`)}
      </TextTooltip>
      <Flex gap="10px">
        <TextTooltip fontWeight="medium">{avgValue}</TextTooltip>
        <TextTooltip> {I18n.t('logSourcesCard.value.avg')}</TextTooltip>
      </Flex>
      <Flex gap="10px">
        <TextTooltip fontWeight="medium">{maxValue}</TextTooltip>
        <TextTooltip> {I18n.t('logSourcesCard.value.max')}</TextTooltip>
      </Flex>
    </Flex>
  );
};

export default LogSourcesCardEpsInfoTooltip;

LogSourcesCardEpsInfoTooltip.displayName = 'LogSourcesCardEpsInfoTooltip';
