import { AnyAction } from 'redux';

import * as types from './actionTypes';
import {
  FetchUsersFailureType,
  FetchUsersRequestType,
  FetchUsersSuccessType,
} from './types';

export const isFetchUsersRequest = (
  action: AnyAction,
): action is FetchUsersRequestType => {
  return action.type === types.USERS_REQUEST;
};

export const isFetchUsersFail = (
  action: AnyAction,
): action is FetchUsersFailureType => {
  return action.type === types.USERS_FAIL;
};

export const isFetchUsersSuccess = (
  action: AnyAction,
): action is FetchUsersSuccessType => {
  return action.type === types.USERS_SUCCESS;
};
