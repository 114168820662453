import React, { FC } from 'react';
import { I18n } from 'react-redux-i18n';

import styled from 'styled-components';

import { Flex, Text } from 'combinezone/core';

import { LogSourcesCardInfoProps } from 'components/LogSources/LogSourcesCard/components/LogSourcesCardInfo/types';

const LogSourcesInfoWrapper = styled(Flex)`
  gap: 8px;
  flex-direction: column;
  margin-top: 8px;
`;

const LogSourcesInfoElement = styled(Flex)`
  gap: 10px;
`;

const LogSourcesCardInfo: FC<LogSourcesCardInfoProps> = ({
  source,
  testId,
}) => {
  const testIdInfo = `${testId}-info`;

  return (
    <LogSourcesInfoWrapper testId={testIdInfo}>
      <LogSourcesInfoElement testId={`${testIdInfo}-first-element`}>
        <Text variant="secondary" testId={`${testIdInfo}-first-element-title`}>
          {I18n.t('logSourcesCard.source')}
        </Text>
        <Text testId={`${testIdInfo}-first-element-type`}>
          {source.log_source_type ? source.log_source_type.name : '—'}
        </Text>
      </LogSourcesInfoElement>
      <LogSourcesInfoElement testId={`${testIdInfo}-second-element`}>
        <Text variant="secondary" testId={`${testIdInfo}-second-element-title`}>
          {I18n.t('logSourcesCard.eventCollector')}
        </Text>
        <Text testId={`${testIdInfo}-second-element-type`}>
          {source.collector
            ? `${source.collector.name} ${
                source.collector.ip ? `- ${source.collector.ip}` : ''
              }`
            : '—'}
        </Text>
      </LogSourcesInfoElement>
    </LogSourcesInfoWrapper>
  );
};

export default LogSourcesCardInfo;

LogSourcesCardInfo.displayName = 'LogSourcesCardInfo';
