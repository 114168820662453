import React, { SyntheticEvent, forwardRef } from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import { IconButton, Text, Flex } from 'combinezone/core';
import { Close } from 'combinezone/icons';

import { TagProps } from '../../../types';

const Tag = forwardRef<HTMLDivElement, TagProps>(
  ({ filter, isActive, label, onClick, setFilter, text, ...rest }, ref) => {
    const removeTag = (e: SyntheticEvent): void => {
      e.stopPropagation();
      setFilter(filter);
    };

    return (
      <Flex
        ref={ref}
        className={isActive ? 'Filter Filter-Active' : 'Filter'}
        {...rest}
        {...(onClick && { onMouseDown: onClick })}
      >
        <Text className="Filter-Label">{label}:</Text>
        <Text isClipped numOfLines={1}>
          {text}
        </Text>
        <IconButton
          className="Filter-RemoveButtonIcon"
          testId={`Filter_RemoveButton_${filter}`}
          icon={Close}
          tooltip={{
            content: I18n.t('filterPanel.removeFilter'),
            position: 'top',
          }}
          onClick={removeTag}
        />
      </Flex>
    );
  },
);

export default Tag;
