export const FIRST_FETCH_REQUEST = '@@ruleList/FIRST_FETCH_REQUEST';
export const FIRST_FETCH_SUCCESS = '@@ruleList/FIRST_FETCH_SUCCESS';
export const FIRST_FETCH_FAIL = '@@ruleList/FIRST_FETCH_FAIL';

export const FETCH_MORE_REQUEST = '@@ruleList/FETCH_MORE_REQUEST';
export const FETCH_MORE_SUCCESS = '@@ruleList/FETCH_MORE_SUCCESS';
export const FETCH_MORE_FAIL = '@@ruleList/FETCH_MORE_FAIL';

export const FETCH_ANALYTICS_MITRE_REQUEST =
  '@@ruleList/FETCH_ANALYTICS_MITRE_REQUEST';
export const FETCH_ANALYTICS_MITRE_SUCCESS =
  '@@ruleList/FETCH_ANALYTICS_MITRE_SUCCESS';
export const FETCH_ANALYTICS_MITRE_FAIL =
  '@@ruleList/FETCH_ANALYTICS_MITRE_FAIL';

export const FETCH_ANALYTICS_REQUEST = '@@ruleList/FETCH_ANALYTICS_REQUEST';
export const FETCH_ANALYTICS_SUCCESS = '@@ruleList/FETCH_ANALYTICS_SUCCESS';
export const FETCH_ANALYTICS_FAIL = '@@ruleList/FETCH_ANALYTICS_FAIL';

export const GET_TOTAL_COUNT_REQUEST = '@@ruleList/GET_TOTAL_COUNT_REQUEST';
export const GET_TOTAL_COUNT_SUCCESS = '@@ruleList/GET_TOTAL_COUNT_SUCCESS';
export const GET_TOTAL_COUNT_FAIL = '@@ruleList/GET_TOTAL_COUNT_FAIL';

export const SET_SEARCH = '@@ruleList/SET_SEARCH';

export const SET_FILTERS = '@@ruleList/SET_FILTERS';

export const SET_SORT = '@@ruleList/SET_SORT';

export const SELECT_TECHNIQUE = '@@ruleList/SELECT_TECHNIQUE';
