import headerLogoDefault from 'static/images/default/header-logo.svg';
import loginLogoDefault from 'static/images/default/new-login-logo.svg';
import headerLogoTtc from 'static/images/ttc/header-logo.svg';
import loginLogoTtc from 'static/images/ttc/login-logo.svg';

export const loginLogo = {
  default: loginLogoDefault,
  TTC: loginLogoTtc,
};

export const headerLogo = {
  default: headerLogoDefault,
  TTC: headerLogoTtc,
};
