import { FetchSensorsResponseType, SensorGroupType } from '../types';

type NormalizeSensorsType = (
  response: FetchSensorsResponseType,
) => SensorGroupType[];

export const normalizeSensors: NormalizeSensorsType = (response) => {
  if (!response.sensor_tuples) {
    return [];
  }

  const result = response.sensor_tuples.map((group) => {
    const sourceCard = group.reduce<SensorGroupType>(
      (sourceGroup, { sensor, sensor_module, sensor_type }) => {
        sourceGroup.sensors.push(sensor);
        sourceGroup.modules.push(sensor_module);
        sourceGroup.types.push(sensor_type);
        return sourceGroup;
      },
      {
        sensors: [],
        modules: [],
        types: [],
      },
    );

    return sourceCard;
  });

  return result;
};
