import { connect } from 'react-redux';

import { DownloadNotification } from '../components/DownloadNotification';
import { downloadFile, remove } from '../store/reducers/fileDownloader/actions';

const mapStateToProps = (state) => ({
  downloads: state.fileDownloader.downloadList,
});

const mapDispatchToProps = {
  downloadFile,
  remove,
};

const DownloadNotificationContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DownloadNotification);
export default DownloadNotificationContainer;
