import { AnyAction } from 'redux';
import { apiMiddleware, isRSAA, RSAAAction } from 'redux-api-middleware';

import brandTransform from 'branding/brandTransform';

import { CreateRSAATokenMiddlewareType } from './types';

export const isRSAAAction = (
  action: AnyAction | RSAAAction,
): action is RSAAAction => {
  return isRSAA(action);
};

export const rsaaMiddleware: CreateRSAATokenMiddlewareType = ({
  dispatch,
  getState,
}) => {
  const middleware = apiMiddleware({
    dispatch,
    getState,
  });

  return (next) => (action) => {
    if (!isRSAAAction(action)) {
      return brandTransform(next)(action);
    }

    return middleware(brandTransform(next))(action);
  };
};
