import { connect } from 'react-redux';

import { TechniqueCell } from '../components/MitreWidget/TechniqueCell';
import { currentLocale } from '../store/reducers';

const mapStateToProps = (state) => ({
  filters: state.filters.incidents.selected,
  locale: currentLocale(state),
});

export default connect(mapStateToProps)(TechniqueCell);
