import { createTheme } from 'combinezone/theme/default/createTheme';
import { DefaultBasis } from 'combinezone/theme/default/types';
import { basis as combinezoneBasis } from 'combinezone/theme/old/light/basis';

import {
  a14,
  a30,
  a40,
  a6,
  a60,
  a8,
  brand,
  constant,
  legend,
  typography,
} from 'branding/themes/global';

const basis = {
  ...combinezoneBasis,
  typography,
};

export const neutral = {
  base: `hsl(${brand.hsl.zero}, 0%, 100%)`,
  primary: `hsl(${brand.hsl.neutral}, 0%, 30%)`,
  secondary: `hsl(${brand.hsl.neutral}, 0%, 60%)`,
  faded: `hsl(${brand.hsl.neutral}, 0%, 80%)`,
  divider: `hsl(${brand.hsl.neutral}, 0%, 90%)`,
  pastel: {
    saturated: `hsl(${brand.hsl.neutral}, 0%, 94%)`,
    disabled: `hsl(${brand.hsl.neutral}, 0%, 95%)`,
    zebra: `hsl(${brand.hsl.neutral}, 0%, 96%)`,
    smoke: `hsl(${brand.hsl.neutral}, 0%, 98%)`,
  },
  alpha: {
    saturated: `hsla(${brand.hsl.neutral}, 0%, 0%, ${a60})`,
    base: `hsla(${brand.hsl.neutral}, 0%, 0%, ${a40})`,
    faded: `hsla(${brand.hsl.neutral}, 0%, 0%, ${a14})`,
    'smoke-saturated': `hsla(${brand.hsl.neutral}, 0%, 0%, ${a8})`,
    'smoke-base': `hsla(${brand.hsl.neutral}, 0%, 0%, ${a6})`,
  },
  gradient: {
    zebra: `linear-gradient(90deg, hsla(${brand.hsl.neutral}, 0%, 96%, 0.001) 0%, hsl(${brand.hsl.neutral}, 0%, 96%) 100%)`,
    base: `linear-gradient(90deg, hsla(${brand.hsl.neutral}, 0%, 100%, 0.001) 0%, hsl(${brand.hsl.neutral}, 0%, 100%) 100%)`,
    saturated: `linear-gradient(90deg, hsla(${brand.hsl.neutral}, 0%, 94%, 0.001) 0%, hsl(${brand.hsl.neutral}, 0%, 94%) 100%)`,
  },
};

export const active = {
  saturated:
    process.env.REACT_APP_PRIMARY_HOVER_COLOR ||
    `hsl(${brand.hsl.active}, 80%, 41%)`,
  base:
    process.env.REACT_APP_PRIMARY_COLOR || `hsl(${brand.hsl.active}, 73%, 47%)`,
  faded:
    process.env.REACT_APP_DISABLED_PRIMARY_COLOR ||
    `hsl(${brand.hsl.active}, 67%, 80%)`,
  pastel: {
    saturated:
      process.env.REACT_APP_SELECTED_HOVER_COLOR ||
      `hsl(${brand.hsl.active}, 63%, 90%)`,
    base:
      process.env.REACT_APP_SELECT_COLOR ||
      `hsl(${brand.hsl.active}, 64%, 94%)`,
  },
  alpha: {
    base:
      process.env.REACT_APP_INPUT_SHADOW_BOX ||
      `hsla(${brand.hsl.active}, 73%, 47%, ${a30})`,
  },
  gradient: {
    saturated: `linear-gradient(90deg, hsla(${brand.hsl.active}, 63%, 90%, 0.001) 0%, hsl(${brand.hsl.active}, 63%, 90%) 100%)`,
    base: `linear-gradient(90deg, hsla(${brand.hsl.active}, 64%, 94%, 0.001) 0%, hsl(${brand.hsl.active}, 64%, 94%) 100%)`,
  },
};

export const positive = {
  saturated: `hsl(${brand.hsl.positive}, 52%, 45%)`,
  base: `hsl(${brand.hsl.positive}, 45%, 57%)`,
  faded: `hsl(${brand.hsl.positive}, 49%, 79%)`,
  pastel: {
    saturated: `hsl(${brand.hsl.positive}, 41%, 90%)`,
    base: `hsl(${brand.hsl.positive}, 41%, 93%)`,
  },
  gradient: {
    saturated: `linear-gradient(90deg, hsla(${brand.hsl.positive}, 41%, 95%, 0.001) 0%, hsl(${brand.hsl.positive}, 41%, 95%) 100%)`,
    base: `linear-gradient(90deg, hsla(${brand.hsl.positive}, 41%, 95%, 0.001) 0%, hsl(${brand.hsl.positive}, 41%, 95%) 100%)`,
  },
};

export const attention = {
  saturated: `hsl(${brand.hsl.attention}, 93%, 48%)`,
  base: `hsl(${brand.hsl.attention}, 85%, 60%)`,
  faded: `hsl(${brand.hsl.attention}, 85%, 82%)`,
  pastel: {
    saturated: `hsl(${brand.hsl.attention}, 83%, 91%)`,
    base: `hsl(${brand.hsl.attention}, 88%, 94%)`,
    smoke: `hsl(${brand.hsl.attention}, 71%, 97%)`,
  },
  gradient: {
    saturated: `linear-gradient(90deg, hsla(${brand.hsl.attention}, 88%, 94%, 0.001) 0%, hsl(${brand.hsl.attention}, 88%, 94%) 100%)`,
    base: `linear-gradient(90deg, hsla(${brand.hsl.attention}, 88%, 94%, 0.001) 0%, hsl(${brand.hsl.attention}, 88%, 94%) 100%)`,
  },
};

export const warning = {
  saturated: `hsl(${brand.hsl.warning}, 84%, 52%)`,
  base: `hsl(${brand.hsl.warning}, 84%, 63%)`,
  faded: `hsl(${brand.hsl.warning}, 84%, 83%)`,
  pastel: {
    saturated: `hsl(${brand.hsl.warning}, 82%, 91%)`,
    base: `hsl(${brand.hsl.warning}, 87%, 94%)`,
  },
  gradient: {
    saturated: `linear-gradient(90deg, hsla(${brand.hsl.warning}, 82%, 91%, 0.001) 0%, hsl(${brand.hsl.warning}, 82%, 91%) 100%)`,
    base: `linear-gradient(90deg, hsla(${brand.hsl.warning}, 87%, 94%, 0.001) 0%, hsl(${brand.hsl.warning}, 87%, 94%) 100%)`,
  },
};

export const critical = {
  saturated: `hsl(${brand.hsl.critical}, 78%, 49%)`,
  base: `hsl(${brand.hsl.critical}, 77%, 61%)`,
  faded: `hsl(${brand.hsl.critical}, 78%, 82%)`,
  pastel: {
    saturated: `hsl(${brand.hsl.critical}, 74%, 91%)`,
    base: `hsl(${brand.hsl.critical}, 75%, 94%)`,
    smoke: `hsl(${brand.hsl.critical}, 80%, 98%)`,
  },
  alpha: {
    base: `hsla(${brand.hsl.critical}, 77%, 61%, ${a30})`,
  },
  gradient: {
    saturated: `linear-gradient(90deg, hsla(${brand.hsl.critical}, 74%, 91%, 0.001) 0%, hsl(${brand.hsl.critical}, 74%, 91%) 100%)`,
    base: `linear-gradient(90deg, hsla(${brand.hsl.critical}, 75%, 94%, 0.001) 0%, hsl(${brand.hsl.critical}, 75%, 94%) 100%)`,
  },
};

export const menu = {
  modal: {
    value: {
      color: '#00000080',
      type: 'dropShadow',
      x: 0,
      y: 0,
      blur: 16,
      spread: 0,
    },
    type: 'boxShadow',
  },
  dropdown: {
    value: {
      color: '#00000033',
      type: 'dropShadow',
      x: 0,
      y: 0,
      blur: 8,
      spread: 4,
    },
    type: 'boxShadow',
  },
  popover: {
    value: {
      color: '#00000033',
      type: 'dropShadow',
      x: 0,
      y: 4,
      blur: 14,
      spread: 0,
    },
    type: 'boxShadow',
  },
};

export const block = {
  'hover-shadow': {
    value: {
      color: '#4d4d4d33',
      type: 'dropShadow',
      x: 0,
      y: 0,
      blur: 4,
      spread: 0,
    },
    type: 'boxShadow',
  },
  'navbar-subpanel-shadow': {
    value: {
      color: '#0000000f',
      type: 'innerShadow',
      x: 0,
      y: -2,
      blur: 0,
      spread: 0,
    },
    type: 'boxShadow',
  },
};

export const form = {
  'error-shadow': {
    value: {
      color: '#e0281b33',
      type: 'dropShadow',
      x: 0,
      y: 0,
      blur: 0,
      spread: 3,
    },
    type: 'boxShadow',
  },
  'warning-shadow': {
    value: {
      color: '#ebaf0933',
      type: 'dropShadow',
      x: 0,
      y: 0,
      blur: 0,
      spread: 2,
    },
    type: 'boxShadow',
  },
};

export const datagrid = {
  'column-header': {
    value: {
      color: '#e9e9e9',
      type: 'innerShadow',
      x: 0,
      y: -1,
      blur: 0,
      spread: 0,
    },
    type: 'boxShadow',
  },
};

export const button = {
  pressed: {
    value: {
      color: '#17212d66',
      type: 'innerShadow',
      x: 0,
      y: 0,
      blur: 8,
      spread: 0,
    },
    type: 'boxShadow',
  },
  'pressed-focus': {
    value: [
      {
        color: '#17212d4d',
        type: 'innerShadow',
        x: 0,
        y: 0,
        blur: 8,
        spread: 0,
      },
      {
        color: '#209ace4d',
        type: 'dropShadow',
        x: 0,
        y: 0,
        blur: 0,
        spread: 3,
      },
    ],
    type: 'boxShadow',
  },
};

export const node = {
  'indicator-attention': {
    value: {
      color: '#ebaf09',
      type: 'innerShadow',
      x: 4,
      y: 0,
      blur: 0,
      spread: 0,
    },
    type: 'boxShadow',
  },
  'indicator-critical': {
    value: {
      color: '#e0281b',
      type: 'innerShadow',
      x: 4,
      y: 0,
      blur: 0,
      spread: 0,
    },
    type: 'boxShadow',
  },
  'indicator-active': {
    value: {
      color: '#209ace',
      type: 'innerShadow',
      x: 4,
      y: 0,
      blur: 0,
      spread: 0,
    },
    type: 'boxShadow',
  },
  'indicator-positive': {
    value: {
      color: '#57ad37',
      type: 'innerShadow',
      x: 4,
      y: 0,
      blur: 0,
      spread: 0,
    },
    type: 'boxShadow',
  },
  'indicator-warning': {
    value: {
      color: '#eb6f1c',
      type: 'innerShadow',
      x: 4,
      y: 0,
      blur: 0,
      spread: 0,
    },
    type: 'boxShadow',
  },
};

export const basisLight: DefaultBasis = {
  zIndexes: basis.zIndexes,
  radius: basis.radius,
  typography: basis.typography,
  spacings: basis.spacings,
  colors: {
    base: {
      block: neutral.base,
      desk: neutral.pastel.smoke,
      active: active.base,
      positive: positive.base,
      attention: attention.base,
      warning: warning.base,
      critical: critical.base,
      navbar: {
        default: constant.saturated,
        active: constant.base,
      },
    },
    borders: {
      normal: neutral.faded,
      disabled: neutral.faded,
      divider: neutral.divider,
    },
    shadows: {
      ...basis.colors.shadows,
      focus: active.alpha.base,
      critical: critical.saturated,
    },
    text: {
      default: neutral.primary,
      secondary: neutral.secondary,
      placeholder: neutral.faded,
      inverted: constant.faded,
      light: constant.faded,
      dark: constant.base,
    },
    link: {
      normal: active.base,
      hover: active.saturated,
      disable: active.faded,
    },
    button: {
      normal: active.base,
      hover: active.saturated,
      disable: active.faded,
      secondary: {
        normal: neutral.pastel.saturated,
        disable: neutral.pastel.saturated,
        hover: `hsl(${brand.hsl.neutral}, 0%, 84%)`,
        pressedFocus: `hsl(${brand.hsl.neutral}, 0%, 84%)`,
      },
      transparent: {
        normal: neutral.pastel.saturated,
        hover: neutral.alpha.faded,
        pressedFocus: neutral.alpha.faded,
      },
    },
    icon: {
      normal: neutral.alpha.base,
      hover: neutral.alpha.saturated,
      disable: neutral.alpha.faded,
      contrast: {
        normal: constant.alpha.base,
        hover: constant.alpha.saturated,
        disable: constant.alpha.base,
      },
      active: {
        normal: active.base,
        hover: active.saturated,
        disable: active.faded,
      },
      attention: {
        normal: attention.base,
        hover: attention.saturated,
        disable: attention.faded,
      },
      warning: {
        normal: warning.base,
        hover: warning.saturated,
        disable: warning.faded,
      },
      critical: {
        normal: critical.base,
        hover: critical.saturated,
        disable: critical.faded,
      },
      positive: {
        normal: positive.base,
        hover: positive.saturated,
        disable: positive.faded,
      },
    },
    node: {
      normal: neutral.base,
      hover: neutral.pastel.saturated,
      zebra: neutral.pastel.zebra,
      select: active.pastel.base,
      selectHover: active.pastel.saturated,
      doubleSelected: active.pastel.saturated,
      doubleSelectedHover: active.pastel.saturated,
      critical: critical.pastel.base,
      criticalHover: critical.pastel.saturated,
      attention: attention.pastel.base,
      attentionHover: attention.pastel.saturated,
      warning: warning.pastel.base,
      warningHover: warning.pastel.saturated,
      positive: positive.pastel.base,
      positiveHover: positive.pastel.saturated,
    },
    tags: {
      plum: legend.plum,
      kelly: legend.kelly,
      stone: legend.stone,
      cornflower: legend.cornflower,
      lime: legend.lime,
      iron: legend.iron,
      olympic: legend.olympic,
      gold: legend.gold,
      steel: legend.steel,
      sky: legend.sky,
      tiger: legend.tiger,
      sea: legend.sea,
      candy: legend.candy,
      smoke: neutral.alpha['smoke-base'],
      smokeHover: neutral.alpha['smoke-saturated'],
    },
  },
};

export const defaultLight = createTheme(basisLight);
defaultLight.components.input.colors.background.disabled =
  neutral.pastel.disabled;
defaultLight.components.input.colors.shadow.focus = active.alpha.base;
defaultLight.components.input.colors.shadow.critical = critical.alpha.base;
defaultLight.components.input.colors.background.critical =
  critical.pastel.smoke;

defaultLight.components.textarea.colors.background.disabled =
  neutral.pastel.disabled;
defaultLight.components.textarea.colors.shadow.focus = active.alpha.base;
defaultLight.components.textarea.colors.shadow.critical = critical.alpha.base;
defaultLight.components.textarea.colors.background.critical =
  critical.pastel.smoke;

defaultLight.components.pinInput.colors.background.disabled =
  neutral.pastel.disabled;
defaultLight.components.pinInput.colors.shadow.focus = active.alpha.base;
defaultLight.components.pinInput.colors.shadow.critical = critical.alpha.base;
defaultLight.components.pinInput.colors.background.critical =
  critical.pastel.smoke;

defaultLight.components.select.input.colors.background.disabled =
  neutral.pastel.disabled;
defaultLight.components.select.input.colors.shadow.focus = active.alpha.base;
defaultLight.components.select.input.colors.shadow.critical =
  critical.alpha.base;
defaultLight.components.select.input.colors.background.critical =
  critical.pastel.smoke;
