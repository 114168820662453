export const FETCH_INC_CARD_REQUEST = '@@incCard/FETCH_INC_CARD_REQUEST';
export const FETCH_INC_CARD_SUCCESS = '@@incCard/FETCH_INC_CARD_SUCCESS';
export const FETCH_INC_CARD_FAIL = '@@incCard/FETCH_INC_CARD_FAIL';

export const FETCH_COMMENTS_REQUEST = '@@incCard/FETCH_COMMENTS_REQUEST';
export const FETCH_COMMENTS_SUCCESS = '@@incCard/FETCH_COMMENTS_SUCCESS';
export const FETCH_COMMENTS_FAILURE = '@@incCard/FETCH_COMMENTS_FAILURE';

export const ADD_COMMENT_REQUEST = '@@incCard/ADD_COMMENT_REQUEST';
export const ADD_COMMENT_SUCCESS = '@@incCard/ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_FAIL = '@@incCard/ADD_COMMENT_FAIL';

export const CLOSE_INC_CARD = '@@incCard/CLOSE_INC_CARD';
export const ADD_COMMENT_MODAL_SUCCESS = '@@incCard/ADD_COMMENT_MODAL_SUCCESS';

export const SEND_GOSSOPKA_REQUEST = '@@incCard/SEND_GOSSOPKA_REQUEST';
export const SEND_GOSSOPKA_SUCCESS = '@@incCard/SEND_GOSSOPKA_SUCCESS';
export const SEND_GOSSOPKA_FAILURE = '@@incCard/SEND_GOSSOPKA_FAILURE';

export const ADD_FEEDBACK_REQUEST = '@@incCard/ADD_FEEDBACK_REQUEST';
export const ADD_FEEDBACK_SUCCESS = '@@incCard/ADD_FEEDBACK_SUCCESS';
export const ADD_FEEDBACK_FAILURE = '@@incCard/ADD_FEEDBACK_FAILURE';

export const GET_AVAILABLE_ACTIONS_REQUEST =
  '@@incCard/GET_AVAILABLE_ACTIONS_REQUEST';
export const GET_AVAILABLE_ACTIONS_SUCCESS =
  '@@incCard/GET_AVAILABLE_ACTIONS_SUCCESS';
export const GET_AVAILABLE_ACTIONS_FAIL =
  '@@incCard/GET_AVAILABLE_ACTIONS_FAIL';

export const UPDATE_INC_CARD_REQUEST = '@@incCard/UPDATE_INC_CARD_REQUEST';
export const UPDATE_INC_CARD_SUCCESS = '@@incCard/UPDATE_INC_CARD_SUCCESS';
export const UPDATE_INC_CARD_FAIL = '@@incCard/UPDATE_INC_CARD_FAIL';

export const FETCH_INC_CARD_ALERTS_REQUEST =
  '@@incCard/FETCH_INC_CARD_ALERTS_REQUEST';
export const FETCH_INC_CARD_ALERTS_SUCCESS =
  '@@incCard/FETCH_INC_CARD_ALERTS_SUCCESS';
export const FETCH_INC_CARD_ALERTS_FAIL =
  '@@incCard/FETCH_INC_CARD_ALERTS_FAIL';

export const FETCH_INC_CARD_SELECTED_ALERT_REQUEST =
  '@@incCard/FETCH_INC_CARD_SELECTED_ALERT_REQUEST';
export const FETCH_INC_CARD_SELECTED_ALERT_SUCCESS =
  '@@incCard/FETCH_INC_CARD_SELECTED_ALERT_SUCCESS';
export const FETCH_INC_CARD_SELECTED_ALERT_FAIL =
  '@@incCard/FETCH_INC_CARD_SELECTED_ALERT_FAIL';

export const FETCH_INC_CARD_SELECTED_ALERT_GROUPED_DATA_REQUEST =
  '@@incCard/FETCH_INC_CARD_SELECTED_ALERT_GROUPED_DATA_REQUEST';
export const FETCH_INC_CARD_SELECTED_ALERT_GROUPED_DATA_SUCCESS =
  '@@incCard/FETCH_INC_CARD_SELECTED_ALERT_GROUPED_DATA_SUCCESS';
export const FETCH_INC_CARD_SELECTED_ALERT_GROUPED_DATA_FAIL =
  '@@incCard/FETCH_INC_CARD_SELECTED_ALERT_GROUPED_DATA_FAIL';

export const FETCH_INC_CARD_SELECTED_ALERT_TREE_REQUEST =
  '@@incCard/FETCH_INC_CARD_SELECTED_ALERT_TREE_REQUEST';
export const FETCH_INC_CARD_SELECTED_ALERT_TREE_SUCCESS =
  '@@incCard/FETCH_INC_CARD_SELECTED_ALERT_TREE_SUCCESS';
export const FETCH_INC_CARD_SELECTED_ALERT_TREE_FAIL =
  '@@incCard/FETCH_INC_CARD_SELECTED_ALERT_TREE_FAIL';

export const FETCH_INC_CARD_SELECTED_ALERT_SYMPTOMATIC_REQUEST =
  '@@incCard/FETCH_INC_CARD_SELECTED_ALERT_SYMPTOMATIC_REQUEST';
export const FETCH_INC_CARD_SELECTED_ALERT_SYMPTOMATIC_SUCCESS =
  '@@incCard/FETCH_INC_CARD_SELECTED_ALERT_SYMPTOMATIC_SUCCESS';
export const FETCH_INC_CARD_SELECTED_ALERT_SYMPTOMATIC_FAIL =
  '@@incCard/FETCH_INC_CARD_SELECTED_ALERT_SYMPTOMATIC_FAIL';

export const UPDATE_ACTION_INCIDENT_REQUEST =
  '@@incCard/UPDATE_ACTION_INCIDENT_REQUEST';
export const UPDATE_ACTION_INCIDENT_SUCCESS =
  '@@incCard/UPDATE_ACTION_INCIDENT_SUCCESS';
export const UPDATE_ACTION_INCIDENT_FAIL =
  '@@incCard/UPDATE_ACTION_INCIDENT_FAIL';

export const INC_CARD_SELECT_ALERT = '@@incCard/INC_CARD_SELECT_ALERT';
export const INC_CARD_SET_ALERT_SEARCH = '@@incCard/INC_CARD_SET_ALERT_SEARCH';

export const FETCH_INC_CARD_ALERTS_FIRST_PAGE_REQUEST =
  '@@incCard/FETCH_INC_CARD_ALERTS_FIRST_PAGE_REQUEST';
export const FETCH_INC_CARD_ALERTS_FIRST_PAGE_SUCCESS =
  '@@incCard/FETCH_INC_CARD_ALERTS_FIRST_PAGE_SUCCESS';
export const FETCH_INC_CARD_ALERTS_FIRST_PAGE_FAIL =
  '@@incCard/FETCH_INC_CARD_ALERTS_FIRST_PAGE_FAIL';

export const FETCH_INC_CARD_ALERTS_MORE_PAGE_REQUEST =
  '@@incCard/FETCH_INC_CARD_ALERTS_MORE_PAGE_REQUEST';
export const FETCH_INC_CARD_ALERTS_MORE_PAGE_SUCCESS =
  '@@incCard/FETCH_INC_CARD_ALERTS_MORE_PAGE_SUCCESS';
export const FETCH_INC_CARD_ALERTS_MORE_PAGE_FAIL =
  '@@incCard/FETCH_INC_CARD_ALERTS_MORE_PAGE_FAIL';

export const FETCH_INC_CARD_CMDB_ASSETS_REQUEST =
  '@@incCard/FETCH_INC_CARD_CMDB_ASSETS_REQUEST';
export const FETCH_INC_CARD_CMDB_ASSETS_SUCCESS =
  '@@incCard/FETCH_INC_CARD_CMDB_ASSETS_SUCCESS';
export const FETCH_INC_CARD_CMDB_ASSETS_FAIL =
  '@@incCard/FETCH_INC_CARD_CMDB_ASSETS_FAIL';

export const FETCH_INC_CARD_MORE_CMDB_ASSETS_REQUEST =
  '@@incCard/FETCH_INC_CARD_MORE_CMDB_ASSETS_REQUEST';
export const FETCH_INC_CARD_MORE_CMDB_ASSETS_SUCCESS =
  '@@incCard/FETCH_INC_CARD_MORE_CMDB_ASSETS_SUCCESS';
export const FETCH_INC_CARD_MORE_CMDB_ASSETS_FAIL =
  '@@incCard/FETCH_INC_CARD_MORE_CMDB_ASSETS_FAIL';
