import { IsNeedTooltipTypes, TransformMitreTypes } from './types';

export const isNeedTooltip = ({ maxLength, text }: IsNeedTooltipTypes) => {
  return text?.length ? (text.length > maxLength ? text : false) : false;
};

export const transformMitre = ({
  allTechniques,
  mitre,
}: TransformMitreTypes) => {
  if (
    Object.keys(mitre).length <= 0 ||
    Object.keys(allTechniques).length <= 0
  ) {
    return [];
  }
  const techniquesSet = new Set<string>();

  Object.values(mitre).forEach((techniques) =>
    techniques.forEach((id) => techniquesSet.add(id)),
  );

  const techniques = Array.from(techniquesSet)
    .map((id) => allTechniques[id])
    .filter(Boolean);

  if (techniques.length > 2) {
    const shortTechniques = techniques.slice(0, 3);
    techniques.length !== 3 &&
      shortTechniques.push({
        fullName: `+${techniques.length - 3}`,
        technique_id: 'techniqueExtraLength',
        extraLength: true,
      });
    return shortTechniques;
  }

  return techniques;
};
