import * as React from 'react';
import Helmet from 'react-helmet';

import { socProperties } from 'utils/brending';

export const TitleComponent = ({ title }) => {
  const defaultTitle = socProperties.portalName;
  const _title = title ? `${title} | ${defaultTitle}` : defaultTitle;

  return (
    <Helmet>
      <title>{_title}</title>
    </Helmet>
  );
};
