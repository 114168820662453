import React from 'react';
import { createPortal } from 'react-dom';
import { I18n, Translate } from 'react-redux-i18n';

import { Button } from '@bizone/ui-bundle/esm/Button';

import notification from '../Notification';

export default class DownloadCSVControl extends React.Component {
  componentDidUpdate() {
    if (this.props.sentByEmailNotification) {
      notification.success({ message: I18n.t('incidentList.csvSentByEmail') });
      this.props.clearedNotification();
    }
  }

  startDownload = () => {
    const { downloadFile, endpoint, endpointAsync, filename, sentByEmailCsv } =
      this.props;

    downloadFile(endpoint, filename).then((result) => {
      if (result.error && result.payload.response.max_records_count_exceeded) {
        sentByEmailCsv(endpointAsync);
      }
    });
  };

  render() {
    const { isEmpty, renderTo } = this.props;

    const body = (
      <div style={{ marginRight: 8 }}>
        <Button
          basic
          leftIcon="file-download"
          onClick={this.startDownload}
          disabled={isEmpty}
        >
          <Translate value="incidentList.loadCsv" />
        </Button>
      </div>
    );
    return renderTo ? createPortal(body, renderTo) : body;
  }
}
