import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import webFont from 'webfontloader';

import {
  darkThemeCustom as defaultDark,
  lightThemeCustom as defaultLight,
} from 'branding';
import { getIsChartFontsLoaded, getTheme } from 'store/reducers';
import { onChartFontsLoadingSuccess } from 'store/reducers/dashboard/actions';

import fontCss from '../font.css';

export const socProperties = getConfigValuesFromEnv({
  primaryColor: '',
  primaryHoverColor: '',
  disabledPrimaryColor: '',

  selectColor: '',
  selectedHoverColor: '',

  cardShadowBox: '',
  inputShadowBox: '',
  headerColor: '',
  loginLogo: undefined,
  headerLogo: undefined,
  portalName: 'SOC Portal',
  brandName: '',
});

const FONTS_TO_LOAD = [
  {
    families: ['Roboto'],
    styleSheetUrl: fontCss,
  },
];

export const combinezoneThemeLight = getConfigValuesFromEnv(defaultLight);
export const combinezoneThemeDark = getConfigValuesFromEnv(defaultDark, 'DARK');

export const useInitTheme = () => {
  const themeType = useSelector(getTheme);
  const isChartFontsLoaded = useSelector(getIsChartFontsLoaded);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isChartFontsLoaded) {
      const envFonts = process.env.REACT_APP_FONTS;
      const fonts =
        envFonts && envFonts > 0 ? JSON.stringify(envFonts) : FONTS_TO_LOAD;

      loadFonts(fonts, {
        active: () => dispatch(onChartFontsLoadingSuccess()),
      });
    }
  }, [isChartFontsLoaded, dispatch]);

  useEffect(() => {
    const combinezoneProperties =
      themeType === 'light' ? combinezoneThemeLight : combinezoneThemeDark;
    configObjIntoCssVariables(combinezoneProperties);
    configObjIntoCssVariables(socProperties);
  }, [themeType]);
  return [
    socProperties,
    themeType === 'light' ? combinezoneThemeLight : combinezoneThemeDark,
  ];
};

export const useTheme = (dark) => {
  const themeType = useSelector(getTheme);
  return {
    ...socProperties,
    ...(themeType === 'light' ? combinezoneThemeLight : combinezoneThemeDark),
  };
};

export function getConfigValuesFromEnv(config, envVariablePostfix) {
  const newConfig = deepCloneMapObjectWithPath(config, (value, path) => {
    const upperSnakeCasePath = path
      .replace(/[A-Z]/g, (res) => `_${res}`)
      .toUpperCase();
    const upperSnakeCasePathWithPostfix = envVariablePostfix
      ? `${upperSnakeCasePath}_${envVariablePostfix}`
      : upperSnakeCasePath;
    const envValue = process.env[`REACT_APP_${upperSnakeCasePathWithPostfix}`];
    return envValue && envValue.length > 0 ? envValue : value;
  });
  return newConfig;
}

function loadFonts(fonts, config) {
  webFont.load({
    families: fonts.families,
    custom: {
      urls: [fonts.styleSheetUrl],
    },
    ...config,
  });
}

export const NCCCIB = process.env.REACT_APP_NCCCIB === 'true';
export const EPS_DISABLED = process.env.REACT_APP_EPS_DISABLED === 'true';

function walkObjectWithPath(root, handle) {
  function walkNode([key, node], path = '') {
    if (node === null) {
      return;
    }

    const newPath = path ? `${path}_${key}` : key;
    if (typeof node === 'object') {
      Object.entries(node).forEach((subNodeEntry) =>
        walkNode(subNodeEntry, newPath),
      );
    } else {
      handle(node, newPath);
    }
  }

  Object.entries(root).forEach((nodeEntry) => walkNode(nodeEntry));
}

function deepCloneMapObjectWithPath(root, handle) {
  function walkNode([key, node], path) {
    if (node === null) {
      return;
    }
    const newPath = path ? `${path}_${key}` : key;
    if (typeof node === 'object') {
      const nodeEntries = Object.entries(node).map((subNodeEntry) => [
        subNodeEntry[0],
        walkNode(subNodeEntry, newPath),
      ]);
      return Object.fromEntries(nodeEntries);
    }
    return handle(node, newPath);
  }

  const nodeEntries = Object.entries(root).map((nodeEntry) => [
    nodeEntry[0],
    walkNode(nodeEntry),
  ]);
  return Object.fromEntries(nodeEntries);
}

function stringToCssVariable(str) {
  return `--${str
    .replaceAll('_', '-')
    .replace(/[A-Z]/g, (res) => `-${res}`)
    .toLowerCase()}`;
}

function configObjIntoCssVariables(config) {
  walkObjectWithPath(config, (value, path) => {
    document.documentElement.style.setProperty(
      stringToCssVariable(path),
      value,
    );
  });
}
