import { connect } from 'react-redux';

import ChangePassword from '../components/ChangePassword';
import {
  changePassword,
  onSuccess,
} from '../store/reducers/changePassword/actions';

const mapStateToProps = (state) => {
  const {
    errors: { old_password: oldPassword, ...rest },
    isChanged,
    isLoading,
  } = state.changePassword;
  const errors = oldPassword ? { oldPassword, ...rest } : { ...rest };

  return {
    errors,
    isChanged,
    isLoading,
  };
};

const mapDispatchToProps = {
  changePassword,
  onSuccess,
};

const ChangePasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChangePassword);
export default ChangePasswordContainer;
