import { connect } from 'react-redux';

import { Panel } from '../components/IncidentList/Panel';
import {
  currentLocale,
  getMitreTactics,
  getMitreTechniques,
  incidentListSelected,
} from '../store/reducers';
import { downloadFile } from '../store/reducers/fileDownloader/actions';
import { sendFeedback } from '../store/reducers/incidentList/actions';

const mapStateToProps = (state) => {
  return {
    incident: incidentListSelected(state),
    locale: currentLocale(state),
    mitreTechniques: getMitreTechniques(state),
    mitreTactics: getMitreTactics(state),
  };
};

const mapDispatchToProps = {
  downloadFile,
  sendFeedback,
};

const PanelContainer = connect(mapStateToProps, mapDispatchToProps)(Panel);
export default PanelContainer;
