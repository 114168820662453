import React from 'react';

import { SvgIcon } from 'combinezone/icons';

export function RulesIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 8.5C20.1046 8.5 21 7.60457 21 6.5V4.5C21 2.29086 19.2091 0.5 17 0.5H5.99867C4.34182 0.5 3 1.84315 3 3.5V12.4735H5V3.5C5 2.94656 5.44754 2.5 5.99867 2.5H15V17.5C15 18.0523 14.5523 18.5 14 18.5H12.0024L11.9915 18.4735C11.8649 18.1607 11.796 17.8241 11.796 17.4735V14.4735H0V17.4735C0 19.1304 1.53842 20.4735 3.43615 20.4735H5.59956C5.73056 20.491 5.86423 20.5 6 20.5H14C14.1358 20.5 14.2694 20.491 14.4004 20.4735H14.8265C14.8265 20.4735 15.2169 20.4735 14.8265 20.4516L14.8029 20.4503C14.7504 20.4446 14.6983 20.4379 14.6466 20.4301C15.9927 20.1344 17 18.9348 17 17.5V2.5C18.1046 2.5 19 3.39543 19 4.5V8.5ZM9.90081 18.4735C9.83235 18.1545 9.79601 17.8206 9.79601 17.4735V16.4735H2V17.4735C2 17.6543 2.07923 17.8765 2.32179 18.0882C2.56832 18.3035 2.95628 18.4735 3.43615 18.4735L9.90081 18.4735Z"
      />
      <path d="M7 5.5H13V7.5H7V5.5Z" />
      <path d="M13 9.5H7V11.5H13V9.5Z" />
    </SvgIcon>
  );
}
