import { connect } from 'react-redux';

import AmChart from '../components/AmChart';
import { currentLocale, makeAmChartDataSelector } from '../store/reducers';
import { fetchDashboardData } from '../store/reducers/dashboard/actions';
import { setFilter } from '../store/reducers/filters/actions';

const makeMapStateToProps = () => {
  const amChartDataSelector = makeAmChartDataSelector();

  return (state, ownProps) => ({
    ...ownProps,
    data: amChartDataSelector(state, ownProps),
    currentLocale: currentLocale(state),
    isChartFontsLoaded: state.dashboard.isChartFontsLoaded,
  });
};

function mapDispatchToProps(dispatch) {
  return {
    setFilter: (key, value) =>
      dispatch(setFilter('incidents', key, value, fetchDashboardData)),
  };
}

const AmChartContainer = connect(
  makeMapStateToProps,
  mapDispatchToProps,
)(AmChart);
export default AmChartContainer;
