import { connect } from 'react-redux';

import Dashboard from '../components/Dashboard';
import { INCIDENTS_ENTITY } from '../services/api';
import {
  getIncidentsDateRange,
  makeIsShowFiltersSelector,
} from '../store/reducers';
import {
  dispatchShowFilters,
  fetchDashboardData,
  getDashboardLayout,
  onChartFontsLoadingSuccess,
  unsetDashboardUpdateTimer,
} from '../store/reducers/dashboard/actions';

function makeMapStateToProps() {
  const selector = makeIsShowFiltersSelector(INCIDENTS_ENTITY);

  return (state) => {
    const isShowFilters = selector(state);

    return {
      isAddChartModalOpen: state.dashboard.isAddChartModalOpen,
      updateFunction: fetchDashboardData,
      isChartFontsLoaded: state.dashboard.isChartFontsLoaded,
      dateRange: getIncidentsDateRange(state),
      isShowFilters,
    };
  };
}

const mapDispatchToProps = {
  fetchDashboardData,
  getDashboardLayout,
  onChartFontsLoadingSuccess,
  unsetDashboardUpdateTimer,
  dispatchShowFilters,
};

const DashboardGridContainer = connect(
  makeMapStateToProps,
  mapDispatchToProps,
)(Dashboard);
export default DashboardGridContainer;
