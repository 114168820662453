import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import { Block } from '@bizone/ui-bundle/esm/Block';
import { Header } from '@bizone/ui-bundle/esm/Header';
import { Icon } from '@bizone/ui-bundle/esm/Icon';
import { List } from '@bizone/ui-bundle/esm/List';
import { Subheader } from '@bizone/ui-bundle/esm/Subheader';

import { Layout, ListSection } from '@common/soc-react-kit';
import moment from 'moment';

import { socProperties } from 'utils/brending';

import { priorityIconProps } from '../../utils/helpers';
import Anchors from '../Anchors';
import Back from '../Back';

import './ProtectedSystemCard.scss';

const { BreadCrumb, Container, Content } = Layout;

export default class ProtectedSystemCard extends React.Component {
  componentDidMount() {
    this.props.fetchSystems();
  }

  render() {
    const { systemId, systems } = this.props;

    const anchors = [
      {
        title: <Translate value="protectedSystems.mainInfo" />,
        id: 'system-main-info',
      },
      {
        title: <Translate value="protectedSystems.services" />,
        id: 'system-services',
      },
      {
        title: <Translate value="protectedSystems.contacts" />,
        id: 'system-contacts',
      },
    ];

    const system = systems.find((system) => system.is_id === systemId) || {};

    return (
      <React.Fragment>
        <BreadCrumb>
          <Back to="/protected-systems">{system.full_name}</Back>
        </BreadCrumb>
        <Container dual id="ProtectedSystemCardContainer">
          <Content>
            <Anchors
              anchors={anchors}
              containerId="ProtectedSystemCardContainer"
            />
            <div className="ProtectedSystemCard">
              <section id="system-main-info">
                <Block
                  collapsible
                  title={
                    <Header size={16}>
                      <Translate value="protectedSystems.mainInfo" />
                    </Header>
                  }
                >
                  <Block.Content>
                    <ListSection
                      data={{
                        [I18n.t('protectedSystems.fullName')]: system.full_name,
                        [I18n.t('protectedSystems.id')]: system.is_id,
                        [I18n.t('protectedSystems.status')]: system.status,
                      }}
                    />
                  </Block.Content>
                </Block>
              </section>
              <section id="system-services">
                <Block
                  collapsible
                  title={
                    <Header size={16}>
                      <Translate value="protectedSystems.services" />
                    </Header>
                  }
                >
                  <Block.Content>
                    {(system.services || [])
                      .sort((service1, service2) =>
                        `${service1.name}`.localeCompare(service2.name),
                      )
                      .map((service) => (
                        <ListSection
                          header={`${socProperties.portalName} ${service.name}`}
                          data={serviceFormatter(service)}
                          key={service.name}
                        />
                      ))}
                  </Block.Content>
                </Block>
              </section>
              <section id="system-contacts">
                <Block
                  collapsible
                  title={
                    <Header size={16}>
                      <Translate value="protectedSystems.contacts" />
                    </Header>
                  }
                >
                  <Block.Content>
                    {system.service_manager && (
                      <ListSection
                        header={
                          <Translate value="protectedSystems.serviceManager" />
                        }
                        data={contactFormatter(system.service_manager)}
                      />
                    )}
                    {system.service_manager_double && (
                      <ListSection
                        header={
                          <Translate value="protectedSystems.serviceManagerDouble" />
                        }
                        data={contactFormatter(system.service_manager_double)}
                      />
                    )}
                    {system.dedicated_analyst && (
                      <ListSection
                        header={
                          <Translate value="protectedSystems.dedicatedAnalyst" />
                        }
                        data={contactFormatter(system.dedicated_analyst)}
                      />
                    )}
                    {system.dedicated_analyst_double && (
                      <ListSection
                        header={
                          <Translate value="protectedSystems.dedicatedAnalystDouble" />
                        }
                        data={contactFormatter(system.dedicated_analyst_double)}
                      />
                    )}
                    {system.customer_contacts &&
                      system.customer_contacts.length > 0 && (
                        <div className="ListSection">
                          <Subheader size={14}>
                            <Translate value="protectedSystems.customerContacts" />
                          </Subheader>
                          {system.customer_contacts
                            .sort((contact1, contact2) =>
                              `${contact1.last_name}`.localeCompare(
                                contact2.last_name,
                              ),
                            )
                            .map((contact, i) => (
                              <React.Fragment key={`${contact.name}-${i}`}>
                                <List
                                  data={contactFormatter(contact)}
                                  keysWidth={180}
                                />
                                {system.customer_contacts.length - 1 !== i && (
                                  <div className="divider" />
                                )}
                              </React.Fragment>
                            ))}
                        </div>
                      )}
                  </Block.Content>
                </Block>
              </section>
            </div>
          </Content>
        </Container>
      </React.Fragment>
    );
  }
}

function contactFormatter(contact) {
  return {
    [I18n.t(
      'protectedSystems.fio',
    )]: `${contact.last_name} ${contact.first_name} ${contact.patronimic}`,
    [I18n.t('protectedSystems.email')]: contact.email,
    [I18n.t('protectedSystems.workPhone')]: contact.work_phone,
    [I18n.t('protectedSystems.mobilePhone')]: contact.mobile_phone,
  };
}

function serviceFormatter(service) {
  const data = {};

  if (service.contract) {
    data[I18n.t('protectedSystems.contract')] = I18n.t(
      'protectedSystems.contractField',
      {
        name: service.contract.name,
        number: service.contract.number,
        start: moment(service.contract.start_date).format('LL'),
      },
    );
  }

  if (service.start_date) {
    data[I18n.t('protectedSystems.startDate')] = moment(
      service.start_date,
    ).format('LL');
  }

  if (service.contract) {
    data[I18n.t('protectedSystems.endDate')] = moment(
      service.contract.end_date,
    ).format('LL');
  }

  if (service.sla) {
    data[I18n.t('protectedSystems.sla')] = service.sla
      .sort((sla1, sla2) => sla1.hours - sla2.hours)
      .map(({ calendar, category, hours, icon = '' }) => (
        <div key={category} style={{ display: 'flex', alignItems: 'center' }}>
          <div className="RowWithIcon">
            <Icon size={24} {...priorityIconProps[icon.toLowerCase()]} />
            <span style={{ marginRight: 4 }}>{category}</span>
            <Translate
              value={`protectedSystems.slaValue.${
                calendar ? 'is' : 'not'
              }Calendar`}
              hours={hours}
            />
          </div>
        </div>
      ));
  }

  return data;
}
