import { connect } from 'react-redux';

import ProtectedSystems from '../components/ProtectedSystems';
import { PROTECTED_SYSTEMS_ENTITY } from '../services/api';
import {
  currentLocale,
  protectedSystemsSystems,
  protectedSystemsIsLoading,
  getFiltersSettings,
} from '../store/reducers';
import { fetchProtectedSystems } from '../store/reducers/protectedSystems/actions';

const mapStateToProps = (state) => ({
  systems: protectedSystemsSystems(state),
  isLoading: protectedSystemsIsLoading(state),
  locale: currentLocale(state),
  settings: getFiltersSettings(PROTECTED_SYSTEMS_ENTITY, state),
});

const mapDispatchToProps = {
  fetchSystems: fetchProtectedSystems,
};

const ProtectedSystemContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProtectedSystems);
export default ProtectedSystemContainer;
