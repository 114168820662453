import {
  i18nReducer,
  syncTranslationWithStore,
  loadTranslations,
} from 'react-redux-i18n';

import { mergeTranslation } from '@common/soc-react-kit';
import { composeWithDevTools } from '@redux-devtools/extension';
import { applyMiddleware, createStore, combineReducers, compose } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import thunk from 'redux-thunk';

import abortOldRequests from 'store/middlewares/abortOldRequests';

import { setLocale, translationObject } from '../utils/i18n';

import checkLogout from './middlewares/checkLogout';
import { renewToken } from './middlewares/renewToken';
import { rsaaMiddleware } from './middlewares/rsaaMiddleware';
import { sentryReduxEnhancer } from './middlewares/sentryEnhancer';
import sentryScopeMiddleware from './middlewares/sentryExceptionsMiddleware';
import showErrorMessage from './middlewares/showErrorMessage';
import reducers from './reducers';

const middlewareList = [
  thunk,
  renewToken,
  abortOldRequests,
  rsaaMiddleware,
  showErrorMessage,
  sentryScopeMiddleware,
];

const middlewares =
  process.env.NODE_ENV === 'development'
    ? composeWithDevTools(
        applyMiddleware(...middlewareList),
        sentryReduxEnhancer,
      )
    : compose(applyMiddleware(...middlewareList), sentryReduxEnhancer);

const resetEnhancer = (rootReducer) => (state, action) => {
  if (action.type !== 'RESET') {
    return rootReducer(state, action);
  }

  const newState = rootReducer(undefined, {});
  newState.auth = state.auth;
  newState.i18n = state.i18n;
  return newState;
};

const persistConfig = {
  key: 'locale',
  storage,
  whitelist: ['locale'],
};

export const allReducers = combineReducers({
  i18n: persistReducer(persistConfig, i18nReducer),
  ...reducers,
});

const store = createStore(resetEnhancer(allReducers), middlewares);

persistStore(store, null, () => {
  const { locale } = store.getState().i18n;
  const langBrowser =
    (
      window.navigator.language.slice(0, 2) || window.navigator.userLanguage
    ).slice(0, 2) === 'ru'
      ? 'ru'
      : 'en';

  store.dispatch(setLocale(!locale ? langBrowser : locale));
});

syncTranslationWithStore(store);
store.dispatch(loadTranslations(mergeTranslation(translationObject)));

export default store;
