import {
  EpsAnalyticData,
  EpsAnalyticState,
} from 'components/EpsWidget/helpers/types';
import * as types from 'store/reducers/epsWidget/actionTypes';

const initialState: EpsAnalyticState = {
  fetching: false,
  data: {} as EpsAnalyticData,
};

export default function reduce(
  state = initialState,
  action: { type: string; payload: EpsAnalyticData },
): EpsAnalyticState {
  switch (action.type) {
    case types.FETCH_EPS_ANALYTICS_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case types.FETCH_EPS_ANALYTICS_SUCCESS:
      return {
        fetching: false,
        data: action.payload,
      };

    case types.FETCH_EPS_ANALYTICS_FAILURE:
      return {
        ...state,
        fetching: false,
      };

    default:
      return state;
  }
}

export const getAnalytic = (state: EpsAnalyticState): EpsAnalyticData =>
  state.data;
export const getFetching = (state: EpsAnalyticState): boolean => state.fetching;
