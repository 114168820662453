import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';

import { Block } from '@bizone/ui-bundle/esm/Block';
import { Header } from '@bizone/ui-bundle/esm/Header';
import { Label } from '@bizone/ui-bundle/esm/Label';

import { Input } from 'combinezone/core';
import { Search } from 'combinezone/icons';

import AlertList from 'components/AlertList';

import { incCardAlertsSearch } from '../../../store/reducers';
import { setSearch } from '../../../store/reducers/incCard/actions';

export const AlertsBlock = ({ alertsCount, incKey }) => {
  const dispatch = useDispatch();
  const searchText = useSelector(incCardAlertsSearch);
  return (
    <Block
      collapsible
      separateHeader
      expandable
      headerControls={
        <Input
          className="IncCard-AlertSearch"
          placeholder={I18n.t('incCard.alerts.searchPlaceholder')}
          onChange={(value) => dispatch(setSearch(value))}
          value={searchText}
          LeftIcon={Search}
        />
      }
      title={
        <Header size={16}>
          <Translate value="incCard.header.alerts" />
          <Label offsetLeft={8}>{alertsCount}</Label>
        </Header>
      }
    >
      <AlertList className="inc-card-alerts-block" incKey={incKey} />
    </Block>
  );
};

AlertsBlock.displayName = 'AlertsBlock';
