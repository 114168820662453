import { connect } from 'react-redux';

import DownloadCSVControl from '../components/DownloadCSVControl';
import {
  INCIDENTS_CSV_ASYNC_ENDPOINT,
  INCIDENTS_CSV_ENDPOINT,
} from '../services/api';
import { urlEncodedIncFiltersSelector } from '../store/reducers';
import { downloadFile } from '../store/reducers/fileDownloader/actions';
import {
  clearedNotification,
  sentByEmailCsv,
} from '../store/reducers/sentByEmail/actions';

function mapStateToProps(state) {
  const encodedQuery = urlEncodedIncFiltersSelector(state);

  return {
    endpoint: `${INCIDENTS_CSV_ENDPOINT}${encodedQuery}`,
    endpointAsync: `${INCIDENTS_CSV_ASYNC_ENDPOINT}${encodedQuery}`,
    filename: 'incidents.csv',
    isEmpty: !state.incidentList.incidents.length,
    sentByEmailNotification: state.sentByEmail.sentByEmailNotification,
  };
}

const mapDispatchToProps = {
  clearedNotification,
  downloadFile,
  sentByEmailCsv,
};

const DownloadIncCSVControlContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DownloadCSVControl);
export default DownloadIncCSVControlContainer;
