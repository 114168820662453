import { RSAA } from 'redux-api-middleware';

import { withAuth } from '..';
import {
  INSTRUCTIONS_ENDPOINT,
  INSTRUCTION_ENDPOINT,
} from '../../../services/api';

import * as types from './actionTypes';

export const fetchInstructionCard = (id) => {
  return {
    [RSAA]: {
      endpoint: `${INSTRUCTIONS_ENDPOINT}${id}/`,
      method: 'GET',
      headers: withAuth({ 'Content-Type': 'application/json' }),
      types: [
        types.FETCH_INSTRUCTION_CARD_REQUEST,
        types.FETCH_INSTRUCTION_CARD_SUCCESS,
        types.FETCH_INSTRUCTION_CARD_FAIL,
      ],
    },
  };
};

export const fetchVersions = (id) => {
  return {
    [RSAA]: {
      endpoint: `${INSTRUCTIONS_ENDPOINT}${id}/history/`,
      method: 'GET',
      headers: withAuth({ 'Content-Type': 'application/json' }),
      types: [
        types.FETCH_INSTRUCTION_VERSION_REQUEST,
        types.FETCH_INSTRUCTION_VERSION_SUCCESS,
        types.FETCH_INSTRUCTION_VERSION_FAIL,
      ],
    },
  };
};

export const submitComment = (id, text) => {
  const formData = new FormData();
  formData.append('text', text);
  return {
    [RSAA]: {
      endpoint: `${INSTRUCTIONS_ENDPOINT}${id}/comments/`,
      method: 'POST',
      body: formData,
      headers: withAuth({}),
      types: [
        {
          type: types.ADD_COMMENT_REQUEST,
          meta: { id },
        },
        {
          type: types.ADD_COMMENT_SUCCESS,
          meta: { id },
        },
        {
          type: types.ADD_COMMENT_FAIL,
          meta: { id },
        },
      ],
    },
  };
};

export const fetchComments = (id) => {
  return {
    [RSAA]: {
      endpoint: `${INSTRUCTION_ENDPOINT}${id}/comments/`,
      method: 'GET',
      headers: withAuth({ 'Content-Type': 'application/json' }),
      types: [
        types.FETCH_INSTRUCTION_COMMENTS_REQUEST,
        types.FETCH_INSTRUCTION_COMMENTS_SUCCESS,
        types.FETCH_INSTRUCTION_COMMENTS_FAIL,
      ],
    },
  };
};

export const closeInstructionCard = () => ({
  type: types.CLOSE_INSTRUCTION_CARD,
});

export const finalizeCommentSuccess = () => ({
  type: types.ADD_COMMENT_MODAL_SUCCESS,
});
