export const FETCH_ARTICLE_CARD_REQUEST =
  '@@articleCard/FETCH_ARTICLE_CARD_REQUEST';
export const FETCH_ARTICLE_CARD_SUCCESS =
  '@@articleCard/FETCH_ARTICLE_CARD_SUCCESS';
export const FETCH_ARTICLE_CARD_FAIL = '@@articleCard/FETCH_ARTICLE_CARD_FAIL';

export const FETCH_ARTICLE_VERSION_REQUEST =
  '@@articleCard/FETCH_ARTICLE_VERSION_REQUEST';
export const FETCH_ARTICLE_VERSION_SUCCESS =
  '@@articleCard/FETCH_ARTICLE_VERSION_SUCCESS';
export const FETCH_ARTICLE_VERSION_FAIL =
  '@@articleCard/FETCH_ARTICLE_VERSION_FAIL';

export const FETCH_ARTICLE_COMMENTS_REQUEST =
  '@@articleCard/FETCH_ARTICLE_COMMENTS_REQUEST';
export const FETCH_ARTICLE_COMMENTS_SUCCESS =
  '@@articleCard/FETCH_ARTICLE_COMMENTS_SUCCESS';
export const FETCH_ARTICLE_COMMENTS_FAIL =
  '@@articleCard/FETCH_ARTICLE_COMMENTS_FAIL';

export const ADD_COMMENT_REQUEST = '@@articleCard/ADD_COMMENT_REQUEST';
export const ADD_COMMENT_SUCCESS = '@@articleCard/ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_FAIL = '@@articleCard/ADD_COMMENT_FAIL';

export const CLOSE_ARTICLE_CARD = '@@articleCard/CLOSE_ARTICLE_CARD';
export const ADD_COMMENT_MODAL_SUCCESS =
  '@@articleCard/ADD_COMMENT_MODAL_SUCCESS';
