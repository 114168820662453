export const FETCH_ANALYTICS_REQUEST = '@@dashboard/FETCH_ANALYTICS_REQUEST';
export const FETCH_ANALYTICS_SUCCESS = '@@dashboard/FETCH_ANALYTICS_SUCCESS';
export const FETCH_ANALYTICS_FAILURE = '@@dashboard/FETCH_ANALYTICS_FAILURE';

export const GET_DASHBOARD = '@@dashboard/GET_DASHBOARD';

export const EDIT_DASHBOARD_SET_GRID_LAYOUT =
  '@@dashboard/EDIT_DASHBOARD_SET_GRID_LAYOUT';

export const DASHBOARD_WIDGETS_MENU_CHECKBOX_CLICK =
  '@@dashboard/DASHBOARD_WIDGETS_MENU_CHECKBOX_CLICK';

export const EDIT_WIDGET_MENU_SUBMIT = '@@dashboard/EDIT_WIDGET_MENU_SUBMIT';

export const DELETE_WIDGET = '@@dashboard/DELETE_WIDGET';

export const SET_DASHBOARD_UPDATE_TIMER =
  '@@dashboard/SET_DASHBOARD_UPDATE_TIMER';
export const UNSET_DASHBOARD_UPDATE_TIMER =
  '@@dashboard/UNSET_DASHBOARD_UPDATE_TIMER';

export const CHART_FONTS_LOADING_SUCCESS =
  '@@dashboard/CHARTS_FONTS_LOADING_SUCCESS';
