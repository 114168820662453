import React from 'react';
import { I18n } from 'react-redux-i18n';

import NoPreview from '../NoPreview';

export default function ErrorComponent({ error }) {
  const errorText =
    error instanceof Error ? error.message : I18n.t(`files.${error}`);

  return <NoPreview text={errorText} />;
}
