export const FIRST_FETCH_REQUEST = '@@articles/FIRST_FETCH_REQUEST';
export const FIRST_FETCH_SUCCESS = '@@articles/FIRST_FETCH_SUCCESS';
export const FIRST_FETCH_FAIL = '@@articles/FIRST_FETCH_FAIL';

export const FETCH_MORE_REQUEST = '@@articles/FETCH_MORE_REQUEST';
export const FETCH_MORE_SUCCESS = '@@articles/FETCH_MORE_SUCCESS';
export const FETCH_MORE_FAIL = '@@articles/FETCH_MORE_FAIL';

export const SET_SEARCH = '@@articles/SET_SEARCH';

export const SET_FILTERS = '@@articles/SET_FILTERS';

export const SET_SORT = '@@articles/SET_SORT';

export const SELECT_INSTRUCTION = '@@articles/SELECT_INSTRUCTION';
