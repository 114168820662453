import React from 'react';
import { I18n } from 'react-redux-i18n';

import { Button } from '@bizone/ui-bundle/esm/Button';

import { useBack } from '../../Back';

export default function NoPreview({ text }) {
  const { canGoBack, goBack } = useBack('/');

  const buttonText = I18n.t(
    `files.${canGoBack ? 'buttonGoBack' : 'buttonGoMain'}`,
  );

  return (
    <div className="NoPreview">
      <div>{text || I18n.t('files.noPreview')}</div>
      <Button onClick={goBack}>{buttonText}</Button>
    </div>
  );
}
