import React, { FC } from 'react';
import { I18n } from 'react-redux-i18n';

import styled from 'styled-components';

import { Flex, Text, Tooltip } from 'combinezone/core';
import { CombinezoneTheme } from 'combinezone/theme';

import LogSourcesCardEpsInfoTooltip from 'components/LogSources/LogSourcesCard/components/LogSourcesCardEpsInfo/components/LogSourcesCardEpsInfoTooltip';
import { LogSourcesCardEpsInfoProps } from 'components/LogSources/LogSourcesCard/components/LogSourcesCardEpsInfo/types';
import { handleRequestItem } from 'components/LogSources/LogSourcesCard/helpers';
import { useTheme } from 'utils/brending';

const LogSourcesEpsInfoWrapper = styled(Flex)`
  margin-top: 16px;
`;

const LogSourcesEpsInfoCell = styled(Flex)<{
  $borderLeft: boolean;
  $color?: string;
}>`
  gap: 5px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: ${(props) => (props.$borderLeft ? '24px' : 'none')};
  padding-right: 24px;
  border-left: ${(props) =>
    props.$borderLeft ? `1px solid ${props.$color}` : 'none'};
  color: ${({ theme }: { theme: CombinezoneTheme }) =>
    theme.basis.colors.base.active};
  font-family: Ubuntu;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
`;

const GrayText = styled(Text)<{
  $fontSize?: string;
}>`
  font-size: {(props) => props.$fontSize || "12px"};
  padding-right: 10px;
  color: ${({ theme }: { theme: CombinezoneTheme }) =>
    theme.basis.colors.text.secondary};
`;

const LogSourcesCardEpsInfo: FC<LogSourcesCardEpsInfoProps> = ({
  source,
  testId,
}) => {
  const {
    basis: {
      colors: {
        text: { placeholder },
      },
    },
  } = useTheme();

  const testIdEpsInfo = `${testId}-eps-info`;
  const testIdEpsInfoPerHour = `${testIdEpsInfo}-per-hour`;
  const testIdEpsInfoPerDay = `${testIdEpsInfo}-per-day`;
  const testIdEpsInfoPerWeek = `${testIdEpsInfo}-per-week`;

  return (
    <LogSourcesEpsInfoWrapper
      testId={testIdEpsInfo}
      direction="column"
      gap="10px"
    >
      <Flex testId={`${testIdEpsInfo}-wrapper`}>
        <Tooltip
          position="bottom"
          content={
            <LogSourcesCardEpsInfoTooltip
              avgValue={`${handleRequestItem(
                source.eps_metrics?.avg_last_hour,
              )}`}
              maxValue={`${handleRequestItem(
                source.eps_metrics?.max_last_hour,
              )}`}
              part="epsPerHour"
              testId={testIdEpsInfoPerHour}
            />
          }
          testId={`${testIdEpsInfoPerHour}-tooltip`}
        >
          <LogSourcesEpsInfoCell $borderLeft={false}>
            {handleRequestItem(source.eps_metrics?.avg_last_hour)}
            <GrayText testId={`${testIdEpsInfoPerHour}-title`}>
              {I18n.t('logSourcesCard.epsPerHour')}
            </GrayText>
          </LogSourcesEpsInfoCell>
        </Tooltip>
        <Tooltip
          position="bottom"
          content={
            <LogSourcesCardEpsInfoTooltip
              avgValue={`${handleRequestItem(
                source.eps_metrics?.avg_last_day,
              )}`}
              maxValue={`${handleRequestItem(
                source.eps_metrics?.max_last_day,
              )}`}
              part="epsPerDay"
              testId={testIdEpsInfoPerDay}
            />
          }
          testId={`${testIdEpsInfoPerDay}-tooltip`}
        >
          <LogSourcesEpsInfoCell $borderLeft $color={placeholder}>
            {handleRequestItem(source.eps_metrics?.avg_last_day)}
            <GrayText testId={`${testIdEpsInfoPerDay}-title`}>
              {I18n.t('logSourcesCard.epsPerDay')}
            </GrayText>
          </LogSourcesEpsInfoCell>
        </Tooltip>
        <Tooltip
          position="bottom"
          content={
            <LogSourcesCardEpsInfoTooltip
              avgValue={`${handleRequestItem(
                source.eps_metrics?.avg_last_week,
              )}`}
              maxValue={`${handleRequestItem(
                source.eps_metrics?.max_last_week,
              )}`}
              part="epsPerWeek"
              testId={testIdEpsInfoPerWeek}
            />
          }
          testId={`${testIdEpsInfoPerWeek}-tooltip`}
        >
          <LogSourcesEpsInfoCell $borderLeft $color={placeholder}>
            {handleRequestItem(source.eps_metrics?.avg_last_week)}
            <GrayText testId={`${testIdEpsInfoPerWeek}-title`}>
              {I18n.t('logSourcesCard.epsPerWeek')}
            </GrayText>
          </LogSourcesEpsInfoCell>
        </Tooltip>
      </Flex>

      {!source.eps_metrics && (
        <GrayText testId={`${testIdEpsInfo}-no-info`} $fontSize="14px">
          {I18n.t('logSourcesCard.noMetrics')}
        </GrayText>
      )}
    </LogSourcesEpsInfoWrapper>
  );
};

export default LogSourcesCardEpsInfo;

LogSourcesCardEpsInfo.displayName = 'LogSourcesCardEpsInfo';
