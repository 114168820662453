import React, { FC, useState, useCallback, useEffect, useMemo } from 'react';
import { I18n } from 'react-redux-i18n';

import { Dropdown, RangeDatepicker } from 'combinezone/core';
import moment from 'moment';

import Tag from '../Tag';

import './RangePicker.scss';
import { RangeDatepickerPresetsTypes, RangePickerProps } from '../../../types';

const RangePicker: FC<RangePickerProps> = ({
  filter,
  locale,
  setFilter,
  settings: { label },
  value = [],
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [start, end] = value;
  const pickerValue: [Date | undefined, Date | undefined] = value.length
    ? [start?.toDate(), end?.toDate()]
    : [new Date(), new Date()];

  const onChange = useCallback(
    (values) => {
      const [start, end] = values;
      setFilter(filter, [
        moment(start).startOf('day'),
        moment(end).endOf('day'),
      ]);
      setIsOpen(false);
    },
    [setFilter],
  );

  useEffect(() => {
    !value.length && setIsOpen(true);
  }, []);

  const rangeDatepickerPresets: RangeDatepickerPresetsTypes = useMemo(
    () => [
      {
        title: I18n.t('filterPanel.rangePicker.last7days'),
        dateValue: [moment().subtract(6, 'days').toDate(), new Date()],
      },
      {
        title: I18n.t('filterPanel.rangePicker.last30days'),
        dateValue: [moment().subtract(29, 'days').toDate(), new Date()],
      },
      {
        title: I18n.t('filterPanel.rangePicker.thisMonth'),
        dateValue: [
          moment().startOf('month').toDate(),
          moment().endOf('month').toDate(),
        ],
      },
      {
        title: I18n.t('filterPanel.rangePicker.lastMonth'),
        dateValue: [
          moment().subtract(1, 'month').startOf('month').toDate(),
          moment().subtract(1, 'month').endOf('month').toDate(),
        ],
      },
    ],
    [locale],
  );

  const format = useMemo(
    () => I18n.t('filterPanel.rangePicker.format'),
    [locale],
  );

  const tagText = useMemo(
    () =>
      `${
        start?.format(format) ?? I18n.t('filterPanel.rangePicker.chooseStart')
      }` +
      ` — ` +
      `${end?.format(format) ?? I18n.t('filterPanel.rangePicker.chooseEnd')}`,
    [locale, start, end],
  );

  return (
    <div className="Filter-RangePicker">
      <Dropdown
        position="bottom-left"
        content={({ onClose }) => (
          <RangeDatepicker
            testId="Filter_RangeDatepicker"
            className="RangeDatepicker-FadeInput"
            presets={rangeDatepickerPresets}
            inline
            value={pickerValue}
            size="medium"
            dateFormat={format.toLowerCase()}
            onChange={(v) => {
              onChange(v);
              onClose();
            }}
          />
        )}
      >
        <Tag
          filter={filter}
          isActive={isOpen}
          setFilter={setFilter}
          onClick={() => setIsOpen((prev) => !prev)}
          label={label}
          text={tagText}
        />
      </Dropdown>
    </div>
  );
};

export default RangePicker;
