import * as types from './actionTypes';

const initialState = {
  isOpen: false,
  loading: 0,
  systems: [],
  createdIncKey: '',
  fileSendFailMessage: undefined,
};

export default function createInc(state = initialState, action = {}) {
  switch (action.type) {
    case types.CREATE_INC_MODAL_OPEN:
      return {
        ...state,
        fileSendFailMessage: undefined,
        isOpen: true,
      };

    case types.CREATE_INC_MODAL_CANCEL:
      return {
        ...state,
        isOpen: false,
      };

    case types.CREATE_INC_MODAL_SUCCESS:
      return {
        ...initialState,
        systems: state.systems,
      };

    case types.FETCH_SYSTEMS_REQUEST:
    case types.CREATE_REQUEST:
      return {
        ...state,
        loading: state.loading + 1,
      };

    case types.FETCH_SYSTEMS_FAIL:
    case types.CREATE_FAIL:
      return {
        ...state,
        loading: state.loading - 1,
      };

    case types.FETCH_SYSTEMS_SUCCESS:
      return {
        ...state,
        systems: action.payload,
        loading: state.loading - 1,
      };

    case types.CREATE_SUCCESS: {
      return {
        ...state,
        loading: state.loading - 1,
        createdIncKey: action.payload.key || 'Успешно!',
        fileSendFailMessage: action.payload.msg,
      };
    }

    default:
      return state;
  }
}

export function isLoading(state) {
  return state.loading !== 0;
}

export function isModalOpen(state) {
  return state.isOpen;
}

export function systems(state) {
  return state.systems;
}

export function createdIncKey(state) {
  return state.createdIncKey;
}
