import { connect } from 'react-redux';

import { SortControl } from '../components/SortControl';
import { INCIDENTS_ENTITY } from '../services/api';
import { currentLocale, getOrdering } from '../store/reducers';
import { setSort } from '../store/reducers/filters/actions';
import { firstFetch } from '../store/reducers/incidentList/actions';

const mapStateToProps = (state) => {
  return {
    locale: currentLocale(state),
    ordering: getOrdering(INCIDENTS_ENTITY, state),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setSort: (ordering) =>
      dispatch(setSort(INCIDENTS_ENTITY, ordering, firstFetch)),
  };
}

const SortControlContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SortControl);
export default SortControlContainer;
