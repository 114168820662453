import React, { FC, memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { Flex, Block } from 'combinezone/core';

import AmChart from 'components/AmChart';
import { getEpsWidget } from 'components/EpsWidget/helpers/helpers';
import { EpsAnalyticData } from 'components/EpsWidget/helpers/types';
import {
  currentLocale,
  getEpsAnalytic,
  getEpsAnalyticsFetching,
  getIncidentsDateRange,
  getIsChartFontsLoaded,
  getSelectedFilters,
} from 'store/reducers';
import { fetchEpsDashboardData } from 'store/reducers/epsWidget/actions';

const AmChartWrapper = styled(Block)`
  height: 550px;
  margin-top: 15px;
`;

const EpsWidget: FC = () => {
  const dispatch = useDispatch();
  const locale = useSelector(currentLocale);
  const analytic = useSelector(getEpsAnalytic);
  const isFetching = useSelector(getEpsAnalyticsFetching);
  const incidentsDateRange = useSelector(getIncidentsDateRange);
  const isChartFontsLoaded = useSelector(getIsChartFontsLoaded);
  const selectedFilters = useSelector((state) =>
    getSelectedFilters('incidents', state),
  );
  const id = 'epsWidget';
  const type = 'column';
  const title = 'EPS';

  const widgetSettings = getEpsWidget();

  useEffect(() => {
    dispatch(fetchEpsDashboardData());
  }, [incidentsDateRange, selectedFilters]);

  const data = useMemo(() => {
    return widgetSettings.dataGenerator(
      analytic as EpsAnalyticData,
      widgetSettings,
    );
  }, [analytic, widgetSettings, incidentsDateRange, selectedFilters]);

  const filter = (): void => {};

  return (
    <AmChartWrapper
      headerContent={title}
      testId="eps-widget"
      isCollapsible={false}
      isExpandable={false}
      isLoading={isFetching}
    >
      <Flex fillWidth fillHeight justify="center">
        <AmChart
          title={title}
          id={id}
          type={type}
          currentLocale={locale}
          isChartFontsLoaded={isChartFontsLoaded}
          data={data}
          widgetSettings={widgetSettings}
          setFilter={filter}
        />
      </Flex>
    </AmChartWrapper>
  );
};

export default memo(EpsWidget);

EpsWidget.displayName = 'EpsWidget';
