import React, { ComponentPropsWithRef, forwardRef } from 'react';

import styled, { css } from 'styled-components';

const blockDomEvent = (event: {
  preventDefault: () => void;
  stopPropagation: () => void;
}): void => {
  event.stopPropagation();
  event.preventDefault();
};

const ContentBox = styled.div<{
  isDisabled?: boolean;
}>`
  ${({
    isDisabled,
    theme: {
      basis: {
        colors: {
          link: { disable, normal },
        },
      },
    },
  }) => {
    return css`
      border-bottom: 1px dashed ${isDisabled ? disable : normal};
      color: ${isDisabled ? disable : normal};
      width: fit-content;
      cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
    `;
  }}
`;

type DashedTextButtonProps = {
  text: string | JSX.Element;
  onClick: (p?: unknown) => unknown;
  isDisabled?: boolean;
};

export const DashedTextButton = forwardRef<
  HTMLDivElement,
  DashedTextButtonProps
>(({ text, ...props }, ref) => {
  const blockProp:
    | (ComponentPropsWithRef<'div'> & { 'data-disabled': unknown })
    | undefined = props.isDisabled
    ? {
        onClick: blockDomEvent,
        onMouseDown: blockDomEvent,
        onMouseUp: blockDomEvent,
        onKeyDown: (event) => {
          if (event.key !== 'Tab') {
            blockDomEvent(event);
          }
        },
        'data-disabled': '',
      }
    : undefined;

  return (
    <ContentBox ref={ref} {...props} {...blockProp}>
      {text}
    </ContentBox>
  );
});
