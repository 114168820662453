import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { I18n, Translate } from 'react-redux-i18n';
import { useHistory, useLocation } from 'react-router-dom';

import { Header } from '@bizone/ui-bundle/esm/Header';

import { Layout } from '@common/soc-react-kit';
import { Alert } from 'antd';
import { OverlaySpinner } from 'combinezone/core/index';

import FilterControlContainer from '../../containers/FilterControlContainer';
import FilterPanelContainer from '../../containers/FilterPanelContainer';
import InstructionsCard from '../../containers/Instructions-CardContainer';
import Panel from '../../containers/Instructions-PanelContainer';
import SortControl from '../../containers/Instructions-SortControlContainer';

import SearchBlock from './SearchBlock';

import './Instructions.scss';
import { INSTRUCTIONS_ENTITY } from '../../services/api';
import { setFilterFromQuerysringGeneral } from '../../utils/helpers';

const { Container, Content } = Layout;

export default function Instructions({
  currentLocale,
  fetchMore,
  filters,
  firstFetch,
  hasMore,
  instructions,
  isFetching,
  localSaveFilters,
  onChangeSearch,
  ordering,
  search = '',
  setInitFilter,
  setInitSearch,
  setInitSort,
  updateFunction,
}) {
  const [isLoading, setLoading] = useState(true);
  const history = useHistory();
  const { pathname, search: querystring } = useLocation();

  useEffect(() => {
    setFilterFromQuerysringGeneral({
      querystring,
      setFilter: setInitFilter,
      setSort: setInitSort,
      setSearch: setInitSearch,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localSaveFilters({ entity: INSTRUCTIONS_ENTITY, history, pathname });
  }, [
    localSaveFilters,
    history,
    pathname,
    filters,
    search,
    currentLocale,
    ordering,
  ]);

  useEffect(() => {
    setLoading(true);
    firstFetch().finally(() => setLoading(false));
  }, [firstFetch, currentLocale]);

  const loadMore = useCallback(() => {
    if (hasMore) {
      setLoading(true);
      fetchMore().finally(() => setLoading(false));
    }
  }, [hasMore, fetchMore]);

  return (
    <Container panel className="Instructions">
      <Content className="InstructionsContainer">
        {instructions?.length === 0 && !isFetching && (
          <Alert
            message={I18n.t('instructions.noDataForSelectedLanguage')}
            type="info"
            showIcon
            closable
          />
        )}
        <div className="InstructionsHeader">
          <div className="InstructionsHeader-ControlsRow">
            <div className="InstructionsHeader-Search">
              <SearchBlock defaultText={search} onSearch={onChangeSearch} />
            </div>
            <FilterControlContainer
              updateFunction={updateFunction}
              entity={INSTRUCTIONS_ENTITY}
            />
            <SortControl />
          </div>
          <div className="InstructionsHeader-FiltersRow">
            <FilterPanelContainer
              updateFunction={updateFunction}
              entity={INSTRUCTIONS_ENTITY}
            />
          </div>
        </div>
        <div
          className={`InstructionsBody${
            isFetching ? ' InstructionsBody_fetching' : ''
          }`}
        >
          {isFetching && <OverlaySpinner />}
          <div className="InstructionsBody-Cards">
            <InfiniteScroll
              initialLoad={false}
              loadMore={loadMore}
              hasMore={isLoading ? false : hasMore}
              useWindow={false}
            >
              {instructions.map((instruction) => (
                <InstructionsCard
                  instruction={instruction}
                  key={instruction.id}
                />
              ))}
            </InfiniteScroll>
            {instructions.length === 0 && !hasMore && (
              <div className="InstructionsBody-NoData">
                <Header size={24}>
                  <Translate value="instructions.noData" />
                </Header>
              </div>
            )}
          </div>
        </div>
      </Content>
      <Panel />
    </Container>
  );
}
