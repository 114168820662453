import { Nullable } from '@common/soc-react-kit';
import {
  Entities,
  SourcePropsType,
} from '@soc/alerts/src/store/reducers/alerts/actions/types';
import { createAction } from 'redux-api-middleware';

import { RSAAAsyncActionType } from 'store/types';

import {
  PatchAlertPropertiesFailureType,
  PatchAlertPropertiesSuccessType,
} from './actionMatchTypes';
import * as types from './actionTypes';
import { FormTagType } from './types';

export type GetBodyForPatchAlertPropertiesProps = {
  tags: FormTagType[];
  responseTeam?: Nullable<{ value: string; content: string }>;
};

export type PatchAlertPropertiesProps = SourcePropsType & {
  options: GetBodyForPatchAlertPropertiesProps;
};

type GetBodyForPatchAlertProperties = (
  options: GetBodyForPatchAlertPropertiesProps,
) => {
  tags: {
    name: string;
    color: string;
  }[];
  response_team: null | {
    id: number;
  };
};

const getBodyForPatchAlertProperties: GetBodyForPatchAlertProperties = ({
  responseTeam,
  tags,
}) => {
  return {
    tags: tags.map((tag) => ({
      name: tag.value,
      color: tag.color,
    })),
    response_team: responseTeam?.value
      ? {
          id: Number(responseTeam.value),
        }
      : null,
  };
};

type ResponsePatchAlertPropertiesType =
  | PatchAlertPropertiesFailureType
  | PatchAlertPropertiesSuccessType;

export type PatchAlertPropertiesType = (
  props: PatchAlertPropertiesProps,
) => RSAAAsyncActionType<ResponsePatchAlertPropertiesType, undefined>;

export const patchAlertProperties: PatchAlertPropertiesType =
  ({ entity = Entities.ALERTS, options, source, uuid }) =>
  (dispatch, getState) => {
    const body = getBodyForPatchAlertProperties(options);

    return dispatch(
      createAction({
        endpoint: source,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
        types: [
          types.PATCH_ALERT_PROPERTIES_REQUEST,
          types.PATCH_ALERT_PROPERTIES_SUCCESS,
          types.PATCH_ALERT_PROPERTIES_FAILURE,
        ],
      }),
    );
  };
