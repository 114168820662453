import React from 'react';

import { SidePanel } from '@soc/alerts/src';

import { ListPage } from '../ListPage';

import { AlertTable } from './AlertTable';
import { useSidePanelProps } from './useSidePanelProps';

const AlertList = ({ className, incKey }) => {
  const panelConfig = useSidePanelProps(incKey);

  return (
    <ListPage
      className={className}
      panelConfig={{ renderer: () => <SidePanel {...panelConfig} /> }}
      listConfig={{
        renderer: () => <AlertTable incKey={incKey} />,
      }}
    />
  );
};

export default AlertList;

AlertList.displayName = 'AlertList';
