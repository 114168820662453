import { useCallback, useEffect, useMemo, useState } from 'react';

import { getNormalizedPanelData } from '@soc/alerts/src/components/SidePanel/Panel/helpers';
import { Inputs } from '@soc/alerts/src/components/SidePanel/hooks/useEditProperties/types';
import {
  PanelDataType,
  Entity,
  SidePanelProps,
} from '@soc/alerts/src/components/SidePanel/types';
import { useAlertsActions } from '@soc/alerts/src/hooks/useAlertsActions';
import { useIsEditingAlertContext } from '@soc/alerts/src/utils/Providers/IsEditingAlert/context';
import {
  fetchAlert,
  patchAlertProperties,
  resetAlertPanel,
  fetchResponseTeams,
  fetchTags,
  fetchTemplatesLinks as fetchTemplates,
  goToLink as goTo,
} from './actions';
import { patchAlertPropertiesSuccess } from './actions/patchAlertsProperties/actionMatchTypes';

import {
  currentLocale,
  getUser,
  incCardSelectedAlert,
} from '../../store/reducers';
import { getPanelMitre } from '../../store/reducers/mitre/actions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useActionsProps } from './actions/useActionsProps';
import {
  alertPanelData,
  alertPanelGroupedEvents,
  alertPanelIsLoading,
  alertPanelSymptomatic,
  alertPanelSymptomaticError,
  alertPanelSymptomaticIsLoading,
  alertPanelTree,
  alertPanelTreeError,
  alertPanelTreeIsLoading,
  isFetchingTemplatesLinks,
  templatesLinks,
} from '../../store/reducers/incCard/reducer';
import {
  fetchSelectedAlert,
  fetchSelectedAlertGroupedData,
  fetchSelectedAlertSymptomatic,
  fetchSelectedAlertTree,
} from '../../store/reducers/incCard/actions';

export type UsePanelConfigType = (incKey: string) => SidePanelProps;

export const useSidePanelProps: UsePanelConfigType = (incKey) => {
  const baseActions = useActionsProps();
  const { setIsEditing } = useIsEditingAlertContext();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const selectedRow = useAppSelector(incCardSelectedAlert);

  const locale = useAppSelector(currentLocale);
  const { permissions, username } = useAppSelector(getUser);
  const dataPanel = useAppSelector(alertPanelData);
  const { _source: source = '', uuid = '' } = dataPanel || {};
  const isPanelLoading = useAppSelector(
    (state) =>
      alertPanelIsLoading(state) ||
      alertPanelTreeIsLoading(state) ||
      alertPanelSymptomaticIsLoading(state),
  );
  const alertTree = useAppSelector(alertPanelTree);
  const alertTreeError = useAppSelector(alertPanelTreeError);
  const alertSymptomaticData = useAppSelector(alertPanelSymptomatic);
  const alertSymptomaticError = useAppSelector(alertPanelSymptomaticError);
  const alertGroupedEvents = useAppSelector(alertPanelGroupedEvents);

  const tabsData = useMemo(
    () => ({
      tree: alertTree,
      symptomatic: alertSymptomaticData,
      groupedEvents: alertGroupedEvents,
      symptomaticError: alertSymptomaticError,
      treeError: alertTreeError,
    }),
    [
      alertTree,
      alertTreeError,
      alertSymptomaticData,
      alertSymptomaticError,
      alertGroupedEvents,
    ],
  );

  const updateGeneral = useCallback(
    () => dispatch<Promise<unknown>>(fetchAlert),
    [uuid, source],
  );

  const data = useMemo(
    () => (dataPanel ? getNormalizedPanelData(dataPanel, locale) : null),
    [dataPanel],
  );

  const isComposeLoading = isPanelLoading || isLoading;

  const fetchAllPanelData = useCallback(async () => {
    if (selectedRow && Object.keys(selectedRow).length > 0) {
      setIsLoading(true);
      await Promise.all([
        dispatch(fetchSelectedAlert(incKey)),
        dispatch(fetchSelectedAlertGroupedData(incKey)),
        dispatch(fetchSelectedAlertTree(incKey)),
        dispatch(fetchSelectedAlertSymptomatic(incKey)),
      ]).finally(() => {
        setIsLoading(false);
      });
    }
  }, [incKey, selectedRow]);

  const {
    assignUser,
    detachIncident,
    modals,
    openModalBindIncident,
    resolve,
    rework,
  } = useAlertsActions(baseActions);

  const panelActionWrapper = useCallback(
    ({
      action,
      patchData,
    }: {
      action: ({ alerts }: { alerts: PanelDataType[] }) => Promise<unknown>;
      patchData: PanelDataType;
    }) => {
      return action({ alerts: [patchData] }).then((isSuccessAction) => {
        if (isSuccessAction) {
          updateGeneral();
        }
        return isSuccessAction;
      });
    },
    [updateGeneral],
  );

  const actions = useMemo(
    () => ({
      assignUser: () => {
        setIsLoading(true);
        return panelActionWrapper({
          action: assignUser,
          patchData: data,
        }).finally(() => {
          setIsLoading(false);
        });
      },
      openModalBindIncident: () =>
        panelActionWrapper({
          action: openModalBindIncident,
          patchData: data,
        }),

      resolve: () => panelActionWrapper({ action: resolve, patchData: data }),
      detachIncident: () => {
        setIsLoading(true);
        return panelActionWrapper({
          action: detachIncident,
          patchData: data,
        }).finally(() => {
          setIsLoading(false);
        });
      },
      rework: () => {
        setIsLoading(true);
        panelActionWrapper({ action: rework, patchData: data }).finally(() => {
          setIsLoading(false);
        });
      },
    }),
    [data, panelActionWrapper],
  );

  const patchProperties = useCallback(
    async (options: Inputs) => {
      if (!uuid) {
        return false;
      }

      const action = await dispatch(
        patchAlertProperties({
          uuid,
          source,
          options,
        }),
      );

      if (patchAlertPropertiesSuccess(action)) {
        return true;
      }

      return false;
    },
    [uuid, source, updateGeneral],
  );

  const getMitre = useCallback(() => dispatch(getPanelMitre), []);

  const templates: string[] = useAppSelector(
    (state) => templatesLinks(state).templates,
  );
  const isFetchingTemplates: boolean = useAppSelector((state) =>
    isFetchingTemplatesLinks(state),
  );

  const fetchTemplatesLinks = useCallback(
    (id: string) => dispatch(fetchTemplates(id)),
    [],
  );

  const goToLink = useCallback(
    (id: string, template: string) => dispatch(goTo(id, template)),
    [],
  );

  const getTags = useCallback(
    (search: string, selected: string[]) =>
      dispatch(fetchTags(search, selected)),
    [],
  );

  const getResponseTeams = useCallback(
    (value: string) => dispatch(fetchResponseTeams(value)),
    [],
  );

  useEffect(() => {
    return () => {
      setIsEditing(false);
      dispatch(resetAlertPanel);
    };
  }, []);

  return useMemo(() => {
    if (!data || !data.uuid) {
      return {
        isSeparated: true,
        actions,
        data: null,
        tabsData,
        entity: Entity.ALERTS_SOC_INCIDENT,
        fetchAllPanelData,
        isLoading,
        hasSla: false,
        testId: 'alert-panel',
        locale,
        updateGeneral,
        username,
        userPermissions: permissions,
        patchProperties,
        modals,
        fetchTags: getTags,
        fetchResponseTeams: getResponseTeams,
        platformProps: {
          canShowTemplates: true,
          templates,
          fetchTemplatesLinks,
          isFetchingTemplates,
          goToLink,
        },
        getMitre,
        incKey,
      };
    }

    return {
      actions,
      data,
      tabsData,
      entity: Entity.ALERTS_SOC_INCIDENT,
      fetchAllPanelData,
      isLoading: isComposeLoading,
      locale,
      hasSla: false,
      testId: 'alert-panel',
      updateGeneral,
      username,
      userPermissions: permissions,
      patchProperties,
      modals,
      getMitre,
      fetchTags: getTags,
      fetchResponseTeams: getResponseTeams,
      isSeparated: true,
      platformProps: {
        canShowTemplates: true,
        templates,
        fetchTemplatesLinks,
        isFetchingTemplates,
        goToLink,
      },
      incKey,
    };
  }, [
    actions,
    data,
    tabsData,
    fetchAllPanelData,
    isComposeLoading,
    locale,
    updateGeneral,
    username,
    patchProperties,
    modals,
    getMitre,
    templates,
    fetchTemplatesLinks,
    isFetchingTemplates,
    goToLink,
    getResponseTeams,
    getTags,
    permissions,
    incKey,
  ]);
};
