import React, { FC, useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import styled, { css } from 'styled-components';

import { Flex, IconButton, Text } from 'combinezone/core';
import { AngleLeft, AngleRight } from 'combinezone/icons';
import { CombinezoneTheme } from 'combinezone/theme';

import { TechniqueCellProps } from 'components/RulesMitreWidget/TechniqueTable/components/TechniqueCell/types';
import { TechniqueTypes } from 'components/RulesMitreWidget/enums';
import { getTypeRulesMitre } from 'components/RulesMitreWidget/helpers';
import { ruleListSelectedMitreTechnique } from 'store/reducers';
import { selectTechnique } from 'store/reducers/ruleList/actions';

const TechniqueCount = styled(Flex)`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }: { theme: CombinezoneTheme }) =>
    theme.basis.colors.text.default};
`;

const TechniqueTitle = styled(Text)`
  margin-bottom: 3px;
  vertical-align: top;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }: { theme: CombinezoneTheme }) =>
    theme.basis.colors.text.default};
`;

const TechniqueBottom = styled(Flex)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row;
`;

const CellWrapper = styled.td`
  padding: 0 !important;
`;

const TechniqueCellElemColorBorder = styled(Flex)<{
  $type: string;
}>`
  ${({ $type }) => css`
    min-width: 7px;
    height: 70px;
    border-radius: 3px 0px 0px 3px;
    background-color: ${({ theme }: { theme: CombinezoneTheme }) => {
      switch ($type) {
        case TechniqueTypes.Higher: {
          return theme.basis.colors.tags.sea;
        }
        case TechniqueTypes.High: {
          return theme.basis.colors.tags.kelly;
        }
        case TechniqueTypes.Medium: {
          return theme.basis.colors.tags.lime;
        }
        case TechniqueTypes.Low: {
          return '#bde4af';
        }
        default: {
          return theme.basis.colors.text.placeholder;
        }
      }
    }};
  `}
`;

const TechniqueCellElemWrapper = styled(Flex)<{
  $isParentSelected: boolean;
  $isMouse: boolean;
  $isOpen: boolean;
}>`
  height: 70px;
  overflow: hidden;
  margin: 8px 4px 0 4px;
  ${({ $isMouse, $isOpen, $isParentSelected }) => css`
    border-radius: 3px;
    box-shadow: ${({ theme }: { theme: CombinezoneTheme }) => {
      if ($isMouse) {
        return `0 0 4px 0 ${theme.basis.colors.shadows.modalShadow}`;
      }
      return `none`;
    }};
    border: ${({ theme }: { theme: CombinezoneTheme }) => {
      let border = `1px solid ${theme.basis.colors.borders.divider}`;

      if ($isParentSelected) {
        border = `3px solid ${theme.basis.colors.node.selectHover}`;
      }
      if ($isOpen) {
        border = `3px solid ${theme.basis.colors.node.selectHover}`;
      }
      return border;
    }};
  `}
`;

const TechniqueCellElem = styled(Flex)<{
  $isSelected: boolean;
}>`
  ${({ $isSelected }) => css`
    padding: 8px;
    min-width: 176px;
    min-height: 70px;
    border-radius: 0px 3px 3px 0px;
    cursor: pointer;
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    background-color: ${({ theme }: { theme: CombinezoneTheme }) => {
      if ($isSelected) {
        return theme.basis.colors.node.select;
      }
      return theme.basis.colors.base.block;
    }};
  `}
`;

const TechniqueCellIconButton = styled(IconButton)`
  position: initial;
`;

const TechniqueCell: FC<TechniqueCellProps> = ({
  col,
  isOpen,
  mousedTechniqueId,
  onCloseTechnique,
  onOpenTechnique,
  setMousedTechniqueId,
  str,
  technique,
  testId,
}) => {
  const testIdTechniqueCell = `${testId}-technique-cell`;
  const dispatch = useDispatch();
  const type = getTypeRulesMitre(technique?.count);

  const isSelected =
    useSelector(ruleListSelectedMitreTechnique)?.key === technique?.key;

  const onTechniqueClick = useCallback(() => {
    dispatch(selectTechnique(technique));
  }, [technique, dispatch]);

  const handleOpenTechnique = useCallback(
    (e: React.MouseEvent<HTMLElement>): void => {
      e.stopPropagation();

      if (technique) {
        onOpenTechnique(technique, str, col);
      }
    },
    [onOpenTechnique, technique, str, col, onTechniqueClick],
  );

  const handleCloseTechnique = useCallback(
    (e: React.MouseEvent<HTMLElement>): void => {
      e.stopPropagation();
      onCloseTechnique();
    },
    [onCloseTechnique],
  );

  const onMouseIn = (): void => {
    setMousedTechniqueId(technique?.technique_id ?? null);
  };

  const onMouseOut = (): void => {
    setMousedTechniqueId(null);
  };

  const [isButtonMouse, setIsButtonMouse] = useState(false);

  const icon = useMemo(() => {
    if (isOpen && isButtonMouse) {
      return AngleLeft;
    }
    return AngleRight;
  }, [isOpen, isButtonMouse]);

  const onMouseInButton = (): void => {
    setIsButtonMouse(true);
  };

  const onMouseOutButton = (): void => {
    setIsButtonMouse(false);
  };

  return (
    <CellWrapper>
      {technique?.name && (
        <TechniqueCellElemWrapper
          direction="row"
          $isParentSelected={technique?.isParentSelected}
          $isMouse={mousedTechniqueId === technique?.technique_id}
          $isOpen={isOpen}
        >
          <TechniqueCellElemColorBorder
            $type={type}
            testId={`${testIdTechniqueCell}-color-border`}
          >
            &nbsp;
          </TechniqueCellElemColorBorder>
          <TechniqueCellElem
            testId={`${testIdTechniqueCell}-elem`}
            direction="column"
            justify="space-between"
            onClick={onTechniqueClick}
            onMouseOver={onMouseIn}
            onMouseOut={onMouseOut}
            $isSelected={isSelected}
          >
            <TechniqueCount testId={`${testIdTechniqueCell}-count`}>
              {technique?.count}
            </TechniqueCount>
            <TechniqueBottom testId={`${testIdTechniqueCell}-bottom`}>
              <TechniqueTitle
                isClipped
                numOfLines={2}
                testId={`${testIdTechniqueCell}-title`}
              >
                {technique?.name}
              </TechniqueTitle>
              {!!technique?.sub_techniques && (
                <Flex testId={`${testIdTechniqueCell}-button`}>
                  {!!technique.sub_techniques.length && (
                    <TechniqueCellIconButton
                      testId={`${testIdTechniqueCell}-angle-right`}
                      accent={isOpen ? 'active' : 'default'}
                      onClick={
                        isOpen ? handleCloseTechnique : handleOpenTechnique
                      }
                      icon={icon}
                      onMouseOver={onMouseInButton}
                      onMouseOut={onMouseOutButton}
                      tooltip={I18n.t('incCard.mitre.tooltip.close')}
                      size="sm"
                    />
                  )}
                </Flex>
              )}
            </TechniqueBottom>
          </TechniqueCellElem>
        </TechniqueCellElemWrapper>
      )}
    </CellWrapper>
  );
};

export default TechniqueCell;

TechniqueCell.displayName = 'TechniqueCell';
