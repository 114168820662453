import {
  SENT_BY_EMAIL_SUCCESS,
  SENT_BY_EMAIL_REQUEST,
  SENT_BY_EMAIL_FAIL,
  CLEAR_NOTIFICATION,
} from './actionTypes';

const initialState = {
  sentByEmailSuccess: false,
  sentByEmailFail: false,
  sentByEmailLoading: false,
  sentByEmailNotification: false,
};

export default function sentByEmail(state = initialState, action = {}) {
  switch (action.type) {
    case SENT_BY_EMAIL_SUCCESS: {
      return {
        ...state,
        sentByEmailSuccess: true,
        sentByEmailFail: false,
        sentByEmailNotification: true,
      };
    }

    case SENT_BY_EMAIL_REQUEST: {
      return {
        ...state,
        sentByEmailSuccess: false,
        sentByEmailFail: false,
      };
    }

    case SENT_BY_EMAIL_FAIL: {
      return {
        ...state,
        sentByEmailSuccess: false,
        sentByEmailFail: true,
        sentByEmailNotification: false,
      };
    }

    case CLEAR_NOTIFICATION: {
      return {
        ...state,
        sentByEmailNotification: false,
      };
    }

    default:
      return state;
  }
}
