import { connect } from 'react-redux';

import CreateIncForm from '../components/CreateIncForm';
import {
  createIncModalCreatedIncKey,
  createIncModalSystems,
  isCreateIncModalLoading,
  isCreateIncModalOpen,
} from '../store/reducers';
import {
  openCreateIncModal,
  cancelCreateIncModal,
  sendCreateInc,
  fetchSystems,
  successCreateIncModal,
  addFile,
} from '../store/reducers/createInc/actions';

function mapStateToProps(state, ownProps) {
  return {
    systems: createIncModalSystems(state),
    isOpen: isCreateIncModalOpen(state),
    isLoading: isCreateIncModalLoading(state),
    createdIncKey: createIncModalCreatedIncKey(state),
    fileSendFailMessage: state.createInc.fileSendFailMessage,
    button: ownProps.children,
  };
}

const mapDispatchToProps = {
  onOpen: openCreateIncModal,
  onCancel: cancelCreateIncModal,
  onSubmit: sendCreateInc,
  onSuccess: successCreateIncModal,
  addFile,
  fetchSystems,
};

const CreateIncFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateIncForm);
export default CreateIncFormContainer;
