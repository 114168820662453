import React, { useCallback, useState } from 'react';

import { Button } from '@bizone/ui-bundle/esm/Button';
import { Icon } from '@bizone/ui-bundle/esm/Icon';

import Assessment from '../../../Assessment';
import emitter, {
  submitEvent,
  resetEvent,
} from '../../../Assessment/Assessment.emitter';

import './AssessmentBlock.scss';

function AssessmentBlock({ onSubmit }) {
  const [isLoading, setLoading] = useState(false);
  const [isVisible, setVisible] = useState(false);

  const startSubmitting = useCallback(() => {
    emitter.emit(submitEvent);
  }, []);

  const resetForm = useCallback(() => {
    emitter.emit(resetEvent);
  }, []);

  const onChangeRate = useCallback((newValue) => {
    setVisible(Boolean(newValue));
  }, []);

  const submit = useCallback(
    async (...props) => {
      setLoading(true);
      return onSubmit(...props).then((result) => {
        setLoading(false);
        return result;
      });
    },
    [onSubmit],
  );

  return (
    <div className="Panel-Assessment">
      <Assessment
        onSubmit={submit}
        onChangeRate={onChangeRate}
        itemLayouts={{
          rating: {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
          },
        }}
      />
      {isVisible && (
        <div className="ActionsSection">
          <Button
            cls="CustomIconButton"
            rich
            primary
            loading={isLoading}
            onClick={startSubmitting}
          >
            <Icon glyph="check" size={24} />
          </Button>
          <Button cls="CustomIconButton" onClick={resetForm}>
            <Icon glyph="close" size={24} />
          </Button>
        </div>
      )}
    </div>
  );
}

export default React.memo(AssessmentBlock);
