import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@bizone/ui-bundle/esm/Button';

import PropTypes from 'prop-types';

import './Attachments.scss';

import { API_URL } from '../../../services/api';
import { formatBytes, instantDownloadSize } from '../../../utils/helpers';

export default class Attachments extends React.Component {
  downloadFile = ({ link, name }, event) => {
    event.preventDefault();

    this.props.downloadFile(`${API_URL}/soc${link}/download/`, name);
  };

  render() {
    const { files } = this.props;

    return (
      <div className="Attachments">
        {files.map((attachment, i) => (
          <div key={i}>
            <span className="attachments-file-name">
              {instantDownloadSize() > attachment.size ? (
                <Link to={attachment.link} target="_blank">
                  {attachment.name}
                </Link>
              ) : (
                <Link onClick={(e) => this.downloadFile(attachment, e)}>
                  {attachment.name}
                </Link>
              )}
            </span>
            <span className="attachments-file-size">
              {formatBytes(attachment.size)}
              {i !== files.length - 1 && ','}
            </span>
          </div>
        ))}
      </div>
    );
  }
}

Attachments.propTypes = {
  downloadFile: PropTypes.func,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};

Attachments.defaultProps = {
  files: [],
};
