import React, { FC, useState, memo } from 'react';
import { I18n } from 'react-redux-i18n';

import { IconButton, Dropdown, Node } from 'combinezone/core';
import { Add } from 'combinezone/icons';

import { FilterPanelProps, FiltersSettingsType, ValueType } from '../types';

import Filter from './Filter';

const FilterPanel: FC<FilterPanelProps> = ({
  currentLocale,
  filters,
  isLoading,
  setFilter,
  settings,
}) => {
  const [selected, setSelected] = useState<keyof FiltersSettingsType | ''>('');

  const onCreateFilter = (key: keyof FiltersSettingsType, items: ValueType) => {
    setSelected('');
    if (
      Array.isArray(items)
        ? items.length > 0
        : items !== null && items !== undefined
    ) {
      setFilter(key, items);
    }
  };

  const keys = Object.keys(filters) as (keyof FiltersSettingsType)[];
  const notFiltered = Object.keys(settings).filter(
    (key) => ![...keys, selected].includes(key),
  ) as [keyof FiltersSettingsType];

  return (
    <div className="SearchPanel-Filters">
      {keys.map(
        (key) =>
          settings[key]?.type && (
            <Filter
              key={key}
              type={settings[key].type}
              filter={key}
              settings={settings[key]}
              label={settings[key].label}
              value={filters[key]}
              locale={currentLocale}
              setFilter={setFilter}
            />
          ),
      )}
      {selected !== '' && (
        <Filter
          key={`$$NEW-${selected}`}
          type={settings[selected].type}
          filter={selected}
          label={settings[selected].label}
          settings={settings[selected]}
          value={filters[selected]}
          initIsOpen
          locale={currentLocale}
          setFilter={onCreateFilter}
        />
      )}
      {notFiltered.length && (
        <Dropdown
          content={({ onClose }) => (
            <div className="Dropdown-List">
              {' '}
              {notFiltered.map((key) => (
                <Node
                  testId={`Node_Not_Filtered_${key}`}
                  key={key}
                  onClick={() => {
                    setSelected(key);
                    onClose();
                  }}
                >
                  {settings[key].label}
                </Node>
              ))}
            </div>
          )}
          position="bottom-left"
          returnFocusOnClose={false}
          defaultIsOpen={!keys.length}
        >
          {({ isOpen }) => (
            <IconButton
              className="SearchPanel-Filters-AddButton"
              testId="IconButton_Add_Filter"
              isPressed={isOpen}
              isLoading={isLoading}
              icon={Add}
              tooltip={{
                content: I18n.t('filterPanel.addFilter'),
                position: 'top',
              }}
            />
          )}
        </Dropdown>
      )}
    </div>
  );
};

export default memo(FilterPanel);
