import styled from 'styled-components';

import { ListSection } from '@common/soc-react-kit';
import { ValueWrapper } from '@common/soc-react-kit/src/components/KeyValueRich/KeyValueRichItem';

export const ListSectionWithOverflowValue = styled(ListSection)`
  margin-top: 0 !important;
  ${ValueWrapper} {
    overflow: unset;
  }
`;
