import React, { useState, useCallback, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { IconButton } from '@bizone/ui-bundle/esm/IconButton';

import { bemCls } from '@common/soc-react-kit';

import './EventsPanel.scss';

export default function EventsPanel({
  events,
  isOpen,
  setActiveEvent,
  setIsOpen,
}) {
  const [active, setActive] = useState('');
  const { name: activeEventName } = useParams();

  useEffect(() => {
    if (Array.isArray(events)) {
      const activeEvent =
        events.find(
          ({ name }) => name?.toLowerCase() === activeEventName?.toLowerCase(),
        ) || events[0];
      setActiveEvent(activeEvent);
      setActive(activeEvent?.name);
    }
  }, [events, setActiveEvent, activeEventName]);

  const toggleSection = useCallback(() => {
    setIsOpen((isOpen) => !isOpen);
  }, [setIsOpen]);

  return (
    <div className={bemCls('EventsPanel', { closed: !isOpen })}>
      <div className="EventsPanelHeader">
        <IconButton
          basic
          icon="angle-right"
          cls={bemCls('PanelIcon', { active: isOpen })}
          onClick={toggleSection}
        />
      </div>
      <div className="Events-Items">
        {events.map(({ name }, i) => (
          <Link
            className={bemCls('EventsItem', { active: name === active })}
            to={`/cyberpolygon/event/${name}`}
            key={i}
          >
            {name}
          </Link>
        ))}
      </div>
    </div>
  );
}
