import React, { useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { useColumnTags } from '@soc/alerts/src/components/DataGrid/Column/Tags/useColumnTags';
import { Text, Tooltip } from 'combinezone/core';
import { Datagrid } from 'combinezone/datagrid';
import { SeverityHigh, SeverityLow, SeverityMedium } from 'combinezone/icons';
import moment from 'moment';

import {
  incCardAlertsFetching,
  incCardAlertsHasMore,
  makeIncCardAlertsWithSearchSelector,
} from 'store/reducers';

import {
  fetchIncCardAlertsMore,
  selectAlert,
} from '../../../store/reducers/incCard/actions';

const priorityIcons = {
  High: <SeverityHigh color="#E8594F" />,
  Medium: <SeverityMedium color="#EBAF09" />,
  Low: <SeverityLow color="#57AD37" />,
};

function getRowId(originalRow) {
  return originalRow.uuid;
}

export const AlertTable = ({ incKey }) => {
  const alerts = useSelector(makeIncCardAlertsWithSearchSelector());
  const fetching = useSelector(incCardAlertsFetching);
  const hasMore = useSelector(incCardAlertsHasMore);
  const isSelectionChangedRef = useRef(false);
  const dispatch = useDispatch();
  const selectRow = useCallback(
    (selectedRow) => {
      selectedRow && dispatch(selectAlert(selectedRow.original));
    },
    [dispatch],
  );

  const tagsColumn = useColumnTags({
    testId: 'incident-page-tab-alerts',
  });

  const columns = [
    {
      Header: I18n.t('alertTable.header.priority'),
      accessor: 'severity',
      width: 56,
      HeaderRenderer: ({ column: { Header } }) => (
        <Tooltip content={I18n.t('alertTable.header.priority')} position="top">
          <Text isClipped variant="secondary">
            {Header}
          </Text>
        </Tooltip>
      ),
      CellRenderer: ({ cell: { value } }) => priorityIcons[value],
    },
    {
      Header: I18n.t('alertTable.header.title'),
      accessor: 'title',
      minWidth: 300,
    },
    {
      Header: I18n.t('alertTable.header.affected_assets'),
      accessor: 'affected_assets',
      minWidth: 200,
    },
    {
      Header: I18n.t('alertTable.header.created'),
      accessor: 'created_time',
      minWidth: 200,
      CellRenderer: ({ cell: { value } }) => (
        <Text>{moment(value).format('DD MMMM, HH:mm')}</Text>
      ),
    },
    {
      Header: I18n.t('alertTable.header.updated'),
      accessor: 'updated_time',
      minWidth: 200,
      CellRenderer: ({ cell: { value } }) => (
        <Text>{moment(value).format('DD MMMM, HH:mm')}</Text>
      ),
    },
    tagsColumn,
  ];

  const fetchMore = useCallback(
    () => dispatch(fetchIncCardAlertsMore(incKey)),
    [incKey],
  );

  const infiniteScrollProps = useMemo(
    () => ({
      canFetchMore: !!alerts.length && hasMore,
      offsetItems: 2,
      onBottomReach: fetchMore,
      isFetchingMore: fetching,
    }),
    [fetching, hasMore, fetchMore, alerts.length],
  );

  return (
    <Datagrid
      className="AlertTable"
      getRowId={getRowId}
      columns={columns}
      data={alerts}
      rowSelectMode="single"
      infiniteScrollProps={infiniteScrollProps}
      onSelect={selectRow}
      canResizeColumn
      autoResetSelectedRows={false}
      useControlledState={(state) => {
        if (
          Object.keys(state.selectedRowIds).length === 0 &&
          alerts.length > 0 &&
          !isSelectionChangedRef.current
        ) {
          state.selectedRowIds = { [getRowId(alerts[0])]: true };
          isSelectionChangedRef.current = true;
        }
        return state;
      }}
    />
  );
};

AlertTable.displayName = 'AlertTable';
