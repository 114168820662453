import React, { FC, memo } from 'react';
import { Translate } from 'react-redux-i18n';

import { Flex, Text } from 'combinezone/core/index';

import { LevelsProps } from '../types';

const Levels: FC<LevelsProps> = ({
  confidence,
  iconConfidence,
  iconSeverity,
  isShort,
  severity,
}) => (
  <>
    <div className={`Trust ${isShort ? `Trust-Short` : ''}`}>
      <Text className="Caption">
        <Translate value="rulePage.confidence" />
      </Text>
      <Flex className="Indicator">
        {iconConfidence}
        <Text className="Text">
          <Translate value={`rulePage.priorityTypes.${confidence}`} />
        </Text>
      </Flex>
    </div>
    <div className="Importance">
      <Text className="Caption">
        <Translate value="rulePage.severity" />
      </Text>
      <Flex className="Indicator">
        {iconSeverity}
        <Text className="Text">
          <Translate value={`rulePage.priorityTypes.${severity}`} />
        </Text>
      </Flex>
    </div>
  </>
);

export default memo(Levels);
