import React, { useCallback, useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';

import { Button } from '@bizone/ui-bundle/esm/Button';

import { OverlaySpinner } from 'combinezone/core/index';
import { saveAs } from 'file-saver';

import { API_URL } from '../../services/api';
import { apiFetch } from '../../services/apiFetch';
import { TitleComponent } from '../TitleComponent';

import BrowserPreview from './BrowserPreview';
import ErrorComponent from './ErrorComponent';
import ImagePreview from './ImagePreview';
import LoadingBar from './LoadingBar';
import NoPreview from './NoPreview';
import TextPreview from './TextPreview';

import './FilePreview.scss';

const NOT_FOUND = 'notFound';
const ERROR = 'error';

export function FilePreview({ location }) {
  const [url, setUrl] = useState(null);
  const [file, setFile] = useState(null);
  const [type, setType] = useState(null);
  const [filename, setFilename] = useState(null);
  const [Preview, setPreview] = useState(null);
  const [error, setError] = useState(false);
  const [percent, setPercent] = useState(0);
  const [autoDownload, setAutoDownload] = useState(false);

  const { backgroundDownload, isDownloading, manualDownload } = useDownloader({
    url,
    filename,
  });

  useEffect(() => {
    return () => URL.revokeObjectURL(url);
  }, [url]);

  useEffect(() => {
    if (autoDownload) {
      backgroundDownload();
    }
  }, [autoDownload, backgroundDownload]);

  useEffect(() => {
    if (!!type && !!url && !!file) {
      const [base, more] = type.split('/');

      switch (base) {
        case 'image':
          setPreview(<ImagePreview src={url} />);
          break;

        case 'text':
          setPreview(
            <TextPreview
              {...{ file, backgroundDownload, filename, src: url }}
            />,
          );
          break;

        case 'audio':
        case 'video':
          setPreview(<BrowserPreview src={url} filename={filename} />);
          break;

        case 'application':
          switch (more) {
            case 'pdf':
              setPreview(<BrowserPreview src={url} filename={filename} />);
              break;

            case 'json':
              setPreview(
                <TextPreview
                  {...{ file, backgroundDownload, filename, src: url }}
                />,
              );
              break;

            default:
              setPreview(<NoPreview />);
              backgroundDownload();
          }
          break;

        default:
          setPreview(<NoPreview />);
          backgroundDownload();
      }
    }
  }, [backgroundDownload, file, filename, type, url]);

  useEffect(() => {
    function getError(response) {
      return Promise.reject(response.status < 500 ? NOT_FOUND : ERROR);
    }

    const autoDownloadRegex = /^\/files\/download/;

    if (location.pathname.search(autoDownloadRegex) >= 0) {
      setAutoDownload(true);
    }

    const pathname = location.pathname.replace(autoDownloadRegex, '/files');
    const fileUrl = `${API_URL}/soc${pathname}/`.replace(/\/\/$/, '/');

    let type = '';
    let filename = '';

    if (fileUrl.indexOf('download') !== -1) {
      setError(NOT_FOUND);
    } else {
      apiFetch
        .get(fileUrl)
        .catch((response) => getError(response))
        .then((json) => {
          filename = json.filename || '';
          setFilename(filename);
        })
        .then(async () => {
          const response = await apiFetch.get(`${fileUrl}download/`, {
            fullResponse: true,
            showNotification: false,
          });

          if (response.ok) {
            type = response.headers.get('Content-Type');
            setType(type);

            const reader = response.body.getReader();
            const totalLength = Number(response.headers.get('Content-Length'));
            let receivedLength = 0;

            return new ReadableStream({
              start(controller) {
                return reader.read().then(function process({ done, value }) {
                  if (done) {
                    setPercent(100);
                    controller.close();
                    return;
                  }

                  receivedLength += value.length;
                  setPercent((100 * receivedLength) / totalLength);

                  controller.enqueue(value);
                  return reader.read().then(process);
                });
              },
            });
          }

          return getError(response);
        })
        .then((stream) => new Response(stream))
        .then((response) => response.blob())
        .then((blob) => {
          const file = new File([blob], filename, { type });
          setFile(file);
          setUrl(URL.createObjectURL(file));
        })
        .catch((error) => setError(error));
    }
  }, [location.pathname]);

  return (
    <div className="Files">
      <TitleComponent title={filename || I18n.t(`titles.files`)} />
      {error ? (
        <ErrorComponent error={error} />
      ) : (
        <React.Fragment>
          <div className="Header">
            <div className="Header-Title">{filename}</div>
            <div className="Header-Download">
              <Button
                basic
                onClick={manualDownload}
                leftIcon="file-download"
                loading={isDownloading}
              >
                {I18n.t('files.downloadButton')}
              </Button>
            </div>
          </div>
          <LoadingBar percent={percent} />
          <div className="Preview">{Preview || <OverlaySpinner />}</div>
        </React.Fragment>
      )}
    </div>
  );
}

function useDownloader({ filename, url }) {
  const [inQueue, setInQueue] = useState(false);
  const [isDownloaded, setIsDownloaded] = useState(false);

  const startDownload = useCallback(() => {
    setInQueue(true);
    setIsDownloaded(true);
  }, []);

  const backgroundDownload = useCallback(() => {
    if (!isDownloaded) {
      startDownload();
    }
  }, [isDownloaded, startDownload]);

  useEffect(() => {
    if (inQueue && url) {
      saveAs(url, filename);
      setInQueue(false);
    }
  }, [filename, inQueue, url]);

  return {
    isDownloading: inQueue,
    manualDownload: startDownload,
    backgroundDownload,
  };
}
