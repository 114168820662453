import React, { useCallback, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import { Header } from '@bizone/ui-bundle/esm/Header';

import { Layout } from '@common/soc-react-kit';
import classNames from 'classnames';
import { OverlaySpinner } from 'combinezone/core/index';
import PropTypes from 'prop-types';

import ListHeader from './Header';
import Panel from './Panel';
import './ListPage.scss';

const { Container, Content } = Layout;

const emptyArray = [];
const nullFunc = () => null;

export function ListPage({
  className,
  headerConfig: { renderer: headerRenderer, ...headerConfig },
  incKey,
  listConfig: {
    cardRenderer = nullFunc,
    data = emptyArray,
    fetchMore,
    hasMore,
    isFetching,
    noData,
    renderer: listRenderer,
    select,
    selected,
    uniqueField = 'id',
  },
  panelConfig: { renderer: panelRenderer, ...panelConfig },
}) {
  const [isLoading, setLoading] = useState(false);
  const [listWidth, setListWidth] = useState(0);
  const listCardsRef = useRef(0);

  const loadMore = useCallback(() => {
    if (hasMore) {
      setLoading(true);
      fetchMore(incKey).then(() => setLoading(false));
    }
  }, [hasMore, fetchMore, incKey]);

  const renderCard = useCallback(
    (entity) =>
      cardRenderer({
        entity,
        select,
        listWidth,
        isSelected: selected?.[uniqueField] === entity[uniqueField],
      }),
    [cardRenderer, select, selected, uniqueField, listWidth],
  );

  useEffect(() => {
    const updateSize = () => {
      const element = listCardsRef.current;
      setListWidth(element.offsetWidth);
    };
    updateSize();
    const resizeObserver = new ResizeObserver(updateSize);
    resizeObserver.observe(listCardsRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  return (
    <Container
      panel
      className={`${classNames('ListPage', className || '')} ${className}`}
    >
      <Content className="ListPageContainer">
        {!headerRenderer
          ? headerConfig &&
            Object.keys(headerConfig).length > 0 && (
              <ListHeader {...headerConfig} />
            )
          : headerRenderer()}
        <div
          className={`ListPageBody${
            isFetching ? ' ListPageBody_fetching' : ''
          }`}
        >
          {isFetching && <OverlaySpinner />}
          <div ref={listCardsRef} className="ListPageBody-Cards">
            {!listRenderer ? (
              <>
                <InfiniteScroll
                  pageStart={1}
                  initialLoad={false}
                  loadMore={loadMore}
                  hasMore={isLoading ? false : hasMore}
                  useWindow={false}
                >
                  {data.map(renderCard)}
                </InfiniteScroll>
                {data.length === 0 && !hasMore && (
                  <div className="ListPageBody-NoData">
                    <Header size={24}>{noData}</Header>
                  </div>
                )}
              </>
            ) : (
              listRenderer()
            )}
          </div>
        </div>
      </Content>
      {!panelRenderer
        ? panelConfig && <Panel entity={selected} {...panelConfig} />
        : panelRenderer()}
    </Container>
  );
}

ListPage.Header = ListHeader;
ListPage.Panel = Panel;

ListPage.defaultProps = {
  listConfig: {},
  headerConfig: {},
};

ListPage.propTypes = {
  className: PropTypes.string,
  headerConfig: PropTypes.shape({
    filtersConfig: PropTypes.shape({
      filters: PropTypes.object,
      hasFilters: PropTypes.bool,
      resetFilters: PropTypes.func,
      setFilter: PropTypes.func,
      settings: PropTypes.object,
    }),
    panelConfig: PropTypes.object,
    renderer: PropTypes.func,
    searchConfig: PropTypes.shape({
      hasSearch: PropTypes.bool,
      onChangeSearch: PropTypes.func,
      placeholder: PropTypes.string,
      search: PropTypes.string,
    }),
    sortConfig: PropTypes.shape({
      ordering: PropTypes.object,
      setSort: PropTypes.func,
      settings: PropTypes.object,
    }),
    tools: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  }),
  listConfig: PropTypes.shape({
    cardRenderer: PropTypes.func,
    data: PropTypes.array,
    fetchMore: PropTypes.func,
    hasMore: PropTypes.bool,
    isFetching: PropTypes.bool,
    noData: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    renderer: PropTypes.func,
    select: PropTypes.func,
    selected: PropTypes.object,
    uniqueField: PropTypes.string,
  }),
};

ListPage.displayName = 'ListPage';
