import { connect } from 'react-redux';

import MiniDashboard from '../components/RuleListPage/MiniDashboard';
import { ruleListTotalCount } from '../store/reducers';
import { fetchTotalCount } from '../store/reducers/ruleList/actions';

const mapStateToProps = (state) => ({
  fetching:
    state.ruleList.analytics.fetching || state.ruleList.analytics.fetchingMitre,
  mitreData: state.ruleList.analytics.dataMitre,
  data: state.ruleList.analytics.data,
  total: ruleListTotalCount(state),
});

const mapDispatchToProps = {
  fetchTotalCount,
};

const RuleListContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MiniDashboard);
export default RuleListContainer;
