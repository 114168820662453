export const FETCH_RULE_REQUEST = '@@rulePage/FETCH_RULE_REQUEST';
export const FETCH_RULE_SUCCESS = '@@rulePage/FETCH_RULE_SUCCESS';
export const FETCH_RULE_FAILURE = '@@rulePage/FETCH_RULE_FAILURE';

export const FETCH_INCIDENTS_REQUEST = '@@rulePage/FETCH_INCIDENTS_REQUEST';
export const FETCH_INCIDENTS_SUCCESS = '@@rulePage/FETCH_INCIDENTS_SUCCESS';
export const FETCH_INCIDENTS_FAILURE = '@@rulePage/FETCH_INCIDENTS_FAILURE';

export const FETCH_MORE_INCIDENTS_REQUEST =
  '@@rulePage/FETCH_MORE_INCIDENTS_REQUEST';
export const FETCH_MORE_INCIDENTS_SUCCESS =
  '@@rulePage/FETCH_MORE_INCIDENTS_SUCCESS';
export const FETCH_MORE_INCIDENTS_FAILURE =
  '@@rulePage/FETCH_MORE_INCIDENTS_FAILURE';
