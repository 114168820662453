import React, { useCallback } from 'react';
import { I18n } from 'react-redux-i18n';

import { Header } from '@bizone/ui-bundle/esm/Header';
import { Icon } from '@bizone/ui-bundle/esm/Icon';
import { IconButton } from '@bizone/ui-bundle/esm/IconButton';
import { List } from '@bizone/ui-bundle/esm/List';

import moment from 'moment';

import {
  SHEDULE_NAME,
  SHEDULE_EMAILS,
  SHEDULE_SYSTEMS,
  SHEDULE_CRONTAB,
  SHEDULE_START_TIME,
  SHEDULE_ID,
} from '../../../services/api';
import bizoneNotification from '../../Notification';

import './Card.scss';

const cls = 'SheduleCard';

export const Card = ({
  deleteReport,
  firstFetch,
  getPeriodFromCrontab,
  isSelected,
  openEditReportModal,
  select,
  shedule,
}) => {
  const deleteShedule = useCallback(() => {
    deleteReport(shedule[SHEDULE_ID]).then(() => {
      bizoneNotification.success({
        message: `${I18n.t('createReport.createConfirm.report')} ${
          shedule[SHEDULE_NAME]
        } ${I18n.t('createReport.createConfirm.deleted')}`,
      });
      firstFetch();
    });
  }, [firstFetch, deleteReport, shedule]);

  return (
    <div
      className={`${cls} ${isSelected ? `${cls}_selected` : ''}`}
      onClick={isSelected ? undefined : select}
    >
      <div className="SheduleCard-Header" style={{ position: 'relative' }}>
        <div className="Controls">
          <div className="Controls-Icons">
            {isSelected ? (
              <div>
                <IconButton onClick={deleteShedule} size={24} icon="delete" />
                <IconButton
                  onClick={openEditReportModal}
                  size={24}
                  icon="edit"
                />
              </div>
            ) : (
              <Icon size={24} glyph="dot-list-h" />
            )}
          </div>
        </div>
        <Header size={14}>{shedule[SHEDULE_NAME]}</Header>
      </div>
      <div className="SheduleCard-Body">
        <List
          data={{
            [I18n.t('sheduleCard.periodicity')]: (
              <div className="PeriodField">
                <Icon size={24} glyph="calendar" />
                {I18n.t(
                  `createReport.periodicity.${getPeriodFromCrontab(
                    shedule[SHEDULE_CRONTAB],
                  )}`,
                )}
              </div>
            ),
            [I18n.t('sheduleCard.startTime')]: moment(
              shedule[SHEDULE_START_TIME],
            ).format('LLL'),
          }}
          cls="SheduleCard-FirstList"
          keysWidth={120}
        />
        <List
          data={{
            [I18n.t('sheduleCard.system')]: shedule[SHEDULE_SYSTEMS].join(', '),
            [I18n.t('sheduleCard.recipients')]:
              shedule[SHEDULE_EMAILS].join(', '),
          }}
          cls="SheduleCard-SecondList"
          keysWidth={90}
        />
      </div>
    </div>
  );
};
