import React from 'react';

import { Select } from '@bizone/ui-bundle/esm/Select';

export function CustomSelect({ options, value, ...rest }) {
  const newVal = value.filter((val) =>
    options.find((opt) => opt.value === val),
  );
  return <Select value={newVal} options={options} {...rest} />;
}
