import React, { Component } from 'react';
import { createPortal } from 'react-dom';

import { Actions } from '@bizone/ui-bundle/esm/Actions';
import { Header } from '@bizone/ui-bundle/esm/Header';
import { Icon as BIcon } from '@bizone/ui-bundle/esm/Icon';

import { OverlaySpinner } from 'combinezone/core/index';

import AmChartContainer from '../../containers/AmChartContainer';

import './Widget.scss';

const icons = {
  pie: 'chart-pie',
  bar: 'chart-bar',
  horizontalBar: 'chart-bar-invert',
  line: 'chart-bullet-line',
  gauge: 'gauge',
  eps: 'gauge',
};

export default class Widget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false,
    };

    this.parent = document.createElement('div');
  }

  toggleExpand = () => {
    const { isExpanded } = this.state;

    if (isExpanded) {
      document.body.removeChild(this.parent);
    } else {
      document.body.appendChild(this.parent);
    }

    this.setState({
      isExpanded: !isExpanded,
    });
  };

  render() {
    const {
      deleteWidget,
      id,
      isEmpty,
      isFetching = false,
      setNewForm,
      title,
      type,
      widgetSettings,
      widgetTypes,
    } = this.props;
    const { isExpanded } = this.state;

    const actionsTypes = [];
    if (widgetTypes.length > 1) {
      widgetTypes.forEach((settings) => {
        actionsTypes.push({
          icon: icons[settings.type],
          action: () => setNewForm(id, settings),
          active: settings.type === type,
          basic: settings.type !== type,
        });
      });
    }

    const body = (
      <div
        className={`box box-info dashboard-box-flex box-layout-edit${
          isExpanded ? ' box_fullScreen' : ''
        }`}
      >
        <div className="box-header with-border">
          <h4 className="box-title">
            <BIcon glyph="drag" size={24} />
            <Header size={16}>{title}</Header>
          </h4>
          <div className="box-tools">
            <Actions
              opened
              stack={[
                {
                  icon: isExpanded ? 'minimize' : 'maximize',
                  action: this.toggleExpand,
                },
                ...actionsTypes,
              ]}
            />
          </div>
        </div>
        <div className="box-body">
          {isFetching && <OverlaySpinner />}
          <AmChartContainer {...{ id, type, widgetSettings }} />
        </div>
      </div>
    );

    return isExpanded ? createPortal(body, this.parent) : body;
  }
}
