import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import { Button } from '@bizone/ui-bundle/esm/Button';
import { FileUploader } from '@bizone/ui-bundle/esm/FileUploader';
import { Form } from '@bizone/ui-bundle/esm/Form';
import { Header } from '@bizone/ui-bundle/esm/Header';
import { Icon } from '@bizone/ui-bundle/esm/Icon';
import { Input } from '@bizone/ui-bundle/esm/Input';
import { Message } from '@bizone/ui-bundle/esm/Message';
import { Select } from '@bizone/ui-bundle/esm/Select';
import { Text } from '@bizone/ui-bundle/esm/Text';
import { Textarea } from '@bizone/ui-bundle/esm/Textarea';

import { FocusButton, getFormErrors } from '@common/soc-react-kit';
import { Modal, notification } from 'antd';
import moment from 'moment';
import shallowEqual from 'shallowequal';

import { HAS_REQUEST_CATEGORIES } from 'services/api';

import incidentMessagesService from '../../services/incidentMessagesService';
import { arrayToOptions } from '../../utils/helpers';
import { CustomSelect } from '../CustomSelect';
import bizoneNotification from '../Notification';

const requestOptions = HAS_REQUEST_CATEGORIES
  ? [
      'Rule Development',
      'Portal Problems',
      'Event Source Question',
      'Use Case Development',
      'Question',
      'Other',
    ]
  : [
      'Вопрос по агентам BI.ZONE EDR',
      'Вопрос по источникам событий',
      'Вопрос по работоспособности инсталляции',
      'Добавление исключений в рамках выявленных инцидентов ИБ',
      'Добавление контактов в группу рассылок',
      'Другое',
      'Запрос дополнительной информации по инциденту / правилу',
      'Корректировка существующего правила корреляции выявления инцидентов ИБ',
      'Подключение новых источников событий к мониторингу',
      'Предложение по изменениям/добавлению функционала',
      'Предоставление / блокировка доступа к SOC Portal',
      'Предоставление / блокировка доступа к серверу управления агентами BI.ZONE EDR',
      'Предоставление выгрузки по запросу клиента',
      'Создание нового правила корреляции по запросу заказчика',
      'Удаление агентов с сервера управления BI.ZONE EDR',
    ];

export default class CreateIncForm extends React.Component {
  state = {
    errors: {},
  };

  componentDidUpdate(prevProps) {
    const { fileSendFailMessage: message } = this.props;
    const { fileSendFailMessage: prevMessage } = prevProps;

    if (message && message !== prevMessage) {
      notification.warning({ message, duration: 0 });
    }
  }

  onError = (result) => {
    const form = this.formRef;
    const errors = result.payload.response;

    if (errors && Object.keys(errors).length) {
      const [fields, nonFields] = getFormErrors(errors);

      if (fields) {
        if (fields.attachments) {
          notification.error({
            duration: 0,
            message: I18n.t('createInc.attachmentsError'),
            description: fields.attachments.map((text, i) => (
              <Text key={i} size={12} paragraph>
                {text}
              </Text>
            )),
          });
        }

        this.setState(
          {
            errors: Object.keys(fields).reduce((acc, item) => {
              acc[item] = [...(form.errors[item] || []), ...fields[item]]
                .filter(Boolean)
                .map((text, i) => (
                  <Text key={i} size={12} paragraph>
                    {text}
                  </Text>
                ));
              return acc;
            }, {}),
          },
          () => form.handleSubmit(),
        );
      }
    }
  };

  onOk = () => {
    const { createdIncKey, onSuccess } = this.props;

    if (createdIncKey) {
      this.formRef.form.reset();
      this.clearFileUploader();
      incidentMessagesService.pushMutedIncident(createdIncKey);
      bizoneNotification.success({
        message: (
          <Translate value="createInc.successMessage" incKey={createdIncKey} />
        ),
      });
      onSuccess();
    }
  };

  handleCreate = () => {
    const { attachments, category, ...rest } = this.formRef.values;
    const [primaryCategory, ...secondaryCategory] = category;
    const data = {
      ...rest,
      primary_category: primaryCategory,
      secondary_category: secondaryCategory,
    };

    const file = attachments && attachments.file;
    this.props
      .onSubmit(data)
      .then((result) => {
        if (file) {
          return this.props.addFile(this.props.createdIncKey, file);
        }
        if (result.error) {
          this.onError(result);
        } else {
          this.onOk();
        }
      })
      .then((result) => {
        result && result.error && this.onError(result);
        this.onOk();
      });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  saveClearFileUploader = (clearFileUploader) => {
    this.clearFileUploader = clearFileUploader;
  };

  fakeHandleSubmit = () => {
    return this.state.errors;
  };

  isEqual = (a, b) => {
    if (a === b) {
      return true;
    }
    return (
      a.priority === b.priority &&
      shallowEqual(a.category, b.category) &&
      shallowEqual(a['system/service'], b['system/service'])
    );
  };

  onModalCancel = () => {
    this.props.onCancel();
    this.formRef.form.reset();
  };

  // TODO: оптимизировать рендер
  render() {
    const { isLoading, isOpen, systems } = this.props;

    const priorityOptions = [
      { value: 'Low', content: I18n.t('createInc.priorityTypes.low') },
      { value: 'Medium', content: I18n.t('createInc.priorityTypes.medium') },
      { value: 'High', content: I18n.t('createInc.priorityTypes.high') },
    ];

    const issueTypeOptions = [
      { value: 'Incident', content: 'Incident' },
      { value: 'Request', content: 'Request' },
      { value: 'Remediation', content: 'Remediation' },
      { value: 'Failure', content: 'Failure' },
    ];

    const categoryOptions = {
      Incident: arrayToOptions([
        'DoS/DDoS',
        'Unauthorized Access/Compromised',
        'Scans/Probes/Attempted Access',
        'Brute Force',
        'Vulnerability',
        'Defacement/Data Manipulation',
        'Data Leak',
        'Malware',
        'Malware C&C Communication',
        'Malicious Host',
        'Phishing',
        'Spam',
        'Fraud',
        'Prohibited Content',
        'Social Engineering',
        'Improper Usage',
        'Traffic Hijacking',
        'Misconfiguration',
        'Suspicious Process Activity',
      ]),
      Request: arrayToOptions(requestOptions),
    };

    const infoSystems = systems.map((system) => ({
      value: system.id,
      content: system.is_id,
    }));

    const gossopkaDelay = systems.reduce((res, system) => {
      if (system.gossopka_send) {
        res[system.id] = system.gossopka_delay;
      }
      return res;
    }, {});

    const initialValues = {
      issuetype: issueTypeOptions[0].value,
      priority: priorityOptions[0].value,
      category: [],
      system: infoSystems.length ? infoSystems[0].value : [],
    };

    return (
      <Form
        initialValues={initialValues}
        initialValuesEqual={this.isEqual}
        onSubmit={this.fakeHandleSubmit}
        cls="createInc"
      >
        {(form) => {
          this.saveFormRef(form);
          const {
            hasSubmitErrors,
            hasValidationErrors,
            submitErrors,
            validating,
            values,
          } = form;

          const isInvalid =
            hasValidationErrors ||
            (hasSubmitErrors &&
              !Object.keys(submitErrors).every(
                (key) => form.form.getFieldState(key)?.dirtySinceLastSubmit,
              ));
          return (
            <React.Fragment>
              {React.cloneElement(this.props.button, {
                onClick: this.props.onOpen,
              })}
              <Modal
                title={<Header size={20}>{I18n.t('createInc.title')}</Header>}
                visible={isOpen}
                onCancel={this.onModalCancel}
                className="CreateIncModal"
                closeIcon={
                  <div className="Modal-CloseIcon">
                    <Icon glyph="close" size={24} />
                  </div>
                }
                footer={[
                  <FocusButton
                    key="submit"
                    rich
                    primary
                    disabled={validating || isInvalid}
                    loading={isLoading}
                    onClick={this.handleCreate}
                  >
                    <Translate value="createInc.submit" />
                  </FocusButton>,
                  <Button key="back" onClick={this.onModalCancel}>
                    <Translate value="createInc.cancel" />
                  </Button>,
                ]}
                width={720}
                bodyStyle={{
                  padding: '32px 56px 45px 56px',
                }}
              >
                <React.Fragment>
                  <Form.Item
                    label={I18n.t('createInc.issueType.label')}
                    name="issuetype"
                  >
                    <Select disabled={isLoading} options={issueTypeOptions} />
                  </Form.Item>
                  <Form.Item
                    required
                    requiredTitle={I18n.t('createInc.summaryRequired')}
                    label={I18n.t('createInc.summary')}
                    name="summary"
                  >
                    <Input disabled={isLoading} />
                  </Form.Item>
                  <Form.Item
                    required
                    requiredTitle={I18n.t('createInc.descriptionRequired')}
                    label={I18n.t('createInc.description')}
                    name="description"
                  >
                    <Textarea disabled={isLoading} />
                  </Form.Item>
                  <Form.Item
                    required
                    label={I18n.t('createInc.systemService')}
                    name="system"
                  >
                    <Select disabled={isLoading} options={infoSystems} />
                  </Form.Item>
                  {gossopkaDelay[form.values.system[0]] && (
                    <div className="Form-Field">
                      <Message icon="warning" warning>
                        {I18n.t('createInc.NCCCIWarning', {
                          duration: moment
                            .duration(
                              gossopkaDelay[form.values.system[0]],
                              'seconds',
                            )
                            .humanize(true),
                        })}
                      </Message>
                    </div>
                  )}
                  <Form.Item
                    required
                    requiredTitle={I18n.t('createInc.category.isRequired')}
                    label={I18n.t('createInc.category.label')}
                    name="category"
                    validate={(value) =>
                      value.length > 0
                        ? undefined
                        : I18n.t('createInc.category.isRequired')
                    }
                  >
                    <CustomSelect
                      multiple
                      disabled={isLoading}
                      options={categoryOptions[values.issuetype]}
                      placeholder={I18n.t('createInc.category.placeholder')}
                    />
                  </Form.Item>
                  <Form.Item
                    label={I18n.t('createInc.priority')}
                    name="priority"
                  >
                    <Select disabled={isLoading} options={priorityOptions} />
                  </Form.Item>
                  <Form.Item
                    label={I18n.t('createInc.attachments.label')}
                    name="attachments"
                  >
                    <FileUploader
                      disabled={isLoading}
                      hint={I18n.t('createInc.attachments.hint')}
                      apiRef={({ clear }) => this.saveClearFileUploader(clear)}
                    >
                      {I18n.t('createInc.attachments.button')}
                    </FileUploader>
                  </Form.Item>
                </React.Fragment>
              </Modal>
            </React.Fragment>
          );
        }}
      </Form>
    );
  }
}
