import { createAction } from 'redux-api-middleware';

import * as types from './actionTypes';

export const fetchAlert = (dispatch, getState) => {
  return dispatch(
    createAction({
      endpoint: getState().incidentPage.alerts.selected.data._source,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      types: [
        types.FETCH_ALERT_REQUEST,
        types.FETCH_ALERT_SUCCESS,
        types.FETCH_ALERT_FAIL,
      ],
      isAbortOldRequests: true,
    }),
  );
};
