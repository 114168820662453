import React from 'react';

import styled from 'styled-components';

import { Block, Flex } from 'combinezone/core';
import { BlockHeader } from 'combinezone/core/Block/Block';

export const Content = styled(Block).attrs(() => ({
  isFullHeight: true,
  isHeaderSeparated: true,
  isCollapsible: false,
  isExpandable: false,
}))`
  ${BlockHeader} {
    padding: 0 24px;
  }
  margin-bottom: 16px;
`;

export const TabsWrapper = styled(Flex).attrs(() => ({
  fillWidth: true,
  justify: 'flex-start',
}))`
  border-bottom: 1px solid ${({ theme }) => theme.basis.colors.borders.normal};
`;

export const ContentWrapper = styled(Flex).attrs(() => ({
  fillHeight: true,
  direction: 'column',
}))`
  width: 100%;
`;

export const TabContainer = styled(Flex).attrs(() => ({}))`
  margin: 0 16px 16px;
`;

export const Layout = styled(Flex).attrs(() => ({
  fillWidth: true,
  fillHeight: true,
  justify: 'space-between',
}))`
  align-self: center;
  width: 100%;
`;
