import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import styled from 'styled-components';

import { IconButton } from 'combinezone/core';
import { Moon, Sun } from 'combinezone/icons';

import { getTheme } from 'store/reducers';
import { setTheme } from 'store/reducers/theme/actions';

const Button = styled(IconButton)`
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: none;

    svg {
      opacity: 1;
    }
  }

  svg {
    color: var(--basis-colors-text-light) !important;
    opacity: 0.8;
  }
`;

export default function ThemeSwitcher() {
  const dispatch = useDispatch();
  const themeType = useSelector(getTheme);
  const Icon = themeType === 'light' ? Moon : Sun;

  const switchTheme = () =>
    dispatch(setTheme(themeType === 'light' ? 'dark' : 'light'));

  return (
    <Button
      onClick={switchTheme}
      tooltip={I18n.t('theme.switch')}
      testId="Theme-Switcher"
      icon={Icon}
    />
  );
}

ThemeSwitcher.displayName = 'ThemeSwitcher';
