export const FIRST_FETCH_REQUEST = '@@incidentList/FIRST_FETCH_REQUEST';
export const FIRST_FETCH_SUCCESS = '@@incidentList/FIRST_FETCH_SUCCESS';
export const FIRST_FETCH_FAIL = '@@incidentList/FIRST_FETCH_FAIL';

export const FETCH_MORE_REQUEST = '@@incidentList/FETCH_MORE_REQUEST';
export const FETCH_MORE_SUCCESS = '@@incidentList/FETCH_MORE_SUCCESS';
export const FETCH_MORE_FAIL = '@@incidentList/FETCH_MORE_FAIL';

export const SELECT_INC = '@@incidentList/SELECT_INC';

export const ADD_FEEDBACK_REQUEST = '@@incidentList/ADD_FEEDBACK_REQUEST';
export const ADD_FEEDBACK_SUCCESS = '@@incidentList/ADD_FEEDBACK_SUCCESS';
export const ADD_FEEDBACK_FAILURE = '@@incidentList/ADD_FEEDBACK_FAILURE';
