import { connect } from 'react-redux';

import { ResponseInstructionPage } from '../components/ResponseInstructionPage';
import { currentLocale } from '../store/reducers';
import { downloadFile } from '../store/reducers/fileDownloader/actions';
import {
  fetchInstructionCard,
  closeInstructionCard,
  submitComment,
  finalizeCommentSuccess,
  fetchComments,
} from '../store/reducers/responseInstructionCard/actions';

const mapStateToProps = (state) => ({
  instruction: state.responseInstructionCard.instruction,
  fetchError: state.responseInstructionCard.error,
  addComment: state.responseInstructionCard.addComment,
  comments: state.responseInstructionCard.comments,
  locale: currentLocale(state),
});

const mapDispatchToProps = {
  submitComment,
  closeInstructionCard,
  finalizeCommentSuccess,
  fetchComments,
  fetchInstructionCard,
  downloadFile,
};

const ResponseInstructionPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResponseInstructionPage);
export default ResponseInstructionPageContainer;
