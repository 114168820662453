import React from 'react';
import { Redirect } from 'react-router-dom';

import PropTypes from 'prop-types';

import { CustomRoute } from '../CustomRoute';

const ProtectedRoute = ({ checkPermission, redirectTo, ...rest }) =>
  checkPermission() ? <CustomRoute {...rest} /> : <Redirect to={redirectTo} />;

const createProtectedRoute =
  ({ checkPermission, redirectTo }) =>
  (props = {}) =>
    (
      <ProtectedRoute
        checkPermission={checkPermission}
        redirectTo={redirectTo}
        {...props}
      />
    );

ProtectedRoute.propTypes = {
  checkPermission: PropTypes.func.isRequired,
  component: PropTypes.elementType,
  pageName: PropTypes.string,
  redirectTo: PropTypes.string.isRequired,
};

export { ProtectedRoute, createProtectedRoute };
