import React, { FC, memo } from 'react';
import { Translate } from 'react-redux-i18n';

import { Flex, Tag, Text } from 'combinezone/core/index';

import { AvailabilityProps } from '../types';

const Availability: FC<AvailabilityProps> = ({ available, id }) => (
  <>
    <Text className="Caption">
      <Translate value="rulePage.availableForServices" />
    </Text>
    <Flex className="Availables-Items">
      {available.map((text) => (
        <Tag
          key={`${id}_${text}`}
          testId={`available_for_services_${text}`}
          accent="transparent"
          className="Badge Badge-Border"
        >
          {text}
        </Tag>
      ))}
    </Flex>
  </>
);

export default memo(Availability);
