import { RSAA } from 'redux-api-middleware';

import { withAuth } from '..';
import { API_URL } from '../../../services/api';

import * as types from './actionTypes';

export const changePassword = (oldPassword, newPassword1, newPassword2) => ({
  [RSAA]: {
    endpoint: `${API_URL}/auth/password_change/`,
    method: 'PUT',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      old_password: oldPassword,
      password: newPassword1,
      confirmed_password: newPassword2,
    }),
    types: [
      types.CHANGE_PASSWORD_REQUEST,
      types.CHANGE_PASSWORD_SUCCESS,
      types.CHANGE_PASSWORD_FAILURE,
    ],
  },
});

export const onSuccess = () => ({
  type: types.ON_SUCCESS,
});
