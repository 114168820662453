import React, { useCallback, useEffect, useState } from "react";

import { abortApiFetch, isAbortError, useApiToastError } from "@soc/kit/api";

import { abortControllerKey, fetchSensors } from "../actions/fetchSensors";
import { SensorGroupType } from "../types";

type UseSensorsType = (id?: string) => {
  data: SensorGroupType[];
  isFetching: boolean;
};

export const useSensors: UseSensorsType = (id) => {
  const apiToastError = useApiToastError();
  const [data, setData] = useState<SensorGroupType[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const getSensors = useCallback((id: string) => {
    setIsFetching(true);
    fetchSensors(id)
      .then((response) => {
        setData(response);
        setIsFetching(false);
      })
      .catch((error) => {
        apiToastError(error);
        setData([]);
        if (isAbortError(error)) {
          return;
        }
        setIsFetching(false);
      });
  }, []);

  useEffect(() => {
    if (id) {
      getSensors(id);
    }
    return () => {
      abortApiFetch(abortControllerKey);
    };
  }, [id]);

  return {
    data,
    isFetching,
  };
};
