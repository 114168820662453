export const USER_LOADING = '@@auth/USER_LOADING';
export const USER_LOADED = '@@auth/USER_LOADED';
export const USER_FAILURE = '@@auth/USER_FAILURE';

export const LOGIN_REQUEST = '@@auth/LOGIN_REQUEST';
export const LOGIN_SUCCESSFUL = '@@auth/LOGIN_SUCCESSFUL';
export const LOGIN_FAILED = '@@auth/LOGIN_FAILED';

export const LOGOUT = '@@auth/LOGOUT';

export const TOKEN_REQUEST = '@@auth/TOKEN_REQUEST';
export const TOKEN_RECEIVED = '@@auth/TOKEN_RECEIVED';
export const TOKEN_FAILURE = '@@auth/TOKEN_FAILURE';

export const SSO_TOKEN_RECEIVED = '@@auth/SSO_TOKEN_RECEIVED';

export const MOUNTED = '@@auth/MOUNTED';

export const CHECK_SMS_REQUEST = '@@auth/CHECK_SMS_REQUEST';
export const CHECK_SMS_SUCCESS = '@@auth/CHECK_SMS_SUCCESS';
export const CHECK_SMS_FAILED = '@@auth/CHECK_SMS_FAILED';

export const SEND_SMS_REQUEST = '@@auth/SEND_SMS_REQUEST';
export const SEND_SMS_SUCCESS = '@@auth/SEND_SMS_SUCCESS';
export const SEND_SMS_FAILED = '@@auth/SEND_SMS_FAILED';

export const RESET_MFA = '@@auth/RESET_MFA';

export const CHANGE_OIDC_USER_MANAGER = '@@auth/CHANGE_OIDC_USER_MANAGER';

export const OIDC_USER_LOADING = '@@auth/OIDC_USER_LOADING';
export const OIDC_USER_LOADED = '@@auth/OIDC_USER_LOADED';
export const OIDC_USER_FAILURE = '@@auth/OIDC_USER_FAILURE';

