import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import styled from 'styled-components';

import { Tooltip, Button } from 'combinezone/core';
import { Language } from 'combinezone/icons';

import { LocalesEnum } from 'shared/enums/locales.enum';
import { currentLocale } from 'store/reducers';
import { setLocale } from 'utils/i18n';

const ButtonCustom = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;

  span {
    color: var(--basis-colors-text-light) !important;
    font-family: var(--basis-typography-fonts-accent);
    opacity: 0.8;
  }

  &:hover {
    background: none;
  }

  svg {
    color: var(--basis-colors-text-light) !important;
    opacity: 0.8;
  }
`;

const TranslateSwitcher: FC = () => {
  const dispatch = useDispatch();
  const language: LocalesEnum = useSelector(currentLocale);

  const switchLocale = (): void => {
    dispatch(
      setLocale(language === LocalesEnum.Ru ? LocalesEnum.En : LocalesEnum.Ru),
    );
  };

  return (
    <Tooltip content={I18n.t('main.switchLanguage')}>
      <ButtonCustom
        onClick={switchLocale}
        testId="Translate-Switcher"
        LeftIcon={Language}
        variant="transparent"
      >
        {language?.toUpperCase()}
      </ButtonCustom>
    </Tooltip>
  );
};

export default TranslateSwitcher;

TranslateSwitcher.displayName = 'TranslateSwitcher';
