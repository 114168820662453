export const CREATE_INC_MODAL_OPEN = '@@createInc/CREATE_INC_MODAL_OPEN';
export const CREATE_INC_MODAL_CANCEL = '@@createInc/CREATE_INC_MODAL_CANCEL';
export const CREATE_INC_MODAL_SUCCESS = '@@createInc/CREATE_INC_MODAL_SUCCESS';
export const CREATE_REQUEST = '@@createInc/SEND_REQUEST';
export const CREATE_SUCCESS = '@@createInc/SEND_SUCCESS';
export const CREATE_FAIL = '@@createInc/SEND_FAIL';
export const FETCH_SYSTEMS_REQUEST = '@@createInc/FETCH_SYSTEMS_REQUEST';
export const FETCH_SYSTEMS_SUCCESS = '@@createInc/FETCH_SYSTEMS_SUCCESS';
export const FETCH_SYSTEMS_FAIL = '@@createInc/FETCH_SYSTEMS_FAIL';
export const ADD_FILE_REQUEST = '@@createInc/ADD_FILE_REQUEST';
export const ADD_FILE_SUCCESS = '@@createInc/ADD_FILE_SUCCESS';
export const ADD_FILE_FAIL = '@@createInc/ADD_FILE_FAIL';
