import React, { CSSProperties } from 'react';
import { I18n } from 'react-redux-i18n';

import styled, { css } from 'styled-components';

import { XYChart } from '@amcharts/amcharts4/charts';
import { Flex } from 'combinezone/core';
import ReactDOMServer from 'react-dom/server';

import { TooltipHTMLRange } from 'components/EpsWidget/helpers/types';

const DivStyled = styled.div<{
  alignSelf?: CSSProperties['alignSelf'];
  gap?: CSSProperties['gap'];
  direction?: CSSProperties['flexDirection'];
}>`
  ${({ alignSelf, direction, gap }) => css`
    display: flex;
    align-self: ${alignSelf};
    flex-direction: ${direction};
    gap: ${gap ?? ''};
  `}
`;

const GrayText = styled.p`
  color: #999999;
`;

const Tr = styled.tr<{ color?: string }>`
  color: ${(props) => props.color || '#999999'};
`;

const Td = styled.td<{ align?: 'left' | 'right' }>`
  padding-top: 10px;
  text-align: ${(props) => props.align || 'left'};
`;

const TooltipContainer = styled(Flex)`
  padding: 10px;
`;

const calendar = (
  <svg xmlns="http://www.w3.org/2000/svg" fill="#999999" width="21" height="21">
    <path d="M17 5V3h-2v2H9V3H7v2H5c-1.11 0-2 .9-2 2v12c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2h-2ZM5 19V9h14v10H5Zm4-8H7v2h2v-2Zm8 0h-2v2h2v-2Zm-4 0h-2v2h2v-2Z" />
  </svg>
);

export const getTooltipHTML = (
  chart: XYChart,
  range: TooltipHTMLRange,
): string => {
  const date = range.date.toLocaleString().slice(0, -3);
  const endDate = range.endDate.toLocaleString().slice(0, -3);
  const { excessCount, excessDuration, maxValue } = range;
  const nums = ['01', '02', '03', '04', '05', '06', '07', '08', '09'];

  chart.durationFormatter.baseUnit = 'millisecond';
  chart.dateFormatter.dateFormat = 'dd.MM.yyyy HH:mm';
  chart.durationFormatter.durationFormats = {
    millisecond: {
      millisecond: `S'${I18n.t('widgets.epsWidget.tooltip.duration.S')}'`,
      second: `ss'${I18n.t('widgets.epsWidget.tooltip.duration.s')}'`,
      minute: `mm'${I18n.t(
        'widgets.epsWidget.tooltip.duration.m',
      )}' ss'${I18n.t('widgets.epsWidget.tooltip.duration.s')}'`,
      hour: `hh'${I18n.t('widgets.epsWidget.tooltip.duration.h')}' mm'${I18n.t(
        'widgets.epsWidget.tooltip.duration.m',
      )}'`,
      day: `d'${I18n.t('widgets.epsWidget.tooltip.duration.d')}' hh'${I18n.t(
        'widgets.epsWidget.tooltip.duration.h',
      )}'`,
      week: `w'${I18n.t('widgets.epsWidget.tooltip.duration.w')}' d'${I18n.t(
        'widgets.epsWidget.tooltip.duration.d',
      )}'`,
      month: `M'${I18n.t('widgets.epsWidget.tooltip.duration.M')}' w'${I18n.t(
        'widgets.epsWidget.tooltip.duration.w',
      )}'`,
      year: `y'${I18n.t('widgets.epsWidget.tooltip.duration.y')}' M'${I18n.t(
        'widgets.epsWidget.tooltip.duration.M',
      )}'`,
    },
  };
  chart.numberFormatter.numberFormat = { thousandsSeparator: ' ' };

  const excessDurationFormatted = nums.includes(
    chart.durationFormatter.format(excessDuration).substring(0, 2),
  )
    ? chart.durationFormatter.format(excessDuration).slice(1)
    : chart.durationFormatter.format(excessDuration);

  const element = (
    <DivStyled direction="column">
      <DivStyled gap="5px" alignSelf="center">
        {calendar}
        <GrayText>{date}</GrayText>
        <GrayText>&mdash;</GrayText>
        {calendar}
        <GrayText>{endDate}</GrayText>
      </DivStyled>
      <table>
        <Tr color="#ffffff">
          <Td>{I18n.t('widgets.epsWidget.tooltip.excessInfo')}:</Td>
          <Td />
        </Tr>
        <Tr>
          <Td>{I18n.t('widgets.epsWidget.tooltip.excessDuration')}:</Td>
          <Td align="right">{excessDurationFormatted}</Td>
        </Tr>
        <Tr>
          <Td>{I18n.t('widgets.epsWidget.tooltip.maxValue')}:</Td>
          <Td align="right">{chart.numberFormatter.format(maxValue)}</Td>
        </Tr>
        <Tr color="#ffffff">
          <Td>{I18n.t('widgets.epsWidget.tooltip.excessesPerPeriod')}:</Td>
          <Td align="right">{excessCount}</Td>
        </Tr>
      </table>
    </DivStyled>
  );

  return ReactDOMServer.renderToString(element);
};

export const getColumnTooltipHTML = (): string => {
  const element = (
    <DivStyled direction="column">
      <DivStyled gap="5px" alignSelf="flex-start">
        {calendar}
        <GrayText> {'{date.formatDate("dd.MM.yyyy HH:mm")}'}</GrayText>
      </DivStyled>
      <table>
        <Tr color="#ffffff">
          <Td>{I18n.t('widgets.epsWidget.tooltip.averageEps')}:</Td>
          <Td align="right">{'{value.formatNumber("#.")}'}</Td>
        </Tr>
        <Tr color="#ffffff">
          <Td>{I18n.t('widgets.epsWidget.tooltip.excessesPerPeriod')}:</Td>
          <Td align="right">0</Td>
        </Tr>
      </table>
    </DivStyled>
  );

  return ReactDOMServer.renderToString(element);
};
