import * as React from 'react';
import { Link } from 'react-scroll';

import { Node } from '@bizone/ui-bundle/esm/Node';

import './Anchors.scss';

export default class Anchors extends React.PureComponent {
  state = {
    activeId: null,
  };

  setActive = (id) => {
    this.setState({
      activeId: id,
    });
  };

  render() {
    const { anchors, containerId } = this.props;
    const { activeId } = this.state;

    return (
      <div className="Anchors">
        {anchors.map((anchor) => (
          <Link
            className={anchor.id === activeId ? 'current' : ''}
            key={`${containerId}-${anchor.id}`}
            containerId={containerId}
            to={anchor.id}
            offset={-16}
            isDynamic
            spy
            smooth
            duration={300}
            onSetActive={this.setActive}
          >
            <Node>{anchor.title}</Node>
          </Link>
        ))}
      </div>
    );
  }
}
